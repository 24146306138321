<script setup>
const types = {
  success: {
    type: 'is-success',
    text: 'Success'
  },
  pending: {
    type: 'is-info',
    text: 'Pending'
  },
  failed: {
    type: 'is-danger',
    text: 'Failed'
  }
};
</script>
<template>
  <b-tooltip
    v-if="params.isSubStatus && params.getDescription(params).description"
    position="is-left"
    type="is-light"
    multilined
    append-to-body
  >
    <b-tag
      :type="types[params.value]?.type || 'is-light'"
    >
      {{ types[params.value]?.text || params.value }}
    </b-tag>
    <template #content>
      <p class="tooltip-title">{{ params.getDescription(params).description }}</p>
      <p
        v-if="params.getDescription(params).error"
        class="tooltip-text"
      >
        {{ params.getDescription(params).error.description }}
      </p>
    </template>
  </b-tooltip>

  <b-tag
    v-else
    :type="types[params.value]?.type || 'is-light'"
  >
    {{ types[params.value]?.text || params.value }}
  </b-tag>
</template>
<style scoped lang="postcss">
.tooltip-title:not(:last-child) {
  font-weight: bold;
}
</style>
