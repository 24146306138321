<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { storeToRefs } from 'pinia';
import { useSidebarStore } from '@/components/Sidebar/stores/sidebar-store';
import { useLibraryStore } from '@/stores/library-store';

const sidebarStore = useSidebarStore();
const libraryStore = useLibraryStore();
const { 
  rSidebarOpen,
  rSidebarComponent,
  getRightSidebarStyle,
  rSidebarIsDragging
} = storeToRefs(sidebarStore);

const isGrabActive = ref(false);
const rightPane = ref(null);
const paneGrabber = ref(null);
const PANE_MIN_WIDTH = 400;
const PANE_MAX_WIDTH = 1500;

const onGrabPointerDown = (event) => {
  event.preventDefault();

  rSidebarIsDragging.value = true;
  isGrabActive.value = true;
  paneGrabber.value.setPointerCapture(event.pointerId);

  let grabRect = paneGrabber.value.getBoundingClientRect();
  let grabShiftX = event.clientX - grabRect.left;

  const onPointerMove = (event) => {
    let paneRect = rightPane.value.getBoundingClientRect();
    let newWidth = paneRect.right - event.clientX + grabShiftX;
    if (newWidth < PANE_MIN_WIDTH) newWidth = PANE_MIN_WIDTH;
    if (newWidth > PANE_MAX_WIDTH) newWidth = PANE_MAX_WIDTH;
    sidebarStore.setRightSidebarWidth(newWidth);
  };

  const onPointerMoveAnimationFrame = (event) => {
    window.requestAnimationFrame(() => onPointerMove(event));
  };

  const releasePointerHandlers = () => {
    rSidebarIsDragging.value = false;
    isGrabActive.value = false;
    paneGrabber.value.removeEventListener('pointerup', onPointerUp);
    paneGrabber.value.removeEventListener('pointermove', onPointerMoveAnimationFrame);
    paneGrabber.value.removeEventListener('lostpointercapture', onLostPointerCapture);
  };

  const onPointerUp = () => releasePointerHandlers();
  const onLostPointerCapture = () => releasePointerHandlers();

  paneGrabber.value.addEventListener('pointermove', onPointerMoveAnimationFrame);
  paneGrabber.value.addEventListener('pointerup', onPointerUp);
  paneGrabber.value.addEventListener('lostpointercapture', onLostPointerCapture);
};

const initGrabResize = () => {
  paneGrabber.value.addEventListener('pointerdown', onGrabPointerDown);
};
const destroyGrabResize = () => {
  paneGrabber.value.removeEventListener('pointerdown', onGrabPointerDown);
};

onMounted(() => {
  initGrabResize();
});
onBeforeUnmount(() => {
  destroyGrabResize();
});
</script>

<template>
  <div
    class="hrbr-right-sidebar-pane"
    ref="rightPane"
    :style="getRightSidebarStyle"
    :class="{ 'hrbr-sidebar-pane--grab-active': isGrabActive }">
    <div
      v-show="rSidebarOpen"
      class="hrbr-right-sidebar-pane__grab"
      ref="paneGrabber"
      @dragstart="() => false"></div>

    <component :is="rSidebarComponent" />
  </div>
</template>

<style lang="postcss" scoped>
.hide-right-sidebar {
  display: none;
}
.sidebar-opener {
  padding: 23px 5px;
  font-size: 10px;
  color: #ddd;

  position: absolute;
  left: -25px;
  top: 45px;
}

.loading {
  font-size: 12px;
  color: #8f8f8f;
}

.loading-text {
  margin-left: 10px;
}
.hrbr-right-sidebar-pane {
  z-index: 50;
  width: 0;
  transition: all 250ms ease;
  --hrbr-sidebar-pane-width-tablet: 284px;

  display: flex;
  flex-direction: column;
  width: var(--hrbr-sidebar-pane-width);
  height: 100%;
  min-height: 0;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.08) -2px 0px 2px 0px;
  transition: width 70ms ease;

  &--grab-active {
    .hrbr-sidebar-pane__grab::after {
      opacity: 1;
    }
  }

  &__grab {
    width: 50px;
    height: 100px;
    position: absolute;
    left: -3px;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 3px;
    width: 7px;
    height: 100%;
    z-index: 2;
    cursor: col-resize;
    pointer-events: auto;
    touch-action: none;

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 2px;
      width: 2px;
      background-color: rgb(0, 128, 212);
      opacity: 0;
      transition-property: opacity;
      transition-duration: 0.25s;
      transition-delay: 200ms;
      pointer-events: none;
      touch-action: none;
    }
  }
}

.hrbr-sidebar-pane-close {
  padding: 2px;

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    color: #666f75;
    cursor: pointer;
    transition: color 0.08s;
    opacity: 0.8;

    &:hover {
      color: var(--hrbr-primary-color-active);
      opacity: 1;
    }

    :deep(svg) {
      width: 18px;
      height: 18px;
      color: currentColor;
    }
  }
}
@media (max-width: 992px) {
  .hrbr-right-sidebar-pane {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
