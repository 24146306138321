<script setup>
const onImgError = (evt) => {
  // eslint-disable-next-line no-param-reassign
  evt.target.onerror = null;
  // eslint-disable-next-line no-param-reassign
  evt.target.src = 'static/imgs/placeholder-marketplace-logo.svg';
};
</script>

<template>
  <div class="integration">
    <div class="icon-container">
      <img
        :src="params.getIcon(params.data)"
        @error="onImgError"
      />
    </div>
    <span>{{ params.value }}</span>
  </div>
</template>
<style lang="postcss" scoped>
.integration {
    display: flex;
    align-items: center;
    gap: 5px
}
.icon-container {
    width: 20px;
    img {
        width: 100%;
    }
}
</style>
