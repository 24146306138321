<script>
import { useHarbourStore } from '@/stores/harbour-store';
export default {
  name: 'CopyLink',
  props: ['url', 'linkObject'],
  setup() {
    return {
      harbourStore: useHarbourStore(),
    };
  },
  data() {
    return {
      copied: false,
      copyText: 'Copy',
      displayURL: null,
      tooltipText: '',
    };
  },
  mounted() {
    this.displayURL = this.url;
  },
  methods: {
    copy() {
      let url;

      const protocol = this.linkObject.url.split('://')[0];
      if (import.meta.env.DEV) url = `http://localhost:9090/agree/${this.linkObject.id}`;
      else url = `${protocol}://${this.url}`;

      // Check if we are in live test environment and fix the copy link function to use current live test environment
      const livetest = 'dot-livetest-dot-harbour-prod-webapp.appspot.com';
      const containsSubstring = window.location.href.includes(livetest);
      if (containsSubstring) {
        url = `https://${window.location.hostname}/agree/${this.linkObject.id}`;
      }
      navigator.clipboard.writeText(url);

      if (this.displayURL === '✓ Copied') return;
      this.displayURL = '✓ Copied';
      setTimeout(() => {
        this.displayURL = this.url;
        this.tooltipText = '';
      }, 2000);
    },

    // UI control functions
    openInNewTab() {
      window.open(`https://${this.url}`, '_blank');
    },
    openQRCode() {
      this.harbourStore.openAgreementLinkCreatedModal(this.linkObject, this, 'qr_code');
    },

    // Text functions
    resetText() {
      this.tooltipText = '';
    },
    linkMouseOver() {
      this.tooltipText = 'Open link in new window';
    },
    qrMouseOver() {
      this.tooltipText = 'View QR code';
    },
    copyMouseOver() {
      this.tooltipText = 'Click to copy link';
    },
  },
  computed: {
    getStyle() {
      if (this.displayURL === '✓ Copied') {
        return {
          backgroundColor: '#03B09Ddd',
          color: 'white',
          pointerEvents: 'none',
          caretColor: 'transparent',
        };
      } else {
        return {
          backgroundColor: 'transparent',
        };
      }
    },
  },
};
</script>
<template>
  <div class="container">
    <div class="row">
      <div class="link-icon" @click.stop="openQRCode" @mouseover="qrMouseOver" @mouseleave="resetText">
        <i class="fal fa-qrcode"></i>
      </div>
      <input
        type="text"
        class="input"
        :style="getStyle"
        @click.stop="copy"
        @mouseover="copyMouseOver"
        @mouseleave="resetText"
        :value="displayURL" />
    </div>
    <div class="tooltip">
      {{ tooltipText }}
    </div>
  </div>
</template>

<style scoped>
.container {
  height: 100%;
  width: 100%;
}
.row {
  display: flex;
  justify-content: center;
  background-color: white;
}
.input {
  width: 100%;
  height: 32px;
  border-radius: 0 6px 6px 0;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0px;
  border: none;
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #777777;
  background-color: white;
  border: 1px solid #dbdbdb;
  z-index: 1;
  transition: all 100ms ease;
  cursor: pointer;
}
.input:hover {
  border-color: #b5b5b5;
  color: black;
}
.input-with-right {
  min-width: 250px;
  width: 100%;
  height: 32px;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0px;
  border: none;
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #777777;
  background-color: white;
  border: 1px solid #dcdcdc;
  z-index: 1;
  padding-left: 10px;
  transition: all 250ms ease;
  cursor: pointer;
}
.input-with-right {
  min-width: 250px;
  width: 100%;
  height: 32px;
  border-radius: 0 6px 6px 0;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0px;
  border: none;
  outline: none;
  color: #777777;
  background-color: white;
  border: 1px solid #dbdbdb;
  z-index: 1;
  transition: all 100ms ease;
  cursor: pointer;
}
.input:hover {
  border-color: #b5b5b5;
  color: black;
}
.link-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdcdc;
  border-right: none;
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  width: 35px;
  transition: all 250ms ease;
}
.link-icon-right {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dcdcdc;
  border-left: none;
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  width: 35px;
  transition: all 250ms ease;
}
.tooltip {
  position: fixed;
  z-index: 999;
  height: 10px;
  font-size: 11px;
  color: #9a9a9a;
}
.link-icon:hover {
  border-color: #b5b5b5;
  color: black;
  font-weight: 600;
}
.link-icon:first-child {
  border-radius: 6px 0 0 6px;
}
.link-icon-right:last-child {
  border-radius: 0 5px 5px 0;
}
.overlay {
  position: absolute;
  margin: 0;
  padding: 3px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  pointer-events: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.left-icon {
  color: red;
  padding: 5px 10px;
  border-right: 1px solid #9fb6c555;
}

.right,
.left {
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
}
.right {
  min-width: 65px;
  background-color: #cccccc;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  font-size: 11px;
  z-index: 2;
  transition: all 100ms ease;
}
.right:hover {
  background-color: #333;
}
.left {
  color: #9da0a2;
  font-size: 0.8em;
}
</style>
