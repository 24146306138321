<script>
import PdfPreview from '@/pages/Dashboard/components/PdfPreview.vue';
export default {
  methods: {
    openPresentationPdf() {
      // Open PDF preview

      let src = `/agreepdfunsigned/${this.params.data.id}`;;
      if (this.params.data.awaitingMyReviewSigned) {
        src = `/agreepdf/${this.params.data.id}}`
      } 
      
      this.$buefy.modal.open({
        parent: this,
        component: PdfPreview,
        canCancel: true,
        hasModalCard: false,
        fullScreen: true,
        props: {
          tgturl: src,
          isEditable: false,
          linkDisplayIdToEdit: this.params.data.id,
          parent: this,
        },
        events: {
          close: () => {},
        },
      });
    },
  },
};
</script>

<template>
  <div class="renderer-container">
    <button class="hrbr-grid-button" @click="openPresentationPdf">
      <i class="fal fa-file-pdf"></i>
      <span>View</span>
    </button>
  </div>
</template>

<style scoped>
.renderer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
}
.hrbr-grid-button:first-child {
  margin-right: 5px;
}
.hrbr-grid-button i {
  margin-right: 5px;
  padding: 0;
}
</style>
