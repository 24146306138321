<script setup>
import HrbrSidebarNavItem from './HrbrSidebarNavItem.vue';
import harbourLogoImg from '../../assets/icons/harbour-logo-blue.svg';
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useRouter, useRoute } from 'vue-router/composables';
import { storeToRefs } from 'pinia';
import { useHarbourStore } from '@/stores/harbour-store';
import { useSidebarStore } from './stores/sidebar-store';
import debounce from '@/utils/debounce';
import { useDashboardStore } from '@/stores/dashboard-store';

const DASHBOARD_ROUTES = ['signed-by-me', 'awaiting-my-review'];

const harbourStore = useHarbourStore();
const sidebarStore = useSidebarStore();
const dashboardStore = useDashboardStore();

const router = useRouter();
const route = useRoute();
const currentRouteName = computed(() => route.name);

const { 
  primaryNavItems, 
  secondaryNavItems, 
  supportUrl, 
  settingsPaneActive,
  automationsPaneActive,
} = storeToRefs(sidebarStore);

const navElem = ref(null);
const isNavScrollable = ref(false);
const isBottomNavShadow = ref(false);

const logoUrl = computed(() => {
  if (harbourStore.logoSrc) return harbourStore.logoSrc;
  return harbourLogoImg;
});

const checkPrimaryNavItemActive = (id) => {
  const isDashboardRoute = DASHBOARD_ROUTES.includes(currentRouteName.value);
  if (id === 'dashboard' && isDashboardRoute) return true;
  return id === currentRouteName.value;
};

const isNavItemDisabled = (item) => {
  return !harbourStore.checkUserAuthPermissions(item.permissions);
};

const checkNavScrollable = () => {
  const isScrollable = navElem.value.scrollHeight > navElem.value.clientHeight;
  isNavScrollable.value = isScrollable;
  if (isScrollable) isBottomNavShadow.value = true;
  else isBottomNavShadow.value = false;
};

const checkNavScrollableDebounce = debounce(checkNavScrollable, 400);

const handleLogoClick = () => {
  router.push({ name: 'dashboard' });
};

const checkCurrentRoute = (name) => name === currentRouteName.value;
const openSupportPage = () => window.open(supportUrl.value, '_blank');

// Dashboard requires custom handling since it includes additional views for 
// Awaiting my review, signed by me
const handleDashboardNav = () => {
  const activePane = sidebarStore.dashboardPaneActive;

  const handlers = {
    'awaiting-my-review': () => {
      router.push({ name: 'awaiting-my-review' });
    },
    'signed-by-me': () => {
      router.push({ name: 'signed-by-me' });
    },
    default: () => {
      const query = { filter: dashboardStore.mylinksCompleteState?.toLowerCase() };
      router.push({ name: 'dashboard', query });
    },
  };
  const handler = handlers[activePane] || handlers.default;
  handler();
};

const handleNavItemClick = (id) => {
  if (id === 'support') {
    openSupportPage();
    return;
  }

  if (checkCurrentRoute(id)) {
    sidebarStore.setSidebarPaneOpen(!sidebarStore.isSidebarPaneOpen);
  } else {
    sidebarStore.setSidebarPaneOpen(true);
    sidebarStore.setSidebarPaneDefaultWidth();
  }
  
  sidebarStore.clearFilter();

  const handlers = {
    dashboard: () => {
      handleDashboardNav(id);
    },
    settings: () => {
      router.push({ name: id, query: { filter: settingsPaneActive.value }});
    },
    automations: () => {
      router.push({ name: id, query: { filter: automationsPaneActive.value }});
    },
    default: () => {
      router.push({ name: id });
    },
  };
  const handler = handlers[id] || handlers.default;
  handler();
};

const handleScroll = (event) => {
  if (!isNavScrollable.value) return;
  const { target } = event;
  const scrollTop = target.scrollTop;
  const hiddenHeight = target.scrollHeight - target.clientHeight;
  if (scrollTop >= hiddenHeight) isBottomNavShadow.value = false;
  else isBottomNavShadow.value = true;
};

onMounted(() => {
  checkNavScrollable();
  navElem.value.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', checkNavScrollableDebounce);
});

onBeforeUnmount(() => {
  navElem.value.removeEventListener('scroll', handleScroll);
  window.removeEventListener('resize', checkNavScrollableDebounce);
});
</script>

<template>
  <div class="hrbr-sidebar-nav" role="navigation" aria-label="Main">
    <div class="hrbr-sidebar-nav__logo">
      <div class="hrbr-sidebar-nav__logo-img" @click="handleLogoClick">
        <img :src="logoUrl" alt="Logo" />
      </div>
    </div>

    <div class="hrbr-sidebar-nav__nav" ref="navElem">
      <div class="hrbr-sidebar-nav__nav-list">
        <HrbrSidebarNavItem
          v-for="navItem in primaryNavItems"
          :key="navItem.id"
          :id="navItem.id"
          :title="navItem.title"
          :icon="navItem.icon"
          :is-active="checkPrimaryNavItemActive(navItem.id)"
          :is-disabled="isNavItemDisabled(navItem)"
          @itemClick="handleNavItemClick" />
      </div>
    </div>

    <div
      class="hrbr-sidebar-nav__bottom-nav"
      :class="{ 'hrbr-sidebar-nav__bottom-nav--shadow': isBottomNavShadow }">
      <div class="hrbr-sidebar-nav__bottom-nav-list">
        <HrbrSidebarNavItem
          v-for="navItem in secondaryNavItems"
          :key="navItem.id"
          :id="navItem.id"
          :title="navItem.title"
          :icon="navItem.icon"
          :is-active="navItem.id === currentRouteName"
          :is-disabled="isNavItemDisabled(navItem)"
          :is-bottom-item="true"
          @itemClick="handleNavItemClick" />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar-nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;
  padding-top: 10px;
  border-right: 1px solid #d7dde4;

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    margin-bottom: 16px;
  }

  &__logo-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    cursor: pointer;

    img {
      height: auto;
      max-width: 100%;
      object-fit: contain;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &__nav::-webkit-scrollbar {
    display: none;
  }

  &__nav-list {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  &__bottom-nav {
    display: flex;
    flex-direction: column;
    padding: 15px 0 10px;
    transition: box-shadow 0.2s ease;

    &--shadow {
      box-shadow: 0px -5px 5px -5px rgba(0, 0, 0, 0.225);
    }
  }

  &__bottom-nav-list {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
}
</style>