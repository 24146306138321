<script setup>
import { useSidebarStore } from './stores/sidebar-store';

const sidebarStore = useSidebarStore();
</script>

<template>
  <div class="hrbr-sidebar-pane-filter">
    <div
      class="hrbr-sidebar-pane-filter__input"
      :class="{ 'hrbr-sidebar-pane-filter__input--active': sidebarStore.filter }">
      <input
        class="hrbr-sidebar-pane-filter__input-el"
        type="text"
        v-model="sidebarStore.filter"
        placeholder="Filter..." />

      <div
        class="hrbr-sidebar-pane-filter__clear"
        v-if="sidebarStore.filter"
        @click="sidebarStore.clearFilter()">
        <i class="fa-regular fa-xmark"></i>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar-pane-filter {
  flex: 1;

  &__input {
    display: flex;
    align-items: center;
    position: relative;
    opacity: 0.75;
    transition: opacity 0.15s ease;

    &:hover,
    &:focus-within,
    &--active {
      opacity: 1;

      .hrbr-sidebar-pane-filter__input-el {
        background: rgba(31,137,199,.1);
      }
    }
  }

  &__input-el {
    font-family: inherit;
    font-size: 15px;
    color: var(--hrbr-black-color);
    display: block;
    width: 100%;
    height: 36px;
    background: #fff;
    padding: 5px 40px 5px 15px;
    border-radius: 4px;
    margin: 0;
    border: 0;
    outline: 0;
    box-shadow: none;
    appearance: none;
    transition: background 0.3s ease;

    &::placeholder {
      color: #4a4a4a;
    }
  }

  &__clear {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--hrbr-black-color);
    width: 22px;
    height: 22px;
    position: absolute;
    right: 10px;
    font-size: 15px;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    transition: background 0.15s ease;

    &:hover {
      background: rgba(198, 205, 215, 0.3);
    }
  }
}
</style>
