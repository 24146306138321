import Vue from 'vue';

// Sidebar
import HrbrSidebarPaneDraftsRenderer from '@/components/Sidebar/HrbrSidebarPaneDraftsRenderer.vue';
import HrbrSidebarPaneFoldersMenuRenderer from '@/components/Sidebar/HrbrSidebarPaneFoldersMenuRenderer.vue';
import HrbrSidebarPaneTemplateGMenuRenderer from '@/components/Sidebar/HrbrSidebarPaneTemplateGMenuRenderer.vue';
import HrbrSidebarPaneTemplateRenderer from '@/components/Sidebar/HrbrSidebarPaneTemplateRenderer.vue';
import WorkflowMenuRenderer from '@/pages/Workflows/components/settings-modules/table-renderers/WorkflowMenuRenderer.vue';
import WorkflowsGroupRenderer from '@/pages/Workflows/components/settings-modules/table-renderers/WorkflowsGroupRenderer.vue';

// Drafts
import DraftsTitleRenderer from '@/pages/Drafts/components/grid-components/DraftsTitleRenderer.vue';
import DraftsCreatorRenderer from '@/pages/Drafts/components/grid-components/DraftsCreatorRenderer.vue';
import DraftsEditorsRenderer from '@/pages/Drafts/components/grid-components/DraftsEditorsRenderer.vue';
import DraftsViewersRenderer from '@/pages/Drafts/components/grid-components/DraftsViewersRenderer.vue';
import DraftsActionsRenderer from '@/pages/Drafts/components/grid-components/DraftsActionsRenderer.vue';
import DraftsTypeRenderer from '@/pages/Drafts/components/grid-components/DraftsTypeRenderer.vue';
import DraftsStatusRenderer from '@/pages/Drafts/components/grid-components/DraftsStatusRenderer.vue';

// Dashboard + Folders
import TitleEditor from '@/pages/Dashboard/components/grid_components/editors/TitleEditor.vue';

// Folders
import ExpiresFilter from '@/components/Other/ExpiresFilter.vue'; //
import ColumnEditor from '@/components/Other/GeneralColumnEditor.vue'; //
import aiColumnHeaderRenderer from '@/components/Other/AiColumnHeaderRenderer.vue'; //
import ActionsRenderer from '@/pages/Folders/components/grid_components/ActionsRenderer.vue';
import AiCellRenderer from '@/pages/Folders/components/grid_components/AiCellRenderer.vue';
import AssetLoadingRenderer from '@/pages/Folders/components/grid_components/AssetLoadingRenderer.vue';
import AttachmentsRenderer from '@/pages/Folders/components/grid_components/AttachmentsRenderer.vue';
import AuditTrailRenderer from '@/pages/Folders/components/grid_components/AuditTrailRenderer.vue';
import FoldersDetailRenderer from '@/pages/Folders/components/grid_components/DetailsRenderer.vue';
import FoldersLoadingRenderer from '@/pages/Folders/components/grid_components/FoldersLoadingRenderer.vue';
import FoldersTitleRenderer from '@/pages/Folders/components/grid_components/FoldersTitleRenderer.vue';
import ItemsRenderer from '@/pages/Folders/components/grid_components/ItemsRenderer.vue';
import MatchesRenderer from '@/pages/Folders/components/grid_components/MatchesRenderer.vue';
import NotesRenderer from '@/pages/Folders/components/grid_components/NotesRenderer.vue';
import StatusRenderer from '@/pages/Folders/components/grid_components/StatusRenderer.vue';
import TagsRenderer from '@/pages/Folders/components/grid_components/TagsRenderer.vue';

// Dashboard
import TitleRenderer from '@/pages/Dashboard/components/grid_components/TitleRenderer.vue';
import ViewsRenderer from '@/pages/Dashboard/components/grid_components/ViewsRenderer.vue';
import StateRenderer from '@/pages/Dashboard/components/grid_components/StateRenderer.vue';
import ActiveToggleRenderer from '@/pages/Dashboard/components/grid_components/ActiveToggleRenderer.vue';
import DashboardActionsRenderer from '@/pages/Dashboard/components/grid_components/ActionsRenderer.vue';
import SignersRenderer from '@/pages/Dashboard/components/grid_components/SignersRenderer.vue';
import CreationRenderer from '@/pages/Dashboard/components/grid_components/CreationRenderer.vue';
import DetailGrid from '@/pages/Dashboard/components/detail_grid/DetailGrid.vue';
import LinkRenderer from '@/pages/Dashboard/components/grid_components/LinkRenderer.vue';
import BrandRenderer from '@/pages/Dashboard/components/grid_components/BrandRenderer.vue';
import FolderRenderer from '@/pages/Dashboard/components/grid_components/FolderRenderer.vue';
import ViewAgreementRenderer from '@/pages/Dashboard/components/grid_components/ViewAgreementRenderer.vue';
import AutomationsRenderer from '@/pages/Dashboard/components/grid_components/AutomationsRenderer.vue';
import GroupsRenderer from '@/pages/Dashboard/components/grid_components/GroupsRenderer.vue';
import WorkflowStatusRenderer from '@/pages/Dashboard/components/grid_components/WorkflowStatusRenderer.vue';

// Dashboard automations
import DashboardAutomationsActionsRenderer from '@/pages/Dashboard/components/detail_grid/AutomationsGrid/ActionsRenderer.vue';
import DashboardAutomationsIntegrationRenderer from '@/pages/Dashboard/components/detail_grid/AutomationsGrid/IntegrationRenderer.vue';
import DashboardAutomationsStatusRenderer from '@/pages/Dashboard/components/detail_grid/AutomationsGrid/StatusRenderer.vue';

// Awaiting my review
import AwaitingMeActions from '@/pages/AwaitingReview/grid_components/AwaitingMyReviewActions.vue';
import DocRenderer from '@/pages/AwaitingReview/grid_components/DocRenderer.vue'
import AwaitingSignersColumn from '@/pages/AwaitingReview/grid_components/AwaitingSignersColumn.vue';
import AwaitingMeTitle from '@/pages/AwaitingReview/grid_components/AwaitingMeTitle.vue';

// Signed by me
// import DocRenderer from '@/pages/SignedByMe/grid_components/DocRenderer.vue'
import SignedByMeCreationRenderer from '@/pages/SignedByMe/grid_components/CreatorRenderer.vue';
import SignedByMePreviewRenderer from '@/pages/SignedByMe/grid_components/PreviewRenderer.vue';

// Settings
import UserListRolesRenderer from '@/pages/Settings/components/grid_components/UserListRolesRenderer.vue';
import UserListActionsRenderer from '@/pages/Settings/components/grid_components/UserListActionsRenderer.vue';
import UserNameRenderer from '@/pages/Settings/components/grid_components/UserNameRenderer.vue';

// User groups
import UserGroupsActionsRenderer from '@/pages/Settings/components/grid_components/ActionsRenderer.vue';
import UserGroupsNameRenderer from '@/pages/Settings/components/grid_components/NameRenderer.vue';
import UserGroupsDetailGrid from '@/pages/Settings/components/grid_components/DetailGrid.vue';

// Automations
import ApiKeysActionsRenderer from '@/pages/Automations/components/api_keys_components/ApiKeysActionsRenderer.vue';
import ApiKeysApiKeyRenderer from '@/pages/Automations/components/api_keys_components/ApiKeysApiKeyRenderer.vue';
import WebhooksActionsRenderer from '@/pages/Automations/components/webhooks_components/WebhooksActionsRenderer.vue';
import WebhooksEventTypesRenderer from '@/pages/Automations/components/webhooks_components/WebhooksEventTypesRenderer.vue';
import WebhooksStatusRenderer from '@/pages/Automations/components/webhooks_components/WebhooksStatusRenderer.vue';
import WebhooksUrlRenderer from '@/pages/Automations/components/webhooks_components/WebhooksUrlRenderer.vue';
import HrbrAddonComponent from '@/pages/Automations/components/HrbrAddonComponent.vue';

// Workflows
import VariablesListGroupRenderer from '@/pages/Workflows/components/settings-modules/table-renderers/VariablesListGroupRenderer.vue';

// Skeleton
import SkeletonRenderer from '@/pages/Dashboard/components/grid_components/SkeletonRenderer.vue';

export const registerGridComponents = () => {
  // Sidebar
  Vue.component('HrbrSidebarPaneDraftsRenderer', HrbrSidebarPaneDraftsRenderer);
  Vue.component('HrbrSidebarPaneFoldersMenuRenderer', HrbrSidebarPaneFoldersMenuRenderer);
  Vue.component('HrbrSidebarPaneTemplateRenderer', HrbrSidebarPaneTemplateRenderer);
  Vue.component('HrbrSidebarPaneTemplateGMenuRenderer', HrbrSidebarPaneTemplateGMenuRenderer);
  Vue.component('WorkflowsGroupRenderer', WorkflowsGroupRenderer);
  Vue.component('WorkflowMenuRenderer', WorkflowMenuRenderer);

  // Drafts
  Vue.component('DraftsTitleRenderer', DraftsTitleRenderer);
  Vue.component('DraftsCreatorRenderer', DraftsCreatorRenderer);
  Vue.component('DraftsEditorsRenderer', DraftsEditorsRenderer);
  Vue.component('DraftsViewersRenderer', DraftsViewersRenderer);
  Vue.component('DraftsActionsRenderer', DraftsActionsRenderer);
  Vue.component('DraftsTypeRenderer', DraftsTypeRenderer);
  Vue.component('DraftsStatusRenderer', DraftsStatusRenderer);

  // Dashboard + Folders
  Vue.component('TitleEditor', TitleEditor);

  // Folders
  Vue.component('ItemsRenderer', ItemsRenderer);
  Vue.component('AttachmentsRenderer', AttachmentsRenderer);
  Vue.component('TagsRenderer', TagsRenderer);
  Vue.component('MatchesRenderer', MatchesRenderer);
  Vue.component('StatusRenderer', StatusRenderer);
  Vue.component('ActionsRenderer', ActionsRenderer);
  Vue.component('DetailsRenderer', FoldersDetailRenderer);
  Vue.component('FoldersLoadingRenderer', FoldersLoadingRenderer);
  Vue.component('FoldersTitleRenderer', FoldersTitleRenderer);
  Vue.component('AuditTrailRenderer', AuditTrailRenderer);
  Vue.component('AssetLoadingRenderer', AssetLoadingRenderer);
  Vue.component('ColumnEditor', ColumnEditor)
  Vue.component('AiCellRenderer', AiCellRenderer);
  Vue.component('NotesRenderer', NotesRenderer);
  Vue.component('ExpiresFilter', ExpiresFilter);
  Vue.component('aiColumnHeaderRenderer', aiColumnHeaderRenderer);

  // Dashboard
  Vue.component('TitleRenderer', TitleRenderer);
  Vue.component('ViewsRenderer', ViewsRenderer);
  Vue.component('StateRenderer', StateRenderer);
  Vue.component('ActiveToggleRenderer', ActiveToggleRenderer);
  Vue.component('DashboardActionsRenderer', DashboardActionsRenderer);
  Vue.component('CreationRenderer', CreationRenderer);
  Vue.component('DetailGrid', DetailGrid);
  Vue.component('LinkRenderer', LinkRenderer);
  Vue.component('BrandRenderer', BrandRenderer);
  Vue.component('FolderRenderer', FolderRenderer);
  Vue.component('ViewAgreementRenderer', ViewAgreementRenderer);
  Vue.component('SignersRenderer', SignersRenderer);
  Vue.component('AutomationsRenderer', AutomationsRenderer);
  Vue.component('GroupsRenderer', GroupsRenderer);
  Vue.component('WorkflowStatusRenderer', WorkflowStatusRenderer);

  // Dashboard automations
  Vue.component('DashboardAutomationsActionsRenderer', DashboardAutomationsActionsRenderer);
  Vue.component('DashboardAutomationsStatusRenderer', DashboardAutomationsStatusRenderer);
  Vue.component('DashboardAutomationsIntegrationRenderer', DashboardAutomationsIntegrationRenderer);
  
  // Awaiting my review
  Vue.component('AwaitingMeActions', AwaitingMeActions);
  Vue.component('DocRenderer', DocRenderer);
  Vue.component('AwaitingSignersColumn', AwaitingSignersColumn);
  Vue.component('AwaitingMeTitle', AwaitingMeTitle);

  // Signed by me
  // Vue.component('DocRenderer', DocRenderer);
  Vue.component('SignedByMeCreationRenderer', SignedByMeCreationRenderer);
  Vue.component('SignedByMePreviewRenderer', SignedByMePreviewRenderer);

  // Settings
  Vue.component('UserListRolesRenderer', UserListRolesRenderer);
  Vue.component('UserListActionsRenderer', UserListActionsRenderer);
  Vue.component('UserNameRenderer', UserNameRenderer);

  // User groups
  Vue.component('UserGroupsActionsRenderer', UserGroupsActionsRenderer);
  Vue.component('UserGroupsNameRenderer', UserGroupsNameRenderer);
  Vue.component('UserGroupsDetailGrid', UserGroupsDetailGrid);

  // Automations
  Vue.component('ApiKeysActionsRenderer', ApiKeysActionsRenderer);
  Vue.component('ApiKeysApiKeyRenderer', ApiKeysApiKeyRenderer);
  Vue.component('WebhooksUrlRenderer', WebhooksUrlRenderer);
  Vue.component('WebhooksStatusRenderer', WebhooksStatusRenderer);
  Vue.component('WebhooksEventTypesRenderer', WebhooksEventTypesRenderer);
  Vue.component('WebhooksActionsRenderer', WebhooksActionsRenderer);
  Vue.component('HrbrAddonComponent', HrbrAddonComponent);

  // Workflows
  Vue.component('VariablesListGroupRenderer', VariablesListGroupRenderer);

  // Skeleton
  Vue.component('SkeletonRenderer', SkeletonRenderer);
};
