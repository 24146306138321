<script>
export default {
  data() {
    return {
      medium: null,
      description: null,
    }
  },
  created() {
    this.medium = this.params.data.medium_desc;
    this.description = this.params.message_type_desc;
  },
  methods: {

  }
}
</script>

<template>
  <div class="container">
    <i class="fal fa-envelope"></i>
    <div class="medium">
      {{ this.medium }}
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden !important;
}
.container i {
  font-size: 30px;
  margin-right: 10px;
  color: #6a6a6aad;
}
.name {
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  max-height: 30% !important;
}
</style>
