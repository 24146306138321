<script setup>
import { useAutomationsStore } from '@/stores/automations-store';
import { useHarbourStore } from '@/stores/harbour-store';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRouter } from 'vue-router/composables';
import HrbrSidebarPaneHeader from './HrbrSidebarPaneHeader.vue';
import HrbrSidebarPaneListItem from './HrbrSidebarPaneListItem.vue';
import HrbrSidebarPaneSection from './HrbrSidebarPaneSection.vue';
import { useFilterItems } from './composables/filter-items';
import { useSidebarStore } from './stores/sidebar-store';

const router = useRouter();

const harbourStore = useHarbourStore();
const sidebarStore = useSidebarStore();
const automationsStore = useAutomationsStore();

const { 
  automationsPaneAddonsItems: addonsItems,
  automationsPaneNoCodeItems: noCodeItems,
  automationsPaneIntegrationsItems: integrationsItems,
} = storeToRefs(sidebarStore);

const { filteredItems: filteredAddonsItems } = useFilterItems(addonsItems);
const { filteredItems: filteredNoCodeItems } = useFilterItems(noCodeItems);
const { filteredItems: filteredIntegrationsItems } = useFilterItems(integrationsItems);

const isOrgAdmin = computed(() => {
  return (harbourStore.contextDict?.auth_roles || []).includes('orgAdmin');
});

const handlePaneChange = (item) => {
  sidebarStore.setAutomationsPaneActive(item.id);
  automationsStore.setTitle(item.title);
  router.push({ name: 'automations', query: { filter: item.id }});
};
</script>

<template>
  <div class="hrbr-sidebar-pane-tab hrbr-sidebar-pane-automations">
    <div class="hrbr-sidebar-pane-tab__head">
      <HrbrSidebarPaneHeader />
    </div>

    <div class="hrbr-sidebar-pane-tab__body">
      <div class="hrbr-sidebar-pane-tab__body-content">
        <HrbrSidebarPaneSection v-if="filteredAddonsItems.length">
          <template v-slot:head>
            <div class="hrbr-sidebar-pane-tab__heading">
              <div class="hrbr-sidebar-pane-tab__heading-text">Add-ons</div>
            </div>
          </template>
          <template v-slot:content>
            <div class="hrbr-sidebar-pane-tab__list">
              <HrbrSidebarPaneListItem
                v-for="item in filteredAddonsItems"
                :key="item.id"
                :title="item.title"
                :icon="item.icon"
                :count="item.count"
                :is-active="sidebarStore.automationsPaneActive === item.id"
                @item-click="handlePaneChange(item)" />
            </div>
          </template>
        </HrbrSidebarPaneSection>

        <HrbrSidebarPaneSection v-if="isOrgAdmin && filteredNoCodeItems.length">
          <template v-slot:head>
            <div class="hrbr-sidebar-pane-tab__heading">
              <div class="hrbr-sidebar-pane-tab__heading-text">No-code</div>
              <div class="hrbr-sidebar-pane-tab__heading-badge">Admin</div>
            </div>
          </template>
          <template v-slot:content>
            <div class="hrbr-sidebar-pane-tab__list">
              <HrbrSidebarPaneListItem
                v-for="item in filteredNoCodeItems"
                :key="item.id"
                :title="item.title"
                :icon="item.icon"
                :count="item.count"
                :is-active="sidebarStore.automationsPaneActive === item.id"
                @item-click="handlePaneChange(item)" />
            </div>
          </template>
        </HrbrSidebarPaneSection>

        <HrbrSidebarPaneSection v-if="isOrgAdmin && filteredIntegrationsItems.length">
          <template v-slot:head>
            <div class="hrbr-sidebar-pane-tab__heading">
              <div class="hrbr-sidebar-pane-tab__heading-text">Integrations</div>
              <div class="hrbr-sidebar-pane-tab__heading-badge">Admin</div>
            </div>
          </template>
          <template v-slot:content>
            <div class="hrbr-sidebar-pane-tab__list">
              <HrbrSidebarPaneListItem
                v-for="item in filteredIntegrationsItems"
                :key="item.id"
                :title="item.title"
                :icon="item.icon"
                :count="item.count"
                :is-active="sidebarStore.automationsPaneActive === item.id"
                @item-click="handlePaneChange(item)" />
            </div>
          </template>
        </HrbrSidebarPaneSection>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.hrbr-sidebar-pane-tab__body {
  * {
    color: #333;
  }
}
</style>
