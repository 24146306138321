<script>
import { useDashboardStore } from '@/stores/dashboard-store';
import MediaPlayer from '@/components/Other/MediaPlayer.vue';
import ModalPresentation from '@/components/Modals/HrbrModalPresentation.vue';

export default {
  components: {
    MediaPlayer,
    ModalPresentation,
  },
  setup() {
    return {
      dashboardStore: useDashboardStore(),
    };
  },
  data() {
    return {
      row: null,
      currentFolderCanEdit: false,
      fileType: null,
      mediasupportedrendertypes:[], //*populated at 'created'
      mediasupportedimagetypes:['bmp','png','jpg','gif','tif','ico','webp','svg'],
      mediasupportedvideotypes:['mp4','avi','mov','webm'],
      mediasupportedaudiotypes:['mp3','wav','aac'],
      mediasupportedbinarydocumenttypes:['pdf','epub'],
      mediasupportedpostscriptdocumenttypes:['eps'],
      mediasupported3dmodeltypes:['glb'], //'usdz'
    };
  },
  created() {
    this.row = this.params.data;
    this.fileType = this.params.data.file_extension;
  },
  methods: {
    handlePdfPreview() {
      this.dashboardStore.openAssetBasedPdfPreview(this.row)
    },
    openPresentationMode() {
      //Open modal
      this.$buefy.modal.open({
        parent: this,
        component: ModalPresentation,
        canCancel:true,
        hasModalCard:false,
        fullScreen: true,
        props:{
          "activeAssets":[this.row],
          "targetAssetId": this.row.id,
        }
      });
    }
  },
};
</script>

<template>
  <div class="cell" v-if="fileType === 'pdf'">
    <MediaPlayer :row="row" :openpresentationmode="handlePdfPreview" assetbackgroundcolor="transparent" />
  </div>
  <div class="cell" v-else>
    <MediaPlayer :row="row" :openpresentationmode="openPresentationMode" assetbackgroundcolor="transparent" />
  </div>
</template>

<style scoped>

.created-time {
  color: #6a6a6a;
  font-size: 11px;
}
.edit-name {
  margin-left: 5px;
  font-size: 10px;
  color: #8a8a8a;
  cursor: pointer;
}
.edit-name:hover {
  color: #2b3134;
}
.item-preview {
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  max-width: 200px;
  border: 1px solid var(--pale-gray, #F0F4F6);
  background: var(--white, #FFF);
  transition: all 250ms ease;
  max-height: 265px;
  overflow: auto;
}
.preview-container {
  border-radius: 3px;
  max-height: 160px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.preview-item {
  height: 100%;
  object-fit: fill;
}
.preview-text {
  color: var(--antrhacite, #2B3134);
  align-self: stretch;
  margin-top: 5px;
  /* Subheading */
  font-size: 13px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 133.333% */
  max-width: 100%;
  white-space: wrap;
}
.preview-item-not-found{
  height:200px;
  width:200px;
  color:white;
  font-size: 21px;
  text-align: center;
}
</style>
