<script setup>
import { useDashboardStore } from '@/stores/dashboard-store';
import { useHarbourStore } from '@/stores/harbour-store';

const harbourStore = useHarbourStore();
const dashboardStore = useDashboardStore();

const handlePreviewClick = (data, linkObject) => {
  const link = linkObject();
  if (!link) return;
  dashboardStore.openCompletedDocPdfPreview(data, link);
}

</script>

<template>
  <div class="container">
    <div class="icon-container" @click="handlePreviewClick(params.data, params.linkObject)">
      <i class="fal fa-file-pdf pdf-icon" v-if="!params.data.reassigned" title="View signed document"></i>
      <i class="fal fa-random reassigned-icon" v-if="params.data.reassigned" title="View signed document"></i>
    </div>
    <div class="name">
      {{ textDeSanitize(params.data.name) }}
    </div>
  </div>
</template>

<style scoped>
.pdf-icon {
  font-weight: 200;
}
.container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden !important;
}
.icon-container {
  width: 40px;
  margin-right: 10px;
  display: flex;
  justify-content: center !important;
  cursor: pointer;
}
.container i {
  font-size: 30px;
  color: #6a6a6aad;
  cursor: pointer;
}
.container i:hover {
  color: #6a6a6a;
  cursor: pointer;
}
.name {
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
