<script>
import { storeToRefs } from 'pinia';
import { useHarbourStore } from '@/stores/harbour-store';
import { useLinkObject } from '@/pages/Dashboard/composables/LinkObject'


export default {
  setup() {
    const harbourStore = useHarbourStore();
    const { contextDict } = storeToRefs(harbourStore);
    const { profilePictures } = storeToRefs(harbourStore);
    return {
      profilePictures,
      contextDict,
    };
  },
  data() {
    return {
      linkObject: null,
      signers: [],
      node: null,
    };
  },
  created() {
    this.linkObject = useLinkObject(this.params.data);
    this.signers = this.linkObject.emailRecipients?.filter((signer) => !signer.reassigned) || [];
  },
  beforeUnmount() {
    this.linkObject = null;
  },
  watch: {
    profilePictures: {
      handler(newValue, oldValue) {
        this.updateSignerPhotos(newValue)
      },
      deep: true
    },
  },
  methods: {
    updateSignerPhotos(newValue) {
      this.signers = this.signers.map((signer) => {
        const profileImageUrl = newValue.find((pic) => pic.email === signer.recipientemail)?.profileImageUrl || null;
        signer.profileImageUrl = profileImageUrl;
        return signer;
      });
    },
    getCompletedSigners(email) {
      let completed_signer = false;
      completed_signer = !!this.linkObject.emailRecipientsCompleted.find((signer) => {
        return signer.recipientemail === email;
      });
      return completed_signer;
    },
    getInitial(name, email) {
      if (name) return name[0].toUpperCase();
      else if (email) return email[0].toUpperCase();
      else return '*';
    },
  },
  computed: {
    getSigners() {
      return this.signers;
    },
  },
};
</script>

<template>
  <div class="signers-container">
    <div v-for="(signer, index) in getSigners" :key="index" class="signer-row">
      <div class="signer-container">
        <div class="signer-image-container">
          <img
            v-if="signer.profileImageUrl"
            :src="signer.profileImageUrl"
            class="creator-updater-image" />
          <div class="photo-initials">
            {{ getInitial(signer.fullname, signer.recipientemail) }}
          </div>
        </div>

        <div class="is-flex is-flex-direction-column details-column">
          <span class="signer-name">{{ signer.fullname }}</span>
          <span class="signer-email">{{ signer.recipientemail }}</span>
        </div>
        
        <div
            class="signer-signed signer-icon signer-complete"
            v-if="getCompletedSigners(signer.recipientemail)"
            title="Signer has completed the document">
            <i class="fas fa-check-circle"></i>
          </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.signers-container {
  max-height: 90px;
  overflow: auto;
  min-width: 260px;
  max-width: 260px;
  padding: 0 20px;
}
.photo-initials {
    padding: 0;
    margin: 0;
    line-height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important;
    color: white;
}
.signer-row {
  margin: 8px 0;
}
.views-container {
    padding: 0 5px;
    color: #787878;
}
.signer-container {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}
.details-column {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}
.signer-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 3px;
}
.signer-email {
    max-width: 100%;
    font-size: 0.8em;
    color: #9a9a9a;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -5px;
}
.signer-image-container {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    border: 0px solid #d9d9d9;
    background-color: #2f5a8899;
    overflow: hidden;
}
.creator-updater-image {
    width: 25px;
    height: 25px;
    max-width: 25px;
    max-height: 25px;
}
.creator-updater-edit i {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px !important;
    background-color: #00000008;
    height: 18px !important;
    width: 18px !important;
    border-radius: 20px;
    cursor: pointer;
    color: #02020275;
    transition: all 150ms ease;
}
.creator-updater-edit i:hover {
    font-size: 10px !important;
    color: #222;
    background-color: #e4e4e4;
}
.signer-complete {
  cursor: pointer;
  color: var(--grid-success);
  transition: all 150ms ease;
}
</style>
