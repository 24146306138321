<script setup>
import { ModalProgrammatic as Modal } from 'buefy';
import { computed, onBeforeUnmount, onMounted } from 'vue';

import { useBillingStore } from '@/pages/Settings/stores/billing-store';
import throttle from '@/utils/throttle';
import HrbrAppHeader from './components/AppHeader/HrbrAppHeader.vue';
import HrbrAppBanner from './components/HrbrAppBanner.vue';
import HrbrAppView from './components/HrbrAppView.vue';
import HrbrUserNameModal from './components/Modals/HrbrUserName.vue';
import HrbrRightSideBar from './components/RightSideBar/HrbrRightSideBar.vue';
import HrbrSidebar from './components/Sidebar/HrbrSidebar.vue';
import NewVersionDialog from './components/ui/NewVersionDialog.vue';
import { useSidebarStore } from './components/Sidebar/stores/sidebar-store';
import { useHarbourStore } from './stores/harbour-store';

const harbourStore = useHarbourStore();
const sidebarStore = useSidebarStore();
const billingStore = useBillingStore();

const mainClasses = computed(() => ({
  'hrbr-app--sidebar-open': sidebarStore.isSidebarOpen,
  'hrbr-app--sidebar-pane-open': sidebarStore.isSidebarPaneOpen,
  'hrbr-app--shake': harbourStore.isShakeHarbour,
}));

const watchWindowSize = () => {
  harbourStore.isMobileView = window.matchMedia('(max-width: 768px)').matches;
};
const watchWindowSizeThrottled = throttle(watchWindowSize, 300);

const setupUserName = () => {
  const userName = harbourStore.contextDict?.systememailname;
  const authProvider = harbourStore.contextDict?.auth_provider;
  const isPasswordAuth = authProvider === 'password';
  const userNameModalShown = localStorage.getItem('harbourUserNameModal');
  if (userName || userNameModalShown) return;
  if (isPasswordAuth) showUserNameModal();
};

const showUserNameModal = () => {
  Modal.open({
    component: HrbrUserNameModal,
    hasModalCard: true,
  });
};

harbourStore.loadPersonalizationFromStorage();
billingStore.handleBillingModals();

onMounted(() => {
  setupUserName();
  watchWindowSize();
  window.addEventListener('resize', watchWindowSizeThrottled);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', watchWindowSizeThrottled);
});
</script>

<template>
  <div class="hrbr-app" :class="mainClasses">

    <div class="hrbr-app__layout">
      <HrbrSidebar />

      <div class="hrbr-app__main">
        <HrbrAppBanner :src="harbourStore.bannerSrc">
          <HrbrAppHeader />
        </HrbrAppBanner>

        <div class="hrbr-app__view">
          <NewVersionDialog v-if="harbourStore.showNewVersionAvailable" />

          <div id="app-main-view-container" class="hrbr-app__view-container">
            <div class="outer-view">
              <div class="hrbr-app__view-content">
                <RouterView />
              </div>
            </div>
          </div>
        </div>
      </div>
      <HrbrRightSideBar class="right-sidebar" />

    </div>

    <HrbrAppView />
  </div>  
</template>

<style lang="postcss" scoped>
.right-sidebar {
  max-height: 100%;
  background-color: white;
  overflow: auto;
  z-index: 5;
}
.outer-view {
  display: flex;
}
.right-sidebar {
  border-left: 1px solid #d7dde4;
  box-shadow: rgba(0, 0, 0, 0.08) 2px 0px 2px 0px;
  transition: width 70ms ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.hrbr-sidebar-pane {
  width: 400px;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.08) 2px 0px 2px 0px;
  transition: width 70ms ease;

  &--grab-active {
    .hrbr-sidebar-pane__grab::after {
      opacity: 1;
    }
  }

  &__grab {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -3px;
    width: 7px;
    height: 100%;
    z-index: 2;
    cursor: col-resize;
    pointer-events: auto;
    touch-action: none;

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 2px;
      width: 2px;
      background-color: rgb(0, 128, 212);
      opacity: 0;
      transition-property: opacity;
      transition-duration: 0.25s;
      transition-delay: 200ms;
      pointer-events: none;
      touch-action: none;
    }
  }
}

.hrbr-app {
  --hrbr-view-container-offset: -112px;

  width: 100%;
  height: 100vh;
  display: flex;

  &--shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }

  &__layout {
    display: grid;
    grid-template-columns: auto 1fr auto;
    width: 100%;
    height: 100%;
  }

  &__main {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__view {
    display: grid;
    padding: 0 20px;
    background: #f6f8f8;
  }

  &__view-container {
    display: grid;
    background: #fff;
    position: relative;
    margin-top: var(--hrbr-view-container-offset);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: 0px 0px 3px 2px #0000000d;
  }

  &__view-content {
    padding: 20px 15px;
    padding-bottom: 2px;
    display: flex;
    flex-grow: 1;
  }

  :deep(.hrbr-app-view) {
    width: 100%;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-8px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(8px, 0, 0);
  }
}

@media (max-width: 1200px) {
  @supports (height: 100dvh) {
    .hrbr-app {
      height: 100dvh;
    }
  }
}

@media (max-width: 992px) {
  .hrbr-app {
    &__layout {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: 768px) {
  .hrbr-app {
    &__view {
      padding: 0;
    }

    &__view-container {
      margin-top: 0;
      border-radius: 0;
    }

    &__view-content {
      padding: 20px;
    }
  }
}
</style>