<script setup>
import { computed } from 'vue';
import HrbrSidebarNav from './HrbrSidebarNav.vue';
import HrbrSidebarPane from './HrbrSidebarPane.vue';
import { useSidebarStore } from './stores/sidebar-store';
import { useRoute } from 'vue-router/composables';

const sidebarStore = useSidebarStore();
const route = useRoute();

const currentRouteName = computed(() => route.name);

const sidebarClasses = computed(() => ({
  'hrbr-sidebar--open': sidebarStore.isSidebarOpen,
  'hrbr-sidebar--pane-open': sidebarStore.isSidebarPaneOpen,
}));

const isSidebarPaneVisible = computed(() => {
  const isOpened = sidebarStore.isSidebarPaneOpen;
  const isEnabled = sidebarStore.checkSidebarPaneEnabled(currentRouteName.value);
  return isOpened && isEnabled;
});
</script>

<template>
  <div class="hrbr-sidebar" :class="sidebarClasses">
    <HrbrSidebarNav />
    <HrbrSidebarPane v-show="isSidebarPaneVisible" />
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar {
  --hrbr-sidebar-nav-width: 80px;
  --hrbr-sidebar-nav-width-tablet: 58px;

  display: grid;
  grid-template-columns: var(--hrbr-sidebar-nav-width);
  grid-auto-flow: column;
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
  min-height: 0;
}

@media (max-width: 992px) {
  .hrbr-sidebar {
    grid-template-columns: var(--hrbr-sidebar-nav-width-tablet);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: auto;
    height: 100%;
    z-index: 30;
    opacity: 0;
    transition: opacity 0.5s, transform 0.3s ease;
    transform: translate(-100%);

    &--open {
      opacity: 1;
      transform: translate(0);
      transition: opacity 0.25s, transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  :deep(.hrbr-sidebar-pane) {
    display: flex !important;
  }
}
</style>