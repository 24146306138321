<script setup>
import { ref, computed } from 'vue';
import { useSidebarStore } from '@components/Sidebar/stores/sidebar-store.js';
const sidebarStore = useSidebarStore();

const { openRightSidebar } = sidebarStore;

const agParams = ref(null);
const workflowsWithStatus = computed(() => {
  return agParams.value.data?.workflows_status || [];
});

const init = (params) => {
  agParams.value = params;
}

const hasWorkflow = computed(() => {
  return workflowsWithStatus.value.length > 0;
});

</script>

<template>
  <div
    :ref="init(params)"
    @click="openRightSidebar"
  >
    <div v-for="wf in workflowsWithStatus" :key="wf.id">
      <div v-if="hasWorkflow" class="wf-status wf-row">
        <i class="fal fa-code-branch"></i>
        <div class="wf-info">
          <div class="wf-name">{{ wf.wf_name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wf-name {
  font-weight: 500;
  color: #999;
  font-size: 12px;
  line-height: 1em;
}
.wf-status {
  font-weight: 500;
  color: #666;
}
.wf-info {
  display: flex;
  flex-direction: column;
}
.wf-row {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px;
}
.wf-row i {
  margin-right: 10px;
  font-weight: 600;
}
</style>
