<script>
import { mapState } from 'pinia';
import { useLibraryStore } from '@/stores/library-store';
import ModalEditTag from '../modals/ModalEditTag.vue';
export default {
  data() {
    return {
      row: null,
      tags: null,
    }
  },
  created() {
    this.row = this.params.data;
    this.tags = null;
  },
  mounted() {
    const displayTags = this.combineTags(this.params.data);
    this.assetlibraryUpdateAssetTags(displayTags);
  },
  computed: {
    ...mapState(useLibraryStore, ['hasEditPermissions']),
  },
  methods: {
    currentFolderCanEdit() {},
    mapTags(tag) {
      const map = {
        'binarydocument': 'document',
        'staticimage': 'image',
        '3dmodel': '3d model',
      }
      return map[tag?.toLowerCase()] || tag;
    },

    parseTagToJson(tag) {
      try {
        tag = JSON.parse(tag);
      } catch (e) {
        // pass
      }
      return tag;
    },

    // Used to run an update on this asset's tags (ie: when they change)
    async getTagsFromServer() {
      const result = await this.$harbourData.post('data?filesystem-getassettags', {
          'requesttype': 'filesystem-getassettags',
          'targetdisplayid': this.row.id,
      })
      if (!result.status === 200) return;

      const displayTags = this.combineTags({
        tags_vision: result.data.assettags_visioninfojsonarray?.map(d => this.parseTagToJson(d)),
        tags_web_matches: result.data.assettags_visionwebmatchesjsonarray?.map(d => this.parseTagToJson(d)),
        tags_user: result.data.assettags_userprovidedinfojsonarray?.map(d => this.parseTagToJson(d)),
      })
      this.assetlibraryUpdateAssetTags(displayTags);
    },

    combineTags(data) {
      const tags_vision = data.tags_vision?.map(tag => this.parseTagToJson(tag)) || [];
      const tags_web_matches = data.tags_web_matches?.map(tag => this.parseTagToJson(tag)) || [];
      const tags_user = data.tags_user?.map(tag => this.parseTagToJson(tag)) || [];

      return {
        vision: tags_vision,
        web: tags_web_matches,
        user: tags_user
      }
    },

    async assetlibraryUpdateAssetTags(result) {
      const assetDisplayId = this.row.id;

      if (!assetDisplayId) return false;

      //Get tags
      let tags_vision = result.vision;
      let tags_web_matches = result.web;
      let tags_user = result.user;

      //Combine and clean displayed tags (vision info tags + user provided tags)
      let assettags_displayedtagsjsonarray = tags_vision.concat(tags_user);
      //-- remove any null or empty or undefined tag values
      assettags_displayedtagsjsonarray = assettags_displayedtagsjsonarray.filter(function(d){
        return d.tagvalue != undefined && d.tagvalue != null && d.tagvalue.trim() != "";
      });
      //-- remove any inactivated tags
      assettags_displayedtagsjsonarray = assettags_displayedtagsjsonarray.filter(function(d){
        return d.tagisactive != false;
      });
      //-- remove color tags (*used only in details)
      assettags_displayedtagsjsonarray = assettags_displayedtagsjsonarray.filter(function(d){ return d.tagtype != "IMAGE_PROPERTIES_ANNOTATIONS_DOMINANTCOLORS"; });
      //-- remove based on specific terms
      let tagValuesToRemove = ["unknown","white-collar worker","wrinkle"];
      assettags_displayedtagsjsonarray = assettags_displayedtagsjsonarray.filter(function(tag){
        return tagValuesToRemove.includes((tag.tagvalue).toLowerCase()) == false;
      });
      //-- remove any duplicates based on value
      let foundValuesLowercase = [];
      assettags_displayedtagsjsonarray = assettags_displayedtagsjsonarray.filter(function(tag){
        if(tag.tagtype == "LOGO_ANNOTATIONS"){
          return true;
        }
        let tagValue = (tag.tagvalue).trim().toLowerCase();
        let isAlreadyInFoundValues = foundValuesLowercase.includes(tagValue);
        foundValuesLowercase.push(tagValue);
        return isAlreadyInFoundValues == false;
      });
      //-- remove tag based on latest-added user tags
      let activeTagValues = [];
      let inactiveTagValues = [];
      tags_user.sort(function(a,b){
        return b.tagdatetime - a.tagdatetime;
      }).forEach(function(tag){
        if(tag.tagvalue == undefined){
          return;
        }
        let tagValue = (tag.tagvalue).trim().toLowerCase();
        let tagIsActiveState = tag.tagisactive;
        //*newer tags will override older tag active state
        if(activeTagValues.includes(tagValue) || inactiveTagValues.includes(tagValue)){
          return;
        }
        if(tagIsActiveState == true){
          activeTagValues.push(tagValue);
        }
        if(tagIsActiveState == false){
          inactiveTagValues.push(tagValue);
        }
      });
      assettags_displayedtagsjsonarray = assettags_displayedtagsjsonarray.filter(function(tag){ return inactiveTagValues.includes((tag.tagvalue).trim().toLowerCase()) == false; });
      //-- sort based on tag type
      let tagOrder = [];
      tagOrder["USERADDED_TAG"] = 0;
      tagOrder["WEB_DETECTION_ENTITY"] = 1;
      tagOrder["LOGO_ANNOTATIONS"] = 2;
      tagOrder["TEXT_ANOTATIONS"] = 3;
      tagOrder["WEB_DETECTION_BESTGUESSLABEL"] = 4;
      tagOrder["ASSET_LICENSESOURCETYPE"] = 5;
      tagOrder["LANDMARK_ANNOTATIONS"] = 6;
      tagOrder["LABEL_ANNOTATION"] = 7;
      tagOrder["LABEL_DETECTION"] = 8;
      tagOrder["ASSET_MEDIATYPE"] = 9;
      tagOrder["ASSET_EXTENSION"] = 10;
      assettags_displayedtagsjsonarray.sort(function(a,b){
          return tagOrder[a.tagtype] - tagOrder[b.tagtype];
      });

      this.tags = assettags_displayedtagsjsonarray;
      this.row.tags_vision = tags_vision;
      this.row.tags_web_matches = tags_web_matches;
      this.row.tags_user = tags_user;
      this.row.assettags_displayedtagsjsonarray = assettags_displayedtagsjsonarray;

      return false;
    },

    openTagModal({ id, tag, isNewTagOnly, isRemoveTagOnly, callback, customcallback }) {
      if (!customcallback) customcallback = () => null;

      this.$buefy.modal.open({
        parent: this,
        component: ModalEditTag,
        hasModalCard: true,
        props:{
          "displayassetid": id,
          "tag": tag,
          "isnewtagonly": isNewTagOnly,
          "isremovetagonly": isRemoveTagOnly,
          "callgettagsupdate": callback,
          "currentfoldercanedit": this.currentFolderCanEdit,
          customcallback,
        }
      });
    },

    onAddTagConfirm(tag) {
      this.tags.unshift({
        tagvalue: tag
      })
    },

    onRemoveTagConfirm(tag) {
      this.tags = this.tags.filter(t => t.tagvalue !== tag.tagvalue)
    },

    onEditTagConfirm(tag, newTagValue) {
      const existingTag = this.tags.find(t => t.tagvalue === tag.tagvalue);
      if (!existingTag) return
      existingTag.tagvalue = newTagValue
    },

    addTag() {
      if (!this.hasEditPermissions) return;
      this.openTagModal({
        id: this.row.id,
        tag: null, 
        isNewTagOnly: true,
        isRemoveTagOnly: false,
        callback: this.getTagsFromServer,
        customcallback: this.onAddTagConfirm
      })
    },

    removeTag(tag) {
      if (!this.hasEditPermissions) return;
      this.openTagModal({
        id: this.row.id,
        tag, 
        isNewTagOnly: false,
        isRemoveTagOnly: true,
        callback: this.getTagsFromServer,
        customcallback: this.onRemoveTagConfirm
      })
    },

    editTag(tag) {
      if (!this.hasEditPermissions) return;
      this.openTagModal({
        id: this.row.id,
        tag, 
        isNewTagOnly: false,
        isRemoveTagOnly: false,
        callback: this.getTagsFromServer,
        customcallback: this.onEditTagConfirm
      })
    },

  }
};
</script>

<template>
  <div class="tag-container">
    <div class="tag-list">
      <div
        class="tag-container"
        v-for="(tag, index) in tags"
        :key="index">

        <div class="tag-holder">
          <div class="tag-content">
            <div><span class="tag-text" :class="{disabled: !hasEditPermissions}" @click="editTag(tag)">{{ mapTags(tag.tagvalue) }}</span></div>
            <span class="remove-tag" :class="{disabled: !hasEditPermissions}" @click="removeTag(tag)"><i class="fas fa-times-circle"></i></span>
          </div>
        </div>

      </div>
    </div>
    <div class="add-tag" :class="{disabled: !hasEditPermissions}" @click="addTag">
      + Add tag
    </div>
  </div>
</template>

<style scoped>
.tag-container {
  max-height: 220px;
  overflow: auto !important;
  text-align: center;
}
.tag-text {
  user-select: none;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  font-size: 12px;
  letter-spacing: .1px;
}
.remove-tag {
  color: #a1a1a199;
  margin-left: 7px;
  cursor: pointer;
  transition: all 150ms ease;
}
.remove-tag:hover {
  color: #a1a1a1;
}
.tag-content {
  display: flex;
  align-items: center;
}

.tag-holder {
  display: inline-block;
  padding: 4px 8px;
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 2px;
  transition: all 250ms ease;
}
.tag-holder:hover {
  background-color: #ededed;
}
.add-tag {
  margin-top: 10px;
  color: var(--olympian-blue, #1A4C8B);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  transition: all 150ms ease;
}
.add-tag:hover {
  color: black;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
</style>
