<script>
import myDraftsIconSvg from '@/assets/icons/my-drafts.svg?raw';
import { DraftMainGroups, DraftTypes } from '@/domain/drafts/draft';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import { useHarbourStore } from '@/stores/harbour-store';

export default {
  data() {
    return {
      newItemName: '',
      isEditItemName: false,
      myDraftsIconSvg,
    };
  },

  setup() {
    const draftsStore = useDraftsStore();
    const harbourStore = useHarbourStore();
    return { 
      draftsStore,
      harbourStore,
    };
  },

  computed: {
    draft() {
      return this.params.data || null;
    },
    isPdfType() {
      return this.draft?.type === DraftTypes.Pdf;
    },
    paramsValue() {
      return this.params.value; // key
    },
    itemName() {
      if (this.isMyDraftsGroup) return 'My drafts';
      if (this.isSharedDraftsGroup) return 'Shared with me';
      return this.draft?.name || '';
    },
    draftGroup() {
      const group = this.draftsStore.draftGroups.find((i) => i === this.paramsValue);
      return group || null;
    },
    isDraftGroup() {
      const hasData = !!this.draft;
      const isGroupFound = !!this.draftGroup;
      return !hasData && isGroupFound;
    },
    isMyDraftsGroup() {
      const equalMyDrafts = this.draftGroup === DraftMainGroups.MyDrafts;
      return this.isDraftGroup && equalMyDrafts;
    },
    isSharedDraftsGroup() {
      const equalMyDrafts = this.draftGroup === DraftMainGroups.SharedDrafts;
      return this.isDraftGroup && equalMyDrafts;
    },
    isUserOwner() {
      return !this.draft?.isShared;
    },
    isUserCanRename() {
      const systemEmail = this.harbourStore.contextDict.systememail;
      const isWriter = this.draft?.writer.includes(systemEmail);
      const isOrgAdmin = this.draftsStore.checkUserOrgAdminPermission(this.draft.orgId);
      return this.isUserOwner || isWriter || isOrgAdmin;
    },
    canPerformAction() {
      const isOrgAdmin = this.draftsStore.checkUserOrgAdminPermission(this.draft.orgId);
      return this.isUserOwner || isOrgAdmin;
    },
    canCopyLink() {
      if (this.isPdfType) return false;
      return true;
    },
    canShare() {
      if (this.isPdfType) return false;
      const systemEmail = this.harbourStore.contextDict.systememail;
      const isWriter = this.draft?.writer.includes(systemEmail);
      const isOrgAdmin = this.draftsStore.checkUserOrgAdminPermission(this.draft.orgId);
      return this.isUserOwner || isWriter || isOrgAdmin;
    },
    showDropdown() {
      return !this.isDraftGroup;
    },
  },

  methods: {
    handleRenameDraftClick() {
      if (!this.isUserCanRename) return;
      const { nameInput } = this.$refs;
      this.isEditItemName = true;
      this.newItemName = this.draft.name;
      this.$nextTick(() => nameInput.focus());
      this.draftsStore.isRenamingInSidebarPane = true;
    },

    onNameInputBlur() {
      const { node } = this.params;
      const { newItemName } = this;
      this.isEditItemName = false;
      this.newItemName = '';
      this.params.nameInputBlured(this.draft, newItemName, node);
      this.draftsStore.isRenamingInSidebarPane = false;
    },

    handleCopyLinkClick() {
      if (!this.canCopyLink) return;
      this.params.copyLinkClicked(this.draft);
    },

    handleShareDraftClick() {
      if (!this.canShare) return;
      this.params.shareDraftClicked(this.draft);
    },

    handleDeleteClick() {
      if (!this.canPerformAction) return;
      this.params.deleteClicked(this.draft);
    },
  },
}
</script>

<template>
  <div 
    class="hrbr-sidebar-pane-drafts-item"
    :class="[`hrbr-sidebar-pane-drafts-item--${isDraftGroup ? 'group' : 'draft'}`]">
    <div class="hrbr-sidebar-pane-drafts-item__icon">
      <div 
        class="hrbr-sidebar-pane-drafts-item__icon-my-drafts" 
        v-if="isMyDraftsGroup"
        v-html="myDraftsIconSvg">
      </div>
      <i v-else-if="isSharedDraftsGroup" class="hrbr-sidebar-pane-drafts-item__icon-shared-drafts fa-regular fa-user-group"></i>
      <i v-else class="fa-light fa-file-pen"></i>
    </div>

    <div class="hrbr-sidebar-pane-drafts-item__name" :title="itemName">
      <div class="hrbr-sidebar-pane-drafts-item__name-text" v-show="!isEditItemName" :style="{'font-weight':400}">
        {{ itemName }}
      </div>
      <input
        class="hrbr-sidebar-pane-drafts-item__name-input"
        v-model="newItemName"
        v-show="isEditItemName"
        ref="nameInput"
        type="text"
        @blur="onNameInputBlur" />
    </div>

    <div class="hrbr-sidebar-pane-drafts-item__actions" data-item-actions>
      <b-dropdown
        class="hrbr-sidebar-pane-drafts-item__dropdown"
        :append-to-body="true"
        :mobile-modal="false"
        position="is-bottom-left"
        v-if="showDropdown">
        <template #trigger>
          <i class="fa-regular fa-ellipsis-vertical"></i>
        </template>
        <template #default>
          <b-dropdown-item @click="handleRenameDraftClick" :disabled="!isUserCanRename">
            <i class="fal fa-pen"></i>
            <span>Rename</span>
          </b-dropdown-item>
          <b-dropdown-item @click="handleCopyLinkClick" :disabled="!canCopyLink">
            <i class="fal fa-link"></i>
            <span>Copy link</span>
          </b-dropdown-item>
          <b-dropdown-item @click="handleShareDraftClick" :disabled="!canShare">
            <i class="fal fa-user-plus"></i>
            <span>Share</span>
          </b-dropdown-item>
          <b-dropdown-item @click="handleDeleteClick" :disabled="!canPerformAction">
            <i class="fal fa-trash"></i>
            <span>Delete</span>
          </b-dropdown-item>
        </template>
      </b-dropdown>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar-pane-drafts-item {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  &__icon {
    display: flex;
    flex-shrink: 0;
  }

  &__icon-my-drafts {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;

    :deep(svg) {
      display: block;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__name {
    flex: 1;
    overflow: hidden;
  }

  &__name-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__name-input {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    display: block;
    width: 100%;
    height: 100%;
    min-height: 24px;
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    box-shadow: none;
    appearance: none;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
    line-height: normal;
  }

  &__dropdown {
    :deep(.dropdown-trigger) {
      font-size: 13px;
      cursor: pointer;
      padding: 0 4px;
      transition: color 0.15s;

      &:hover {
        color: var(--hrbr-primary-color-active);
      }
    }

    :deep(.background) {
      display: none !important;
    }

    &.is-active :deep(.dropdown-trigger) {
      visibility: visible !important;
    }
  }
}
</style>

<style lang="postcss">
.hrbr-sidebar-pane-drafts-item {
  &__dropdown {
    .dropdown-item {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 6px;

      i {
        display: inline-flex;
        justify-content: center;
        width: 20px;
      }
    }
  }
}
</style>
