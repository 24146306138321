<script setup>
const getUserInitials = (name) => {
  const fullName = name.split(' ');
  const firstInitial = fullName[0][0];
  const lastInitial = fullName[1] ? fullName[1][0] : '';

  return (firstInitial + lastInitial).toUpperCase();
};
</script>

<template>
  <div class="user-name">
    <div class="avatar">
      <img
        v-if="params.getUserDetails(params.value)?.profileImageUrl"
        :src="params.getUserDetails(params.value)?.profileImageUrl"
        alt="user-avatar"
      >
      <p v-else>{{ getUserInitials(params.getUserDetails(params.value)?.name || params.value) }}</p>
    </div>
    <div class="user-data">
      <p class="name">{{ params.getUserDetails(params.value)?.name || '-' }}</p>
      <p>{{ params.value }}</p>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.user-name {
  display: flex;
  align-items: center;
  gap: 15px;
}
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #D1DBF4;
  img {
    width: 100%;
    border-radius: 50%;
  }
}
.user-data {
  .name {
    font-weight: 600;
  }
}
</style>
