<script>
import debounce from '@/utils/debounce';

import { useHarbourStore } from '@/stores/harbour-store';
import { useSettingsPageStore } from '@/pages/Settings/stores/settings-store.js';


import hrbrSwitch from '@/components/ui/HrbrSwitch.vue';

export default {
  name: 'HrbrAdminGroupSettings',
  props: {
    activeGroups: {
      type: Array,
      default: () => [],
    },
    linkCreator: {
      type: String,
      default: '',
    },
  },
  components: {
    hrbrSwitch
  },
  async mounted() {
    this.initAvailableUserGroups();
    this.initFilteredAvailableGroups();
    this.initUserGroups();
    this.$nextTick(this.setInitialModalHeight);

  },
  data() {
    return {
      filteredAvailableGroups: [],
      selectedGroups: {},
      filteredAvailableGroupsString: '',
      harbourStore: useHarbourStore(),
      settingsStore: useSettingsPageStore(),
      availableGroups: [],
      initialModalHeight: 'auto',
    };
  },
  methods: {
    setInitialModalHeight() {
      const modal = this.$refs['sharing-modal'];
      this.initialModalHeight = modal.offsetHeight + 'px';
    },
    getBylineText(creatorEmail) {
      const limit = 25;
      if (creatorEmail.length > limit) {
        creatorEmail = creatorEmail.substring(0, limit) + '...';
      }
      return `Created by ${creatorEmail}`
    },

    switchClickHandler(groupId) {
      this.$set(this.selectedGroups, groupId, !this.selectedGroups[groupId])
    },

    switchIsDisabled(isGroupAdmin) {
      // link creators can share with any group they have access to
      const isLinkCreator = this.linkCreator === this.harbourStore.contextDict?.systememail;
      if (isLinkCreator) return false;

      // group admins can share with any group they are admin of
      return !this.harbourStore.isAdmin && !isGroupAdmin;
    },

    initAvailableUserGroups() {
      this.availableGroups = this.settingsStore.listUserGroups;
    },

    initFilteredAvailableGroups() {
      this.filteredAvailableGroups = this.availableGroups;
    },

    // debounced filter user groups list
    debouncedFilterGroups: debounce(function () {
      console.log('debounceFilterGroups');
      this.filterGroups();
    }, 350),

    // filter user groups list
    filterGroups() {
      if (!this.filteredAvailableGroupsString) {
        this.initFilteredAvailableGroups();
        return;
      }
      this.filteredAvailableGroups = this.availableGroups.filter((group) =>
        group.name.toLowerCase().includes(this.filteredAvailableGroupsString.toLowerCase()),
      );
    },

    closeClickHandler() {
      this.$emit('close')
    },
    saveClickHandler() {
      const selection = Object.keys(this.selectedGroups).map(groupId => {
        return {
          id: groupId,
          name: this.availableGroups.find(group => group.id === groupId)?.name,
          is_active: this.selectedGroups[groupId]
        }
      });
      this.$emit('save', selection);
      this.closeClickHandler();
    },
    async initUserGroups() {
      this.activeGroups.forEach((group) => {
        this.$set(this.selectedGroups, group.id, true);
      });
    }
  }
}
</script>

<template>
  <div class="modal-card user-group-sharing"
  :style="{height: initialModalHeight}"
  :ref="'sharing-modal'">
    <header class="modal-card-head">
      <p class="modal-card-title">Share agreement with group</p>
    </header>

    <b-field class="filter-input">
      <b-input
      v-model.trim="filteredAvailableGroupsString"
      @input="debouncedFilterGroups"
      placeholder="Filter by group name"
      icon="filter"
      icon-pack="fal"
      style="width: 100%"
      clearable
      expanded></b-input>
    </b-field>

    <section class="modal-card-body">

    <b-table
      :data="filteredAvailableGroups"
      pagination-simple
      icon-pack="fal"
      icon-prev="chevron-left"
      icon-next="chevron-right"
      per-page="5">
        <!-- availavle groups column -->
        <b-table-column label="Available groups">
          <template v-slot="props">
            <div class="group-label">
              <b-icon pack="far" :icon="props.row.icon"></b-icon>
              <div>
                <p class="group-name">{{ props.row.name }}</p>
                <p class="group-info">{{ getBylineText(props.row.created_by) }} | {{ props.row.members.length }} members</p>
              </div>
            </div>
          </template>
        </b-table-column>

        <!-- sharing switch column -->
        <b-table-column label="Shared">
          <template v-slot="props">
            <hrbrSwitch
            :disabled="switchIsDisabled(props.row.isGroupAdmin)"
            class="sharing-switch"
            :toggled-on="selectedGroups[props.row.id]"
            @click="switchClickHandler(props.row.id)">
            </hrbrSwitch>
          </template>
        </b-table-column>
      </b-table>

      <!-- loading icon if available groups not loaded yet -->
      <div v-if="!availableGroups.length" class="has-text-centered">
        <b-icon pack="fas" icon="spinner-third" size="is-large" custom-class="fa-spin"></b-icon>
      </div>
    </section>

    <footer class="modal-card-foot">
      <HrbrButton :button="{ text: 'Cancel', styleType: 'secondary' }" @click="closeClickHandler"/>
      <HrbrButton :button="{ text: 'Update', styleType: 'primary' }" @click="saveClickHandler"/>
    </footer>
  </div>
</template>

<style>
:root {
  --group-settings-edge-padding: 32px;
}

.modal-card.user-group-sharing {
  margin: 0 auto !important;
  max-width: 640px;
  background-color: white;
  border-radius: 8px;
}

.user-group-sharing .modal-card-foot,
.user-group-sharing .modal-card-head  {
  background-color: white;
}

.user-group-sharing .modal-card-foot {
  border-top: none;

  button:last-child {
    margin-left: 6px;
  }
}

.user-group-sharing .modal-card-head {
  padding: var(--group-settings-edge-padding);
  padding-bottom: 24px;
  border-bottom: none;
}

.user-group-sharing .modal-card-title {
  text-align: left;
}

.user-group-sharing .modal-card-body {
  padding: 0 var(--group-settings-edge-padding);
}

.user-group-sharing .modal-card-foot {
  padding: var(--group-settings-edge-padding);
  padding-top: 20px;
}

.user-group-sharing th {
  border-bottom-width: 1px !important;
  padding: 8px 0;
}
.user-group-sharing th:last-child .th-wrap {
  flex-direction: row-reverse;
}

.user-group-sharing .sharing-switch {
  transform: translate(0px, 50%);
  float: right;
}

.user-group-sharing .filter-input {
  margin: 0 var(--group-settings-edge-padding);
  margin-bottom: 24px;
}

.user-group-sharing .filter-input input:focus {
  border-color: var(--new-harbour-blue);
}

/* left column */
.user-group-sharing tr td {
  padding-left: 0;
}

/* right column */
.user-group-sharing tr td:last-child {
  padding-right: 0;
}

.user-group-sharing .group-label {
  display: flex;
  align-items: center;
}

.user-group-sharing .group-label .icon {
  margin-right: 12px;
}

.user-group-sharing .group-label .group-name {
  font-weight: 700;
  font-size: 14px;
}

.user-group-sharing .group-label .group-info {
  font-size: 12px;
}

.user-group-sharing .button {
  font-weight: 700;
}

.user-group-sharing .update-btn {
  background-color: var(--new-harbour-blue);
}

.user-group-sharing .update-btn:hover {
  background-color: var(--new-harbour-blue-dark);
}
</style>
