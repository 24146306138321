<script setup>
import { useHarbourStore } from '@/stores/harbour-store';
import { computed } from 'vue';
import HrbrSidebarPaneFoldersMenu from './HrbrSidebarPaneFoldersMenu.vue';
import HrbrSidebarPaneHeader from './HrbrSidebarPaneHeader.vue';
import HrbrSidebarPaneSection from './HrbrSidebarPaneSection.vue';
import { useFolderActions } from './composables/use-folder-actions';

const EDIT_PERMISSION = 'permissions.filesystem.folder.edit';

const harbourStore = useHarbourStore();
const { handleAddFolderMain } = useFolderActions();

const handleAddFolderClick = () => {
  handleAddFolderMain('#home');
};

const isEditPermission = computed(() => {
  const permissions = harbourStore.contextDict?.auth_permissions ?? [];
  return permissions.includes(EDIT_PERMISSION);
});

const isAddFolderDisabled = computed(() => {
  if (harbourStore.currentFolder === '#shared') return true;
  if (!isEditPermission.value) return true;
  return false;
});
</script>

<template>
  <div class="hrbr-sidebar-pane-tab hrbr-sidebar-pane-folders">
    <div class="hrbr-sidebar-pane-tab__head">
      <HrbrSidebarPaneHeader />
    </div>

    <div class="hrbr-sidebar-pane-tab__body">
      <div class="hrbr-sidebar-pane-tab__body-content">
        <HrbrSidebarPaneSection>
          <template v-slot:head>
            <div class="hrbr-sidebar-pane-tab__heading">
              <div class="hrbr-sidebar-pane-tab__heading-text">Folders</div>
              <div class="hrbr-sidebar-pane-tab__heading-action">
                <button
                  class="hrbr-sidebar-pane-folders__add-folder"
                  @click="handleAddFolderClick"
                  :disabled="isAddFolderDisabled">
                  <i class="fa-solid fa-plus" title="Add new folder"></i>
                </button>
              </div>
            </div>
          </template>
          <template v-slot:content>
            <HrbrSidebarPaneFoldersMenu />
          </template>
        </HrbrSidebarPaneSection>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
/* override general styles */
.hrbr-sidebar-pane-tab {
  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__body-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  :deep(.hrbr-sidebar-pane-section),
  :deep(.hrbr-sidebar-pane-section__content) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.hrbr-sidebar-pane-folders {
  &__add-folder {
    font-family: inherit;
    font-size: 14px;
    color: #8b9198;
    font-weight: 600 !important;
    padding-top: 15px;
    cursor: pointer;
    transition: color 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    padding: 0;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    appearance: none;

    &[disabled] {
      opacity: 0.4;
      cursor: default;
    }

    &:hover {
      color: var(--hrbr-primary-color-active) !important;
    }
  }
}
</style>
