<script setup>
import HrbrAppHeaderMenuItem from './HrbrAppHeaderMenuItem.vue';
import { useHarbourStore } from '@/stores/harbour-store';
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router/composables';
import { useSidebarStore } from '../Sidebar/stores/sidebar-store';
import userImgPlaceholder from '../../assets/imgs/pirate.png';
import userImgImpersontation from '../../assets/imgs/impersonation-mask.png';
import { useSettingsPageStore } from '@/pages/Settings/stores/settings-store.js';

const harbourStore = useHarbourStore();
const sidebarStore = useSidebarStore();
const settingsPageStore = useSettingsPageStore();
const router = useRouter();

const { isOrgAdmin, isGroupAdmin } = storeToRefs(settingsPageStore);

const menuList = computed(() => {
  if (isGroupAdmin.value || isOrgAdmin.value) {
    return [
      {
        id: 'settings',
        icon: 'gear',
        title: 'Settings',
      },
      {
        id: 'userGroups',
        icon: 'users',
        title: 'Users',
      },
      {
        id: 'logout',
        icon: 'arrow-right-from-bracket',
        title: 'Log out',
      },
    ];
  }

  return [
    {
      id: 'settings',
      icon: 'gear',
      title: 'Settings',
    },
    {
      id: 'logout',
      icon: 'arrow-right-from-bracket',
      title: 'Log out',
    },
  ];
});

const menuHandlers = {
  settings: () => {
    sidebarStore.setSettingsPaneActive('contact-info');
    settingsPageStore.setTitle('Company info');
    router.push({ name: 'settings', query: { filter: 'contact-info' }});
    sidebarStore.setSidebarPaneOpen(true);
    sidebarStore.setSidebarPaneDefaultWidth();
  },
  userGroups: () => {
    sidebarStore.setSettingsPaneActive('add-users');
    settingsPageStore.setTitle('Users');
    router.push({ name: 'settings', query: { filter: 'add-users' }});
  },
  logout: () => (window.location.href = '/logout'),
};

const isMenuOpen = ref(false);

const mainClasses = computed(() => ({
  'hrbr-app-header-menu--active': isMenuOpen.value,
}));

const userName = computed(() => {
  const name = harbourStore.contextDict?.systememailname;
  return name || '';
});

const userEmail = computed(() => {
  const email = harbourStore.contextDict?.systememail;
  return email || '';
});

const userImgUrl = computed(() => {
  const isUserImpersonation = harbourStore.contextDict?.is_user_impersonated;
  if (isUserImpersonation) return userImgImpersontation;
  const picture = harbourStore.contextDict?.picture;
  if (picture) return picture;
  return userImgPlaceholder;
});

const isContextLoaded = computed(() => {
  return Object.keys(harbourStore.contextDict).length;
});

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const closeMenu = () => {
  isMenuOpen.value = false;
};

const handleMenuItemClick = (id) => {
  const handler = menuHandlers[id];
  if (handler) handler();
  closeMenu();
};
</script>

<template>
  <div class="hrbr-app-header-menu" :class="mainClasses">
    <div class="hrbr-app-header-menu__trigger" @click.stop="toggleMenu">
      <img
        class="hrbr-app-header-menu__trigger-avatar"
        v-if="isContextLoaded"
        :src="userImgUrl"
        alt="" />
    </div>

    <div class="hrbr-app-header-menu__menu" v-if="isMenuOpen" v-click-outside="closeMenu">
      <div class="hrbr-app-header-menu__user-info">
        <div class="hrbr-app-header-menu__user-name">
          <div class="hrbr-app-header-menu__user-name-text">{{ userName }}</div>
          <div class="hrbr-app-header-menu__user-name-badge" v-if="isOrgAdmin">Admin</div>
        </div>
        <div class="hrbr-app-header-menu__user-email">{{ userEmail }}</div>
      </div>

      <div class="hrbr-app-header-menu__menu-list">
        <HrbrAppHeaderMenuItem
          v-for="item in menuList"
          :key="item.id"
          :id="item.id"
          :title="item.title"
          :icon="item.icon"
          @itemClick="handleMenuItemClick" />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-app-header-menu {
  display: inline-flex;
  flex: none;
  position: relative;

  &--active {
    .hrbr-app-header-menu__trigger,
    .hrbr-app-header-menu__trigger:hover {
      border-color: rgba(0, 0, 0, 0.4);
    }
  }

  &__trigger {
    --trigger-size: 46px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--trigger-size);
    height: var(--trigger-size);
    border: 5px solid #ffffff14;
    border-radius: 100%;
    cursor: pointer;
    overflow: hidden;
    transition: border-color 0.15s;

    &:hover {
      border-color: rgba(0, 0, 0, 0.2);
    }
  }

  &__trigger-avatar {
    --avatar-size: 36px;

    width: var(--avatar-size);
    height: var(--avatar-size);
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    pointer-events: none;
    user-select: none;
    background-color: #36353526;
  }

  &__menu {
    position: absolute;
    right: 0;
    top: 44px;
    width: 240px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 4px 10px rgb(0 0 0 / 25%);
    z-index: 10;
    user-select: none;
  }

  &__menu-list {
    padding: 8px 0;
    border-top: 1px solid #d7dde4;
  }

  &__user-info {
    font-size: 15px;
    line-height: 1.2;
    color: var(--hrbr-black-color);
    display: flex;
    flex-direction: column;
    padding: 20px 15px 15px;
  }

  &__user-name {
    display: flex;
    align-items: center;
    column-gap: 6px;
    font-weight: 500;
    letter-spacing: 0.2px;
  }

  &__user-name-text {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__user-name-badge {
    font-size: 11px;
    font-weight: 400;
    line-height: 1.2;
    color: var(--hrbr-black-color);
    display: inline-flex;
    flex-shrink: 0;
    padding: 2px 5px;
    border-radius: 4px;
    background: #ffd4b8;
  }

  &__user-email {
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 5px;
  }
}
</style>
