<script>
export default {
  name: 'SkeletonRenderer',
};
</script>

<template>
  <div class="container">
    <b-skeleton width="70%" :animated="true"></b-skeleton>
  </div>
</template>

<style scoped>
.container {
  width: 50% !important;

  max-width: 50%;
  display: flex;
  justify-content: center;
  pointer-events: none;
}
</style>
