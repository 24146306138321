<script>
import { mapActions } from 'pinia';
import { useLibraryStore } from '@/stores/library-store';

export default {
  data() {
    return {
      row: null,
      type: 'other',
      iconsByType: {
        userUpload: 'fa-file-upload',
        agreementAttachment: 'fa-paperclip',
        completedAgreement: 'fa-check',
        other: 'fa-file-upload',
        eRecordedDoc: 'fa-stamp'
      }
    };
  },
  created() {
    this.row = this.params.data;
  },
  methods: {
    getStatusText() {
      const status = this.row.context_source_type;

      if (status === 'AGREELINK-FINAL-AGREESUBMISSION') {
        const attachmentType = this.row.attachment_type;
        if (attachmentType === "USERATTACHMENT") {
          this.type = 'agreementAttachment'
          return "Agreement attachment"
        }
      }

      if (status === 'CATALIS_WEBSERVICE') {
        this.type = 'eRecordedDoc';
      }

      const statusMap = {
        'AGREELINK-FINAL-AGREESUBMISSION': 'Completed agreement',
        'ONLY-SIGNER-SUBMISSION': 'Completed agreement',
        'CATALIS_WEBSERVICE': 'eRecorded document'
      }

      if (!(status in statusMap)) {
        this.type='userUpload'
        return 'User upload';
      }
      return statusMap[status]
    }
  },
  computed: {
    getIconByType() {
      return this.iconsByType[this.type];
    },
    isCompletedAgreement() {
      return this.getStatusText() === 'Completed agreement'
    },
    getPillClass() {
      if (this.isCompletedAgreement) return 'completed';
      if (this.type === 'eRecordedDoc') return 'eRecorded';
      else return 'other';
    }
  }
};
</script>

<template>
  <div class="cell">
    <div class="status-container">
      <div class="status completed" v-if="getStatusText() === 'Completed agreement'">
        <i class="fal fa-check status-icon"></i>
        {{ getStatusText() }}
      </div>
      <div v-else class="status" :class="getPillClass">
        <i class="fal status-icon" :class="getIconByType"></i>
        {{ getStatusText() }}
      </div>
    </div>
  </div>
</template>

<style scoped>

.status-icon {
  margin-right: 2px;
  font-weight: 400;
}
.cell {
  display: flex;
  justify-content: center;
}
.status {
  display: inline-block;
  border-radius: 4px;
  padding: 4px 8px;
  margin-bottom: 4px;
  white-space: nowrap;

  /* Supportive Text */
  font-family: Helvetica Neue;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.completed {
    background: #06b09d;
    color: white;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 16px;
}
.other {
    background: #7885b8;
    color: white;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 16px;
    padding-left: 12px;
    padding-right: 12px;
}
.eRecorded {
  background: #197BE6;
  color: white;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 16px;
}
</style>
