// Formats a date in the format of "Apr 2 2024 4:33 pm"
export const formatDate = (date) => {
  if (!date) {
    return;
  }

  // Create a formatter for the date part (e.g., "Apr 2 2024")
  const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  // Create a formatter for the time part (e.g., "4:33 PM")
  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });

  return `${dateFormatter.format(date).replace(',', '')} ${timeFormatter
    .format(date)
    .toLowerCase()}`;
};
