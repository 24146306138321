<script setup>
import { ref } from 'vue';
const props = defineProps({
  cancelText: {
    type: String,
  },
  confirmText: {
    type: String,
  },
});
const emit = defineEmits(['confirm', 'decline']);

const show = ref(false);

defineExpose({
  show
});

const onModalClose = () => {
  cancel();
};

const confirm = () => {
  emit('confirm');
  show.value = false;
}

const cancel = () => {
  emit('decline');
  show.value = false;
};

const cancelButton = {
  text: props.cancelText || 'Cancel',
  styleType: 'secondary',
};

const confirmButton = {
  text: props.confirmText || 'Confirm',
  styleType: 'alert',
};
</script>

<template>
  <b-modal
    :active="show"
    :can-cancel="['outside']"
    trap-focus
    custom-class="confirm-dialog-modal"
    aria-role="dialog"
    aria-label="ConfirmDialog"
    aria-modal
    :on-cancel="onModalClose"
  >
    <section class="modal-card-body">
      <slot name="confirmTitle">
        <h3>Are you sure you want to leave without saving?</h3>
      </slot>
      <slot name="confirmText">
      </slot>
    </section>
    <section class="modal-card-foot">
      <slot name="dialogActions">
        <div class="actions">
          <HrbrButton :button="cancelButton" @click="cancel"/>
          <HrbrButton :button="confirmButton" @click="confirm"/>
        </div>
      </slot>
    </section>
  </b-modal>
</template>

<style lang="postcss" scoped>
.confirm-dialog-modal {
  z-index: 100;
  :deep(.modal-close) {
    display: none;
  }
  &.is-full-screen > .animation-content {
    background-color: transparent;
  }
  :deep(.modal-content) {
    max-width: 320px !important;
  }
  .modal-card-body {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }
  .modal-card-foot {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
    background-color: #fff;
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .confirm-btn {
    padding: 12px 22px 12px 25px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    background: #CB0E47;
    transition: .2s ease-in;

    &:hover {
      background: #8E0A32;
    }
    &:disabled {
      background-color: #F5CFDA;
    }
  }
  .cancel-btn {
    padding: 12px 22px 12px 25px;
    color: #333;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #D8D8D8;
    background-color: #fff;
    transition: .2s ease-in;
    &:hover {
      background-color: #DBDBDB;
    }
  }
}
</style>
