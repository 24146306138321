<script setup>
import Vue, { ref, onMounted, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useDashboardStore } from '@/stores/dashboard-store';
import LiveSignerBar from './LiveSignerBar.vue';

const dashboardStore = useDashboardStore();
const { liveSessions } = storeToRefs(dashboardStore);
const props = defineProps({
  linkObject: {
    type: Object,
    required: true,
  },
})

const isLoading = ref(true);
const sessionData = ref([]);
const totalInputs = ref(null);;

// Ask the link composable to get its audit trail data by session
const loadSessionData = async () => {
  await loadLiveSessionData();
  isLoading.value = false;
}


// Merge audit trail data without overwriting existing data
const mergeSessionData = (newSession, existingSession) => {
  const auditTrail = existingSession.audit_trail_data_json;
  const newAuditTrail = newSession.audit_trail_data_json;
  newAuditTrail.forEach((item) => {
    const existingItem = auditTrail.find(
      (existingItem) => existingItem.created_time === item.created_time,
    );
    if (!existingItem) {
      auditTrail.push(item);
    }
  });
};

// Get historical audit trail data organized into sessions for this link
const loadLiveSessionData = async () => {
  const result = await Vue.prototype.$harbourData.get(`/core/sessions/${props.linkObject.id}`);
  sessionData.value?.forEach((session) => {
    const sessionId = session.session_id;
    const existingSession = result.data.session_dict.find(
      (session) => session.session_id === sessionId,
    );
    if (existingSession) mergeSessionData(session, existingSession);
    else sessionData.value.unshift(session);
  });

  sessionData.value = result.data.session_dict;
  totalInputs.value = result.data.total_inputs;
};

const getLiveUpdates = computed(() => {
  const sessions = liveSessions.value.filter((session) => session.link_id === props.linkObject.id) || [];
  if (!sessions.length) return sessionData.value;

  sessions.forEach((s) => {
    const existingSession = sessionData.value.find((existingSession) => existingSession.session_id === s.session_id);
    if (existingSession) {
      const trail = s.audit_trail_data_json.sort((a, b) =>  a.created_time - b.created_time);
      existingSession.audit_trail_data_json = trail;
    } else {
      sessionData.value.unshift(s);
    }
  });

  return sessionData.value;
})

onMounted(() => {
  loadSessionData();
})

</script>

<template>
  <div class="live-container" v-if="!isLoading">
    <div v-for="(session, index) in getLiveUpdates" :key="index" class="session-bar-container">
      <LiveSignerBar :session="session" :totalinputs="totalInputs" :link-object="linkObject" />
    </div>
  </div>
  <div v-else class="live-loader">
    <i class="fa-light fa-spinner-third fa-spin"></i>
    <span>Loading signer session data...</span>
  </div>
</template>

<style scoped>
.live-container {
  height: 500px;
  max-height: 250px;
  overflow: auto;
}
.session-bar-container {
  padding: 2px 5px;
  height: 50px;
}
.live-loader {
  width: 100%;
  height: 100% !important;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
}
.live-loader i {
  margin-right: 10px;
}

</style>
