<script setup>
import { ref } from 'vue';
import { useColumnStore } from '@/stores/column-store';

const columnStore = useColumnStore();

const headerTitle = ref(null);
const titleEditable = ref(false);
const previousTitle = ref(null);

const handleEdit = (params) => {
  if (params.column.artisanal_id === 'document_type') return;
  titleEditable.value = true;
};

const handleBlur = (params) => {
  const title = headerTitle.value.textContent;
  const prev = previousTitle.value || title;

  if (title.length <= 3) {
    headerTitle.value.textContent = prev;
    return;
  }

  previousTitle.value = title;
  columnStore.changeColumnName(params.id, title);
};
</script>

<template>
  <div class="header-container">
    {{ params.value }}
    <div class="ai-header-icon"><i :class="params.icon"></i></div>
    <div
      class="header-input-container"
      @dblclick="handleEdit(params)"
      @blur="handleBlur(params)"
      ref="headerTitle"
      :contenteditable="titleEditable">
      {{ params.displayName }}
    </div>
  </div>
</template>

<style scoped>
.header-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
}
.ai-header-icon {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-right: 4px;
  color: #999;
}
.ai-header-icon i::before {
  font-weight: 500;
  margin-right: 2px;
  background: linear-gradient(
    270deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(191, 100, 100, 1) 30%,
    rgba(77, 82, 217, 1) 60%,
    rgb(255, 219, 102) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  color: transparent;
}

.header-input {
  outline: none;
  border: none;
  font-weight: 500;
  background-color: #ff000099;
  min-width: 0px !important;
  background-color: blue;
}
.header-input-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-input-container:focus {
  padding: 2px 5px;
}
</style>
