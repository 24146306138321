<script>
//Modal component for viewing, editing, deleting a tag
export default {
  name: 'ModalEditTag',
  props: [
    'assets',
    'displayassetid',
    'tag',
    'isnewtagonly',
    'isremovetagonly',
    'callupdatetag',
    'calladdtag',
    'callgettagsupdate',
    'customcallback',
    'currentfoldercanedit',
  ],
  data: function () {
    return {
      inputTagValue: null,
    };
  },
  created: function () {
    let self = this;
    if (self.tag != null) {
      self.inputTagValue = self.tag.tagvalue;
    } else {
      self.inputTagValue = '';
    }
  },
  mounted: function () {
    let self = this;
    if (self.isremovetagonly != true) {
      self.$refs.taginputfield.focus();
    }
  },
  methods: {
    //Close modal
    closeModal: function () {
      const self = this;
      self.$parent.close();
    },

    //Add tag
    modalAddTag: function () {
      const self = this;
      let newTagValue = self.inputTagValue.trim();
      if (newTagValue == '') {
        alert("Please enter your tag's text to proceed. Thanks.");
        return false;
      }
      self.customcallback(newTagValue);
      self.closeModal();
      let addTagToast = self.$buefy.toast.open({
        duration: 3000,
        message: `&#9654;&nbsp;&nbsp;Adding tag now...`,
        position: 'is-bottom',
        type: 'is-black',
      });

      self.$harbourData
        .post('data?filesystem-addtag', {
          requesttype: 'filesystem-addtag',
          currentassetid: self.displayassetid,
          tagvalue: newTagValue,
          tagstate: true,
        })
        .then((successResponse) => {
          self.callgettagsupdate(self.displayassetid, 'VISIONTAGS');
        })
        .catch((errorResponse) => {
          self.callgettagsupdate(self.displayassetid, 'VISIONTAGS');
          console.log('modalAddTag - unable to save new tag', errorResponse);
          alert(
            'Error: unable to add new tag at this time: please try again later or contact support@harbourshare.com',
          );
          self.closeModal();
        });

      self.$buefy.toast.open({
        duration: 2500,
        message: 'Success - new tag added',
        position: 'is-top',
        type: 'is-success',
      });
    },

    //Remove tag (by adding a tag with inactive value)
    modalRemoveTag: function () {
      const self = this;
      let newTagValue = self.inputTagValue.trim();
      self.closeModal();
      self.customcallback(this.tag);
      let removingTagToast = self.$buefy.toast.open({
        duration: 3000,
        message: `&#9654;&nbsp;&nbsp;Removing tag now...`,
        position: 'is-bottom',
        type: 'is-black',
      });

      self.$harbourData
        .post('data?filesystem-addtag', {
          requesttype: 'filesystem-addtag',
          currentassetid: self.displayassetid,
          tagvalue: newTagValue,
          tagstate: false,
        })
        .then((successResponse) => {
          self.callgettagsupdate(self.displayassetid, 'VISIONTAGS');
        })
        .catch((errorResponse) => {
          self.callgettagsupdate(self.displayassetid, 'VISIONTAGS');
          console.log('modalRemoveTag - unable to remove tag', errorResponse);
          alert(
            'Error: unable to remove tag at this time: please try again later or contact support@harbourshare.com',
          );
          self.closeModal();
        });

      self.$buefy.toast.open({
        duration: 2500,
        message: 'Success - tag removed',
        position: 'is-top',
        type: 'is-success',
      });
    },

    //Update existing tag
    modalUpdateTagValues: function () {
      const self = this;
      let newTagValue = self.inputTagValue.trim();
      self.customcallback(this.tag, newTagValue);
      if (newTagValue == '') {
        alert("Please enter your tag's text to proceed. Thanks.");
        return false;
      }
      let updatingTagToast = self.$buefy.toast.open({
        duration: 3000,
        message: `&#9654;&nbsp;&nbsp;Updating tag now...`,
        position: 'is-bottom',
        type: 'is-black',
      });
      self.closeModal();

      //Remove prior tag
      self.$harbourData
        .post('data?filesystem-addtag', {
          requesttype: 'filesystem-addtag',
          currentassetid: self.displayassetid,
          tagvalue: self.tag.tagvalue,
          tagstate: false,
        })
        .then((successResponse) => {})
        .catch((errorResponse) => {
          console.log('modalRemoveTag - unable to remove tag', errorResponse);
        });

      //Add new tag
      self.$harbourData
        .post('data?filesystem-addtag', {
          requesttype: 'filesystem-addtag',
          currentassetid: self.displayassetid,
          tagvalue: newTagValue,
          tagstate: true,
        })
        .then((successResponse) => {
          self.callgettagsupdate(self.displayassetid, 'VISIONTAGS');
        })
        .catch((errorResponse) => {
          self.callgettagsupdate(self.displayassetid, 'VISIONTAGS');
          console.log('modalAddTag - unable to save new tag', errorResponse);
          alert(
            'Error: unable to add new tag at this time: please try again later or contact support@harbourshare.com',
          );
          self.closeModal();
        });

      self.$buefy.toast.open({
        duration: 2500,
        message: 'Success - tag value updated',
        position: 'is-top',
        type: 'is-success',
      });
    },
  },
};
</script>

<template>
  <div id="modal-card-viewedittags" class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Tag</p>
    </header>
    <section class="modal-card-body">
      <!-- access note -->
      <b-message type="is-info" v-show="currentfoldercanedit == false">
        FYI - your current access is view-only (role: viewer). Only editors here can modify item
        tags.
      </b-message>
      <!-- input -->
      <b-field>
        <b-input
          ref="taginputfield"
          :disabled="isremovetagonly == true || currentfoldercanedit == false"
          v-model="inputTagValue"
          placeholder="Enter tag value..."
          type="text"
          icon-pack="fal"
          icon="tag"></b-input>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-outline" v-on:click="closeModal()">Cancel</button>
      <button
        :disabled="currentfoldercanedit == false"
        v-if="isnewtagonly == true"
        class="button is-primary"
        v-on:click="modalAddTag()">
        Add tag
      </button>
      <button
        :disabled="currentfoldercanedit == false"
        v-if="isnewtagonly != true"
        :class="isremovetagonly ? 'button is-primary' : 'button is-outline'"
        v-on:click="modalRemoveTag()">
        Remove tag
      </button>
      <button
        :disabled="currentfoldercanedit == false"
        v-if="isnewtagonly != true && isremovetagonly != true"
        class="button is-primary"
        v-on:click="modalUpdateTagValues()">
        Update tag
      </button>
    </footer>
  </div>
</template>
