<script setup>
import { ref, computed, onMounted, onUnmounted, reactive } from 'vue';
import { useHarbourStore } from '@/stores/harbour-store';
import { useDashboardStore } from '@/stores/dashboard-store';
import { ToastProgrammatic as Toast } from 'buefy';

const harbourStore = useHarbourStore();
const dashboardStore = useDashboardStore();
const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  linkObject: {
    type: Object,
    required: true,
  },
});

const isMounted = ref(false);
const isLoadingFrame = ref(true);
const currentNodes = ref([]);
const currentNodeIndex = ref();
const showSuccessMessage = ref(false);

const emit = defineEmits(['close']);

const handlePrev = () => {
  isLoadingFrame.value = true;
  if (isPrevDisabled.value || !currentNodes.value.length) return;
  currentNodeIndex.value = Math.max(0, currentNodeIndex.value - 1);
};

const handleNext = () => {
  isLoadingFrame.value = true;
  if (isNextDisabled.value || !currentNodes.value.length) return;
  currentNodeIndex.value = Math.min(currentNodeIndex.value + 1, currentNodes.value.length - 1);
};

const handleFrameReady = () => {
  isLoadingFrame.value = false;
};

const goToNextAgreement = () => {
  if (!currentNodes.value.length) {
    setTimeout(() => {
      emit('close');
    }, 4000);
    return;
  }

  showSuccessMessage.value = true;
  setTimeout(() => {
    handleNext();
    showSuccessMessage.value = false;
  }, 4000);
};

const showSuccess = () => {
  Toast.open({
    message: 'Agreement successfully completed.',
    type: 'is-success',
    position: 'is-top',
    duration: 3500,
  });
};

const handleFramePostMessage = (event) => {
  if (event.data === 'harbour_submission-completed') {
    setNodeToCompleted();
    showSuccess();
    goToNextAgreement();
  }
};

const setNodeToCompleted = () => {
  // Set the link object to signed

  const linkObject = harbourStore.myPendingContracts.find((link) => link.id === node.value.data.id);
  linkObject.awaitingMyReviewSigned = true;

  // Apply the transaction
  const transaction = {
    update: [{ id: node.value.id, awaitingMyReviewSigned: true, ...node.value.data }],
  };
  dashboardStore.gridApi.applyTransaction(transaction);
  dashboardStore.awaitingNodesToRemove.add(node.value.data.id);
  currentNodes.value = currentNodes.value.filter((n) => n.data.id !== node.value.data.id);
  currentNodeIndex.value -= 1;
  dashboardStore.gridApi.refreshCells({ force: true });
};

const isPrevDisabled = computed(() => currentNodeIndex.value - 1 < 0);
const isNextDisabled = computed(() => currentNodeIndex.value + 1 >= currentNodes.value.length);

const getMagicLink = () => {
  const signers = props.linkObject.emailRecipients;
  const me = signers.find(
    (signer) => signer.recipientemail === harbourStore.contextDict.systememail,
  );
  return me?.emailconfirmationcode;
};
const title = computed(() => node.value?.data.title);
const node = computed(() => currentNodes.value[currentNodeIndex.value]);

// Get the URL of the current node
const currentURL = computed(() => {
  if (!node.value) return;
  let url = node.value.data.url;
  const emailConfirmation = getMagicLink();
  if (emailConfirmation) {
    url = `${url}?confirmation=${emailConfirmation}`;
  }
  return url;
});

const getCurrentNodes = () => {
  dashboardStore.gridApi?.forEachNodeAfterFilter((rowNode, index) => {
    currentNodes.value.push(rowNode);
  });
};

const windowSize = reactive({
  width: window.innerWidth,
  height: window.innerHeight,
});

const changeWindowSize = () => {
  windowSize.width = window.innerWidth;
  windowSize.height = window.innerHeight;
}

const getStyle = computed(() => {
  return {
    width: `${windowSize?.width}px !important`,
    height: `${windowSize?.height}px !important`,
  }
})

onMounted(() => {
  // handleNext();
  isMounted.value = true;
  getCurrentNodes();
  changeWindowSize();

  // Set initial node index from prop
  currentNodeIndex.value = currentNodes.value.findIndex((n) => n.data.id === props.linkObject.id);
  window.addEventListener('message', handleFramePostMessage);
  window.addEventListener('resize', changeWindowSize)
});

onUnmounted(() => {
  currentNodes.value = [];
  window.removeEventListener('message', handleFramePostMessage);
  window.removeEventListener('resize', changeWindowSize)
});
</script>

<template>
  <div class="card" v-if="isMounted" :style="getStyle">
    <div class="card-header main-window-header" v-if="node">
      <div class="card-header-title">
        <span>{{ title }}</span>
        <span class="links-count">{{ currentNodeIndex + 1 }} / {{ currentNodes.length }}</span>
      </div>
    </div>

    <div class="modal-container">
      <div class="left" :class="{ disabled: isPrevDisabled }">
        <span @click="handlePrev" class="control-button">
          <i class="fas fa-chevron-left"></i>
        </span>
      </div>

      <div class="content" v-if="!currentNodes.length">
        <div>
          <i class="fal fa-check is-complete mr-1"></i><span>All pending contracts completed.</span>
        </div>
      </div>

      <div class="content" v-if="currentNodes.length && showSuccessMessage">
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <div>
            <i class="fal fa-check is-complete mr-1"></i
            ><span>Successfully completed agreement.</span>
          </div>
          <div class="mt-2">Loading next document...</div>
        </div>
      </div>

      <div class="content" v-show="!isLoadingFrame && !showSuccessMessage && currentNodes.length">
        <iframe
          class="viewport"
          :src="currentURL"
          frameborder="0"
          @load="handleFrameReady"></iframe>
      </div>
      <div class="content" v-show="isLoadingFrame && !showSuccessMessage && currentNodes.length">
        <i class="fa-light fa-spinner-third fa-spin mr-2"></i>Loading...
      </div>

      <div class="right" :class="{ disabled: isNextDisabled }">
        <span @click="handleNext" class="control-button">
          <i class="fas fa-chevron-right"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.disabled {
  pointer-events: none;
  opacity: 0.1;
}
.main-window-header {
  display: block;
}
.card {
  min-height: 100%;
  min-width: 100%;
  max-width: 100%;
  max-height: 100%;
}
.card-header-title {
  display: flex;
  justify-content: space-between;
}
.links-count {
  font-size: 11px;
  color: #999;
}
.modal-container {
  display: flex;
  flex-direction: row;
  height: 100% !important;
  padding: 10px;
  min-height: 100%;
  min-width: 100%;
}
.left,
.right,
.content {
  padding: 5px;
}
.left,
.right {
  height: 100%;
  display: flex;
  align-items: center;
}
.content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  width: 100%;
}
.content iframe {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  border: none;
}
.viewport {
  width: 100%;
  height: 100%;
}

.control-button {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.control-button:hover {
  background-color: white;
}
.is-complete {
  color: white;
  border-radius: 50%;
  padding: 5px;
  background-color: var(--grid-success);
}
@media (max-width: 768px) {
  .card {
    width: 300px;
    max-width: 300px;
    min-width: 100%;
  }
  .left,
  .right,
  .content {
    padding: 1px;
  }
}
</style>