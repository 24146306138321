<script>
import { DraftTypes } from '@/domain/drafts/draft';

export default {
  name: 'DraftsTypeRenderer',

  data() {
    return {};
  },

  computed: {
    type() {
      return this.params.data.type;
    },
    typeToView() {
      if (!this.type) return '';
      const capitalized = this.type[0].toUpperCase() + this.type.slice(1);
      return capitalized;
    },
    isPdfType() {
      return this.type === DraftTypes.Pdf;
    },
    isDocxType() {
      return this.type === DraftTypes.Docx;
    },
  },
};
</script>

<template>
  <div class="draft-type-column">
    <div class="draft-type-column__container">
        <div class="draft-type-column__type">
          <div class="draft-type-column__type-icon">
          <i class="fa-light fa-file-pdf" v-if="isPdfType"></i>
          <i class="fa-light fa-file-word" v-else-if="isDocxType"></i>
        </div>
        <div class="draft-type-column__type-name">
          {{ typeToView }}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.draft-type-column {
  padding: 0 5px;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__type {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  &__type-icon {
    font-size: 20px;
    color: #333;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &__type-name {
    font-size: 14px;
    line-height: 1;
    color: #333;
    user-select: none;
    white-space: nowrap;
  }
}
</style>
