<script>
import { reactive } from 'vue';

import HrbrSwitch from '@/components/ui/HrbrSwitch.vue';
import { useWebhooksStore } from '@/stores/api/webhooks-store';

export default {
  name: 'WebhooksStatusRenderer',
  components: {
    HrbrSwitch,
  },
  setup() {
    return {
      useWebhooksStore: useWebhooksStore(),
    };
  },
  data() {
    return {
      webhook: null,
      isActive: false,
    };
  },
  created() {
    this.webhook = reactive(this.params.data);
    this.isActive = this.webhook.status || false;
  },
  methods: {
    async onStatusChanged() {
      this.isActive = !this.isActive;
      await this.useWebhooksStore.editWebhook(this.webhook.id, {
        status: this.isActive
      });
    },
  },
};
</script>

<template>
  <div class="container">
    <HrbrSwitch
      :toggled-on="isActive"
      @click="onStatusChanged"
    />
    <div class="linkDetailText">
      {{ isActive ? 'Active' : 'Inactive' }}
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.switch-outer .toggled-on {

}

.linkDetailText {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}
</style>
