<script setup>
import { storeToRefs } from 'pinia';
import { useSettingsPageStore } from '@/pages/Settings/stores/settings-store.js';
import { DialogProgrammatic as Dialog } from 'buefy';

const settingsPageStore = useSettingsPageStore();
const { editableGroup, isOrgAdmin } = storeToRefs(settingsPageStore);

const deleteUser = (params) => {
  const isAllUsersGroup = params.isDefaultGroup() && params.getGroupId() !== 'admins';
  let message = `Are you sure you want to delete selected User from the group?`;
  if (isAllUsersGroup) {
      message = `Removing ${params.data.email} from this group will also remove their access to organization folders and drafts. Delete anyway?`;
  }
  Dialog.confirm({
    title: 'Delete User',
    message: message,
    confirmText: 'Delete',
    type: 'is-danger',
    onConfirm: () => {
      const currentGroupId = params.getGroupId();
      editableGroup.value = settingsPageStore.listUserGroups.find(group => group.group_id === currentGroupId);
      settingsPageStore.editUserGroup('deleteMember', {
          memberEmail: params.data.email,
      })
    },
  });
};

const isDeleteDisabled = (params) => {
  return params.isActionsDisabled();
};
</script>

<template>
  <div class="action-buttons">
    <div
      :class="['btn single', isDeleteDisabled(params) && 'disabled']"
      @click="deleteUser(params)"
    >
      <i class="fa-regular fa-trash"></i>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import '@/assets/css/elements/table-actions.css';
</style>

