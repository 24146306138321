<script setup>
import { onMounted, ref } from 'vue';
import { useSidebarStore } from '@components/Sidebar/stores/sidebar-store.js';
import HrbrButton from '@/components/ui/HrbrButton.vue';

const props = defineProps({
  itemData: {
    type: Object,
    default: () => {
      return {};
    },
  },
  title: {
    type: String,
  },
  icon: {
    type: String,
    default: null,
  },
  count: {
    type: Number,
    default: null,
  },
  isActive: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  overflowMenuData: {
    type: Object,
    default: null,
  },
});

const sidebarStore = useSidebarStore();

const emit = defineEmits({
  itemClick: null,
});

const onPaneItemClick = () => {
  emit('item-click');
  // Close mobile menu
  sidebarStore.setSidebarOpen(false);
};

const overflowMenu = ref(null);
onMounted(() => {
  if (props.overflowMenuData) overflowMenu.value = props.overflowMenuData
})

</script>

<template>
  <div
    class="hrbr-sidebar-pane-list-item"
    :class="{ 'hrbr-sidebar-pane-list-item--active': isActive }" @click="onPaneItemClick">
    <div class="hrbr-sidebar-pane-list-item__icon" v-if="icon" v-html="icon"></div>
    <div class="hrbr-sidebar-pane-list-item__title">
      {{ title }}
    </div>

    <HrbrButton
      v-if="overflowMenu"
      :button="overflowMenu"
      :parent-id="itemData.id"
      side="left"
      type="overflow">
    </HrbrButton>

    <!-- display loading -->
    <div class="hrbr-sidebar-pane-list-item__icon" v-if="loading"></div>

     <!-- display count -->
    <div class="hrbr-sidebar-pane-list-item__count" v-else-if="count !== null && !loading">
      {{ count }}
    </div>

  </div>
</template>

<style lang="postcss" scoped>
.hrbr-button-sidebar {
  height: auto !important;
  width: auto;
  background-color: transparent;
}
.hrbr-sidebar-pane-list-item {
  font-size: 15px;
  color: #666f75;
  line-height: 1.2;
  display: flex;
  align-items: center;
  column-gap: 6px;
  width: 100%;
  height: 36px;
  min-width: 0;
  padding: 3px 10px;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: background 0.15s, color 0.15s;

  &:hover {
    background: rgb(228 233 236 / 40%);
  }

  &--active {
    color: var(--hrbr-primary-color-active);
    background: #e2e9fb82;

    .hrbr-sidebar-pane-list-item__count {
      background: #E2E9FB;
      color: #1255fe;
    }
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    font-size: 15px;
  }

  &__title {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: 0.2px;
  }

  &__count {
    font-size: 13px;
    display: inline-flex;
    flex-shrink: 0;
    padding: 2px 5px;
    border-radius: 4px;
    background: rgba(228, 233, 236, 0.6);
    transition: background 0.15s;
  }
}
</style>