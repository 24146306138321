<script>
export default {
  name: 'HrbrAddonComponent',
  props: {
    addonName: {
      type: String,
    },
    addonUrl: {
      type: String,
    },
    addonHelpUrl: {
      type: String,
    },
  },
  methods: {
    onGetAddonClick() {
      window.open(this.addonUrl, '_blank');
    },
  },
};
</script>

<template>
  <div class="container">
    <div id="addon-card">
      <img src="../../../assets/icons/harbour-logo-blue.svg" />
      <div id="addon-main-content">
        <h1>Harbour for {{ addonName }}</h1>
        <p>By Harbour Enterprises, Inc.</p>
      </div>
      <b-button class="btn-get-addon" @click="onGetAddonClick()">
        <i class="fa-regular fa-arrow-up-right-from-square"></i>
        <span>Get</span>
      </b-button>
    </div>

    <p id="help-page">
      Having trouble installing the Harbour add-on? Visit our
      <a :href="addonHelpUrl" target="_blank">help page</a>.
    </p>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

#addon-card {
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 24px;
  width: 100%;
  max-width: 642px;
  border-radius: 8px;
  border: 1px solid var(--light-gray, #d6dbdf);
  background: #fff;
  box-shadow: 4px 8px 12px 0px rgba(93, 96, 100, 0.15);
}

#addon-card img {
  height: 120px;
  width: 120px;

  border-radius: 8px;
  border: 1px solid var(--light-gray, #d6dbdf);
  background: #fff;
  box-shadow: 4px 8px 12px 0px rgba(93, 96, 100, 0.15);
  padding: 8px;
}

#addon-card > button {
  height: 48px;
  padding: 12px 32px;
  margin-left: auto;
  border-radius: 4px;
  background: var(--olympian-blue, #1a4c8b);
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn-get-addon > :deep(span) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

#addon-main-content {
  display: flex;
  flex-direction: column;
}

#addon-main-content h1 {
  color: var(--antrhacite, #2b3134);
  /* Heading 3 */

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 150% */
}

#addon-get-options {
  padding-top: 20px;
}

#addon-get-options > a {
  display: inline-block;
  padding: 10px 0 10px 0;
}

.btn-get-addon:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  #addon-card  {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    max-width: none;
  }

  #addon-main-content {
    align-items: center;
    text-align: center;
  }

  #addon-card > button {
    margin: 0;
  }
}
</style>
