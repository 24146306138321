<script>
import { useColumnStore } from '@/stores/column-store';
import { useHarbourStore } from '@/stores/harbour-store';

export default {
  setup() {
    return {
      harbourStore: useHarbourStore(),
      columnStore: useColumnStore(),
    }
  },
  data: function () {
      return {
          filterValue: null,
          params: null
      };
  },
  methods: {
    init(params) {
      this.params = params
    },  
      updateFilter() {
          this.params.filterChangedCallback();
      },

      doesFilterPass(params) {
        // Check for rows with dates within N (filterValue) days of today
        const { column } = this.params;
        const { colId } = column;
        const { node } = params;

        // Get the row's value
        const itemValue = node.data[colId]?.extraction;

        // If the row has no value, return false
        if (!this.filterValue) return true;

        // If the filter has no value, return true so that we see all rows
        if (this.filterValue && !itemValue) return false;
        
        return this.isWithinNDaysFromDate(itemValue, this.filterValue);
      },

      isFilterActive() {
          return true;
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null;
        }
        return { value: this.filterValue };
      },

      setModel(model) {
        this.filterValue = model ? model.value : null;
      },
      
      handleInput(e) {
        this.filterValue = e.target.value;
      },

      isWithinNDaysFromDate(dateString, N) {
        const inputDate = new Date(dateString);
        const today = new Date();
        const differenceInMs = inputDate - today;
        const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
        return Math.abs(differenceInDays) <= N;
      }
  }
}
</script>

<template>
  <div class="expires-filter-container">
    <div class="filter-row">
      <div class="filter-title">Expiring in</div>
      <input type="number" class="ag-input-field-input ag-text-field-input" v-model="filterValue" @input="updateFilter($event)" :class="{ 'has-value': filterValue?.length }" />
      <span class="filter-interval">days</span>
    </div>
  </div>
</template>

<style scoped>
.has-value {
  background-color: white;
}
.filter-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-row input {
  flex-grow: 1;
}
.filter-title {
  font-weight: 400;
  padding-right: 10px;
  white-space: nowrap;
}
.filter-interval {
  padding: 0 10px;
}
.expires-filter-container {
  all: unset;
  display: flex;
  flex-direction: column;
  padding: 0 12px 7px 12px;
}
</style>