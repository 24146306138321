<script setup>
import { useSettingsPageStore } from '@/pages/Settings/stores/settings-store.js';
import { useHarbourStore } from '@/stores/harbour-store';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router/composables';
import HrbrSidebarPaneHeader from './HrbrSidebarPaneHeader.vue';
import HrbrSidebarPaneListItem from './HrbrSidebarPaneListItem.vue';
import HrbrSidebarPaneSection from './HrbrSidebarPaneSection.vue';
import { useFilterItems } from './composables/filter-items';
import { useSidebarStore } from './stores/sidebar-store';

const router = useRouter();

const harbourStore = useHarbourStore();
const sidebarStore = useSidebarStore();
const settingsPageStore = useSettingsPageStore();

const { isOrgAdmin, isGroupAdmin } = storeToRefs(settingsPageStore);

const {
  settingsPanePersonalItems: personalItems,
  settingsPaneOrgItems: orgItems,
  settingsPaneAdminItems: adminItems
} = storeToRefs(sidebarStore);

const { filteredItems: filteredPersonalItems } = useFilterItems(personalItems);
const { filteredItems: filteredOrgItems } = useFilterItems(orgItems);
const { filteredItems: filteredAdminItems } = useFilterItems(adminItems);

const handlePaneChange = (item) => {
  sidebarStore.setSettingsPaneActive(item.id);
  settingsPageStore.setTitle(item.title);
  settingsPageStore.setSubtitle(item.subtitle);
  router.push({ name: 'settings', query: { filter: item.id }});
};
</script>

<template>
  <div class="hrbr-sidebar-pane-tab hrbr-sidebar-pane-settings">
    <div class="hrbr-sidebar-pane-tab__head">
      <HrbrSidebarPaneHeader />
    </div>

    <div class="hrbr-sidebar-pane-tab__body">
      <div class="hrbr-sidebar-pane-tab__body-content">
        <HrbrSidebarPaneSection v-if="filteredPersonalItems.length">
          <template v-slot:head>
            <div class="hrbr-sidebar-pane-tab__heading">
              <div class="hrbr-sidebar-pane-tab__heading-text">Personal</div>
            </div>
          </template>

          <template v-slot:content>
            <div class="hrbr-sidebar-pane-tab__list">
              <HrbrSidebarPaneListItem
                v-for="item in filteredPersonalItems"
                :key="item.id"
                :title="item.title"
                :icon="item.icon"
                :count="item.count"
                :is-active="sidebarStore.settingsPaneActive === item.id"
                @item-click="handlePaneChange(item)" />
            </div>
          </template>
        </HrbrSidebarPaneSection>

        <HrbrSidebarPaneSection v-if="(isGroupAdmin || isOrgAdmin) && filteredAdminItems.length">
          <template v-slot:head>
            <div class="hrbr-sidebar-pane-tab__heading">
              <div class="hrbr-sidebar-pane-tab__heading-text">Group admin</div>
              <div class="hrbr-sidebar-pane-tab__heading-badge">Admin</div>
            </div>
          </template>

          <template v-slot:content>
            <div class="hrbr-sidebar-pane-tab__list">
              <HrbrSidebarPaneListItem
                v-for="item in filteredAdminItems"
                :key="item.id"
                :title="item.title"
                :icon="item.icon"
                :count="item.count"
                :is-active="sidebarStore.settingsPaneActive === item.id"
                @item-click="handlePaneChange(item)" />
            </div>
          </template>
        </HrbrSidebarPaneSection>

        <HrbrSidebarPaneSection v-if="isOrgAdmin && filteredOrgItems.length">
          <template v-slot:head>
            <div class="hrbr-sidebar-pane-tab__heading">
              <div class="hrbr-sidebar-pane-tab__heading-text">Organization</div>
              <div class="hrbr-sidebar-pane-tab__heading-badge">Admin</div>
            </div>
          </template>

          <template v-slot:content>
            <div class="hrbr-sidebar-pane-tab__list">
              <HrbrSidebarPaneListItem
                v-for="item in filteredOrgItems"
                :key="item.id"
                :title="item.title"
                :icon="item.icon"
                :count="item.count"
                :is-active="sidebarStore.settingsPaneActive === item.id"
                @item-click="handlePaneChange(item)" />
            </div>
          </template>
        </HrbrSidebarPaneSection>
      </div>
    </div>
  </div>
</template>
