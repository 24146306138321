<script setup>
import automationsApiService from '@/services/automations-api-service.js';
import { useAutomationsStore } from '@/stores/automations-store.js';
import { useHarbourStore } from '@/stores/harbour-store.js';
import { AgGridVue } from 'ag-grid-vue';
import dateFormat from 'dateformat';
import Vue, { onMounted, ref } from 'vue';

const TRIGGER_TYPE_KEYS = {
  VIEW_AGREEMENT: 'Viewed agreement',
  COMPLETE_AGREEMENT: 'Completed agreement',
  SIGN_AGREEMENT: 'Signed agreement',
};

const harbourStore = useHarbourStore();
const automationsStore = useAutomationsStore();

const props = defineProps({
  link: {
    type: Object,
    default: () => {},
  },
  gridOptions: {
    type: Object,
    default: () => {},
  },
});

const linkAutomations = ref(null);
const events = ref(null);

const emit = defineEmits(['grid-ready']);

const defaultColDef = {
  ...harbourStore.defaultColDefForGrids,
  suppressMenu: false,
  suppressColumnsToolPanel: true,
  filter: true,
  editable: false,
  resizable: false,
  sortable: true,
  suppressMovable: true,
};
const columns = [
  {
    field: 'integration_type',
    minWidth: 200,
    headerName: 'Application',
    valueGetter: (params) => {
      let integration = '';
      if (params.data.integration_type?.startsWith('custom')) {
        integration = automationsStore.paragonIntegrations.find(
          (item) => item.customIntegration?.slug === params.data.integration_type,
        );
      } else
        integration = automationsStore.paragonIntegrations.find(
          (item) => item.type === params.data.integration_type,
        );

      return integration?.name || '';
    },
    cellRenderer: 'DashboardAutomationsIntegrationRenderer',
    cellRendererParams: {
      getIcon: (data) => automationsStore.paragonMetadata[data.integration_type]?.icon,
    },
    filterValueGetter: (params) => {
      let integration = '';
      if (params.data.integration_type?.startsWith('custom')) {
        integration = automationsStore.paragonIntegrations.find(
          (item) => item.customIntegration?.slug === params.data.integration_type,
        );
      } else
        integration = automationsStore.paragonIntegrations.find(
          (item) => item.type === params.data.integration_type,
        );

      return integration?.name;
    },
    filter: 'agSetColumnFilter',
  },
  {
    field: 'trigger_type',
    minWidth: 200,
    headerName: 'Event',
    filter: 'agSetColumnFilter',
    valueGetter: (params) => TRIGGER_TYPE_KEYS[params.data.trigger_type],
    filterValueGetter: (params) => TRIGGER_TYPE_KEYS[params.data.trigger_type],
  },
  {
    field: 'updated_at',
    minWidth: 200,
    headerName: 'Date',
    valueFormatter: (params) => params.value && dateFormat(params.value, 'mmm dd yyyy hh:MM TT'),
    filter: 'agDateColumnFilter',
    filterValueGetter: (params) => new Date(params.value).setHours(0, 0, 0, 0),
  },
  {
    minWidth: 120,
    field: 'status',
    headerName: 'Status',
    cellRenderer: 'DashboardAutomationsStatusRenderer',
    filterValueGetter: (params) => {
      const status = params.data.status;
      if (status === 'success') return 'Success';
      if (status === 'pending') return 'Pending';
      if (status === 'failed') return 'Failed';
    },
    filter: 'agSetColumnFilter',
  },
  {
    minWidth: 120,
    field: 'response_body',
    headerName: 'Sub status',
    cellRenderer: 'DashboardAutomationsStatusRenderer',
    cellRendererParams: {
      isSubStatus: true,
      getDescription: (params) => {
        const response = JSON.parse(params.data.response_body).response?.pria_response?.status;
        return {
          description: response?.description,
          error: response?.recording_error,
        }
      }
    },
    valueGetter: (params) => {
      const response = JSON.parse(params.data.response_body).response?.pria_response?.status;
      return response?.name;
    },
    filterValueGetter: (params) => {
      const response = JSON.parse(params.data.response_body).response?.pria_response?.status;
      return response?.name;
    },
    filter: 'agSetColumnFilter',
  },
  {
    minWidth: 140,
    field: 'status',
    headerName: 'Actions',
    cellRenderer: 'DashboardAutomationsActionsRenderer',
    cellRendererParams: {
      linkId: props.link.id,
      link: props.link,
      updateTableData: async () => await getLinkAutomationsEvents(),
    },
    filter: false,
    suppressMenu: true,
    sortable: false,
  },
];

onMounted(async () => {
  linkAutomations.value = await automationsStore.listHarbourIntegrations(props.link.id, null, true);
  await getLinkAutomationsEvents();
});

const getLinkAutomationsEvents = async () => {
  if (linkAutomations.value.length) {
    const promises = [];
    for (const automation of linkAutomations.value) {
      const promise = automationsApiService.getAutomationEvents(automation.automation_id);
      promises.push(promise);
    }
    const results = await Promise.allSettled(promises);
    for (const result of results) {
      if (result.status === 'rejected') {
        this.$buefy.toast.open({
          duration: 2500,
          message: 'Error while listening automation events',
          position: 'is-top',
          type: 'is-danger',
        });
      }
    }
    events.value = results.reduce((acc, item) => [...acc, ...item.value.items], []);

    events.value = events.value.map(event => {
      const automation = linkAutomations.value.find(automation => automation.automation_id === event.automation_id);
      return {
        ...event,
        trigger_type: automation?.trigger_type,
        integration_type: automation?.integration_type,
        linkAutomation: automation,
      };
    });
  } else events.value = [];
};
</script>
<template>
  <div class="detail-grid-container">
    <ag-grid-vue
      class="detail-grid ag-theme-alpine hrbr-ag-font-family"
      :gridOptions="gridOptions"
      :columnDefs="columns"
      :rowData="events"
      :headerHeight="38"
      :defaultColDef="defaultColDef"
      :animateRows="false"
      @grid-ready="(evt) => emit('grid-ready', evt)" />
  </div>
</template>
<style lang="postcss" scoped>
.detail-grid-container {
  width: 100%;
  height: 100%;
}
.detail-grid-container >>> .recipient-emails-cell {
  max-width: 100%;
  width: 50%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}
.detail-grid-container .header-cell {
  font-size: 9px;
}
.detail-grid {
  height: 260px;
  --ag-cell-horizontal-padding: 0;
  --ag-icon-font-family: 'agGridAlpine';
  --ag-header-height: 200;
  --ag-header-foreground-color: #666666;
  --ag-header-cell-hover-background-color: none;
  --ag-header-cell-moving-background-color: none;
  --ag-row-hover-color: none;
  --ag-header-column-resize-handle-display: inline-block;
  --ag-header-column-resize-handle-height: 25%;
  --ag-header-column-resize-handle-width: 2px;
  --ag-header-column-resize-handle-color: #0c0c0c2b;
  --ag-tooltip-background-color: #ffffff99;
  --ag-borders: none;
  font-size: 12px !important;
  overflow: auto;
}
.detail-grid >>> .ag-header-cell {
  font-weight: 500;
  color: #666;
  border-bottom: 1px solid transparent;
  padding: 0;
}

.detail-grid >>> .ag-header {
  background-color: transparent;
  font-weight: 700;
  border-bottom: 1px solid #ededed;
}
.detail-grid >>> .ag-header-active {
  background-color: transparent;
}

.detail-grid >>> .ag-row {
  border-bottom: 1px solid #ededed;
}
.detail-grid >>> .ag-header-cell-text {
  width: 100%;
}
.detail-grid >>> .ag-header-cell {
  height: 100%;
  font-weight: 500;
  color: #666;
  border-bottom: 1px solid transparent;
}
.detail-grid >>> .ag-header-cell:hover {
  background-color: none;
  border-bottom: 1px solid #666666;
}
.detail-grid >>> .ag-column-select-header {
  display: none;
}
.detail-grid >>> .ag-header-cell-label {
  color: #363636;
  padding: 0;
  margin: 0;
  font-size: 12px !important;
  font-weight: 600;
}

.detail-grid >>> .ag-overlay-loading-center {
  padding: 20px;
  background-color: #ffffff99;
  border-radius: 5px;
}
.detail-grid >>> .detail-grid {
  height: 100%;
  width: 100%;
  line-height: 1em;
  --ag-cell-horizontal-padding: 0;
  --ag-icon-font-family: 'agGridAlpine';
  --ag-header-foreground-color: #666666;
  --ag-header-cell-hover-background-color: none;
  --ag-header-cell-moving-background-color: none;
  --ag-row-hover-color: none;
  --ag-header-column-resize-handle-display: block;
}
.detail-grid >>> .ag-cell-label-container {
  height: 40px;
}
.detail-grid >>> .ag-header-cell-label {
  font-size: 12px !important;
  color: #9ea5b0 !important;
}
.detail-grid >>> .ag-header-cell {
  padding: 10px;
  line-height: 1em;
  border: none !important;
}
.detail-grid >>> .ag-cell {
  height: 100%;
  width: 100%;
  font-size: 12px !important;
  border: none !important;
  display: flex;
  align-items: center;
}
.detail-grid >>> .ag-cell-value {
  max-height: 100%;
  overflow: auto;
  margin: 5px;
  scrollbar-width: thin;
  color: #424141eb;
}

@media (max-width: 768px) {
  .detail-grid-container {
    padding: 0;
    margin: 0;
  }
  .detail-grid {
    max-height: 100px;
  }
}
</style>
