<script setup>
import { useWebhooksStore } from '@/stores/api/webhooks-store';
const webhooksStore = useWebhooksStore();

</script>

<template>
  <div class="container">
    <div
      v-for="eventType in params.data.event_types"
      :key="eventType"
      class="webhook-status"
    >
      {{ webhooksStore.getAllowedEventTypes[eventType] }}
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: 7px;
  max-height: 115px;
  overflow: auto;
  padding: 10px 0;
  align-items: center;
}

.webhook-status {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #333;
  gap: 4px;
  height: 24px;
  white-space: nowrap;
  border-radius: 20px;
  padding: 2px 10px;
  user-select: none;
  background-color: #f4f4f4;
}
</style>
