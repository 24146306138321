<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useHarbourStore } from '@/stores/harbour-store';

const harbourStore = useHarbourStore();
const { showNewVersionAvailable } = storeToRefs(harbourStore);

const isLoading = ref(false);
const refresh = () => {
  isLoading.value = true;
  window.location.reload();
};

const later = () => {
  // If you still have this tab open tomorrow, we will remind you again.
  const reminderInterval = 24 * 60 * 60 * 1000;
  showNewVersionAvailable.value = false;

  setTimeout(() => {
    showNewVersionAvailable.value = true;
  }, reminderInterval)
};

</script>

<template>
  <div class="new-version-prompt" v-if="!isLoading">
    <div class="title-row">
      <i class="fas fa-info-circle"></i>
      New version available
    </div>
    <div class="middle-text">
      Refresh this browser tab to get the latest version of Harbour
    </div>
    <div class="button-row">
      <button class="button" @click=later>Later</button>
      <button class="button primary" @click="refresh">Refresh now</button>
    </div>
  </div>
  <div class="new-version-prompt loader-new-version" v-else>
    <div>
      <i class="fal fa-spinner-third fa-spin"></i>
    </div>
  </div>
</template>

<style scoped>
.primary:hover,
.primary:focus,
.primary:active {
  color: white !important;
}
.new-version-prompt {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 400px;
  height: 175px;
  padding: 20px;
  z-index: 99999;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #DBDBDB;
  -webkit-box-shadow: 0px 0px 1px 1px rgba(50, 50, 50, 0.15);
  -moz-box-shadow: 0px 0px 1px 1px rgba(50, 50, 50, 0.15);
  box-shadow: 0px 0px 1px 1px rgba(50, 50, 50, 0.15);
}
.new-version-prompt .title-row {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.new-version-prompt i {
  font-size: 24px;
  margin-right: 10px;
  color: #1355FF;
}
.middle-text {
  margin: 10px 0;
}
.button-row {
  display: flex;
  justify-content: flex-end;
}
.button-row > * {
  margin-right: 10px;
}
.loader-new-version {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>