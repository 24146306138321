import Vue from 'vue';
import { getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router/composables';
import { useHarbourStore } from '@/stores/harbour-store';
import { useFolderActions } from '../../Sidebar/composables/use-folder-actions';

export function useCommands() {
  const harbourStore = useHarbourStore();
  const { handleAddFolderMain } = useFolderActions();

  const router = useRouter();

  // Usage of getCurrentInstance is discouraged in app code.
  // But it's necessary to support components that use buefy.
  const instance = getCurrentInstance();

  const defaultCommands = [
    {
      title: 'Upload a document',
      icon: 'file-arrow-up',
      type: 'command',
      handler: () => {
        openAgreementEditor('agreement');
      },
    },
    {
      title: 'Start from a template',
      icon: 'file-contract',
      type: 'command',
      handler: () => {
        router.push({ name: 'templates' });
      },
    },
    {
      title: 'Create a new folder',
      icon: 'folder-plus',
      type: 'command',
      handler: () => {
        handleAddFolderMain('#home');
      },
    },
  ];

  const commands = [
    {
      title: 'Create new template',
      icon: 'file-contract',
      type: 'command',
      handler: () => {
        openAgreementEditor('template');
      },
    },
    {
      title: 'Create new agreement',
      icon: 'file-arrow-up',
      type: 'command',
      handler: () => {
        openAgreementEditor('agreement');
      },
    },
    {
      title: 'View Dashboard',
      icon: 'signature',
      type: 'command',
      handler: () => {
        router.push({ name: 'dashboard' });
      },
    },
    {
      title: 'View Templates',
      icon: 'layer-plus',
      type: 'command',
      handler: () => {
        router.push({ name: 'templates' });
      },
    },
    {
      title: 'View Folders',
      icon: 'folder',
      type: 'command',
      handler: () => {
        router.push({ name: 'folders' });
      },
    },
    {
      title: 'View Settings',
      icon: 'gear',
      type: 'command',
      handler: () => {
        router.push({ name: 'settings' });
      },
    },
    {
      title: 'View Automations',
      icon: 'bolt',
      type: 'command',
      handler: () => {
        router.push({ name: 'automations' });
      },
    },
    {
      // easter egg
      title: 'Shake it off~!',
      icon: 'comment-music',
      type: 'command',
      handler: () => {
        harbourStore.isShakeHarbour = true;
        setTimeout(() => {
          harbourStore.isShakeHarbour = false;
        }, 1800);
      },
    },
  ];

  const openAgreementEditor = (creationMode = 'agreement') => {
    const props = {
      initial_workspaceCustomProperties: harbourStore.workspaceCustomProperties,
      system_email: harbourStore.contextDict.systememail,
      gae_service: harbourStore.contextDict.gae_service,
      creationMode,
      lastbrand: true,
      parent: instance.proxy,
    };
    Vue.prototype.$openAgreementEditorModal({ props });
  };

  return {
    commands,
    defaultCommands,
  };
}
