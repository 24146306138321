<script setup>
import Vue from 'vue';
import { useDashboardStore } from '@/stores/dashboard-store';

const dashboardStore = useDashboardStore();

const handleClick = (assetData) => {
  // Send request
  Vue.prototype.$harbourData
    .post('data?assetlibrary-getmysignedagreementsignedurl', {
      requesttype: 'assetlibrary-getmysignedagreementsignedurl',
      assetfilename: assetData.assetfile_filename,
      assetgcspath: assetData.assetfile_cloudstoragename,
    })
    .then((res) => {
      // Open new tab
      window.open(res.data.signed_url, '_blank');
    })
    .catch((e) => {
      console.log('   - error: ' + e);
      alert(
        'Oops - we are unable to view the pdf file at this time. Try again later in a bit, or contact: support@harbourshare.com. Thanks~!',
      );
    });
};
</script>

<template>
  <div class="preview-container">
    <img
      :src="params.data.assetpreview_base64img"
      class="preview-img"
      @click="handleClick(params.data)" />
  </div>
</template>

<style scoped>
.preview-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
}
.preview-img {
  width: auto;
  max-height: 80px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.preview-img:hover {
  opacity: 0.8;
}
</style>
