<script setup>
import { useSidebarStore } from './stores/sidebar-store';
import sidebaPaneCloseIcon from '@/assets/icons/sidebar-pane-close.svg?raw';

const sidebarStore = useSidebarStore();

const closeSidebar = () => sidebarStore.setSidebarOpen(false);
const closeSidebarPane = () => sidebarStore.setSidebarPaneOpen(false);
</script>

<template>
  <div class="hrbr-sidebar-pane-close">
    <div
      class="hrbr-sidebar-pane-close__btn"
      v-html="sidebaPaneCloseIcon"
      @click="closeSidebarPane"></div>

    <div
      class="hrbr-sidebar-pane-close__btn hrbr-sidebar-pane-close__btn--mobile"
      v-html="sidebaPaneCloseIcon"
      @click="closeSidebar"></div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar-pane-close {
  padding: 2px;

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    color: #666f75;
    cursor: pointer;
    transition: color 0.08s;
    opacity: .8;

    &:hover {
      color: var(--hrbr-primary-color-active);
      opacity: 1;
    }

    &--mobile {
      display: none;
    }

    :deep(svg) {
      width: 18px;
      height: 18px;
      color: currentColor;
    }
  }
}

@media (max-width: 992px) {
  .hrbr-sidebar-pane-close {
    &__btn {
      display: none;

      &--mobile {
        display: flex;
      }
    }
  }
}
</style>
