import 'vite/modulepreload-polyfill';

import Vue, { markRaw } from 'vue';
import './assets/css/main.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import App from './MainApp.vue';
import router from './services/router';
import Buefy from 'buefy';
import VueMask from 'v-mask';
import config from '@/config/general-config';
import initQuill from './config/quill-config';
import { createPinia, PiniaVuePlugin } from 'pinia';
import VueObserveVisibility from 'vue-observe-visibility';
import ApiPlugin from './plugins/api';
import AgreementEditorPlugin from './plugins/agreement-editor-plugin';
import AgreementLinkPlugin from './plugins/agreement-link-plugin';
import CkeditorModalPlugin from './plugins/ckeditor-modal-plugin';
import CkeditorViewerPlugin from './plugins/ckeditor-viewer-plugin';
import clickOutsideDirective from './utils/directives/click-outside';
import EmailInput from './components/EmailInput.vue';
import HrbrButton from './components/ui/HrbrButton.vue';
import { useHarbourStore } from '@/stores/harbour-store';
import { LicenseManager } from 'ag-grid-enterprise';
import { registerGridComponents } from '@/utils/registerComponents.js';
const PdfjsPluginModule = import('./plugins/pdfjs-plugin');

/* Webapp initialization */
const start = Date.now();
let initialized = false;

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(({ store }) => {
  store.$router = markRaw(router);
});

Vue.use(pinia);
Vue.use(Buefy, { defaultIconPack: 'fal' });
Vue.use(VueObserveVisibility);
Vue.use(ApiPlugin, { spa: true, debug: false });
Vue.use(AgreementEditorPlugin);
Vue.use(AgreementLinkPlugin);
Vue.use(CkeditorModalPlugin);
Vue.use(CkeditorViewerPlugin);
Vue.use(VueMask);
Vue.component('EmailInput', EmailInput);
Vue.component('HrbrButton', HrbrButton);

registerGridComponents();

// Dynamic import for code-splitting
PdfjsPluginModule.then((mod) => Vue.use(mod.default)).catch(console.error);

// Directives
Vue.directive('click-outside', clickOutsideDirective);

// Ingore web components
Vue.config.ignoredElements = ['zapier-app-directory'];

const initHarbourKeys = () => {
  LicenseManager.setLicenseKey(config.agGridKey);
};

const removeUnusedServiceWorkers = async () => {
  const registrations = await navigator.serviceWorker.getRegistrations();
  registrations.forEach(async (registration) => {
    await registration.unregister();
  });
};

const getTime = () => `${Date.now() - start}ms`;

const restoreParams = async (params_base64) => {
  const result = await fetch(`data:application/json;base64,${params_base64}`);
  const str = await result.text();
  return JSON.parse(str);
};

// Server params come as base64 string so we need to parse it
// And initialize the store with the parsed params
const initServerParams = async () => {
  const harbourStore = useHarbourStore();
  harbourStore.logMemory("Initial memory use")
  const appElement = document.getElementById('main-app');
  const params = appElement.getAttribute('data-params');
  const parsedJsonParams = await restoreParams(params);
  harbourStore.initializeParamsFromServer(parsedJsonParams);
};

// Attempt to register the service worker
async function registerServiceWorker() {
  return new Promise((resolve, reject) => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/serviceWorker.js', { scope: '/' })
        .then((registration) => {
          console.debug('🦺 [worker init] registered with scope:', registration.scope);

          // Register the update listener
          registration.addEventListener('updatefound', () => onUpdateFound(registration));

          // We will listen for the worker ready event
          navigator.serviceWorker.addEventListener('message', onWorkerMessage);

          // If we have a worker already, check if it's the correct version
          if (registration.active) {
            console.debug('🦺 [worker init] active:', registration.active);
            navigator.serviceWorker.addEventListener('controllerchange', function onControllerChange() {
              console.debug('\n\n🦺 [worker init] controller change:', navigator.serviceWorker.controller);
            });

            if (!navigator.serviceWorker.controller) {
              console.debug('🦺 [worker init] no controller found:', navigator.serviceWorker);
            }

            setTimeout(() => {
              console.debug('🦺 [worker init] controller:', navigator.serviceWorker.controller);
              resolve('version');
              navigator.serviceWorker.controller?.postMessage({ request_type: 'version' });
            }, 150)
          } else {
            console.debug('🦺 [worker init] waiting for activation:', registration.installing);
          }
        })
        .catch((error) => {
          console.error('🦺 ⛔️ [worker init] registration failed:', error);
          reject(error);
        });
    } else {
      resolve();
    }
  });
}

// Service worker helpers
const onWorkerMessage = async (event) => {
  console.debug('🦺 [worker init] Message received:', event.data);
  if (event.data.request_type !== 'version') return;

  const { data: version } = event.data;
  const ver = import.meta.env.VITE_SERVICE_WORKER_VERSION
  if (version === ver) {
    console.debug('🦺 [worker init] No new worker version. Starting app.', getTime());
    return initApp();
  }

  // If the versions do not match, wait for the new worker to activate which will be handled by onUpdateFound
  // And receivd via the statechange event
  console.debug('🦺 [worker init] New worker version found. Cancelling requests...', getTime());
  navigator.serviceWorker.controller.postMessage({ request_type: 'abort' });
  console.debug('🦺 [worker init] New worker version found. Waiting for install', getTime());
};

const onUpdateFound = (registration) => {
  console.debug('🦺 [worker init] update found:', registration.installing);
  const newWorker = registration.installing || registration.waiting;
  newWorker.addEventListener('statechange', () => {
    console.debug('🦺 [worker init] state change:', newWorker.state);
    if (newWorker.state === 'activated') {
      console.debug('🦺 [worker init] activated:', newWorker);
      navigator.serviceWorker.removeEventListener('message', onWorkerMessage);
      initApp();
    }
  });
};

const newVersionTriggered = () => {
  const harbourStore = useHarbourStore();
  harbourStore.showNewVersionAvailable = true;
}

// Initialize the app, triggered after service worker registration
async function initApp() {
  if (initialized) return newVersionTriggered()

  console.debug('🦺 [worker init] Starting app...', getTime());
  navigator.serviceWorker.removeEventListener('message', onWorkerMessage);
  await initServerParams();   // Parse server params
  initHarbourKeys();
  initQuill();
  initialized = true;

  const harbourStore = useHarbourStore();
  harbourStore.init();

  window.onbeforeunload = function () {
    const harbourStore = useHarbourStore();
    harbourStore.logMemory("Memory use on exit")
    harbourStore.userExiting();
  };

  new Vue({
    el: '#main-app',
    router,
    pinia,
    render: (h) => h(App),
  });
}

// Attempt to start the app with worker, and fallback to store only
const init = async () => {
  console.debug('🦺 [worker init] Starting...', getTime());
  navigator.serviceWorker.addEventListener('message', onWorkerMessage);
  const result = await registerServiceWorker();
  if (result !== 'version') {
    initApp();
    return;
  }
};
init();