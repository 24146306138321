<script>
import { useWebhooksStore } from '@/stores/api/webhooks-store';
import { DialogProgrammatic as Dialog, ModalProgrammatic as Modal } from 'buefy';
import { reactive } from 'vue';
import WebhooksModal from './WebhooksModal.vue';

export default {
  name: 'WebhooksActionsRenderer',
  setup() {
    return {
      webhooksStore: useWebhooksStore(),
    };
  },
  data() {
    return {
      webhook: null,
    };
  },
  created() {
    this.webhook = reactive(this.params.data);
  },
  methods: {
    onDeleteWebhookClick() {
      const message = 'Are you sure you want to delete this webhook?';
      Dialog.confirm({
        title: 'Delete webhook',
        message,
        confirmText: 'Delete',
        type: 'is-danger',
        onConfirm: () => {
          this.webhooksStore.removeWebhook(this.webhook.id);
        },
      });
    },

    onEditWebhookClick() {
      this.webhooksStore.editedWebhook = this.webhook;
      Modal.open({
        parent: this,
        component: WebhooksModal,
        hasModalCard: true,
        events: {
          'edit-webhook': async (webhook) => {
            this.webhooksStore.editWebhook(webhook);
          },
        },
        onCancel: () => {
          this.webhooksStore.editedWebhook = null;
        }
      });
    },
  },
};
</script>

<template>
  <div class="action-buttons">
    <div
      class="btn"
      @click="onEditWebhookClick"
    >
      <i class="fal fa-edit"></i>
      Edit
    </div>
    <div
      class="btn"
      @click="onDeleteWebhookClick"
    >
      <i class="fa-light fa-trash-can"></i>
      Delete
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import '@/assets/css/elements/table-actions.css';
</style>
