<script>
export default {
  data() {
    return {
      submission: null,
    }
  },
  created() {
    this.submission = this.params.data;
  },
  methods: {
    openInFolder() {
      const query = { folderid: this.submission.folder, search: this.submission.id };
      this.$router.push({ name: 'folders', query });
    }
  }
}
</script>

<template>
  <div class="container">
    <div>
      <button @click="openInFolder" class="button">
        <i class="fal fa-folder-open mr-2"></i>
          Open in folder
      </button>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  height: 100% !important;
}
.details {
  height: 100%;
}
.button {
  padding: 10px 15px;
  border: 1px solid #dedede;
  outline: none;
  background-color: white;
  font-size: 12px !important;
}
.button:hover {
  background-color: #e2e2e2;
  color: #333;
}
</style>
