<script setup>
import { storeToRefs } from 'pinia';

import { useWorkflowsStore } from '@/stores/workflows-store';
import { DialogProgrammatic as Dialog } from 'buefy';
import HrbrSidebarPaneHeader from './HrbrSidebarPaneHeader.vue';
import HrbrSidebarPaneSection from './HrbrSidebarPaneSection.vue';
import HrbrSidebarPaneWorkflowsMenu from './HrbrSidebarPaneWorkflowsMenu.vue';
const workflowsStore = useWorkflowsStore();

const { addNewWorkflowGroup, addWorkflowFromPrompt } = workflowsStore;
const { workflowGroups, activeWorkflowGroup, groupNodeState, isWorkflowsListReady } = storeToRefs(workflowsStore);
const createNewGroup = () => {
  Dialog.prompt({
    title: 'New workflows group',
    message: 'Enter a name for the new workflows group:',
    inputAttrs: {
      placeholder: 'Workflow name',
      type: 'text',
    },
    confirmText: 'Create',
    cancelText: 'Cancel',
    onConfirm: (value) => {
      addNewWorkflowGroup(value);
    },
  });
}

const createNewWorkflow = () => {
  if (!activeWorkflowGroup.value) activeWorkflowGroup.value = workflowGroups[0];
  groupNodeState.value[activeWorkflowGroup.value] = true;
  const callback = () => {
    isWorkflowsListReady.value = true;
  }
  isWorkflowsListReady.value = false;
  addWorkflowFromPrompt(callback);
}
</script>

<template>
  <div class="hrbr-sidebar-pane-tab hrbr-sidebar-pane-workflows">
    <div class="hrbr-sidebar-pane-tab__head">
      <HrbrSidebarPaneHeader />
    </div>
    <div class="hrbr-sidebar-pane-tab__body">
      <div class="hrbr-sidebar-pane-tab__body-content">
        <HrbrSidebarPaneSection>
          <template v-slot:head>
            <div class="hrbr-sidebar-pane-tab__heading">
              <div class="hrbr-sidebar-pane-tab__heading-text">Workflow groups</div>
              <div class="hrbr-sidebar-pane-tab__heading-action">
                <b-dropdown
                  class="hrbr-sidebar-pane-workflows__heading-action-dropdown"
                  position="is-bottom-left"
                  animation=""
                  append-to-body
                  :triggers="['click']">
                  <template #trigger>
                    <i class="fa-solid fa-plus" title="New workflow or group"></i>
                  </template>
                  <template #default>
                    <b-dropdown-item @click="createNewWorkflow">
                      <i class="fal fa-project-diagram workflow-icon menu-icon"></i>
                      <span>New workflow</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="createNewGroup">
                      <i class="fal fa-code-branch menu-icon"></i>
                      <span>New group</span>
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </div>
            </div>
          </template>

          <template v-slot:content>
            <HrbrSidebarPaneWorkflowsMenu />
          </template>
        </HrbrSidebarPaneSection>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.workflow-icon {
  transform: rotate(59deg);
  margin-left: -3px;
}
.menu-icon {
  margin-right: 5px;
}
.hrbr-sidebar-pane-workflows {

  &__add-workflow-group {
    font-family: inherit;
    font-size: 14px;
    color: #8b9198;
    font-weight: 600 !important;
    padding-top: 15px;
    cursor: pointer;
    transition: color 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    padding: 0;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    appearance: none;

    &[disabled] {
      opacity: 0.4;
      cursor: default;
    }

    &:hover {
      color: var(--hrbr-primary-color-active) !important;
    }
  }
}

/* override general styles */
.hrbr-sidebar-pane-tab {
  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px 0px;
  }

  &__body-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  :deep(.hrbr-sidebar-pane-section),
  :deep(.hrbr-sidebar-pane-section__content) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
</style>
