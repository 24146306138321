<script>
import HrbrTemplateGroupSettingsModal from '@/components/Modals/HrbrTemplateGroupSettings.vue';
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';

// Modal component for viewing, editing, deleting a tag
// Migrated for vite from vue-app-library-modals
export default {
    props:["isagreementtotemplate", "parentUpdateAction"],
    data(){
        return{
            templateGroupId:null,
            templatesStore: useTemplatesStore(),
            continueButton: { text: 'Continue', styleType: 'secondary'},
            addNewTemplateButton: {
                text: 'New',
                styleType: 'secondary',
                style: {
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0
                },
                iconLeft: 'fa-regular fa-plus',
            }
        };
    },
    methods:{

        // Add new template group modal window call
        addNewTemplateGroup(){
            this.$buefy.modal.open({
                parent: this,
                component: HrbrTemplateGroupSettingsModal,
                hasModalCard: true,
                props: {
                  initUserGroupsList: this.templatesStore.userGroupsList,
                },
                events:{
                  'add-template-group': (newTemplateGroup) => {
                      this.templateGroupId = newTemplateGroup.groupId;
                      this.templatesStore.addTemplateGroup(newTemplateGroup);

                      this.$buefy.toast.open(
                      {
                          duration: 2500,
                          message: 'Success - new group label is added, click start to save',
                          position: 'is-top',
                          type: 'is-success'
                      }
                      );
                    }
                }
            });
        },

        //Update group label value
        modalUpdatePublishSettings:function(){
          this.parentUpdateAction(this.templateGroupId);
          this.closeModal();
        },

        //Close modal
        closeModal:function(){
            this.$parent.close();
        }
    },
};
</script>

<template>
  <div id="modal-card-publishtemplatesettings" class="modal-card" style="width: 520px;">
            <header class="modal-card-head">
                <p class="modal-card-title">Publish settings</p>
            </header>
            <section class="modal-card-body">
                <!-- signer selections -->
                <div v-if="isagreementtotemplate">
                   Changing from agreement to template will require some more information, please fill out the following:
                </div>
                <section v-if="isagreementtotemplate" class="signerSelectionSection">
                    <!-- Create template -->
                    <div>
                        <!-- Select group label -->
                        <b-field position="is-centered">
                            <b-select class="templateGroupLabelId" v-model="templateGroupId" placeholder="-- Select template group name --" expanded>
                                <option
                                        v-for="templateGroup in templatesStore.templatesGroups"
                                        :value="templateGroup.groupId"
                                        :key="templateGroup.groupId">
                                    {{ templateGroup.groupName }}
                                </option>
                            </b-select>
                            <HrbrButton :button="addNewTemplateButton" @click="addNewTemplateGroup"/>
                        </b-field>
                    </div>
                </section>
            </section>
            <footer class="modal-card-foot">
                <HrbrButton :button="continueButton" @click="modalUpdatePublishSettings()"/>
            </footer>
        </div>
</template>
