<script setup>
import HrbrParagonIntegrations from '@components/Paragon/HrbrParagonIntegrations.vue';
import { useAutomationsStore } from '@/stores/automations-store.js';

defineProps({
  linkId: {
    type: String
  }
});

const automationsStore = useAutomationsStore();
const emit = defineEmits(['updateDashboardView']);
</script>

<template>
  <div :class="['modal-card', {'file-picker-opened': automationsStore.filePickerOpened}]">
    <div class="modal-card-head">
      <p class="modal-card-title">Set up automations</p>
    </div>
    <div class="modal-card-body">
      <HrbrParagonIntegrations
        :agreement-link-id="linkId"
        @update-dashboard-view="emit('update-dashboard-view')"
      />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.modal-card {
  &.file-picker-opened {
    & + :deep(.modal-close) {
      display: none !important;
    }
  }
}
 .modal-card-body {
     border-bottom-left-radius: 6px;
     border-bottom-right-radius: 6px;
 }
</style>
