<script setup>
import { ref } from 'vue';
import { useLibraryStore } from '@/stores/library-store';
const { expandDetails } = useLibraryStore();
const isExpanded = ref(false);
const expand = (id) => {
  expandDetails(id);
  isExpanded.value = !isExpanded.value;
}
</script>

<template>
  <div class="folder-name-container">
    <div class="name-row">
      {{ params.data?.name }}
    </div>
    <div class="audit-trail-button-row">
      <button class="hrbr-grid-button" @click="expand(params.data?.id)">
        <i class="fal fa-list"></i>
        <span v-if="!isExpanded">View audit trail</span>
        <span v-else>Hide audit trail</span>
      </button>
    </div>
  </div>
</template>

<style scoped>
.name-row {
  cursor: text;
  word-break: normal;
}
.audit-trail-button-row {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
}
.audit-trail-button-row > button > i {
  margin-right: 4px;
}
</style>