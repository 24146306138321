<script>
import { useSearchStore } from '@/components/Search/stores/search-store';
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';
import { useHarbourStore } from "@/stores/harbour-store";
import debounce from '@/utils/debounce';

export default {
  name: 'HrbrAdvancedSearchModal',

  props: ['searchInput', 'isMoreFilters'],

  setup() {
      const harbourStore = useHarbourStore();
      const searchStore = useSearchStore();
      const templatesStore = useTemplatesStore();
      return {searchStore, harbourStore, templatesStore};
  },

  data() {
    return {
      agreementFilters: {},
      isAutoPopulateInProgress: false,
      agreementTypes: [],
      agreementCreators: [],
      agreementInputFields: [],
      agreementsFolders: [],
      agreementUniqueIDFilterValue: null,
      agreementFolderIDFilterValue: null,
      agreementCreatorEmailFilterValue: null,
      agreementInputFilters: [],
      agreementAttachmentFilters: [{ value: null, type: 'filename' }],
      autoCompleteSuggestions: [],
      agreementTagsFilter: [],
      agreementCreateDatesFilter: null,
      attachmentFileName: null,
      totalHitsCount: -1,
      isExactTextMatch: false,
      isFiltersLoading: false,
      agreementTextFilter: '',
      multipleTextSearchSettings: 'or',
      multipleAgreementTextFilter: [''],
      selectedInputFilterValueOptions: [],
      numInputFilters: 0,
      numAttachmentFilters: 0,
      numTextFilters: 0,
      activeFilters: 0,
      contextDict: null,
      accessOption: 'defaultaccess',
    };
  },

  created() {
    let self = this;

    self.multipleAgreementTextFilter = self.searchInput.split(' OR ');
    //Get the number of active filters
    self.activeFilters = localStorage.getItem('activeFilters');
    if (self.activeFilters == undefined) {
        self.activeFilters = 0;
    } else {
        self.activeFilters = parseInt(self.activeFilters);
    }

    //Get advanced search filters from local storage
    let filters = localStorage.getItem('advancedSearchFilters');
    if (filters) {
        //Get advanced search filters from local storage
        self.agreementFilters = JSON.parse(filters);

        //If in advancedsearch page auto populate filters from local storage
        if (window.location.href.includes('/advancedsearch') || self.isMoreFilters) {
            //Get multiple text filters
            self.multipleAgreementTextFilter = localStorage.getItem('multipleAgreementTextFilter');
            if (self.multipleAgreementTextFilter == undefined) {
                self.multipleAgreementTextFilter = [''];
            } else {
                self.multipleAgreementTextFilter = JSON.parse(self.multipleAgreementTextFilter);
            }

            self.autoPopulateSearchFilters(
                function () {
                    self.isAutoPopulateInProgress = false;
                    console.log('#runQuickSearch after autoPopulateSearchFilters');
                    self.runQuickSearch(false, false);
                },
                function (error) {
                    self.isAutoPopulateInProgress = false;
                    console.log('#created error while autoPopulateSearchFilters:' + error);
                    console.log('#build filters from scratch and runQuickSearch');
                    self.runQuickSearch();
                },
            );
        } else {
            //Get total number of existing documentswithout filters
            self.runQuickSearch();
        }
    } else {
        //Get total number of existing documentswithout filters
        self.runQuickSearch();
    }
  },

  computed: {
    searchAccessOptions() {
      return [
        {
          title: 'My view',
          value: 'defaultaccess',
          icon: 'user',
          tooltipLabel: 'Search across my agreements',
        },
        {
          title: 'Admin view',
          value: 'adminaccess',
          icon: 'users-crown',
          tooltipLabel: 'Search across organization agreements',
        },
      ];
    },
    isOrgAdmin() {
        return (this.harbourStore.contextDict?.auth_roles || []).includes('orgAdmin');
    },
    cancelButton() {
      return {
        text: 'Cancel',
        styleType: 'secondary',
      };
    },
    noResultsButton() {
      return {
        text: 'No results',
        styleType: 'secondary',
        style: {
          marginLeft: '6px'
        },
        disabled: true
      };
    },
    showAgreementsButton() {
      return {
        text: `Show ${this.totalHitsCount > 0 ? this.totalHitsCount : ''} agreements`,
        styleType: 'primary',
        style: {
          marginLeft: '6px'
        },
      };
    },
  },

  methods: {
    // get agreement custom inputs
    getAgreementTypes(successCallback = function () {}, failedCallback = function () {}) {
      let self = this;

      // Get all agreement types
      self.$harbourData
        .post('data?advancedsearch-getagreementtypes', {
          requesttype: 'advancedsearch-getagreementtypes',
        })
        .then((response) => {
          // Response data and set agreement types
          response = response.data;
          if ('agreement_types' in response && response['agreement_types']) {
            self.agreementTypes = response.agreement_types ?? [];
            self.agreementTypes = self.agreementTypes.sort(this._agreemntsByTitle);
          }
          successCallback();
        })
        .catch((errorResponse) => {
          console.log(
            'advancedsearch-getagreementtypes - unable to get agreement types for filters',
            errorResponse,
          );
          failedCallback(errorResponse);
        });
    },

    //get agreement creators emails
    getAgreementsCreatorsEmails(successCallback = function () {}, failedCallback = function () {}) {
      let self = this;

      self.$harbourData
        .post('data?advancedsearch-getagreementscreators', {
          requesttype: 'advancedsearch-getagreementscreators',
          filters: {
            text_filter: {},
            input_filters: [],
            attachment_filters: [],
            tags_filter: {},
            creation_date_filter: {},
            is_admin_access: self.accessOption == 'defaultaccess' ? false : true,
            page_num: 1,
          },
        })
        .then((response) => {
          //Response data and set agreement creators
          response = response.data;
          self.agreementCreators = response.linkscreators;
          if (self.agreementCreators.length > 0) {
            self.agreementCreators.sort();
          }
          successCallback();
        })
        .catch((errorResponse) => {
          console.log(
            'advancedsearch-getagreementscreators - unable to get agreement creatores for filters',
            errorResponse,
          );
          failedCallback(errorResponse);
        });
    },

    // get folders names and ids
    getAgreementsFolders(successCallback = function () {}, failedCallback = function () {}) {
      let self = this;

      //Get all agreement folders
      self.$harbourData
        .post('data?advancedsearch-getaccountfolders', {
          requesttype: 'advancedsearch-getaccountfolders',
          filters: {
            text_filter: {},
            input_filters: [],
            attachment_filters: [],
            tags_filter: {},
            creation_date_filter: {},
            is_admin_access: self.accessOption == 'defaultaccess' ? false : true,
            page_num: 1,
          },
        })
        .then((response) => {
          //Response data and set agreements folders
          response = response.data;
          self.agreementsFolders = response.folders_details;
          if (self.agreementsFolders.length > 0) {
            self.agreementsFolders.sort((a, b) => (a.preferred_name > b.preferred_name ? 1 : -1));
          }
          successCallback();
        })
        .catch((errorResponse) => {
          console.log(
            'advancedsearch-getaccountfolders - unable to get agreements folders for filters',
            errorResponse,
          );
          failedCallback(errorResponse);
        });
    },

    //get agreement custom inputs
    getAgreementInputs(
      isCalledFromInput = false,
      successCallback = function () {},
      failedCallback = function () {},
    ) {
      let self = this;

      //Avoid calling this wile auto-populatinf
      if (isCalledFromInput && self.isAutoPopulateInProgress) {
        successCallback();
        return false;
      }

      //If agreement type is in filters get agreement inputs
      if (self.agreementUniqueIDFilterValue) {
        self.isFiltersLoading = true;

        self.$harbourData
          .post('data?advancedsearch-getagreementinputs', {
            requesttype: 'advancedsearch-getagreementinputs',
            agreementuniqueid: self.agreementUniqueIDFilterValue,
          })
          .then((response) => {
            response = response.data;
            if ('custom_input_fields' in response && response['custom_input_fields']) {
              self.agreementInputFields = response.custom_input_fields;
              if (self.agreementInputFields.length > 0) {
                self.agreementInputFields = self.agreementInputFields.filter(
                  (p) => p.itemtype != 'SIGNATUREINPUT',
                );
              }
            }
            self.isFiltersLoading = false;
            successCallback();
          })
          .catch((errorResponse) => {
            console.log(
              'advancedsearch-getagreementtypes - unable to get agreement types for filters',
              errorResponse,
            );
            self.isFiltersLoading = false;
            failedCallback();
          });
      } else {
        self.agreementInputFields = [];
        self.isFiltersLoading = false;
        successCallback();
      }
    },

    //Delay calling quicksearch for some inputs
    debouncedRunQuickSearch: debounce(function (event) {
      let self = this;
      self.runQuickSearch();
    }, 500),

    //Delay calling autocomplete results
    debouncedGetAutoCompleteSuggestions: debounce(function (event) {
      let self = this;
      let text_search_word_len = event.split(' ').length;
      if (event && event.length >= 4 && text_search_word_len < 15) {
        self.getAutoCompleteSuggestions(event);
      }
    }, 500),

    //Aut populate input filters
    autoPopulateInputSearchFilters(
      successCallback = function () {},
      failedCallback = function () {},
    ) {
      let self = this;
      let inputFilters = self.agreementFilters['input_filters'];
      self.agreementInputFilters = [];
      self.numInputFilters = 0;
      if (inputFilters.length > 0) {
        let agreementIdFilter = inputFilters.find((a) => a.filter_name == 'source.agreement_id');
        if (agreementIdFilter) {
          let agreement_id = agreementIdFilter.filter_value;
          let agreement = self.templatesStore.myTemplates.find((a) => a.agreement_id == agreement_id);
          self.agreementUniqueIDFilterValue = agreement.agreement_unique_id;
          self.getAgreementInputs(
            false,
            function () {
              inputFilters.forEach(function (filter) {
                let index = filter.filter_index;
                if (filter.filter_name != 'source.agreement_id') {
                  if (
                    filter.filter_name ==
                    'document.context_source.agreementcustominputsjson.custominputs.itemid'
                  ) {
                    self.agreementInputFilters.push({
                      itemid: null,
                      itemlinkvalue: null,
                      itemtype: null,
                    });
                    self.agreementInputFilters[index]['itemid'] = filter.filter_value;
                    self.getAgreementInputFieldDetails(index);
                    let inputValue = inputFilters.find(
                      (a) =>
                        a.filter_index == index &&
                        a.filter_name ==
                          'document.context_source.agreementcustominputsjson.custominputs.itemlinkvalue',
                    );
                    let selectedInputFilter = self.agreementInputFields.find(
                      (a) => a.itemid == self.agreementInputFilters[index]['itemid'],
                    );
                    if (selectedInputFilter) {
                      self.agreementInputFilters[index]['itemlinkvalue'] = inputValue.filter_value;
                      self.agreementInputFilters[index]['itemtype'] = inputValue.filter_type;
                      self.numInputFilters += 1;
                    }
                  }
                }
              });
              successCallback();
            },
            function (error) {
              console.log('#autoPopulateInputSearchFilters error:' + error);
              failedCallback(error);
            },
          );
        }
        let agreementCreatorFilter = inputFilters.find(
          (a) => a.filter_name == 'creator_email' || a.filter_name == 'uploader_email',
        );
        if (agreementCreatorFilter) {
          let agreement_creator_email = agreementCreatorFilter.filter_value;
          self.agreementCreatorEmailFilterValue = agreement_creator_email;
        }
        let agreementFolderNameFilter = inputFilters.find(
          (a) => a.filter_name == 'source.parent_folder_details.unique_id',
        );
        if (agreementFolderNameFilter) {
          let agreement_folder_name = agreementFolderNameFilter.filter_value;
          self.agreementFolderIDFilterValue = agreement_folder_name;
        }
      }
      successCallback();
    },

    //Aut populate all search filters
    autoPopulateSearchFilters(successCallback = function () {}, failedCallback = function () {}) {
      let self = this;
      self.isAutoPopulateInProgress = true;
      self.autoPopulateInputSearchFilters(
        function () {
          let attachmentFilters = self.agreementFilters['attachment_filters'];
          self.numAttachmentFilters = 0;
          if (attachmentFilters.length > 0) {
            attachmentFilters.forEach(function (filter) {
              if (filter && filter.filter_name == 'source.file_name') {
                let index = attachmentFilters.indexOf(filter);
                if (index > self.agreementAttachmentFilters.length - 1) {
                  self.agreementAttachmentFilters.push({ value: null, type: 'filename' });
                  self.numAttachmentFilters += 1;
                }
                self.agreementAttachmentFilters[index]['value'] = filter.filter_value;
              }
            });
          }

          let dateFilters = self.agreementFilters['creation_date_filter'];
          if (Object.keys(dateFilters).length > 0) {
            if ('filter_value' in dateFilters) {
              let fromDateTime = new Date(dateFilters.filter_value[0]);
              let toDateTime = new Date(dateFilters.filter_value[1]);
              self.agreementCreateDatesFilter = [fromDateTime, toDateTime];
            }
          }
          let tagsFilters = self.agreementFilters['tags_filter'];
          if (Object.keys(tagsFilters).length > 0) {
            if ('filter_value' in tagsFilters) {
              self.agreementTagsFilter = tagsFilters.filter_value;
            }
          }
          let textFilters = self.agreementFilters['text_filter'];
          if (Object.keys(textFilters).length > 0) {
            if ('filter_value' in textFilters) {
              self.agreementTextFilter = textFilters.filter_value;
              self.isExactTextMatch = textFilters.is_exact_match;
            }
          }
          if (self.agreementFilters['is_admin_access']) {
            self.accessOption = 'adminaccess';
          }
          self.isAutoPopulateInProgress = false;
          successCallback();
        },
        function (error) {
          self.isAutoPopulateInProgress = false;
          console.log('#autoPopulateSearchFilters error:' + error);
          failedCallback(error);
        },
      );
    },

    processTextFilters(text) {
      let self = this;
      text = text.replace(/[&\/\\:~'"*<>{}]/g, '').toLowerCase();
      text = self.textSanitize(text);
      if (/^-?\d+$/.test(text)) {
        let textFormatted = (+text).toLocaleString('en');
        text = text + ' || ' + textFormatted;
      }
      return text;
    },

    //Build agreement filters from the user inputted filters
    buildAgreementsFilters(isAutoComplete = false, autoCompleteText = '') {
      let self = this;
      let tempAgreementFilters = {
        text_filter: {},
        input_filters: [],
        attachment_filters: [],
        tags_filter: {},
        creation_date_filter: {},
        is_admin_access: self.accessOption == 'defaultaccess' ? false : true,
        page_num: 1,
      };
      let activeFilters = 0;

      //Input Filters
      let inputFilters = self.agreementInputFilters.filter(
        (a) => a.itemlinkvalue != null && a.itemlinkvalue != '',
      );
      tempAgreementFilters['input_filters'] = tempAgreementFilters['input_filters'] || [];
      if (inputFilters.length > 0) {
        inputFilters.forEach(function (input) {
          let index = inputFilters.indexOf(input);
          let temp_input_id = {};
          temp_input_id['filter_name'] =
            'document.context_source.agreementcustominputsjson.custominputs.itemid';
          temp_input_id['filter_index'] = index;
          temp_input_id['filter_type'] = input['itemtype'];
          temp_input_id['filter_value'] = input['itemid'];
          let temp_input_value = {};
          temp_input_value['filter_name'] =
            'document.context_source.agreementcustominputsjson.custominputs.itemlinkvalue';
          temp_input_value['filter_index'] = index;
          temp_input_value['filter_type'] = input['itemtype'];
          temp_input_value['filter_value'] = input['itemlinkvalue'];
          tempAgreementFilters['input_filters'].push(temp_input_id);
          tempAgreementFilters['input_filters'].push(temp_input_value);
          activeFilters += 1;
        });
      }

      //Agreement type id filter
      if (self.agreementUniqueIDFilterValue) {
        let agreement = self.agreementTypes.find(
          (a) => a.agreement_unique_id == self.agreementUniqueIDFilterValue,
        );
        if (agreement) {
          let temp_input_value = {};
          temp_input_value['filter_name'] = 'source.agreement_id';
          temp_input_value['filter_index'] = -1;
          temp_input_value['filter_type'] = 'agreement_id';
          temp_input_value['filter_value'] = agreement.agreement_id;
          tempAgreementFilters['input_filters'].push(temp_input_value);
        }
        activeFilters += 1;
      }

      //Agreement Creator email filter
      if (self.agreementCreatorEmailFilterValue) {
        let temp_creator_email = {};
        temp_creator_email['filter_name'] = 'creator_email';
        temp_creator_email['filter_index'] = -1;
        temp_creator_email['filter_type'] = 'creator_email';
        temp_creator_email['filter_value'] = self.agreementCreatorEmailFilterValue;
        tempAgreementFilters['input_filters'].push(temp_creator_email);
        activeFilters += 1;
      }

      //Agreement folder name filter
      if (self.agreementFolderIDFilterValue) {
        let temp_folder = {};
        temp_folder['filter_name'] = 'source.parent_folder_details.unique_id';
        temp_folder['filter_index'] = -1;
        temp_folder['filter_type'] = 'parent_folder_id';
        temp_folder['filter_value'] = self.agreementFolderIDFilterValue;
        tempAgreementFilters['input_filters'].push(temp_folder);
        activeFilters += 1;
      }

      //Create date filter
      if (self.agreementCreateDatesFilter && self.agreementCreateDatesFilter.length == 2) {
        let fromDate = self.agreementCreateDatesFilter[0];
        let toDate = self.agreementCreateDatesFilter[1];
        if (fromDate && toDate) {
          let fromDateTime = new Date(fromDate);
          let toDateTime = new Date(toDate);
          let fromDateStr =
            fromDateTime.getFullYear() +
            '-' +
            (fromDateTime.getMonth() + 1) +
            '-' +
            fromDateTime.getDate();
          let toDateStr =
            toDateTime.getFullYear() +
            '-' +
            (toDateTime.getMonth() + 1) +
            '-' +
            toDateTime.getDate();
          tempAgreementFilters['creation_date_filter']['filter_name'] =
            'source.creation_details.uploader_date_time';
          tempAgreementFilters['creation_date_filter']['filter_value'] = [fromDateStr, toDateStr];
          activeFilters += 1;
        }
      }

      //Attachment Filters
      let attachmentFilters = self.agreementAttachmentFilters.filter(
        (a) => a.value != null && a.value != '',
      );
      tempAgreementFilters['attachment_filters'] = tempAgreementFilters['attachment_filters'] || [];
      if (attachmentFilters.length > 0) {
        attachmentFilters.forEach(function (attachment) {
          let temp_attachment = {};
          temp_attachment['filter_name'] = 'source.file_name';
          temp_attachment['filter_type'] = 'fielname';
          temp_attachment['filter_value'] = attachment['value'];
          tempAgreementFilters['attachment_filters'].push(temp_attachment);
        });
        activeFilters += 1;
      }

      //Tags Filters
      if (self.agreementTagsFilter && self.agreementTagsFilter.length > 0) {
        tempAgreementFilters['tags_filter']['filter_name'] = 'document.user_provided_info';
        tempAgreementFilters['tags_filter']['filter_value'] = self.agreementTagsFilter;
        activeFilters += 1;
      }

      //Text Search Filters
      if (!isAutoComplete) {
        if (self.multipleAgreementTextFilter.length > 0 && self.multipleAgreementTextFilter[0]) {
          //Preprocess the text filters
          let processedMultipleAgreementTextFilter = self.multipleAgreementTextFilter
            .map((s) => self.processTextFilters(s))
            .filter((s) => s != null && s != '');

          let processed_text_filter = processedMultipleAgreementTextFilter.join(' || ');
          tempAgreementFilters['text_filter']['filter_name'] =
            'document.full_asset_text.pdf_paragraph_text';
          tempAgreementFilters['text_filter']['filter_value'] = processed_text_filter;
          tempAgreementFilters['text_filter']['is_exact_match'] = self.isExactTextMatch;
          activeFilters += 1;
        }
      } else {
        let processedOriginalText = self.processTextFilters(autoCompleteText);
        tempAgreementFilters['text_filter']['filter_name'] =
          'document.full_asset_text.pdf_paragraph_text';
        tempAgreementFilters['text_filter']['filter_value'] = processedOriginalText;
        tempAgreementFilters['text_filter']['is_exact_match'] = self.isExactTextMatch;
      }

      self.agreementFilters = tempAgreementFilters;
      self.activeFilters = activeFilters;
    },

    //Run quick search to get documents count
    runQuickSearch(isCalledFromInput = false, buildFilters = true) {
      let self = this;
      if (isCalledFromInput && self.isAutoPopulateInProgress) {
        return false;
      }
      if (buildFilters) {
        self.buildAgreementsFilters();
      }
      self.isFiltersLoading = true;
      self.$harbourData
        .post('data?advancedsearch-runquicksearch', {
          requesttype: 'advancedsearch-runquicksearch',
          filters: self.agreementFilters,
        })
        .then((response) => {
          response = response.data;
          self.totalHitsCount = response.totalhitscount;
          self.isFiltersLoading = false;
        })
        .catch((errorResponse) => {
          console.log(
            'advancedsearch-getagreementtypes - unable to get agreement types for filters',
            errorResponse,
          );
          self.isFiltersLoading = false;
        });
    },

    //Autocomplete suggestions
    getAutoCompleteSuggestions(autoCompleteText) {
      let self = this;
      self.buildAgreementsFilters(true, autoCompleteText);

      self.$harbourData
        .post('data?advancedsearch-autocompletesuggestions', {
          requesttype: 'advancedsearch-autocompletesuggestions',
          filters: self.agreementFilters,
        })
        .then((response) => {
          response = response.data;
          self.autoCompleteSuggestions = response.autocompletesuggestions;
        })
        .catch((errorResponse) => {
          console.log(
            'advancedsearch-autocompletesuggestions - unable to get autocomplete suggestions',
            errorResponse,
          );
        });
    },

    //Get the search results page
    applyFilters() {
      let self = this;
      localStorage.setItem('advancedSearchFilters', JSON.stringify(self.agreementFilters));
      localStorage.setItem(
        'multipleAgreementTextFilter',
        JSON.stringify(self.multipleAgreementTextFilter),
      );
      localStorage.setItem('activeFilters', JSON.stringify(self.activeFilters));
      if (window.location.href.includes('/advancedsearch')) {
        self.closeModal();
        self.searchStore.currentPage = 1;
        self.$emit('searchInputChange', self.multipleAgreementTextFilter.join(' OR '));
        self.searchStore.getPaginatedDocuments();
      } else {
        self.closeModal();
        self.$router.push({ name: 'advancedsearch' });
      }
    },

    //Get agreement input field details
    getAgreementInputFieldDetails(i) {
      let self = this;
      if (
        self.agreementInputFields.length > 0 &&
        self.agreementInputFilters.length > 0 &&
        self.agreementInputFilters[i]['itemid']
      ) {
        let selectedInputFilter = self.agreementInputFields.find(
          (a) => a.itemid == self.agreementInputFilters[i]['itemid'],
        );
        if (selectedInputFilter) {
          self.agreementInputFilters[i]['itemtype'] = selectedInputFilter.itemtype;
          if (self.agreementInputFilters[i]['itemtype'] == 'SELECT') {
            self.selectedInputFilterValueOptions = selectedInputFilter.itemoptions;
          }
        }
      }
    },

    addAgreementInputFilter() {
      let self = this;
      let inputFiltersLength = self.agreementInputFilters.length;
      if (
        inputFiltersLength > 0 &&
        !self.agreementInputFilters[inputFiltersLength - 1]['itemlinkvalue']
      ) {
        alert('Please fill current input filter before adding new one.');
        return;
      }
      self.numInputFilters += 1;
      self.agreementInputFilters.push({ itemid: null, itemlinkvalue: null, itemtype: null });
    },

    removeAgreementInputField(i) {
      let self = this;
      self.numInputFilters -= 1;
      self.agreementInputFilters.splice(i, 1);
      self.runQuickSearch();
    },

    addAgreementAttachmentFilter() {
      let self = this;
      let attachmentFiltersLength = self.agreementAttachmentFilters.length;
      if (!self.agreementAttachmentFilters[attachmentFiltersLength - 1]['value']) {
        alert('Please fill current attachment filter before adding new one.');
        return;
      }
      self.numAttachmentFilters += 1;
      self.agreementAttachmentFilters.push({ value: null, type: 'filename' });
    },

    addTextFilter() {
      let self = this;
      let textFiltersLength = self.multipleAgreementTextFilter.length;
      if (!self.multipleAgreementTextFilter[textFiltersLength - 1]) {
        alert('Please fill current text filter before adding new one.');
        return;
      }
      self.numTextFilters += 1;
      self.multipleAgreementTextFilter.push('');
    },

    removeTextFilter(i) {
      let self = this;
      self.numTextFilters -= 1;
      self.multipleAgreementTextFilter.splice(i, 1);
      self.runQuickSearch();
    },

    removeAgreementAttachmentFilter(i) {
      let self = this;
      self.numAttachmentFilters -= 1;
      self.agreementAttachmentFilters.splice(i, 1);
      self.runQuickSearch();
    },

    //Store custom input attachment
    getResizedImageInputBase64(agreementCustomInput) {
      //Create/click file upload element
      let self = this;
      let fileInputElement = document.createElement('input');
      fileInputElement.style.display = 'none';
      fileInputElement.type = 'file';
      fileInputElement.addEventListener(
        'change',
        function (evt) {
          //Validate: file selected?
          if (this.files == undefined || this.files == null || this.files.length == 0) {
            console.log('--> [dev] file uplooader: no upload file(s) selected');
            return false;
          }
          let tgtFile = this.files[0];

          //Validate: type (JPG, PNG)?
          let tgtFileName = tgtFile.name;
          let tgtFileNameForComparison = tgtFileName.toLowerCase().replace('.jpeg', '.jpg');
          if (
            tgtFileNameForComparison.includes('.jpg') == false &&
            tgtFileNameForComparison.includes('.png') == false
          ) {
            alert(
              'The provided image is not a required type (JPG or PNG). Please re-export and try again.',
            );
            return false;
          }

          //Validate: min file size?
          let tgtFileBytes = tgtFile.size;
          if (tgtFileBytes < 5) {
            alert('The provided image is too small. Please try again with a larger version.');
            return false;
          }

          //Create thumbnail
          let maxWidth = 250;
          let maxHeight = 250;
          self.createThumbnailFromFile(
            tgtFile,
            maxWidth,
            maxHeight,
            function (resizedImageBase64Data) {
              let itemlinkvalue = resizedImageBase64Data;
              let itemfilename = tgtFile.name;
              self.agreementInputFilters.push({
                itemid: agreementCustomInput.itemid,
                itemlinkvalue: itemlinkvalue,
                itemtype: 'IMAGEINPUT',
              });
            },
          );
        },
        false,
      );
    },

    textAreaChangeHeight() {
      document.querySelector('textarea').addEventListener('input', function () {
        this.style.height = '0px';
        this.style.height = this.scrollHeight + 'px';
      });
    },

    clearAllFilters() {
      let self = this;
      self.agreementInputFields = [];
      self.agreementUniqueIDFilterValue = null;
      self.agreementInputFilters = [];
      self.agreementAttachmentFilters = [{ value: null, type: 'filename' }];
      self.agreementTagsFilter = [];
      self.agreementCreateDatesFilter = null;
      self.attachmentFileName = null;
      self.agreementTextFilter = '';
      self.multipleAgreementTextFilter = [''];
      self.isExactTextMatch = false;
      (self.agreementUniqueIDFilterValue = null),
        (self.agreementFolderIDFilterValue = null),
        (self.agreementCreatorEmailFilterValue = null),
        (self.autoCompleteSuggestions = []);
      self.multipleAgreementTextFilter = [''];
      self.selectedInputFilterValueOptions = [];
      self.numInputFilters = 0;
      self.numAttachmentFilters = 0;
      self.numTextFilters = 0;
      self.accessOption = 'defaultaccess';
      self.agreementFilters = {
        text_filter: {},
        input_filters: [],
        attachment_filters: [],
        tags_filter: {},
        creation_date_filter: {},
        is_admin_access: self.accessOption == 'defaultaccess' ? false : true,
        page_num: 1,
      };
      self.runQuickSearch();
    },

    reformatSelectedSearchText(event, i) {
      let self = this;
      let text = event;
      if (text) {
        text = text.replaceAll('<strong>', '');
        text = text.replaceAll('</strong>', '');
        self.multipleAgreementTextFilter[i] = text;
      }
    },

    scrollTextAreaElement(event, i) {
      let elm;
      elm = document.querySelector('#text-filter-id-' + i);
      elm.style.height = '40px';
      if (event) {
        elm.style.height = elm.scrollHeight + 'px';
      }
    },

    onAccessOptionChange() {
      let self = this;
      self.getAgreementsCreatorsEmails();
      self.getAgreementsFolders();
    },

    //Close button
    closeModal: function () {
      let self = this;
      self.$parent.close();
    },
    _agreemntsByTitle(a, b) {
        const name1 = a.title.toLowerCase();
        const name2 = b.title.toLowerCase();
        return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
    },
  },
};
</script>

<template>
  <div id="modalCardAdvancedSearch" class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Advanced Search</p>
    </header>
    <section class="modal-card-body" style="max-height: 450px">
      <b-loading
        :is-full-page="false"
        :active="isFiltersLoading"
        :can-cancel="false"
        animation=""></b-loading>
      <div id="agreement-access-id" class="level-item" v-if="isOrgAdmin">
        <b-field>
          <b-tooltip
            v-for="option in searchAccessOptions"
            :key="option.value"
            :label="option.tooltipLabel"
            type="is-light"
            position="is-bottom">
            <b-radio-button
              v-model="accessOption"
              :native-value="option.value"
              :class="
                option.value == 'adminaccess'
                  ? 'view-type-radio-btn view-type-radio-btn--adminaccess'
                  : 'view-type-radio-btn view-type-radio-btn--defaultaccess'
              "
              type="is-primary"
              @input="
                onAccessOptionChange();
                runQuickSearch(true);
              ">
              <b-icon pack="fal" :icon="option.icon" size="is-small"></b-icon>
              <span>{{ option.title }}</span>
            </b-radio-button>
          </b-tooltip>
        </b-field>
      </div>
      <div id="agreement-text-id" style="padding: 10px">
        <b-field label="Document text search">
          <b-autocomplete
            :id="'text-filter-id-' + 0"
            v-model="multipleAgreementTextFilter[0]"
            icon="search"
            icon-pack="fal"
            type="textarea"
            maxlength="1000"
            ref="autocomplete"
            :data="autoCompleteSuggestions"
            :placeholder="
              harbourStore.contextDict && !isMobileView
                ? 'Search ' + harbourStore.contextDict['org_name'] + ' agreements by text'
                : 'Search agreements by text'
            "
            v-on:typing="debouncedGetAutoCompleteSuggestions($event)"
            v-on:keyup.enter.native="applyFilters()"
            v-on:focus="autoCompleteSuggestions = []"
            v-on:input="
              reformatSelectedSearchText($event, 0);
              scrollTextAreaElement($event, 0);
              debouncedRunQuickSearch($event);
            "
            style="width: 100%; max-width: 571px"
            :has-counter="false"
            clearable>
            <template slot-scope="props">
              <!-- results header -->
              <div
                class="searchAutoCompleteItems"
                v-show="props.index == 0 && harbourStore.contextDict && !isMobileView">
                Matching text found in your {{ harbourStore.contextDict['org_name'] }} documents:
              </div>
              <div class="searchAutoCompleteItems" v-show="!harbourStore.contextDict || isMobileView">
                Matching text found in your documents:
              </div>
              <!-- results -->
              <div class="media">
                <!-- preview -->
                <div class="media-left" style="margin-left: 0px">
                  <div>
                    <b-icon pack="fal" icon="search-plus"></b-icon>
                  </div>
                </div>
                <!-- item info -->
                <div class="media-content">
                  <span v-html="props.option"></span>
                </div>
              </div>
            </template>
          </b-autocomplete>
          <b-field
            v-for="i in multipleAgreementTextFilter.length - 1"
            v-if="i <= 4"
            :key="'text-filter-' + i">
            <div class="divider" style="width: 100%; max-width: 571px">OR</div>
            <div style="display: inline-flex">
              <b-autocomplete
                :id="'text-filter-id-' + i"
                v-model="multipleAgreementTextFilter[i]"
                icon="search"
                icon-pack="fal"
                type="textarea"
                maxlength="1000"
                :data="autoCompleteSuggestions"
                :placeholder="
                  harbourStore.contextDict
                    ? 'Search ' + harbourStore.contextDict['org_name'] + ' agreements by text'
                    : 'Search agreements by text'
                "
                v-on:typing="debouncedGetAutoCompleteSuggestions($event)"
                v-on:keyup.enter.native="applyFilters()"
                v-on:focus="autoCompleteSuggestions = []"
                v-on:input="
                  reformatSelectedSearchText($event, i);
                  scrollTextAreaElement($event, i);
                  debouncedRunQuickSearch($event);
                "
                :style="!isMobileView ? 'width: 571px;' : ''"
                :has-counter="false"
                clearable>
                <template slot-scope="props">
                  <!-- results header -->
                  <div class="searchAutoCompleteItems" v-show="props.index == 0 && harbourStore.contextDict">
                    Matching text found in your {{ harbourStore.contextDict['org_name'] }} documents:
                  </div>
                  <div class="searchAutoCompleteItems" v-show="!harbourStore.contextDict">
                    Matching text found in your documents:
                  </div>
                  <!-- results -->
                  <div class="media">
                    <!-- preview -->
                    <div class="media-left" style="margin-left: 0px">
                      <div>
                        <b-icon pack="fal" icon="search-plus"></b-icon>
                      </div>
                    </div>
                    <!-- item info -->
                    <div class="media-content">
                      <span v-html="props.option"></span>
                    </div>
                  </div>
                </template>
              </b-autocomplete>
              <b-field>
                <a v-on:click="removeTextFilter(i)">
                  <b-icon pack="fas" icon="minus-circle" class="icon-minus-circle"></b-icon>
                </a>
              </b-field>
            </div>
          </b-field>
          <div
            :style="
              numTextFilters > 0
                ? 'display: flex;justify-content: flex-end;padding-right: 40px;'
                : 'display: flex;justify-content: flex-end;padding-right: 10px;'
            ">
            <b-checkbox
              v-model="isExactTextMatch"
              size="is-small"
              v-on:input="runQuickSearch(true)"
              style="margin-right: 1.5em"
              >Exact text match</b-checkbox
            >
            <div style="margin-bottom: 2px">
              <a style="font-size: 0.75rem" v-on:click="addTextFilter()" href="#"
                >+ Add text filter</a
              >
            </div>
          </div>
        </b-field>
      </div>
      <div id="agreement-folder-id" style="padding: 10px; display: flex">
        <!-- icon -->
        <div class="groupIcon">
          <div>
            <i class="fal fa-folder-open"></i>
          </div>
        </div>
        <!-- info -->
        <div class="groupInfo" style="padding-left: 0px">
          <b-field label="Agreement folder">
            <b-select
              v-model="agreementFolderIDFilterValue"
              placeholder="Select agreement parent folder"
              v-on:input="runQuickSearch(true)"
              expanded>
              <option :value="null">- Filter by all folders -</option>
              <option
                v-for="(folder, index) in harbourStore.myFolders"
                :value="folder.id"
                :key="folder.id">
                {{ folder.name }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div id="agreement-type-id" style="padding: 10px; display: flex">
        <!-- icon -->
        <div class="groupIcon">
          <div>
            <i class="fal fa-file-contract"></i>
          </div>
        </div>
        <!-- info -->
        <div class="groupInfo">
          <b-field label="Agreement type">
            <div>
              <b-select
                v-model="agreementUniqueIDFilterValue"
                placeholder="Select agreement type"
                v-on:input="
                  getAgreementInputs(true);
                  runQuickSearch(true);
                "
                expanded>
                <option :value="null">- Filter by all agreement types -</option>
                <option
                  v-for="(agreement, index) in templatesStore.myTemplates"
                  :value="agreement.agreement_unique_id"
                  :key="agreement.agreement_unique_id">
                  {{ agreement.title }}
                </option>
              </b-select>
            </div>
          </b-field>
          <b-field
            v-for="i in agreementInputFields.length"
            v-if="i <= numInputFilters"
            :key="'agreementinput-' + i"
            style="display: flex">
            <b-select
              v-model="agreementInputFilters[i - 1]['itemid']"
              v-on:input="getAgreementInputFieldDetails(i - 1)"
              placeholder="Select input field"
              class="firstInput">
              <option
                v-for="(agreementInput, index) in agreementInputFields.filter(
                  (p) => p.itemtype != 'SIGNATUREINPUT',
                )"
                :key="agreementInput.itemid"
                :value="agreementInput.itemid">
                {{ agreementInput.itemdisplaylabel }}
              </option>
            </b-select>
            <b-field
              v-if="
                !agreementInputFilters ||
                agreementInputFilters[i - 1]['itemtype'] == 'TEXTINPUT' ||
                !agreementInputFilters[i - 1]['itemtype']
              ">
              <b-input
                v-model="agreementInputFilters[i - 1]['itemlinkvalue']"
                class="filterInputClass thirdInput"
                icon="pen"
                icon-pack="fal"
                v-on:input="debouncedRunQuickSearch($event)"
                expanded></b-input>
            </b-field>
            <b-field v-if="agreementInputFilters[i - 1]['itemtype'] == 'SELECT'">
              <b-select
                v-model="agreementInputFilters[i - 1]['itemlinkvalue']"
                placeholder="Select input value"
                class="filterInputClass thirdInput"
                v-on:input="runQuickSearch()"
                expanded>
                <option
                  v-for="(agreementInputValueOptions, index) in selectedInputFilterValueOptions"
                  :key="index"
                  :value="agreementInputValueOptions">
                  {{ agreementInputValueOptions }}
                </option>
              </b-select>
            </b-field>
            <b-field
              v-if="agreementInputFilters[i - 1]['itemtype'] == 'IMAGEINPUT'"
              style="display: flex">
              <b-input
                id="upload-filter-input-id"
                placeholder="Attach a PNG or JPG image"
                type="search"
                icon-pack="fal"
                :maxlength="500"
                :has-counter="false"
                class="secondInput"
                expanded></b-input>
              <p class="control">
                <b-button
                  v-on:click="getResizedImageInputBase64(agreementInputFields[i - 1])"
                  class="thirdInput"
                  style="font-size: 16px"
                  >Attach</b-button
                >
              </p>
            </b-field>
            <b-field>
              <a v-on:click="removeAgreementInputField(i - 1)">
                <b-icon
                  pack="fas"
                  icon="minus-circle"
                  class="icon-minus-circle"
                  style="padding-right: 0px"></b-icon>
              </a>
            </b-field>
          </b-field>
          <div class="addFilter">
            <a
              v-if="agreementUniqueIDFilterValue && agreementInputFields.length > 0"
              v-on:click="addAgreementInputFilter()"
              href="#"
              style="font-size: 0.75rem"
              >+ (Optional) Add agreement input filter</a
            >
          </div>
        </div>
      </div>
      <div id="agreement-creator-id" style="padding: 10px; display: flex">
        <!-- icon -->
        <div class="groupIcon">
          <div>
            <i class="fal fa-at"></i>
          </div>
        </div>
        <!-- info -->
        <div class="groupInfo" style="padding-left: 5px">
          <b-field label="Agreement creator email">
            <b-select
              v-model="agreementCreatorEmailFilterValue"
              placeholder="Select agreement creator email"
              v-on:input="runQuickSearch(true)"
              expanded>
              <option :value="null">- Filter by all emails -</option>
              <option v-for="(email, index) in searchStore.agreementCreatorEmails" :value="email" :key="index">
                {{ email }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div id="agreement-date-id" style="padding: 10px; display: flex">
        <!-- icon -->
        <div class="groupIcon">
          <div>
            <i class="fal fa-calendar-alt"></i>
          </div>
        </div>
        <!-- info -->
        <div class="groupInfo">
          <b-field label="Agreement completion date">
            <b-datepicker
              icon-pack="fal"
              placeholder="10/18/2021 - 10/28/2021"
              v-model="agreementCreateDatesFilter"
              v-on:input="runQuickSearch(true)"
              icon="calendar-alt"
              :icon-right="agreementCreateDatesFilter ? 'close-circle' : ''"
              icon-right-clickable
              v-on:icon-right-click="
                agreementCreateDatesFilter = null;
                runQuickSearch(true);
              "
              editable
              range
              expanded>
            </b-datepicker>
          </b-field>
        </div>
      </div>
      <div id="agreement-attachments-id" style="padding: 10px; display: flex">
        <!-- icon -->
        <div class="groupIcon">
          <div>
            <i class="fal fa-paperclip"></i>
          </div>
        </div>
        <!-- info -->
        <div class="groupInfo" style="padding-left: 10px">
          <b-field label="Agreement attachment name">
            <b-field style="display: flex" :key="'attachment-0'">
              <b-input
                v-model="agreementAttachmentFilters[0]['value']"
                icon="pen"
                icon-pack="fal"
                class="filter-input"
                v-on:input="debouncedRunQuickSearch($event)"
                style="width: 100%"
                clearable
                expanded></b-input>
            </b-field>
            <b-field
              v-for="i in 4"
              v-if="i <= numAttachmentFilters"
              style="display: flex"
              :key="'attachment-' + i">
              <b-input
                v-model="agreementAttachmentFilters[i]['value']"
                icon="pen"
                icon-pack="fal"
                class="filter-input"
                v-on:input="debouncedRunQuickSearch($event)"
                style="width: 100%"
                expanded></b-input>
              <a v-if="numAttachmentFilters > 0" v-on:click="removeAgreementAttachmentFilter(i)">
                <b-icon pack="fas" icon="minus-circle" class="icon-minus-circle"></b-icon>
              </a>
            </b-field>
          </b-field>
          <div class="addFilter" :style="numAttachmentFilters > 1 ? 'padding-right: 40px;' : ''">
            <a v-on:click="addAgreementAttachmentFilter()" href="#" style="font-size: 0.75rem"
              >+ Add attachment filter</a
            >
          </div>
        </div>
      </div>
      <div id="agreement-tags-id" style="padding: 10px; padding-left: 0px; display: flex">
        <!-- icon -->
        <div class="groupIcon">
          <div>
            <i class="fal fa-tags"></i>
          </div>
        </div>
        <!-- info -->
        <div class="groupInfo" style="padding-left: 10px">
          <b-field label="Agreement tags">
            <b-taginput
              v-on:input="runQuickSearch(true)"
              v-model="agreementTagsFilter"
              icon-pack="fal"
              icon="tags"
              placeholder="Add a tag"
              aria-close-label="Delete this tag"
              expanded></b-taginput>
          </b-field>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot" style="justify-content: space-between">
      <div style="display: flex">
        <a style="color: gray; text-decoration: underline" v-on:click="clearAllFilters()"
          >Clear all</a
        >
      </div>
      <div style="display: flex">
        <HrbrButton :button="cancelButton" @click="closeModal()" />
        <HrbrButton v-if="totalHitsCount == 0" :button="noResultsButton" />
        <HrbrButton v-else :button="showAgreementsButton" @click="applyFilters()" />

      </div>
    </footer>
  </div>
</template>

<style lang="postcss" scoped></style>

<style>
/*
TODO: 
- move to scoped style 
- remove selector by id
*/

.icon-minus-circle {
  padding: 20px;
  color: #757373;
}
.icon-minus-circle:hover {
  color: red;
}

#modalCardAdvancedSearch #filter-input-id {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

#modalCardAdvancedSearch .filter-input .input {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

#modalCardAdvancedSearch #agreement-type-id .thirdInput .input {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  width: 260px;
  max-width: 260px;
}

#modalCardAdvancedSearch #agreement-type-id .thirdInput .button {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

#modalCardAdvancedSearch #agreement-type-id .firstInput .input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

#modalCardAdvancedSearch #agreement-type-id .firstInput .input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  margin-right: 5px;
}

#modalCardAdvancedSearch #agreement-type-id .firstInput .select select {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  width: 195px;
  max-width: 195px;
  margin-right: 5px;
}
#modalCardAdvancedSearch #agreement-type-id .secondInput .select select {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
#modalCardAdvancedSearch #agreement-type-id .thirdInput .select select {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  width: 260px;
  max-width: 260px;
}

#modalCardAdvancedSearch #upload-filter-input-id {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
#modalCardAdvancedSearch .disable {
  pointer-events: none;
  cursor: default;
}
#modalCardAdvancedSearch
  .datepicker
  .datepicker-table
  .datepicker-body
  .datepicker-cell.is-selected.is-first-selected {
  background-color: #2d71ad;
}
#modalCardAdvancedSearch
  .datepicker
  .datepicker-table
  .datepicker-body
  .datepicker-cell.is-selected.is-last-selected {
  background-color: #2d71ad;
}
#modalCardAdvancedSearch
  .datepicker
  .datepicker-table
  .datepicker-body
  .datepicker-cell.is-selected.is-within-selected {
  background-color: rgba(45, 113, 173, 0.5);
}
#modalCardAdvancedSearch .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-today {
  border: solid 1px rgba(45, 113, 173, 0.5);
}
#modalCardAdvancedSearch .has-text-primary {
  color: #2d71ad !important;
}
#modalCardAdvancedSearch .datepicker .dropdown-trigger .input[readonly]:focus {
  box-shadow: inset 0 0.0625em 0.125em rgba(45, 113, 173, 0.5);
}
#modalCardAdvancedSearch .datepicker .a:focus {
  box-shadow: rgba(45, 113, 173, 0.5);
}
#modalCardAdvancedSearch .i .mdi .mdi-chevron-left .mdi-24px {
  color: #2d71ad !important;
}

#modalCardAdvancedSearch .datepicker .dropdown .input[readonly].is-active,
.datepicker .dropdown .input[readonly].is-focused,
.datepicker .dropdown .input[readonly]:active,
.datepicker .dropdown .input[readonly]:focus,
.datepicker .dropdown-trigger .input[readonly].is-active,
.datepicker .dropdown-trigger .input[readonly].is-focused,
.datepicker .dropdown-trigger .input[readonly]:active,
.datepicker .dropdown-trigger .input[readonly]:focus {
  box-shadow: 0 0 0 0.125em rgba(45, 113, 173, 0.25);
}

#modalCardAdvancedSearch .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-today {
  border: solid 1px rgba(45, 113, 173, 0.5);
}

#modalCardAdvancedSearch
  .datepicker
  .datepicker-table
  .datepicker-body
  .datepicker-cell.is-selected {
  background-color: #2d71ad;
  color: #fff;
}

#modalCardAdvancedSearch
  .datepicker
  .datepicker-table
  .datepicker-body
  .datepicker-cell.is-selected.is-first-selected {
  background-color: #2d71ad;
  color: #fff;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

#modalCardAdvancedSearch
  .datepicker
  .datepicker-table
  .datepicker-body
  .datepicker-cell.is-selected.is-within-selected {
  background-color: rgba(45, 113, 173, 0.5);
  border-radius: 0;
}

#modalCardAdvancedSearch
  .datepicker
  .datepicker-table
  .datepicker-body
  .datepicker-cell.is-selected.is-last-selected {
  background-color: #2d71ad;
  color: #fff;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

#modalCardAdvancedSearch
  .datepicker
  .datepicker-table
  .datepicker-body.has-events
  .datepicker-cell.has-event
  .events
  .event.is-primary {
  background-color: #2d71ad;
}

#modalCardAdvancedSearch
  .datepicker
  .datepicker-table
  .datepicker-body.has-events
  .datepicker-cell.has-event
  .events
  .event.is-link {
  background-color: #2d71ad;
}

#modalCardAdvancedSearch
  .datepicker
  .datepicker-table
  .datepicker-body.has-events
  .datepicker-cell.is-selected
  .events
  .event.is-primary {
  background-color: rgba(45, 113, 173, 0.22);
}
#modalCardAdvancedSearch .pagination-link:focus,
.pagination-next:focus,
.pagination-previous:focus {
  border: solid 1px rgba(45, 113, 173, 0.5);
}
#modalCardAdvancedSearch .groupIcon {
  flex-grow: 0;
  text-align: center;
  line-height: 80px;
  padding-top: 25px !important;
  padding: 12px;
  border-top: 1px solid #f5f5f5;
  background-color: white;
}
#modalCardAdvancedSearch .groupIcon i {
  margin-top: 6px;
  font-size: 50px;
  color: #08080863;
}

#modalCardAdvancedSearch .groupInfo {
  flex-grow: 1;
  border-top: 1px solid #f5f5f5;
  background-color: white;
  padding: 21px 12px 15px 15px;
}
#modalCardAdvancedSearch .searchAutoCompleteItems {
  font-size: 13px;
  margin-bottom: 8px;
  color: #00000085;
}
#modalCardAdvancedSearch .autocomplete .dropdown-content {
  max-width: 580px;
}
#modalCardAdvancedSearch .autocomplete .textarea {
  min-height: 40px;
  height: 40px;
  padding: 7px 11px 7px 32px;
}
#modalCardAdvancedSearch .media-left {
  margin-right: 0rem !important;
}
#modalCardAdvancedSearch #agreement-text-id .field.has-addons {
  flex-flow: column;
}
#modalCardAdvancedSearch #agreement-text-id .select select {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
#modalCardAdvancedSearch #agreement-text-id .text-search-settings-class {
  background: white;
  color: #363636;
  border-color: #dbdbdb;
  cursor: auto;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
}

#modalCardAdvancedSearch #agreement-text-id .taginput .taginput-container.is-focusable {
  padding-right: 1px !important;
}
#modalCardAdvancedSearch #agreement-attachments-id .field:not(:last-child) {
  margin-bottom: 0.3rem;
}
#modalCardAdvancedSearch .addFilter {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}
#modalCardAdvancedSearch .view-type-radio-btn .is-selected {
  color: #fff;
  background-color: #1355FF;
}

#modalCardAdvancedSearch .view-type-radio-btn--defaultaccess .button {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#modalCardAdvancedSearch .view-type-radio-btn--adminaccess .button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
