<script setup>
import { useSettingsPageStore } from '@/pages/Settings/stores/settings-store.js';
import { DialogProgrammatic as Dialog } from 'buefy';
import { storeToRefs } from 'pinia';

const settingsPageStore = useSettingsPageStore();
const {
  editableGroup,
  isEditGroupModalOpened,
  isAddUsersModalOpened,
  isOrgAdmin,
  getUserEmail,
} = storeToRefs(settingsPageStore);

const addUsers = (params) => {
  const currentGroup = settingsPageStore.listUserGroups.find(item => item.group_id === params.data.group_id);

  editableGroup.value = {
    ...currentGroup,
    added_members: [],
  };
  isAddUsersModalOpened.value = true;
};

const editGroup = (params) => {
  const currentGroup = settingsPageStore.listUserGroups.find(item => item.group_id === params.data.group_id);

  editableGroup.value = {
    ...currentGroup,
    added_members: [],
  };
  isEditGroupModalOpened.value = true;
};

const isUserGroupAdmin = (params) => {
  if (isOrgAdmin.value) return true;
  const currentGroup = settingsPageStore.listUserGroups.find(item => item.group_id === params.data.group_id);
  const currentUserGroupRole = currentGroup?.members.find(member => member.email === getUserEmail.value)?.role;
  return currentUserGroupRole === 'admin';
};

const deleteGroup = (groupId) => {
  Dialog.confirm({
    title: 'Delete group',
    message: `Are you sure you want to delete this group?`,
    confirmText: 'Delete',
    type: 'is-danger',
    onConfirm: () => {
      settingsPageStore.deleteUserGroup(groupId);
    },
  });
};
</script>

<template>
  <div class="action-buttons">
    <div
      :class="[
        'btn',
        !isUserGroupAdmin(params) && 'disabled'
      ]"
      @click="addUsers(params)"
    >
      <i class="fa-regular fa-user-plus"></i>
      Add
    </div>
    <div
      :class="[
        'btn',
        (params.data.group_id === 'admins' || !isOrgAdmin) && 'disabled'
      ]"
      @click="editGroup(params)"
    >
      <i class="fa-regular fa-pen-to-square"></i>
      Edit
    </div>
    <div
      :class="[
        'btn',
        (params.data.is_default || !isOrgAdmin) && 'disabled'
      ]"
      @click.stop="deleteGroup(params.data.group_id)"
    >
      <i class="fa-regular fa-trash"></i>
      Delete
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import '@/assets/css/elements/table-actions.css';
</style>
