import { useSidebarStore } from '../stores/sidebar-store';
import { computed, unref } from 'vue';

export function useFilterItems(items) {
  const sidebarStore = useSidebarStore();

  const filteredItems = computed(() => {
    const itemsList = unref(items);

    const filter = sidebarStore.filter?.toLowerCase();

    if (filter && filter.length > 1) {
      return itemsList.filter((item) => {
        const title = item.title.toLowerCase();
        return title.includes(filter);
      });
    }
    return itemsList;
  });

  return { filteredItems };
}
