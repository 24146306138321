<script>
import { reactive } from 'vue';
import { useHarbourStore } from '@/stores/harbour-store';
import { useLinkObject } from '@/pages/Dashboard/composables/LinkObject'
import CopyLink from '@/pages/Dashboard/components/ui_components/CopyLink.vue';
export default { 
  name: 'LinkRenderer', 
  components: {
    CopyLink,
  },
  setup() {
    return {
      harbourStore: useHarbourStore(),
    }
  },
  data() {
    return {
      field: null,
      id: null,
      linkObject: null,
      url: null,
      isRendererReady: false,
    }
  },
  created() {
    this.id = this.params.data.id;
    this.field = this.params.colDef.field;
    this.linkObject = useLinkObject(this.params.data);
    this.linkObject.authMethod === 'EMAILS' && this.getBrand();
    this.init();
  },
  beforeUnmount() {
    this.linkObject = null;
  },
  methods: {
    async init() {
      await new Promise((resolve) => setTimeout(resolve, 10));
      this.isRendererReady = true;
    },
    dropProtocolFromUrl() {
      return this.linkObject.url.replace(/(^\w+:|^)\/\//, '') || '';
    },
    getURL() {
      if (import.meta.env.DEV) return `hrbr.co/${this.linkObject.id}`
      return this.dropProtocolFromUrl();
    },
    toggleActive() {
      const { active } = this.linkObject;
      this.linkObject.setValue('active', !active);
    },
    async getBrand() {
      this.logo = this.logos[Math.floor(Math.random() * this.logos.length)];
    }
  },
};
</script>

<template>
  <div class="title-display-container" v-if="isRendererReady">
    <div class="link-container">
      <CopyLink :url="getURL()" :link-object="linkObject" />
    </div>
  </div>
</template>

<style scoped>
.title-display-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-container {
  flex-grow: 1;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  margin-bottom: 2px;
  color: rgb(54, 54, 54);
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-font-smoothing: antialiased;
}
.title-editor-row {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.title-edit-icon {
  margin: 5px;
  cursor: pointer;
}
.title-editor-row span {
  color: #666;
  font-size: 14px;
  margin-bottom: 5px;
}
.title-editor {
  margin-right: 5px;
}
.title-edit-icon {
  width: 20px;
  height: 100%;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  margin-left: 5px;
  color: #666;
  cursor: pointer;
  padding: 5px;
  transition: all 250ms ease;
}
.save-title:hover {
  color: var(--grid-success);
}
.discard-title:hover {
  color: #7f1f18;
}
.display-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.title-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: #666;
  margin-bottom: 5px;
}
.link-container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  padding: 0;
}
.toggle {
  padding: 0;
  margin: 0 0 0 5px;
}
.qrcode {
  margin-right: 5px;
  border-radius: 5px;
  border: 1px solid #dadada;
  padding: 5px;
  transition: all 250ms ease;
}
.qrcode:hover {
  background-color: rgb(196, 196, 196);
  cursor: pointer;
  color: black;
}
.brand-image-container {
  width: 50px;
  max-width: 50px;
}
.brand-image, .globe-replacement {
  width: 25px;
  height: 25px;
  max-width: 25px;
  max-height: 25px;
  border-radius: 20px;
  margin: 10px;
}
.globe-replacement {
  font-size: 24px;
  color: #c8c8c8;
}
.title-editor {
  width: 100%;
  outline: none;
  border: 1px solid #dadada;
  padding: 5px;
  border-radius: 10px;
  color: #666;
}

</style>