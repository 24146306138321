<script setup>
import { useSlots } from 'vue';

const slots = useSlots();
</script>

<template>
  <div class="hrbr-sidebar-pane-section">
    <div class="hrbr-sidebar-pane-section__head" v-if="slots.head">
      <slot name="head"></slot>
    </div>
    <div class="hrbr-sidebar-pane-section__content" v-if="slots.content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style lang="postcss">
.hrbr-sidebar-pane-section {
  & + & {
    margin-top: 30px;
  }

  &__head {
    margin-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.hrbr-sidebar-pane-tab__body {
  flex: 1;
}
</style>
