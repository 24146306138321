<script setup>
import { computed } from 'vue';

const props = defineProps({
  src: {
    type: String,
    default: null,
  },
  overlay: {
    type: Number,
    default: 0,
  },
});

const bannerStyle = computed(() => ({
  backgroundImage: props.src,
}));

const overlayClasses = computed(() => {
  return ['hrbr-app-banner__overlay', `hrbr-app-banner__overlay--${props.overlay}`];
});
</script>

<template>
  <div class="hrbr-app-banner" :style="bannerStyle">
    <div :class="overlayClasses"></div>

    <div class="hrbr-app-banner__content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-app-banner {
  --hrbr-app-banner-height: 190px;
  --hrbr-app-banner-height-mobile: 84px;

  width: 100%;
  height: var(--hrbr-app-banner-height);
  background-image: linear-gradient(155deg, rgba(85,178,222,1) 37%, rgba(151,216,246,1) 61%);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  &__content {
    height: 100%;
    position: relative;
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    &--0 {
      display: none;
    }
    &--10 {
      background: rgba(0, 0, 0, 0.1);
    }
    &--20 {
      background: rgba(0, 0, 0, 0.2);
    }
    &--30 {
      background: rgba(0, 0, 0, 0.3);
    }
    &--40 {
      background: rgba(0, 0, 0, 0.4);
    }
    &--50 {
      background: rgba(0, 0, 0, 0.5);
    }
    &--60 {
      background: rgba(0, 0, 0, 0.6);
    }
    &--70 {
      background: rgba(0, 0, 0, 0.7);
    }
    &--80 {
      background: rgba(0, 0, 0, 0.8);
    }
    &--90 {
      background: rgba(0, 0, 0, 0.9);
    }
    &--100 {
      background: rgba(0, 0, 0, 1);
    }
  }
}

@media (max-width: 768px) {
  .hrbr-app-banner {
    height: var(--hrbr-app-banner-height-mobile);
  }
}
</style>
