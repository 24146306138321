<script setup>
import HrbrSidebarPaneTab from './HrbrSidebarPaneTab.vue';
import { useRoute } from 'vue-router/composables';
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useSidebarStore } from './stores/sidebar-store';
import { PANE_MIN_WIDTH, PANE_MAX_WIDTH } from './scripts/constants';

const route = useRoute();
const currentRouteName = computed(() => route.name);

const sidebarStore = useSidebarStore();

// grab and resize
const paneEl = ref(null);
const paneGrabEl = ref(null);
const isGrabActive = ref(false);

const paneStyles = computed(() => {
  return {
    '--hrbr-sidebar-pane-width': `${sidebarStore.sidebarPaneWidth}px`,
  };
});

const onGrabPointerDown = (event) => {
  event.preventDefault();

  isGrabActive.value = true;
  paneGrabEl.value.setPointerCapture(event.pointerId);

  let grabRect = paneGrabEl.value.getBoundingClientRect();
  let grabShiftX = event.clientX - grabRect.left;

  const onPointerMove = (event) => {
    let paneRect = paneEl.value.getBoundingClientRect();
    let newWidth = event.clientX - grabShiftX - paneRect.left;
    if (newWidth < PANE_MIN_WIDTH) newWidth = PANE_MIN_WIDTH;
    if (newWidth > PANE_MAX_WIDTH) newWidth = PANE_MAX_WIDTH;
    sidebarStore.setSidebarPaneWidth(newWidth);
  };

  const onPointerMoveAnimationFrame = (event) => {
    window.requestAnimationFrame(() => onPointerMove(event));
  };

  const releasePointerHandlers = () => {
    isGrabActive.value = false;
    paneGrabEl.value.removeEventListener('pointerup', onPointerUp);
    paneGrabEl.value.removeEventListener('pointermove', onPointerMoveAnimationFrame);
    paneGrabEl.value.removeEventListener('lostpointercapture', onLostPointerCapture);
  };

  const onPointerUp = () => releasePointerHandlers();
  const onLostPointerCapture = () => releasePointerHandlers();
  
  paneGrabEl.value.addEventListener('pointermove', onPointerMoveAnimationFrame);
  paneGrabEl.value.addEventListener('pointerup', onPointerUp);
  paneGrabEl.value.addEventListener('lostpointercapture', onLostPointerCapture);
};

const initGrabResize = () => {
  paneGrabEl.value.addEventListener('pointerdown', onGrabPointerDown);
};

const destroyGrabResize = () => {
  paneGrabEl.value.removeEventListener('pointerdown', onGrabPointerDown);
};

onMounted(() => {
  initGrabResize();
});

onBeforeUnmount(() => {
  destroyGrabResize();
});
</script>

<template>
  <div 
    class="hrbr-sidebar-pane" 
    :class="{'hrbr-sidebar-pane--grab-active': isGrabActive}"
    :style="paneStyles" 
    ref="paneEl">
    <HrbrSidebarPaneTab :tab="currentRouteName" />

    <div 
      class="hrbr-sidebar-pane__grab" 
      ref="paneGrabEl"
      @dragstart="() => false">
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar-pane {
  --hrbr-sidebar-pane-width-tablet: 284px;

  display: flex;
  flex-direction: column;
  width: var(--hrbr-sidebar-pane-width);
  height: 100%;
  min-height: 0;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.08) 2px 0px 2px 0px;
  transition: width 70ms ease;

  &--grab-active {
    .hrbr-sidebar-pane__grab::after {
      opacity: 1;
    }
  }

  &__grab {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -3px;
    width: 7px;
    height: 100%;
    z-index: 2;
    cursor: col-resize;
    pointer-events: auto;
    touch-action: none;

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 2px;
      width: 2px;
      background-color: rgb(0, 128, 212);
      opacity: 0;
      transition-property: opacity;
      transition-duration: 0.25s;
      transition-delay: 200ms;
      pointer-events: none;
      touch-action: none;
    }
  }
}

@media (max-width: 992px) {
  .hrbr-sidebar-pane {
    width: var(--hrbr-sidebar-pane-width-tablet);

    &__grab {
      display: none;
    }
  }
}
</style>