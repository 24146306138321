<script setup>
const props = defineProps({
  id: {
    type: String,
  },
  title: {
    type: String,
  },
  icon: {
    type: String,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
      type: Boolean,
      default: false,
  },
  isBottomItem: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits({
  itemClick: null,
});

const handleNavItemClick = (id) => {
    if (props.isDisabled) return;
    emit('itemClick', id);
};
</script>

<template>
  <button
    :id="'sidebar-nav-item-' + id"
    class="hrbr-sidebar-nav-item"
    :class="{
      'hrbr-sidebar-nav-item--active': isActive,
      'hrbr-sidebar-nav-item--disabled': isDisabled,
      'hrbr-sidebar-nav-item--bottom': isBottomItem,
    }"
    :disabled="isDisabled"
    :aria-label="title"
    role="button"
    @click="handleNavItemClick(id)">
    <div class="hrbr-sidebar-nav-item__box">
      <div class="hrbr-sidebar-nav-item__icon">
        <div class="hrbr-sidebar-nav-item__icon-box">
          <i class="fa-light" :class="'fa-' + icon"></i>
        </div>
      </div>
      <div class="hrbr-sidebar-nav-item__title" v-if="!isBottomItem">
        {{ title }}
      </div>
      <div class="hrbr-sidebar-nav-item__tooltip" v-if="isBottomItem">
        <div class="hrbr-sidebar-nav-item__tooltip-title">{{ title }}</div>
      </div>
    </div>
  </button>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar-nav-item {
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--hrbr-black-color);
  width: 100%;
  height: 70px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  appearance: none;

  &:hover .hrbr-sidebar-nav-item__icon {
    background: #e4e9ec;
  }

  &:active .hrbr-sidebar-nav-item__icon {
    background: #d7dde4;
    transition-duration: 70ms;
  }

  &--active {
    color: var(--hrbr-primary-color-active);

    &:hover .hrbr-sidebar-nav-item__icon,
    &:active .hrbr-sidebar-nav-item__icon {
      background: rgba(31, 137, 199, 0.1);
    }

    .hrbr-sidebar-nav-item__icon {
      background: rgba(31, 137, 199, 0.1);
      border-color: var(--hrbr-primary-color-active);
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
  }

  &--disabled {
    cursor: not-allowed;
    color: #777;

    &:hover .hrbr-sidebar-nav-item__icon,
    &:active .hrbr-sidebar-nav-item__icon {
      background: none;
    }
  }

  &__icon {
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border: 2px solid transparent;
    border-radius: 12px;
    transition: background 0.15s ease, border 0.15s ease;
    will-change: background, border, color;
  }

  &__icon-box {
    display: inline-flex;
    font-size: 21px;
  }

  &__title {
    color: inherit;
    font-size: 12px;
    line-height: 1.2;
    letter-spacing: 0.15px;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    margin-top: 3px;
  }

  &__tooltip {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    padding: 0 10px;
    position: absolute;
    left: calc(100% + 5px);
    top: 0;
    bottom: 0;
    margin: auto;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 4px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    transform: translateX(-10px);
    transition: opacity 0.15s ease, visibility 0.15s ease, transform 0.3s ease;
    will-change: opacity, visibility, transform;
    pointer-events: none;
    user-select: none;
    z-index: 1;
  }

  &__tooltip-title {
    font-size: 13px;
    line-height: 1.2;
    color: #fff;
    text-align: center;
    white-space: nowrap;
  }

  /* Bottom item */
  &--bottom {
    height: auto;
    padding: 3px 0;
    color: rgb(22, 22, 26, 0.6);

    &:hover {
      .hrbr-sidebar-nav-item__tooltip {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
      }
    }

    .hrbr-sidebar-nav-item__box {
      width: auto;
    }

    .hrbr-sidebar-nav-item__icon {
      width: 35px;
      height: 35px;
    }

    .hrbr-sidebar-nav-item__icon-box {
      font-size: 18px;
    }
  }
}

@media (max-width: 992px) {
  .hrbr-sidebar-nav-item {
    height: 52px;

    &__title {
      display: none;
    }
  }
}
</style>