<script setup>
</script>

<template>
  <div class="asset-loading-container">
    <div class="asset-inner">
      <div class="loading-row">
        Uploading {{ params.data.name }}...
      </div>
      <div class="progress-line"></div>
    </div>
  </div>
</template>

<style scoped>
/* Progress line */
.asset-loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 0.5;
  padding: 5px;
  color: #555;
}
.asset-inner {
  width: 100%;
  height: 100%;
  background-color: #ddd;
}
.loading-row {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 5px 10px;
}

.progress-line, .progress-line:before{
    height: 3px;
    width: 100%;
    margin: 0;
}
.progress-line{
    background-color: #00000024;
    display: flex;
}
.progress-line:before{
    background-color: #167cf04d;
    content: '';
    animation: running-progress 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}
@-webkit-keyframes running-progress{
    0% { margin-left: 0px; margin-right: 100%; }
    50% { margin-left: 25%; margin-right: 0%; }
    100% { margin-left: 100%; margin-right: 0; }
}
@keyframes running-progress{
    0% { margin-left: 0px; margin-right: 100%; }
    50% { margin-left: 25%; margin-right: 0%; }
    100% { margin-left: 100%; margin-right: 0; }
}

.progress-line-pendinguploads{
    margin-top:8px;
}
</style>
