<script>
import { useLinkObject } from '@/pages/Dashboard/composables/LinkObject';
import { useDashboardStore } from '@/stores/dashboard-store';
import { useHarbourStore } from '@/stores/harbour-store';
import { AgGridVue } from 'ag-grid-vue';
import { ref } from 'vue';

// Documents grid
import DocRenderer from './DocRenderer.vue';

// Submissions grid
import LiveSigners from './LiveSigners.vue';
import OpenInFolder from './SubmissionsGrid/OpenInFolder.vue';
import SubmissionTitle from './SubmissionsGrid/SubmissionTitle.vue';

// Messages grid
import EmailsRenderer from './MessagesGrid/EmailsRenderer.vue';
import MessageType from './MessagesGrid/MessageType.vue';

// Automations
import DashboardAutomations from './AutomationsGrid/DashboardAutomations.vue';

export default {
  name: 'DetailGrid',
  components: {
    DashboardAutomations,
    AgGridVue,
    OpenInFolder,
    SubmissionTitle,
    MessageType,
    EmailsRenderer,
    LiveSigners,
    DocRenderer
  },
  setup() {
    return {
      harbourStore: useHarbourStore(),
      dashboardStore: useDashboardStore(),
      minWidth: 170,
    };
  },
  data() {
    return {
      isReady: false,
      authmode: null,
      allColumnDefs: {
        documentsEmails: [
          {
            field: 'submission',
            width: 250,
            maxWidth: 250,
            minWidth: this.minWidth,
            headerName: 'Document',
            hide: this.harbourStore.isMobileView,
            rowSpan: (params) => params.data.rowSpan || 1,
            cellClassRules: {
              'cell-span': (params) => params.data.rowSpan > 1,
            },
            cellRenderer: 'DocRenderer',
            cellRendererParams: {
              linkObject: () => this.linkObject
            },
            cellDataType: false,
          },
          {
            field: 'signers',
            minWidth: this.minWidth,
            valueGetter: (params) => params.data?.signer_email,
            rowSpan: (params) => params.data.rowSpanAll && (params.data.rowSpan || 1),
            cellClassRules: {
              'cell-span': (params) => params.data.rowSpanAll && params.data.rowSpan > 1,
            },
          },
          {
            field: 'upload_datetime',
            minWidth: this.minWidth,
            headerName: 'Signed date',
            sortable: true,
            valueFormatter: (params) => {
              if (params.data?.upload_datetime) {
                return this.harbourStore.formatUnixTimestamp(params.data?.upload_datetime)
              }
            },
            rowSpan: (params) => params.data.rowSpanAll && (params.data.rowSpan || 1),
            cellClassRules: {
              'cell-span': (params) => params.data.rowSpanAll && params.data.rowSpan > 1,
            },
          },
          {
            field: 'status',
            minWidth: this.minWidth,
            headerName: 'Details',
            hide: true,
            rowSpan: (params) => params.data.rowSpanAll && (params.data.rowSpan || 1),
            cellClassRules: {
              'cell-span': (params) => params.data.rowSpanAll && params.data.rowSpan > 1,
            },
          },
        ],
        documentsPublic: [
          {
            field: 'name',
            headerName: 'Document',
            cellRenderer: 'SubmissionTitle',
            cellRendererParams: {
              linkObject: () => this.linkObject,
            },
            minWidth: this.minWidth,
          },
          {
            field: 'upload_datetime',
            headerName: 'Signed date',
            minWidth: this.minWidth,
            sortable: true,
            valueFormatter: (params) =>
              params.data.upload_datetime &&
              this.harbourStore.formatUnixTimestamp(params.data.upload_datetime),
          },
          { field: 'signers', minWidth: 200, valueGetter: (params) => params.data.signer_email },
          { field: 'status', headerName: 'Details', minWidth: 120, hide: true },
          { field: 'folder', headerName: 'Folder', minWidth: 250, cellRenderer: 'OpenInFolder' },
        ],
        messages: [
          {
            field: 'medium_desc',
            headerName: 'Message type',
            minWidth: this.minWidth,
            cellRenderer: 'MessageType',
          },
          {
            field: 'message_type_desc',
            headerName: 'Event',
            cellClass: 'event-cell',
            minWidth: this.minWidth,
            valueGetter: (params) => params.data?.message_type_desc,
          },
          {
            field: 'original_create_datetime_epochs',
            headerName: 'Date',
            minWidth: this.minWidth,
            sortable: true,
            valueFormatter: (params) =>
              this.$root.displayLocalDateTimeFromEpochMs(
                params.data.original_create_datetime_epochs,
              ),
          },
          {
            field: 'recipient_emails',
            headerName: 'Recipient',
            minWidth: this.minWidth,
            cellRenderer: 'EmailsRenderer',
          },
        ],
      },
      data: {
        documents: ref([]),
        messages: ref([]),
      },
      defaultColDef: {
        ...this.harbourStore.defaultColDefForGrids,
        suppressMenu: true,
        suppressColumnsToolPanel: true,
        filter: false,
        editable: false,
        resizable: false,
        sortable: false,
        suppressMovable: true,
      },
      externalparams: null,
      linkObject: null,
      view: 'documents',
      gridOptions: {},
      gridOptionsDocuments: {},
      updateMessage: null,
      gridApi: null,
      gridColumnApi: null,
    };
  },
  beforeUnmount() {
    this.linkObject = null;
  },
  created() {
    this.data.documents = this.params.data.submissions;
    this.externalparams = this.params;
    this.linkObject = useLinkObject(this.params.data);
    this.gridOptions = {
      ...this.harbourStore.defaultGridOptionsForGrids,
      enableCellTextSelection: true,
      suppressContextMenu: true,
      overlayNoRowsTemplate: `<span class="ag-overlay-loading-center">- No submission yet -</span>`,
      getRowHeight: params => params.data.rowSpan === 1 && params.data.status === 'Completed' ? 120 : 60,
      getRowClass: params => {
        if (params.data.rowSpan > 1) {
          return 'spanned-row';
        }
      }
    };
    this.data.messages = ref(this.linkObject.messages);
    this.authmode = this.linkObject.authMethod?.authmode;

    this.getSentMessages();
    this.initializeData();
    window.onresize = this.resizeDetailGrid;
  },
  methods: {
    initializeData() {
      if (this.authmode === 'EMAILS') {
        this.data.documents = this.createEmailsData();
      } else this.rowData = this.linkObject.submissions;

      if (this.params.data.live && this.view !== 'live') {
        this.changeView('live');
        this.params.data.live = null;
      }
    },
    async getSentMessages() {
      this.updateMessage = 'Syncing messages...';
      const options = {
        requesttype: 'assetlibrary-getsentmessages',
        agreelink_display_id: this.linkObject.id,
      };
      const result = await this.$harbourData.post('/data', options);
      const messages = result?.data.messagesSent;
      this.linkObject.messages = messages;
      this.data.messages = ref(messages);
      this.updateMessage = null;
    },
    getDocsForEmailLinks(submission) {
      const completedSignerEmails = this.linkObject.emailRecipientsCompleted.map(
        (recipient) => recipient.recipientemail,
      );
      const signers = this.linkObject.emailRecipients;

      return signers.map((recipient, index) => {
        const returnRow = {
          id: submission?.id,
          name: submission?.filename || this.linkObject.title,
          upload_datetime: null,
          signer_email: recipient.recipientemail,
          folder: this.linkObject.folder,
          status: 'Completed',
          ...(submission && { rowSpan: index === 0 ? signers.length : null }),
          submission: submission
        };

        const folderAsset = this.harbourStore.myAssets.find((asset) => asset.id === submission?.id);
        if (folderAsset?.context_source_type === 'CATALIS_WEBSERVICE') {
          returnRow.status = 'eRecorded';
          returnRow.signer_email = '';
          returnRow.upload_datetime = submission.upload_datetime;
          returnRow.rowSpanAll = true;
          return returnRow;
        }

        if (completedSignerEmails.includes(recipient.recipientemail)) {
          const completed = this.linkObject.emailRecipientsCompleted;
          const signer = completed?.find(
            (signer) => signer.recipientemail === recipient.recipientemail,
          );
          returnRow.status = 'Completed';
          returnRow.upload_datetime = signer?.statejson?.completiondatetimeepochms;
        } else returnRow.status = 'Pending';
        if (recipient.reassigned) {
          returnRow.reassigned = true;
          returnRow.status = `Reassigned to ${recipient.reassignedto}`;
          returnRow.upload_datetime = null;
        }
        return returnRow;
      });
    },
    createEmailsData() {
      if (!this.linkObject.submissions.length) {
        return this.getDocsForEmailLinks();
      }

      return this.linkObject.submissions.reduce((acc, item) => ([
        ...acc,
        ...this.getDocsForEmailLinks(item)
      ]), []);
    },
    resizeDetailGrid() {
      this.gridOptions?.api?.sizeColumnsToFit();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.setView();
      this.resizeDetailGrid();
    },
    setView() {
      if (this.view === 'documents' && this.authmode === 'EMAILS') {
        this.gridColumnApi.setColumnVisible('status', true);
        if (this.linkObject.getCompletionState === 'COMPLETED' && !this.harbourStore.isMobileView) {
          this.gridColumnApi.setColumnVisible('submission', true);
        }
      }
    },
    changeView(view) {
      this.view = view;
      this.initializeData();
      this.columnDefs = this.getDocumentView;
      this.$nextTick(() => {
        this.resizeDetailGrid();
        this.setView();
      });
    },
    openPdfPreview() {
      // Open CK document
      if (this.linkObject.customInputs?.ckeditorAgreementId) {
        const htmlData = this.linkObject.customInputs.ckeditorHtmlData;
        return this.dashboardStore.openCkPreview(htmlData);
      }

      // Pending agreements
      if (this.linkObject.getCompletionState === 'PENDING') {
        return this.dashboardStore.openPresentationPdf(
          this.linkObject,
          `/agreepdfunsigned/${this.linkObject.id}`,
          this,
        );
      }

      // Completed agreements
      try {
        let linkAssets = this.linkObject.submissions;
        const linkAssetsFirstAsset = linkAssets[0];
        const assetFileName = linkAssetsFirstAsset.name;

        const src = `/agreepdf/${linkAssetsFirstAsset.id}/${assetFileName}`;
        this.dashboardStore.openPresentationPdf(this.linkObject, src, this);
      } catch (error) {
        // Catch-all open unsigned PDF
        return this.dashboardStore.openPresentationPdf(
          this.linkObject,
          `/agreepdfunsigned/${this.linkObject.id}`,
          this,
        );
      }
    },
    openDocumentInFolder() {
      const submissionId = this.linkObject.submissions[0]?.id;
      if (!submissionId) return;
      const query = { folderid: this.linkObject.folder, search: submissionId };
      this.$router.push({ name: 'folders', query });
    },
  },
  computed: {
    getDocumentView() {
      if (this.authmode === 'EMAILS') {
        const defs = this.allColumnDefs.documentsEmails;
        const status = defs.find((def) => def.field === 'status');
        status.hide = false;
        return defs;
      }
      return this.allColumnDefs.documentsPublic;
    },
    countLiveSessions() {
      const sessions = this.dashboardStore.liveSessions;
      if (!sessions) return 0;
      return sessions.filter((session) => session.link_id === this.linkObject.id)?.length || 0;
    },
    completedDocuments() {
      if (this.authmode === 'EMAILS') {
        return this.linkObject?.emailRecipientsCompleted?.length || 0;
      }
      if (this.authmode === 'PUBLIC') {
        return this.linkObject?.submissions?.length || 0;
      }

      return 0;
    },
  },
};
</script>

<template>
  <div class="detail-grid-container">
    <div class="section-row">
      <button @click="changeView('documents')" :class="{ active: view === 'documents' }">
        <i class="fa-light fa-file-check" style="margin-right: 5px"></i>
          <span>Completed documents ({{ completedDocuments }})</span>
      </button>
      <button @click="changeView('messages')" :class="{ active: view === 'messages' }">
        <i class="fa-light fa-envelope" style="margin-right: 4px"></i>
          <span>Sent messages ({{ data.messages?.length || 0 }})</span>
      </button>
      <button @click="changeView('live')" :class="{ active: view === 'live' }">
        <i class="fa-light fa-signal-stream" style="margin-right: 4px"></i>
          <span>Live stream ({{ countLiveSessions || 0 }})</span>
      </button>
      <button @click="changeView('automations')" :class="{ active: view === 'automations' }"
      >
        <i class="fa-light fa-bolt" style="margin-right: 4px"></i>
          <span>Automations ({{ linkObject.automations?.length || 0 }})</span>
      </button>
    </div>

    <div class="content">
      <div id="detailGridContainer" class="completed-document" v-if="view === 'documents'">

        <div class="detail-grid-container">
          <ag-grid-vue
            class="detail-grid ag-theme-alpine hrbr-ag-font-family"
            :gridOptions="gridOptions"
            :columnDefs="getDocumentView"
            :rowData="data.documents"
            :headerHeight="38"
            :defaultColDef="defaultColDef"
            :animateRows="false"
            @openDocumentFolder="openDocumentInFolder"
            @grid-ready="onGridReady" />
        </div>
      </div>

      <div class="sent-messages" v-if="view === 'messages'">
        <div class="status-bar" v-if="updateMessage">
          <span><i class="fal fa-sync fa-spin mr-2"></i></span>
          <span>{{ updateMessage }}</span>
        </div>
        <div class="detail-grid-container">
          <ag-grid-vue
            class="detail-grid ag-theme-alpine hrbr-ag-font-family"
            :gridOptions="gridOptions"
            :columnDefs="allColumnDefs.messages"
            :rowData="data.messages"
            :headerHeight="38"
            :defaultColDef="defaultColDef"
            :animateRows="true"
            @grid-ready="onGridReady" />
        </div>
      </div>

      <div v-if="view === 'live'">
        <LiveSigners :link-object="linkObject" />
      </div>

      <div v-if="view === 'automations'">
        <DashboardAutomations
          :grid-options="gridOptions"
          :link="linkObject"
          @grid-ready="onGridReady" />
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.sent-messages {
  height: 100%;
  width: 100%;
}
.detail-grid-container {
  width: 100%;
  height: 100%;
}
.detail-grid-container >>> .recipient-emails-cell {
  max-width: 100%;
  width: 50%;
  height: 100%;
  max-height: 100%;
  overflow: auto;
}
.detail-grid-container .header-cell {
  font-size: 9px;
}
.detail-grid {
  height: 100%;
  --ag-cell-horizontal-padding: 0;
  --ag-icon-font-family: 'agGridAlpine';
  --ag-header-height: 200;
  --ag-header-foreground-color: #666666;
  --ag-header-cell-hover-background-color: none;
  --ag-header-cell-moving-background-color: none;
  --ag-row-hover-color: none;
  --ag-header-column-resize-handle-display: inline-block;
  --ag-header-column-resize-handle-height: 25%;
  --ag-header-column-resize-handle-width: 2px;
  --ag-header-column-resize-handle-color: #0c0c0c2b;
  --ag-tooltip-background-color: #ffffff99;
  --ag-borders: none;
  font-size: 12px !important;
}
.detail-grid >>> .ag-header-cell {
  font-weight: 500;
  color: #666;
  border-bottom: 1px solid transparent;
  padding: 0;
}

.detail-grid >>> .ag-header {
  background-color: transparent;
  font-weight: 700;
  border-bottom: 1px solid #ededed;
}
.detail-grid >>> .ag-header-active {
  background-color: transparent;
}

.detail-grid >>> .ag-row {
  border-bottom: 1px solid #ededed;
  z-index: 1;
  &.spanned-row {
    z-index: 2;
  }
}
.detail-grid >>> .ag-header-cell-text {
  width: 100%;
}
.detail-grid >>> .ag-header-cell {
  height: 100%;
  font-weight: 500;
  color: #666;
  border-bottom: 1px solid transparent;
}
.detail-grid >>> .ag-header-cell:hover {
  background-color: none;
  border-bottom: 1px solid #666666;
}
.detail-grid >>> .ag-column-select-header {
  display: none;
}
.detail-grid >>> .ag-header-cell-label {
  color: #363636;
  padding: 0;
  margin: 0;
  font-size: 12px !important;
  font-weight: 600;
}
.detail-grid >>> .cell-span {
  min-height: 120px;
  background-color: #fff;
}

.detail-grid >>> .event-cell {
  line-height: 1.2em;
  word-break: break-word;
}

.completed-document {
  height: 100%;
  width: 100%;
  display: flex;
}
.status-bar {
  font-size: 11px;
  color: #adadad;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.detail-grid {
  height: 260px;
}
.container {
  height: 100% !important;
}
.content {
  padding: 10px 40px;
  width: 100%;
  height: 100% !important;
  font-size: 12px;
}
.headers {
  display: flex;
  padding: 10px;
  color: #9ea5b0;
}
.row {
  display: flex;
}
.section-row {
  display: flex;
  background-color: #f5f5f7;
  padding: 10px;
}
.section-row button {
  padding: 10px 15px;
  border-radius: 5px;
  outline: none;
  border: none;
  color: #868686;
  font-size: 14px;
  margin-right: 8px;
  cursor: pointer;
  transition: all 100ms ease;
}
.active {
  background-color: rgb(255, 255, 255);
  color: #2c2c2c !important;
}

.detail-grid >>> .ag-overlay-loading-center {
  padding: 20px;
  background-color: #ffffff99;
  border-radius: 5px;
}
.detail-grid >>> .detail-grid {
  height: 100%;
  width: 100%;
  line-height: 1em;
  --ag-cell-horizontal-padding: 0;
  --ag-icon-font-family: 'agGridAlpine';
  --ag-header-foreground-color: #666666;
  --ag-header-cell-hover-background-color: none;
  --ag-header-cell-moving-background-color: none;
  --ag-row-hover-color: none;
  --ag-header-column-resize-handle-display: block;
}
.detail-grid >>> .ag-cell-label-container {
  height: 40px;
}
.detail-grid >>> .ag-header-cell-label {
  font-size: 12px !important;
  color: #9ea5b0 !important;
}
.detail-grid >>> .ag-header-cell {
  padding: 10px;
  line-height: 1em;
  border: none !important;
}
.detail-grid >>> .ag-cell {
  height: 100%;
  width: 100%;
  font-size: 12px !important;
  border: none !important;
  display: flex;
  align-items: center;
  &.cell-span {
    border-bottom: 1px solid #ededed !important;
  }
}
.detail-grid >>> .ag-cell-value {
  max-height: 100%;
  overflow: auto;
  margin: 5px;
  scrollbar-width: thin;
  color: #424141eb;
}

@media (max-width: 768px) {
  .detail-grid-container {
    padding: 0;
    margin: 0;
  }
  .section-row {
    max-width: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
  }
  .content {
    padding: 0;
  }

  .emails-doc {
    position: inherit;
    background-color: blue;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .completed-document {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .detail-grid {
    max-height: 100px;
  }
}
</style>
