<script setup>
import { useHarbourStore } from '@/stores/harbour-store.js';
import { useDashboardStore } from '@/stores/dashboard-store.js';
import { useRouter } from 'vue-router/composables';

const harbourStore = useHarbourStore();
const dashboardStore = useDashboardStore();
const router = useRouter();

const openDocumentInFolder = (params) => {
  const submission = params.value;
  if (!submission.id) return;
  const query = { folderid: params.linkObject().folder, search: submission.id };
  router.push({ name: 'folders', query });
}

</script>
<template>
  <div
    class="emails-doc"
    v-if="
      params.data.rowSpan &&
      params.linkObject().getCompletionState === 'COMPLETED' &&
      params.linkObject().authMethod.authmode === 'EMAILS' &&
      !harbourStore.isMobileView
    ">
    <div class="completed-icon" @click="dashboardStore.openCompletedDocPdfPreview(params.value, params.linkObject())">
      <i class="fal fa-file-pdf pdf-icon mr-2" title="View completed document"></i>
    </div>
    <div class="completed-title pb-2">
      {{ params.value.name }}
    </div>
    <div class="emails-doc-folder" @click="openDocumentInFolder(params)">
      <i class="fal fa-folder-open mr-2"></i>
      Open in folder
    </div>
  </div>
</template>

<style scoped lang="postcss">
.emails-doc {
  width: 220px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #9ea5b0;
  background-color: #ffffff88;
  &-title {
    color: #9ea5b0;
    height: 36px;
    padding-top: 10px;
    padding-left: 10px;
    font-size: 12px !important;
  }
  &-folder {
    display: flex;
    color: #888;
    align-items: center !important;
    transition: all 250ms ease;
    cursor: pointer;
    &:hover {
      color: #666;
    }
  }
  &-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    white-space: wrap;
    padding: 10px;
    color: black;
    font-size: 12px;
    font-weight: 400;
    border-bottom: 1px solid #ededed;
    i {
      font-size: 30px;
      padding: 15px;
      color: #6a6a6aad;
      cursor: pointer;
    }
  }
}
.completed-icon {
  &:hover {
    color: #666;
  }
  i {
    font-size: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 250ms ease;
  }
}
.completed-title {
  max-width: 100%;
  text-align: center;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
  padding: 10px;
}
@media (max-width: 768px) {
  .emails-doc {
    position: inherit;
    background-color: blue;
    height: auto;
    display: flex;
    flex-direction: column;
  }
}
</style>
