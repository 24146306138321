<script>
import { useHarbourStore } from '@/stores/harbour-store';
import { useDashboardStore } from '@/stores/dashboard-store';
import { useLinkObject } from '@/pages/Dashboard/composables/LinkObject'
import HrbrFolderSelector from '@/components/Other/HrbrFolderSelector.vue';
import { subscribeTo, unsubscribeFrom } from '@/utils/bus';

export default {
  name: 'FolderRenderer',
  setup() {
    return {
      harbourStore: useHarbourStore(),
      dashboardStore: useDashboardStore(),
    };
  },
  data() {
    return {
      linkObject: null,
      currentIcon: 'fa-folder',
      isWaitingForServer: false,
    };
  },
  mounted() {
    this.linkObject = useLinkObject(this.params.data);

    subscribeTo('dashboard:hide-dropdown-menu', this.hideDropdown);
  },
  beforeUnmount() {
    this.linkObject = null;

    unsubscribeFrom('dashboard:hide-dropdown-menu', this.hideDropdown)
  },
  methods: {
    displayPencil() {
      this.currentIcon = 'fa-pencil';
    },
    displayFolder() {
      this.currentIcon = 'fa-folder';
    },
    hideDropdown() {
      if (!this.$refs.foldersDropdown) return;
      if (this.$refs.foldersDropdown.isActive) {
        this.$refs.foldersDropdown.toggle();
      }
    },
    async folderChange(folder) {
      this.isWaitingForServer = true;
      const result = await this.dashboardStore.updateLinkFolder(folder, this.linkObject.id);
      const data = result?.data;

      if (data === 1) {
        this.linkObject.folder = folder.id;

        const gridApi = this.params.api;
        const link = gridApi.getRowNode(this.linkObject.id);
        if (!link) return;
        link.folder = folder.id;
      }
      this.isWaitingForServer = false;
      setTimeout(() => {
        this.harbourStore.refreshFolders()
      }, 150);
    },
    openFolderChanger() {
      this.$buefy.modal.open({
        fullScreen: false,
        parent: this,
        component: HrbrFolderSelector,
        customClass: 'dashboard-folder-selector-modal',
        canCancel: ['escape', 'x', 'outside'],
        props: {
          handleExternalFolderChange: true,
          externalCallback: this.folderChange,
          assets: [this.linkObject.id],
          updatetablemove: () => null,
          titleOverride: `Change destination folder`,
          currentfolderid: this.linkObject.folder,
          currentfoldername: this.linkObject.folderName,
          refreshassets: () => null,
          clearcheckedrows: () => null,
        },
      });
    },

    handleViewFolder() {
      const query = { folderid: this.linkObject.folder };
      this.$router.push({ name: 'folders', query });
    },
  },
  computed: {
    hideFolderPicker() {
      const state = this.linkObject?.getCompletionState === 'COMPLETED';
      const mode = this.linkObject?.authMethod?.authmode === 'EMAILS';
      if (state && mode) return true;
      return false;
    },
  },
};
</script>

<template>
  <div class="folder-renderer">
    <div class="folder-renderer-container">
      <div class="folder-inner">
        <b-dropdown
          position="is-bottom-left"
          animation=""
          append-to-body
          :triggers="['click']"
          :mobile-modal="false"
          :disabled="isWaitingForServer"
          aria-role="list"
          class="folders-dropdown"
          ref="foldersDropdown"
          @click.native.stop
        >
          <button
            class="hrbr-grid-button folder-dropdown-button"
            slot="trigger"
            title="Change or view destination folder">
            <span v-if="!isWaitingForServer" class="folder-name">
              <i class="fal folder-renderer-icon mr-1" :class="currentIcon"></i>
              {{ linkObject?.folderName }}
            </span>
            <span v-else>
              <i class="far fa-circle-notch fa-spin"></i>
            </span>
          </button>
          <b-dropdown-item @click.native.stop="openFolderChanger" :disabled="hideFolderPicker">
            <i class="fal fa-arrow-square-right mr-2"></i>
            <span>Change destination folder</span>
          </b-dropdown-item>
          <b-dropdown-item @click.native.stop="handleViewFolder">
            <i class="fal fa-folder-open mr-2"></i>
            <span>View destination folder</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<style>
.dashboard-folder-selector-modal .modal-content {
  width: 650px;
  display: flex !important;
  justify-content: center !important;
}
@media all and (max-width: 650px) {
  .dashboard-folder-selector-modal .modal-content {
    width: 100%;
  }
}
@media all and (max-width: 480px) {
  .folders-dropdown .dropdown-menu {
    position: fixed !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}
</style>
<style scoped>
.folder-name {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size:12px;
}
.folder-renderer {
  display: flex;
  justify-content: flex-start;
  min-width: 100%;
}
.folder-changer {
  max-height: 120px;
  display: flex;
  flex-direction: column;
}
.folder-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  overflow-y: auto;
}
.folders-dropdown {
  display: inline-block;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.folder-dropdown-button {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height:13px;
}

.folder-dropdown-button:hover {
  border-color: #b5b5b5;
  color: #000;
}


.folder-renderer-container {
  min-width: 100%;
  max-width: 100%;
  display: block;
  text-align: center;
  justify-content: center;
  align-items: center;
  line-height: 1.1em;
  white-space: nowrap;
}
.folder-inner {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
}
.folder-name {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.folder-renderer-icon {
  margin-right: 2px !important;
  border-radius: 5px;
  transition: all 250ms ease;
}
</style>
