<template>
    <div class="gallery-outer">
        <div class="gallery-inner">
            <!-- gallery controls -->
            <div class="controls-row">
                <div class="left">
                    <!-- attachment number out of toal attachments -->
                    <span>{{currentAttachmentIndex.value + 1}}/{{attachments.length}}</span>
                </div>
                <div class="right">
                    <!-- download button -->
                    <a v-if="currentAttachment.fileType !== 'pdf'"
                        :href="currentAttachment.url" 
                        target="_blank" :style="{marginRight: '1em', color: 'white'}" 
                        class="gallery-button">
                        <b-icon pack="far" icon="arrow-down-to-line"></b-icon>
                    </a>
                    <!-- close button -->
                    <span @click="closeGallery" 
                        class="gallery-button">
                        <b-icon pack="far" icon="x"></b-icon>
                    </span>
                </div>
            </div>

            <!-- current attachment display -->
            <div class="display-row">
                <span class="gallery-button arrow-button" @click="prevAttachment">
                    <b-icon pack="far" icon="arrow-left"></b-icon>
                </span>

                <!-- image display -->
                <div v-if="currentAttachment.fileType === 'image' && attachmentIsRenderable(currentAttachment)" class="current-attachment-image" :style="{background: `url(/attachment/${currentAttachment.id}/preview) no-repeat`}"></div>
                <!-- video display -->
                <video v-else-if="currentAttachment.fileType === 'video' && attachmentIsRenderable(currentAttachment)" class="current-attachment-video" :src="currentAttachment.displayUrl" controls></video>
                <!-- pdf display -->
                <div v-else-if="currentAttachment.fileType === 'pdf' && attachmentIsRenderable(currentAttachment)" class="current-attachment-image">
                    <HrbrPdfViewer
                        v-bind="{
                            file: currentAttachment.displayUrl,
                            mode: 'view',
                        }">
                    </HrbrPdfViewer>
                </div>
                <!-- unrenderable attachment display -->
                <div v-else class="current-attachment-unrenderable">
                    <i class="far" :class="'fa-' + getAttachmentFileIcon(currentAttachment)"></i>
                    <span class="current-attachment-extension">{{ getAttachmentExtension(currentAttachment) }}</span>
                </div>
                
                <!-- loading animation -->
                <b-icon v-if="attachmentIsRenderable(currentAttachment) && !isFailedToLoadImage" class="current-attachment-loading-icon" pack="fas" icon="spinner-third"></b-icon>


                <span class="gallery-button arrow-button" @click="nextAttachment">
                    <b-icon pack="far" icon="arrow-right"></b-icon>
                </span>
            </div>

            <!-- uploader name and date -->
            <div class="details-row">
                <!-- name -->
                <p>Filename: {{ currentAttachment.name }}</p>
                <!-- file size -->
                <p v-if="currentAttachment.fileSizeBytes">File size: {{ currentAttachment.fileSizeString }}</p>
                <!-- video duration -->
                <p v-if="currentAttachment.videoDuration">Clip length: {{ getAttachmentDurationString(currentAttachment.videoDuration) }}</p>
                <!-- uploader -->
                <p>Uploaded by: {{ currentAttachment.uploaderEmail }}</p>
                <!-- upload context info -->
                <p v-if="currentAttachment.uploadContext">Uploaded as: {{ currentAttachment.uploadContext }}</p>
            </div>

            <!-- row of thumbnails -->
            <div class="thumbnails-row">
                <div v-for="(attachment, index) in attachments" 
                class="attachment"
                :key="index">
                    <div class="image-ctn thumbnail-row-square"
                    @click="handleImageClick(index)"
                    :class="{'current-attachment-thumbnail': index === currentAttachmentIndex.value}"
                    :style="{ background: `url(${attachment.thumbnailUrl}) no-repeat` }">
                    <!-- vide duration -->
                    <span v-if="attachment.videoDuration" class="video-duration">
                        {{ getAttachmentDurationString(attachment.videoDuration) }}
                    </span>
                    
                    <!-- if unrenderable, show icon (no thumbnail) -->
                        <div v-if="!attachment.thumbnailUrl" class="image-ctn-inner">
                            <b-icon
                            pack="far"
                            :icon="getAttachmentFileIcon(attachment)">
                            </b-icon>
                            <span class="extension">{{ getAttachmentExtension(attachment) }}</span>
                        </div>
                    </div>
                </div>
                <!-- upload button to add more attachments -->
                <div
                class="upload-btn thumbnail-row-square"
                :class="{disabled: !hasEditPermissions}"
                @click="uploadButtonClickHandler">
                    <b-icon v-if="uploadingAttachments.value" class="loading-icon" pack="fas" icon="spinner-third"></b-icon>
                    <b-icon v-else pack="far" icon="plus"></b-icon>
                </div>
            </div> <!-- end thumbnails row -->
        </div>
    </div>
</template>

<script>
import { getAttachmentFileIcon, attachmentIsRenderable, getAttachmentExtension, getAttachmentDurationString } from '@/utils/helpers/inline-attachments.js';
import HrbrPdfViewer from '@/components/PdfViewer/HrbrPdfViewer.vue';

export default {
    props: {
        attachments: {
            type: Array,
            default: () => [],
        },
        currentAttachmentIndex: {
            type: Object,
            default: () => ({value: 0}),
        },
        uploadingAttachments: {
            type: Object,
            default: () => ({value: false}),
        },
        hasEditPermissions: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isFailedToLoadImage: false,
            failedToLoadImageTimeoutID: null,
        }
    },
    created(){
        // add keydown listener for left/right arrow keys
        document.addEventListener('keydown', this.keydownHandler);
        this.waitUntilImageLoaded();
    },
    beforeDestroy() {
        // remove keydown listener
        document.removeEventListener('keydown', this.keydownHandler);
    },
    computed: {
        currentAttachment() {            
            return this.attachments[this.currentAttachmentIndex.value];
        },
    },
    watch: {
        currentAttachment() {
            this.waitUntilImageLoaded()
        },
    },
    methods: {
        keydownHandler(e) {
            const handlers = {
                ArrowLeft: this.prevAttachment,
                ArrowRight: this.nextAttachment,
                Escape: this.closeGallery
            }
            const handler = handlers[e.key];
            if (handler) handler();
        },
        handleImageClick(index) {
            this.$emit('update-current-attachment', index);
        },
        prevAttachment() {
            this.$emit('prev-attachment');            
        },
        nextAttachment() {
            this.$emit('next-attachment');
        },
        closeGallery() {
            this.$emit('close');
        },
        downloadAttachment() {
            this.$emit('download', this.currentAttachment);
        },
        uploadButtonClickHandler () {
            if (!this.hasEditPermissions) return;
            this.$emit('upload-attachments');
        },
        waitUntilImageLoaded() {
            clearTimeout(this.timeoutID);
            this.isFailedToLoadImage = false;
            const currentAttachment = this.attachments[this.currentAttachmentIndex.value];
            if (!currentAttachment?.thumbnailUrl) {
                return;
            }
            this.timeoutID = setTimeout(() => {
                this.isFailedToLoadImage = true;
            }, 5000)
        },
        getAttachmentFileIcon,
        attachmentIsRenderable,
        getAttachmentExtension,
        getAttachmentDurationString
    },
    components: {
        HrbrPdfViewer,
    },
}
</script>

<style scoped>
.gallery-button {
    cursor: pointer;
    border-radius: 50%;
    padding: 10px;
    transition: all 0.2s ease-in-out;
    z-index: 2;
}
.gallery-button.arrow-button {
    padding: 10px 13px;
}
.gallery-button:hover {
    background-color: rgb(73, 73, 73);
}
.gallery-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: black;
    position: absolute;
    width: 100%;
    height: 100%;
}
.gallery-inner {
    display: flex;
    flex-direction: column;
    padding: 2em;
    width: 100%;
    height: 100%;
}
.controls-row, .display-row, .thumbnails-row {
    font-size: 1.3em;
}
.controls-row {
    display: flex;
    justify-content: space-between;
}

.display-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
}

.current-attachment-image {
    width: 100%;
    height: 100%;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    z-index: 1;
}
.current-attachment-image img {
    visibility: hidden;
}

.current-attachment-video {
    width: 60%;
    max-height: 60vh;
    z-index: 1;
}

.current-attachment-unrenderable {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    width: 500px;
    background-color: black;
}

.current-attachment-unrenderable i {
    font-size: 10em;
    height: 230px;
}

.details-row {
    text-align: center;
    margin: 10px 0;
}

.thumbnails-row {
    display: flex;
    justify-content: center;
}

.thumbnails-row .thumbnail-row-square {
    cursor: pointer;
    background-size: cover !important;
    background-position: center !important;
    height: 100px;
    width: 100px;
    border: solid 3px white;
    margin: 0 5px;
    border-radius: 4px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.thumbnails-row .thumbnail-row-square .video-duration {
    text-align: center;
    min-width: 50px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    font-weight: 700;
}

.image-ctn-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 5px;
}

.thumbnails-row .thumbnail-row-square .icon {
    font-size: 2em;
    height: 40px;
}

.thumbnails-row .thumbnail-row-square.current-attachment-thumbnail{
    border: solid 3px #f14668;
}

.thumbnail-row-square-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 9px;
}

.thumbnail-row-square-inner .extension {
    font-size: 15px;
    text-transform: uppercase;
}

.thumbnails-row .thumbnail-row-square img {
    visibility: hidden;
}

.thumbnails-row .thumbnail-row-square .loading-icon {
    animation: spin .8s linear infinite;
}

.thumbnails-row .upload-btn span {
    font-size: 2em;
    position: relative;
    top: -2px;
}

.current-attachment-extension {
    text-transform: uppercase;
}

.current-attachment-loading-icon {
    animation: spin .55s linear infinite;
    font-size: 4em;
    position: absolute;
    left: 50%;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

.faied-to-load-icon{
    font-size: 4em;
    position: absolute;
    left: 50%;
}
</style>