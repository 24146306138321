<script>
import { useLinkObject } from '@/pages/Dashboard/composables/LinkObject'

export default {
  name: 'ViewsRenderer',
  data() {
    return {
      field: null,
      linkObject: null,
      isActive: false,
    };
  },
  created() {
    this.field = this.params.colDef.field;
    this.linkObject = useLinkObject(this.params.data);
  },
  mounted() {
    setTimeout(() => {
      this.isActive = true;
    }, 5);
  },
  beforeUnmount() {
    this.linkObject = null;
  },
};
</script>

<template>
  <div class="views-container" v-if="isActive">
    <div class="views-text">{{ linkObject.totalViews }}</div>
    <div class="linkDetailText">views</div>
  </div>
</template>

<style scoped>
.views-container {
  line-height: 1em;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #787878;
}
.views-text {
  font-size: 18px;
  font-weight: 300;
  padding-bottom: 5px;
}
.linkDetailText {
  font-size: 0.8em;
}
</style>
