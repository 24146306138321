<script setup>
import { useHarbourStore } from '@/stores/harbour-store';
import { useLibraryStore } from '@/stores/library-store';
import { atcb_action } from "add-to-calendar-button";
import nlp from 'compromise';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import AiLoader from '@/components/Other/AiLoader.vue';
import { useColumnStore } from '@/stores/column-store';

const harbourStore = useHarbourStore();
const columnStore = useColumnStore();
const libraryStore = useLibraryStore();
const { hasEditPermissions } = storeToRefs(libraryStore);

const { formatDateWithoutTime } = harbourStore;
const agParams = ref(null);

const isDate = ref(false);
const confidence = ref(null);
const extraction = ref(null);
const clickTimer = ref(null);
const selectedDate = ref(null);
const datePicker = ref(null);


const checkNumericDate = (dateStr) => {
  const date = new Date(dateStr.join('/'));
  return !isNaN(date.getTime());
}

const startsWithMonth = (value) => {
  const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'sept', 'oct', 'nov', 'dec'];
  return months.some(month => value.toLowerCase().startsWith(month));
}

const checkDateStringWithMonthName = (splitString) => {
  const monthIndex = splitString.findIndex(startsWithMonth);
  const month = splitString[monthIndex];

  // Remove the month from the array
  splitString.splice(monthIndex, 1);

  // Check if we have a number with 4 digits, which would be the year
  const yearIndex = splitString.findIndex(value => value.length === 4);
  if (!yearIndex || yearIndex === -1) return false;
  const year = splitString[yearIndex];

  // The day is the remaining value
  splitString.splice(yearIndex, 1);
  const day = splitString[0];

  // Attempt to parse the date
  const date = new Date(`${month} ${day}, ${year}`);
  return !isNaN(date.getTime());
}

function checkIsDate(dateString) {
  // Function to check if this value is a date so we can render
  // the datepicker instead of a text input
  if (!dateString || typeof(dateString) === 'object') return false;

  const splitString = dateString.split(/[,\s-/]+/);
  if (splitString.length !== 3) return false;

  // We could have a date in MM/DD/YYYY format
  const allNumeric = splitString.every(value => !isNaN(value));
  if (allNumeric) return checkNumericDate(splitString);

  // Or a date with a string as the month name
  const hasMonthName = splitString.some(startsWithMonth);
  if (hasMonthName) return checkDateStringWithMonthName(splitString);
  return false;
}

const processExtraction = (extraction) => {
  if (!extraction) return;

  try {
    isDate.value = checkIsDate(extraction);
  } catch (e) {
    // If something goes wrong with date parsing, default to false
    isDate.value = false;
    console.log('Error checking if date', e);
  }
};

const init = (params) => {
  agParams.value = params;
  confidence.value = parseFloat(params.value?.confidence_score);
  extraction.value = params.value?.extraction;
  processExtraction(extraction.value);
  if (!selectedDate.value) selectedDate.value = new Date(extraction.value);
};

const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

const isUserColumn = computed(() => {
  return agParams.value?.column.colDef.userColumn;
});

const isAiColumn = computed(() => {
  return agParams.value?.column.colDef.ai;
});

const shouldRenderAiLoader = computed(() =>
  !agParams.value?.value &&
  agParams.value?.column.colDef.ai &&
  agParams.value?.data.file_extension?.toLowerCase() === 'pdf'
);

const formatDateForCalendar = (dateObj) => {
  const processedDate = new Date(dateObj);
  const year = processedDate.getFullYear();
  const month = String(processedDate.getMonth() + 1).padStart(2, '0');
  const day = String(processedDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

const getDateThirtyPrior = (dateObj) => {
  const processedDate = new Date(dateObj);
  const priorDate = new Date(processedDate);
  priorDate.setDate(processedDate.getDate() - 30);

  const year = priorDate.getFullYear();
  const month = String(priorDate.getMonth() + 1).padStart(2, '0');
  const day = String(priorDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formatDateWithoutTime(formattedDate);
}

const mapTextForFuzzyComparison = (text) => {
  return text.trim().toLowerCase();
}

const getNounMatches = (docs) => {
  const nounSets = docs.map(doc => new Set(doc.match('#Noun').out('array').map(mapTextForFuzzyComparison)));

  const intersection = new Set([...nounSets[0]].filter(word => nounSets[1].has(word)));
  const matches = intersection.size;

  const requestLength = nounSets[0].size;
  const percent = requestLength ? matches / requestLength : 0;

  return { intersection, percent };
}

const addToCalendar = (e, reminder = null) => {
  const assetName = agParams.value?.data?.name;

  const reminderDate = formatDateForCalendar(getDateThirtyPrior(extraction.value));
  const eventDate = formatDateForCalendar(extraction.value);
  const timezone = getUserTimezone();
  const header = agParams.value?.column.colDef.headerName;

  const docs = [nlp(header), nlp('expiration date')];
  const { intersection, percent } = getNounMatches(docs);
  const isExpiration = intersection.has('expiration');

  let eventTitle = `${assetName}`;
  if (isExpiration) eventTitle += ` expires on:`
  else eventTitle += reminder ? ` ${header} reminder:` : ` ${header}`;
  eventTitle += ` ${eventDate}.`;

  const currentURL = new URL(window.location.href).origin;
  const id = agParams.value.data.id;
  const assetFinderUrl = `${currentURL}/show-asset/${id}`
  const config = {
    action: 'TEMPLATE',
    name: eventTitle,
    description: `Click here to view the agreement: ${assetFinderUrl}`,
    startDate: reminder ? reminderDate : eventDate,
    options: ["Google", "Apple", "iCal", "Outlook.com", "Microsoft365"],
    timeZone: timezone || "America/Los_Angeles"
  };
  atcb_action(config, e.target);
}

const handleDateChange = () => {
  if (!hasEditPermissions.value) return;

  extraction.value = formatDateWithoutTime(selectedDate.value);
  const column = agParams.value.colDef.field;
  const params = {
    rowId: agParams.value.data.id,
    columnTitle: agParams.value.colDef.headerName,
    value: { extraction: extraction.value },
    column,
  }
  columnStore.updateCellValue(params);
}

const handleItemClick = () => {
  if (!hasEditPermissions.value) return;

  clearTimeout(clickTimer.value);
  datePicker.value?.toggle();
  clickTimer.value = setTimeout(() => {
  }, 200);
}

const handleItemDoubleClick = () => {
  if (!hasEditPermissions.value) return;

  clearTimeout(clickTimer.value);
}

const getExtraction = (value) => {
  const extractionValue = value.extraction;

  if (typeof extractionValue === 'object') return extractionValue.extraction
  return extractionValue;
}

</script>

<template>
  <div class="ai-cell-container">
    {{ init(params) }}

    <div v-if="!isUserColumn">-</div>
    <div v-else-if="shouldRenderAiLoader">
      <AiLoader />
    </div>

    <!-- Sometimes the cell data can be an array, in which case we handle that separately further down -->
    <!-- For non-array cell data. For array data we render that below -->
    <div v-else-if="params.value && !Array.isArray(extraction)" class="main-content-single">
      <div class="ai-row">
        
        <!-- AI EXTRACTION: IF VALUE IS NOT A DATE - SHOW TEXT -->
        <span
          class="ai-cell-text"
          :class="{ 'is-date': isDate }"
          v-if="params.value?.extraction && !isDate"
          @dblclick="handleItemDoubleClick"
          @click="handleItemClick"
          >{{ getExtraction(params.value) }}</span>

        <!-- IF VALUE IS DATE -->
        <div v-else-if="params.value?.extraction && isDate">
          <span
            class="ai-cell-text"
            :class="{ 'is-date': isDate, 'has-edit': hasEditPermissions.value }"
            @dblclick="handleItemDoubleClick"
            @click="handleItemClick">
            {{ formatDateWithoutTime(selectedDate) }}
          </span>
          <b-datepicker
            v-if="hasEditPermissions"
            v-model="selectedDate"
            :mobile-native="false"
            :append-to-body="true"
            icon-pack="fal"
            size="is-small"
            position="is-bottom-left"
            class="datepicker-style"
            ref="datePicker"
            @input="handleDateChange">
            <template #trigger>
              <span class="hidden-trigger"></span>
            </template>
          </b-datepicker>
        </div>

        <span class="ai-cell-text ai-cell-no-data" v-else>[no data]</span>
        <b-dropdown
          class="ai-calendar"
          v-if="isDate"
          :mobile-modal="true"
          append-to-body
          position="is-bottom-left">
          <template #trigger>
            <i class="fad fa-bell"></i>
          </template>

          <b-dropdown-item class="ai-calendar__dropdown-header" custom>
            <span>Actions</span>
          </b-dropdown-item>
          <hr class="ai-calendar__dropdown-divider dropdown-divider">

          <b-dropdown-item>
            <div class="hrbr-agreement-editor-preview__signers-dropdown-item-value" @click="addToCalendar">
              <span class="ai-calendar__dropdown-icon">
                <i class="fa-sharp fa-light fa-calendar-star"></i>
              </span>
              Create calendar event at: {{ formatDateWithoutTime(selectedDate) }}
            </div>
          </b-dropdown-item>
          <b-dropdown-item>
            <div class="hrbr-agreement-editor-preview__signers-dropdown-item-value" @click="(e) => addToCalendar(e, 'reminder')">
              <span class="ai-calendar__dropdown-icon">
                <i class="fa-light fa-calendar-clock"></i>
              </span>
              Create reminder event 30 days prior: {{ getDateThirtyPrior(selectedDate) }}
            </div>
          </b-dropdown-item>
        </b-dropdown>

      </div>
    </div>

    <!-- For array data: handle this separately -->
    <div v-else class="main-content">
      <div v-for="(item, index) in extraction" :key="index" class="extraction-item" :class="{ 'is-date': isDate }" :title="item">
        - {{ item }}
      </div>
    </div>

  </div>
</template>

<style scoped>

.ai-row .control {
  /* Needed to override the trigger for calendar */
  height: 0;
}
.hidden-trigger {
  display: none;
}
.main-content {
  scrollbar-gutter: stable;
  overflow-y: auto;
  max-height: 85%;
  width: 100%;
}
.ai-row {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 100%;
  height: 100%;
  white-space: wrap;
}
.has-edit {
  cursor: pointer;
}
.extraction-item {
  padding: 10px 0;
  border-bottom: 1px solid #aaaaaa33;
}
.is-date {
  border-bottom: 1px dashed #acacac;
  text-decoration: none;
  white-space: nowrap;
}
.ai-cell-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  height: 100%;
  width: 100%;
  padding: 5px;

}
.ai-cell-text {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  /* max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.ai-cell-no-data {
  color: #aaa;
  font-size: 12px;
  text-align: center;
}
.ai-calendar {
  margin-left: 5px;
  color: #333;
  cursor: pointer;
  transition: all 0.25s ease;
}
.ai-calendar:hover {
  color: #333;
}
.ai-footer {
  position: absolute;
  height: 20px;
  bottom: 0;
  right: 10px;
}
.confidence-circle {
  height: 8px;
  width: 8px;
  margin: 5px;
  border-radius: 50%;
}

.ai-calendar__dropdown-header {
  font-weight: 500;
  padding-top: 0;
  padding-bottom: 0;
}

.ai-calendar__dropdown-divider {
  margin-top: 6px;
  margin-bottom: 6px;
}

.ai-calendar__dropdown-icon {
  font-size: 15px;
  display: inline-flex;
  width: 16px;
  margin-right: 4px;
}
</style>
