<script setup>
import { computed } from 'vue';
import { DraftStatuses } from '@/domain/drafts/draft';

const props = defineProps({
  draftId: {
    type: String,
    default: null,
  },
  status: {
    type: String,
    default: DraftStatuses.Started,
  },
  disabled: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits([
  'status-selected',
]);

const statusItems = {
  [DraftStatuses.Started]: {
    id: DraftStatuses.Started,
    title: 'Started',
    icon: 'rocket-launch',
  },
  [DraftStatuses.ReviewInternal]: {
    id: DraftStatuses.ReviewInternal,
    title: 'Team review',
    icon: 'user-group',
  },
  [DraftStatuses.ReviewExternal]: {
    id: DraftStatuses.ReviewExternal,
    title: 'Sent for external review',
    icon: 'paper-plane',
  },
  [DraftStatuses.Approved]: {
    id: DraftStatuses.Approved,
    title: 'Approved',
    icon: 'file-check',
  },
};

const selectedItem = computed(() => {
  return statusItems[props.status] || statusItems[DraftStatuses.Started];
});

const selectStatus = (statusId) => {
  emit('status-selected', { statusId, draftId: props.draftId })
};

const getClassModifierFromId = (id) => {
  return id.replace(':', '-');
};
</script>

<template>
  <div class="hrbr-drafts-status">
    <b-dropdown
      class="hrbr-drafts-status__dropdown"
      position="is-bottom-right"
      animation=""
      append-to-body
      :triggers="['click']"
      :disabled="disabled">
      <template #trigger>
        <div 
          class="hrbr-drafts-status__trigger" 
          :class="[`hrbr-drafts-status__trigger--${getClassModifierFromId(selectedItem.id)}`]">
          <div class="hrbr-drafts-status__trigger-icon">
            <i :class="['fa-regular', `fa-${selectedItem.icon}`]"></i>
          </div>
          <div class="hrbr-drafts-status__trigger-title">
            {{ selectedItem.title }}
          </div>
          <div class="hrbr-drafts-status__trigger-arrow">
            <i class="fa-regular fa-chevron-down"></i>
          </div>      
        </div>
      </template>

      <template #default>
        <b-dropdown-item 
          class="hrbr-drafts-status__dropdown-item"
          v-for="status in statusItems"
          :key="status.id"
          @click="selectStatus(status.id)">
          <div class="hrbr-drafts-status__status-item">
            <div 
              class="hrbr-drafts-status__status"  
              :class="[`hrbr-drafts-status__status--${getClassModifierFromId(status.id)}`]">
              <div class="hrbr-drafts-status__status-icon">
                <i :class="['fa-regular', `fa-${status.icon}`]"></i>
              </div>
              <div class="hrbr-drafts-status__status-title">
                {{ status.title }}
              </div>
            </div>
          </div>
        </b-dropdown-item>
      </template>
    </b-dropdown>
  </div>
</template>

<style>
:root {
  --drafts-status-started-color: #B015B366;
  --drafts-status-review-internal-color: #BE381B66;
  --drafts-status-review-external-color: #DDAF0966;
  --drafts-status-approved-color: #07838366;
}
</style>

<style lang="postcss" scoped>
.hrbr-drafts-status {
  display: inline-flex;
  
  &__trigger {
    font-size: 14px;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    height: 24px;
    border-radius: 20px;
    padding: 2px 10px;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;

    &--started {
      background-color: var(--drafts-status-started-color);
    }
    &--review-internal {
      background-color: var(--drafts-status-review-internal-color);
    }
    &--review-external {
      background-color: var(--drafts-status-review-external-color);
    }
    &--approved {
      background-color: var(--drafts-status-approved-color);
    }
  }

  &__trigger-icon {
    font-size: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }

  &__trigger-arrow {
    font-size: 13px;
    display: inline-flex;
    flex-shrink: 0;
  }

  &__dropdown-item {
    padding-left: 16px;
    padding-right: 16px;
  }

  &__status-item {
    display: flex;
    justify-content: flex-start;
  }

  &__status {
    font-size: 14px;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    height: 24px;
    white-space: nowrap;
    border-radius: 20px;
    padding: 2px 10px;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;

    &--started {
      background-color: var(--drafts-status-started-color);
    }
    &--review-internal {
      background-color: var(--drafts-status-review-internal-color);
    }
    &--review-external {
      background-color: var(--drafts-status-review-external-color);
    }
    &--approved {
      background-color: var(--drafts-status-approved-color);
    }
  }

  &__status-icon {
    font-size: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }
}
</style>
