<script setup>
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import Vue, { onMounted, onUnmounted, reactive, ref, watch } from 'vue';

import Avatar from '@/components/Avatar/Avatar.vue';
import { useColumnStore } from '@/stores/column-store';
import { useHarbourStore } from '@/stores/harbour-store';
import { formatDate } from './utils';

const notes = ref('');
const agParams = ref({});
const isUserTyping = ref(false);

const userTyping = reactive({
  email: null,
  name: null,
  profileImageUrl: null,  
});
const lastUserUpdate = reactive({
  date: null,
  name: null,
  profileImageUrl: null,
  email: null,
})
const avatar = reactive({
  profileImageUrl: null,
  name: null,
  email: null
})

const harbourStore = useHarbourStore();
const columnStore = useColumnStore();
const { myAssets } = storeToRefs(harbourStore);

onMounted(() => {
  const currentAsset = myAssets.value.find(asset => asset.id === agParams.value.data.id);
  if (!currentAsset?.notes) {
    return;
  }

  notes.value = currentAsset.notes?.value?.extraction;

  if (currentAsset.notes.user_typing && !isOwnUserTyping(currentAsset.notes.user_typing.locked_by_user)) {
    isUserTyping.value = true;
    userTyping.name = currentAsset.notes.user_typing.locked_by_user_name;
    userTyping.profileImageUrl = currentAsset.notes.user_typing.locked_by_user_image_url;
    userTyping.email = currentAsset.notes.user_typing.locked_by_user;

    const lockExpiryTime = new Date(currentAsset.notes.user_typing.lock_expiry_time).getTime()
    const now = Date.now();
    setCellUnlockTimer(lockExpiryTime - now);
  } else {
    isUserTyping.value = false;
  }

  if (currentAsset.notes.entry_timestamp) {
    lastUserUpdate.date = new Date(currentAsset.notes.entry_timestamp * 1000);
    lastUserUpdate.name = currentAsset.notes.entry_user_name;
    lastUserUpdate.profileImageUrl = currentAsset.notes.entry_user_picture;
    lastUserUpdate.email = currentAsset.notes.entry_user;
  }

  avatar.profileImageUrl = getAvatarImageUrl();
  avatar.name = getAvatarName();
  avatar.email = getAvatarEmail();
});

onUnmounted(async () => {
  await columnStore.updateCellLock({ lock: false, column_id: 'notes', parent_id: agParams.value.data.folder_id, asset_id: agParams.value.data.id })
});

const init = (e) => {
  agParams.value = e;
}

watch(() => myAssets, (newAssets) => {
  const currentAsset = newAssets.value.find(({id}) => agParams.value.data.id === id)
  const currentUserTyping = currentAsset.notes?.user_typing;

  if (!currentUserTyping) {
    isUserTyping.value = false;
    userTyping.email = null;
    userTyping.name = null;
    userTyping.profileImageUrl = null;
  } else if (!isOwnUserTyping(currentUserTyping.locked_by_user)) {
    isUserTyping.value = true;
    userTyping.email = currentUserTyping.locked_by_user;
    userTyping.name = currentUserTyping.locked_by_user_name;
    userTyping.profileImageUrl = currentUserTyping.locked_by_user_image_url;

    const lockExpiryTime = new Date(currentAsset.notes.user_typing.lock_expiry_time).getTime()
    const now = Date.now();    
    setCellUnlockTimer(lockExpiryTime - now);
  }

  avatar.profileImageUrl = getAvatarImageUrl();
  avatar.name = getAvatarName();
  avatar.email = getAvatarEmail();
}, { deep: true })

const handleTextAreaFocus = async function(el) {
  el.target.parentElement?.parentElement?.classList.add('focused');

  const asset = myAssets.value.find(asset => asset.id === agParams.value.data.id);
  if (!asset) {
    return;
  }
  await columnStore.updateCellLock({ lock: true, column_id: 'notes', parent_id: asset.folder_id, asset_id: asset.id })
}

const handleTextAreaBlur = async function(el) {
  el.target.parentElement?.parentElement?.classList.remove('focused');

  const asset = myAssets.value.find(asset => asset.id === agParams.value.data.id);
  if (!asset) {
    return;
  }

  if (isUserTyping.value && !isOwnUserTyping(userTyping.email)) {
    return;
  }

  await columnStore.updateCellLock({ lock: false, column_id: 'notes', parent_id: asset.folder_id, asset_id: asset.id })
}

const handleAutoSave = debounce(async () => {
  const asset = myAssets.value.find(asset => asset.id === agParams.value.data.id);
  if (!asset) {
    return;
  }

  const params = {
    rowId: agParams.value.data.id,
    columnTitle: agParams.value.colDef.headerName,
    value: { extraction: notes.value },
    column: agParams.value.colDef.field
  }

  await columnStore.updateCellValue(params);

  if (!asset.notes) {
    Vue.set(asset, { notes: { value: { extraction: notes.value } } });
  } else {
    Vue.set(asset.notes.value, 'extraction', notes.value);
  }
  Vue.set(asset.notes, 'entry_user_name', harbourStore.contextDict.name);
  Vue.set(asset.notes, 'entry_user', harbourStore.contextDict.systememail);
  Vue.set(asset.notes, 'entry_user_picture', harbourStore.contextDict.picture);

  lastUserUpdate.date = new Date();
  lastUserUpdate.name = harbourStore.contextDict.name;
  lastUserUpdate.email = harbourStore.contextDict.systememail;
  lastUserUpdate.profileImageUrl = harbourStore.contextDict.picture;

  avatar.profileImageUrl = getAvatarImageUrl();
  avatar.name = getAvatarName();
  avatar.email = getAvatarEmail();
}, 1000);

// Helper function to unlock cell after the expiry time
const setCellUnlockTimer = (ms) => {
  setTimeout(() => {
      isUserTyping.value = false;
      userTyping.email = null;
      userTyping.name = null;
      userTyping.profileImageUrl = null;
  }, ms);

  avatar.profileImageUrl = getAvatarImageUrl();
  avatar.name = getAvatarName();
  avatar.email = getAvatarEmail();
}

const isOwnUserTyping = (userTypingEmail) => userTypingEmail === harbourStore.contextDict.systememail;
const getAvatarImageUrl = () => isUserTyping.value ? userTyping.profileImageUrl : lastUserUpdate.profileImageUrl;
const getAvatarName = () => isUserTyping.value ? userTyping.name : lastUserUpdate.name;
const getAvatarEmail = () => isUserTyping.value ? userTyping.email : lastUserUpdate.email;

</script>

<template>
    <div class="cell">
      {{ init(params) }}

        <div class="cell-container">
          <textarea
            v-model="notes" 
            placeholder="Type a note…"
            :disabled="isUserTyping && userTyping.email !== harbourStore.contextDict.systememail"
            @focus="handleTextAreaFocus" 
            @blur="handleTextAreaBlur"
            @keyup="handleAutoSave"
          />
      </div>
      <i v-if="isUserTyping && userTyping.email !== harbourStore.contextDict.systememail" class="fa-solid fa-lock"></i>
      <div v-if="isUserTyping || lastUserUpdate.date" class="cell-img">
        <b-tooltip 
          :label="avatar.name || avatar.email"
          type="is-dark"
          position="is-right"
        >
          <Avatar
          v-if="avatar.email"
          :image="avatar.profileImageUrl" 
          :name="avatar.name" 
          :email="avatar.email" 
          size="small"
          />
        </b-tooltip>

        <span v-if="!isUserTyping && lastUserUpdate.date">updated {{ formatDate(lastUserUpdate.date) }}</span>
        <span v-else-if="isUserTyping">typing...</span>
      </div>
    </div>
</template>

<style scoped>
.cell { 
  box-sizing: content-box;
  position: relative;

  background-color: #fff;
  font-size: 12px;
  color: #555;

  width: 220px;
  height: 120px;
  border-radius: 8px;
  border: 1px solid #DBDBDB;
  resize: none;

  line-height: 18px;
  padding: 10px 12px;
  
}

.cell-container {
  position: relative; 
  height: 70%;
}

.cell textarea {
  border: none;
  outline: none;
  background: inherit;
  resize: none;
  scrollbar-width: none;
  color: #555;

  height: 100%;
  width: 100%;
}

.cell.focused {
  border: 1px solid #1355FF;
  box-shadow: 0px 4px 12px 0px #1355FF26;
}

.cell textarea::placeholder {
  color: #CCCCCC;
}

.cell-img {
  position: absolute;
  bottom: 10px;
  left: 10px;

  display: flex;
  align-items: center;
}

.cell-img img {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.cell-img span {
  margin-left: 6px;
  font-size: 12px;
  color: #555;
  font-style: italic;
}

.fa-lock {
  position: absolute;
  right: 15px;
  bottom: 13px;
}

</style>
