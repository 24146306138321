<script setup>
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';
import { useHarbourStore } from '@/stores/harbour-store';
import { computed } from 'vue';
import HrbrSidebarPaneHeader from './HrbrSidebarPaneHeader.vue';
import HrbrSidebarPaneSection from './HrbrSidebarPaneSection.vue';
import HrbrSidebarPaneTemplateGMenu from './HrbrSidebarPaneTemplateGMenu.vue';
const templatesStore = useTemplatesStore();
const harbourStore = useHarbourStore();

const userEmail = computed(() => {
  const email = harbourStore.contextDict?.systememail;
  return email || '';
});
</script>

<template>
  <div class="hrbr-sidebar-pane-tab hrbr-sidebar-pane-templates">
    <div class="hrbr-sidebar-pane-tab__head">
      <HrbrSidebarPaneHeader />
    </div>
    <div class="hrbr-sidebar-pane-tab__body">
      <div class="hrbr-sidebar-pane-tab__body-content">
        <HrbrSidebarPaneSection>
          <template v-slot:head>
            <div class="hrbr-sidebar-pane-tab__heading">
              <div class="hrbr-sidebar-pane-tab__heading-text">Template groups</div>
              <div class="hrbr-sidebar-pane-tab__heading-action">
                <button
                  :disabled="templatesStore.isLoadingTemplates"
                  class="hrbr-sidebar-pane-templates__add-template-group"
                  @click="templatesStore.onAddTemplateGroupClick(userEmail)">
                  <i class="fa-solid fa-plus" title="Create new template group"></i>
                </button>
              </div>
            </div>
          </template>

          <template v-slot:content>
            <HrbrSidebarPaneTemplateGMenu />
          </template>
        </HrbrSidebarPaneSection>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar-pane-templates {
  &__add-template-group {
    font-family: inherit;
    font-size: 14px;
    color: #8b9198;
    font-weight: 600 !important;
    padding-top: 15px;
    cursor: pointer;
    transition: color 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    padding: 0;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    appearance: none;

    &[disabled] {
      opacity: 0.4;
      cursor: default;
    }

    &:hover {
      color: var(--hrbr-primary-color-active) !important;
    }
  }
}

/* override general styles */
.hrbr-sidebar-pane-tab {
  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px 0px;
  }

  &__body-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  :deep(.hrbr-sidebar-pane-section),
  :deep(.hrbr-sidebar-pane-section__content) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
</style>
