<script>
export default {
  data() {
    return {
      emails: [],
    };
  },
  created() {
    this.emails = this.params?.data?.recipient_emails || [];
  },
};
</script>

<template>
  <div class="emails-container">
    <div v-for="email in emails" :key="email">
      {{ email }}
    </div>
  </div>
</template>

<style scoped>
.emails-container {
  display: flex;
  flex-direction: column;
}
</style>
