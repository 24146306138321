<script setup>
import Vue, { ref } from 'vue';
import { useHarbourStore } from '@/stores/harbour-store';
import { ToastProgrammatic as Toast } from 'buefy';
import { firebaseConfig } from '@/config/firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const emit = defineEmits(['close']);

const harbourStore = useHarbourStore();
const systemEmail = harbourStore.contextDict.systememail;

const isLoginInProgress = ref(false);

const closeModal = () => emit('close');

const initializeApp = () => {
  if (firebase.apps.length) return;
  firebase.initializeApp(firebaseConfig);
};

const setPersistence = (persistence) => {
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence[persistence]);
};

const getLoginProvider = () => {
  return new Promise((resolve) => {
    firebase
      .auth()
      .fetchSignInMethodsForEmail(systemEmail)
      .then((providers) => {
        if (providers.length > 0) {
          const authProvider = providers[0];
          const provider = new firebase.auth.OAuthProvider(authProvider);
          provider.setCustomParameters({
            login_hint: systemEmail,
          });
          resolve(provider)
        } else {
          resolve(null);
        }
      })
      .catch(() => {
        resolve(null);
      });
  });
};

const sendCredentialsRequest = async (tokenId) => {
  const resp = await fetch('/loginstore', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ tokenId }),
    credentials: 'include',
  });
  return await resp.json();
};

const sendCredentials = async (user) => {
  const tokenId = await user.getIdToken();
  setPersistence('NONE');
  try {
    await sendCredentialsRequest(tokenId);
  } catch (err) {
    console.error(err);
    throw new Error('Login error');
  }
};

const signinWithPopup = async (provider) => {
  try {
    const result = await firebase.auth().signInWithPopup(provider);
    if (!result) {
      throw new Error('Login error');
    }

    const canLogin = result && result.user.email === systemEmail;
    if (canLogin) {
      await sendCredentials(result.user);
    } else {
      throw new Error('Login error');
    }
  } catch (err) {
    console.error(err);
    throw new Error('Login error');
  }
};

const login = async () => {
  if (!systemEmail) return;

  try {
    isLoginInProgress.value = true;

    initializeApp();

    const provider = await getLoginProvider();
    if (!provider) {
      throw new Error('Login error');
    }
    await signinWithPopup(provider);

    Vue.prototype.$resetAccessExpired();
  } catch (err) {
    Toast.open({
      duration: 3500,
      message: 'Please try reloading the page and login again',
      position: 'is-top',
      type: 'is-warning',
    });
    console.error(err);
  } finally {
    isLoginInProgress.value = false;
  }
};

const onLoginClick = async () => {
  await login();
  closeModal();
};
</script>

<template>
  <div class="hrbr-relogin-modal modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Login</p>
    </header>
    <section class="modal-card-body">
      <div class="hrbr-relogin-modal__description">
        Access has expired — please login again to proceed
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button class="hrbr-relogin-modal__login-btn" @click="onLoginClick" type="is-primary">
        <i v-if="isLoginInProgress" class="fal fa-spinner-third fa-spin"></i>
        <span v-else>Login</span>
      </b-button>
    </footer>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-relogin-modal {
  max-width: 320px;

  &__login-btn {
    min-width: 76px;
  }
}
</style>
