<script>
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';
import { useHarbourStore } from '@/stores/harbour-store';

export default {
    setup() {
        const harbourStore = useHarbourStore();
        const templatesStore = useTemplatesStore();

        return {
            harbourStore,
            templatesStore
        };
    },

    data() {
        return {
            templateGroup: {},
            newGroupName: '',
            isEditGroupName: false,
        };
    },

    computed: {
        templateGroupId() {
            return this.params.value;
        },
        userEmail() {
            const email = this.harbourStore.contextDict?.systememail;
            return email || '';
        },
        templateGroupName() {
            let templateGroup = this.templatesStore.templatesGroups?.find(
                (templateGroup) => templateGroup.groupId === this.templateGroupId,
            );
            if (this.templateGroupId == 'TEMPLGROUP-ALL-TEMPLATES') {
                return 'My templates';
            }
            return templateGroup?.groupName || '';
        },

        isOrgAdmin() {
            return (this.harbourStore.contextDict?.auth_roles || []).includes('orgAdmin');
        },

        isOwner() {
            return this.userEmail == this.templateGroup?.groupCreatorEmail || this.isOrgAdmin
        },
    },

    methods: {
        renameTemplateGroup() {
            const {nameInput} = this.$refs;
            this.isEditGroupName = true;
            this.newGroupName = this.templateGroupName;
            this.$nextTick(() => nameInput.focus());
        },
        onNameInputBlur() {
            this.templatesStore.setGroupName(this.newGroupName, this.templateGroupId);
            this.isEditGroupName = false;
            this.newGroupName = '';
        },
        async deleteCurrentTemplateGroup(templateGroupId) {
            try {
                this.templatesStore.myTemplates = this.templatesStore.myTemplates.filter(
                    (template) => (template.template_group_id !== templateGroupId)
                );
                this.templatesStore.setActiveTemplateGroup('TEMPLGROUP-ALL-TEMPLATES');
                const targetTemplateIdx = this.templatesStore.templatesGroups.findIndex(
                    (i) => i.groupId === templateGroupId,
                );
                if (targetTemplateIdx !== -1) {
                    this.templatesStore.templatesGroups.splice(targetTemplateIdx, 1);
                }
                const data = await this.templatesStore.deactivateTemplateGroup(templateGroupId);
                if (!data.templategroupid) {
                    this.$buefy.toast.open({
                        message: 'Deleting this template group failed. Try again later',
                        type: 'is-danger',
                        position: 'is-top',
                        duration: 3500,
                    });
                    return;
                }
                this.$buefy.toast.open({
                    message: 'Success - template group is deleted',
                    type: 'is-success',
                    position: 'is-top',
                    duration: 3500,
                });
            } catch (e) {
                this.$buefy.toast.open({
                    message: 'Deleting this template group failed. Try again later',
                    type: 'is-danger',
                    position: 'is-top',
                    duration: 3500,
                });
                console.error(e);
            } finally {
                this.isTemplateDeleteInProgress = false;
            }
        },

        confirmDeleteTemplateGroup() {
            const templates = this.templatesStore.myTemplates?.filter(
                (r) => r.template_group_id == this.templateGroupId,
            );
            const numTemplates = templates.length;
            const message = `Are you sure you want to delete this template group including its ${numTemplates} existing templates?`;

            this.$buefy.dialog.confirm({
                title: 'Delete template group',
                message,
                confirmText: 'Delete',
                type: 'is-danger',
                onConfirm: () => {
                    this.deleteCurrentTemplateGroup(this.templateGroupId);
                },
            });
        },

        openNewAgreemenModal() {
            const {contextDict} = this.harbourStore;
            const {workspaceCustomProperties} = this.harbourStore;

            const props = {
                initial_workspaceCustomProperties: workspaceCustomProperties,
                system_email: contextDict.systememail,
                gae_service: contextDict.gae_service,
                creationMode: 'template',
                lastbrand: true,
                templateGroupId: this.templateGroupId
            };
            this.$openAgreementEditorModal({props});
        },
    },
};
</script>

<template>
    <div :title="templateGroupName" class="hrbr-sidebar-pane-template-groups">
        <div  class="hrbr-sidebar-pane-template-groups__icon">
            <i v-if="templateGroupId == 'TEMPLGROUP-ALL-TEMPLATES'" class="fa-duotone fa-layer-group"></i>
            <i v-else class="fa-light fa-layer-group"></i>
        </div>
        <div class="hrbr-sidebar-pane-template-groups__name-actions" >
            <div class="hrbr-sidebar-pane-template-groups__title-rename">
                <div class="hrbr-sidebar-pane-template-groups__title" v-show="!isEditGroupName">
                    {{ templateGroupName }}
                </div>
                <input
                    class="hrbr-sidebar-pane-template-groups__name-input"
                    v-model="newGroupName"
                    v-show="isEditGroupName"
                    ref="nameInput"
                    type="text"
                    @blur="onNameInputBlur" />
            </div>

            <div class="hrbr-sidebar-pane-template-groups__actions" v-if="isOwner && !params.customRowClick" data-template-actions>
                <div
                    class="hrbr-sidebar-pane-template-groups__add-template"
                    @click="openNewAgreemenModal">
                    <i class="fa-regular fa-plus"></i>
                </div>
                <b-dropdown
                    class="hrbr-sidebar-pane-template-groups__dropdown"
                    :append-to-body="true"
                    position="is-bottom-left">
                    <template #trigger>
                        <i class="fa-regular fa-ellipsis-vertical"></i>
                    </template>
                    <template #default>
                        <b-dropdown-item key="rename-template-group-key" @click="renameTemplateGroup">
                            <b-icon pack="far" icon="pen"></b-icon>
                            <span>Rename</span>
                        </b-dropdown-item>
                        <b-dropdown-item key="delete-template-group-key" @click="confirmDeleteTemplateGroup">
                            <b-icon pack="far" icon="trash" type="is-danger"></b-icon>
                            <span>Delete</span>
                        </b-dropdown-item>
                    </template>
                </b-dropdown>
            </div>
        </div>
    </div>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar-pane-template-groups {
    display: flex;
    align-items: center;
    gap: 5px;
    max-width: 222px;
    &__title{
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__title-rename{
        max-width: 85%;
    }

    &__name-actions{
        justify-content: space-between;
        font-size: 15px;
        color: #666f75;
        line-height: 1.2;
        display: flex;
        align-items: center;
        column-gap: 10px;
        width: 100%;
        height: 36px;
        min-width: 0;
        padding: 3px 4px;
        position: relative;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
        overflow: hidden;
        transition: background 0.15s, color 0.15s;
    }

    &__actions {
        display: flex;
        align-items: center;
        gap: 5px;
        position: relative;
        z-index: 1;
        line-height: normal;
        visibility: hidden;
    }

    &:hover {
        .hrbr-sidebar-pane-template-groups__actions {
            visibility: visible;
        }
    }

    &__icon {
        display: flex;
        flex-shrink: 0;
    }

    &__name {
        flex: 1;
        overflow: hidden;
    }

    &__name-text {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__name-input {
        font-family: inherit;
        font-size: inherit;
        color: inherit;
        display: block;
        width: 100%;
        height: 100%;
        min-height: 24px;
        background: transparent;
        padding: 0;
        margin: 0;
        border: 0;
        outline: 0;
        box-shadow: none;
        appearance: none;
    }

    &__actions {
        display: flex;
        align-items: center;
        gap: 5px;
        flex-shrink: 0;
        position: relative;
        z-index: 1;
        line-height: normal;
    }

    &__add-template {
        font-size: 13px;
        cursor: pointer;
        transition: color 0.15s;

        &:hover {
            color: var(--hrbr-primary-color-active);
        }
    }

    &__dropdown {
        :deep(.dropdown-trigger) {
            font-size: 13px;
            cursor: pointer;
            padding: 0 4px;
            transition: color 0.15s;

            &:hover {
                color: var(--hrbr-primary-color-active);
            }
        }

        :deep(.background) {
            display: none !important;
        }

        &.is-active :deep(.dropdown-trigger) {
            visibility: visible !important;
        }
    }
}
</style>

<style lang="postcss">
.hrbr-sidebar-pane-template-groups {
    &__dropdown {
        .dropdown-item {
            font-size: 14px;
            display: flex;
            align-items: center;
            gap: 6px;

            i {
                display: inline-flex;
                justify-content: center;
                width: 20px;
            }
        }
    }
}
</style>
