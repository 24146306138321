<script setup>
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useWorkflowsStore } from '@/stores/workflows-store';
import WfBlockIconCircle from '../../WfBlockIconCircle.vue';
import WfVariablePill from '@/pages/Workflows/components/settings-modules/WfVariablePill.vue';

const workflowsStore = useWorkflowsStore();
const { activeWorkflow } = storeToRefs(workflowsStore);

const agParams = ref(null);
const containerInit = (params) => {
  agParams.value = params;
};

const currentBlock = computed(() => {
  if (!agParams.value || agParams.value?.node?.level !== 0) return null;

  for (const block of activeWorkflow.value.blocks) {
    if (block.id === agParams.value.value) {
      return block;
    }
  }
  return null;
});


const isRowOpen = ref(false);
</script>

<template>
  <div
    v-if="!params.data"
    class="renderer-container"
    :ref="containerInit(params)"
    @click="isRowOpen = !isRowOpen">
    <!-- FOR DEPTH LEVEL 0: Blocks -->
    <div v-if="params.node.level === 0" class="block-group">
      <WfBlockIconCircle
        :lightColor="currentBlock.lightColor"
        :darkColor="currentBlock.darkColor"
        :icon="currentBlock.icon"
        :circle-style="{ width: '20px', height: '20px', fontSize: '11px' }" />
      <div class="block-title">{{ currentBlock?.generalSettings.title }}</div>
    </div>

    <div v-else-if="params.node.level === 1">
      {{ params.value }}
    </div>
  </div>
  <div v-else class="pill-container">
    <WfVariablePill :variable="params.data" :is-locked="true" />
  </div>
</template>

<style scoped>
.pill-container {
  margin-left: 10px;
}
.block-title {
  font-weight: 500;
  margin-left: 10px;
}
.block-group {
  width: 100%;
  display: flex;
  align-items: center;
}
.renderer-container i {
  margin-right: 0px;
}
.vars-row {
  display: flex;
  align-items: center;
}
</style>
