<script>
import { useApiKeysStore } from '@/stores/api/api-keys-store';
import { DialogProgrammatic as Dialog } from 'buefy';
import { reactive } from 'vue';

export default {
  name: 'ApiKeysActionsRenderer',
  setup() {
    return {
      apiKeysStore: useApiKeysStore(),
    };
  },
  data() {
    return {
      apiKey: null,
      confirmDialog: null,
    };
  },
  created() {
    this.apiKey = reactive(this.params.data || {});
  },
  methods: {
    onDeleteApiKeyClick() {
      const message = 'Are you sure you want to delete this api key?';
      Dialog.confirm({
        title: 'Delete API Key',
        message,
        confirmText: 'Delete',
        type: 'is-danger',
        onConfirm: () => {
          this.apiKeysStore.removeApiKey(this.apiKey.id);
        },
      });
    },

    async onEditApiKeyClick() {
      Dialog.prompt({
        title: 'Edit API Key',
        inputAttrs: {
          value: this.apiKey.name,
          placeholder: 'Key name',
          maxlength: 100,
          minlength: 1,
        },
        onConfirm: (apiKeyName) => {
          this.apiKeysStore.editApiKey(this.apiKey.id, { name: apiKeyName });
        },
      });
    },
  },
};
</script>

<template>
  <div class="action-buttons">
    <div
      class="btn"
      @click="copyToClipboard(apiKey.id)"
    >
      <i class="fal fa-copy"></i>
      Copy
    </div>
    <div
      class="btn"
      @click="onEditApiKeyClick"
    >
      <i class="fal fa-edit"></i>
      Edit
    </div>
    <div
      class="btn"
      @click="onDeleteApiKeyClick"
    >
      <i class="fa-light fa-trash-can"></i>
      Delete
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import '@/assets/css/elements/table-actions.css';
</style>
