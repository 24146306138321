<script setup>
defineProps({
  searchMatchList: {
    type: Array,
    default: () => [],
  },
});

const getFormattedSearchMatch = (searchMatch) => {
  let matchValue = searchMatch.matchvalue;
  matchValue = matchValue.replaceAll('&amp;lt', '<');
  matchValue = matchValue.replaceAll('&amp;gt', '>');
  return 'exact text match (' + matchValue + ')';
};
</script>

<template>
  <span class="hrbr-search-match">
    <span class="hrbr-search-match__match" v-for="(searchMatch, idx) in searchMatchList" :key="idx">
      <i class="fal fa-check"></i>&#32;
      <!-- title -->
      <span v-if="searchMatch.matchtype == 'titlematch'"> title match </span>
      <!-- exact text -->
      <span
        v-if="searchMatch.matchtype == 'exacttextmatch'"
        v-html="getFormattedSearchMatch(searchMatch)">
      </span>
      <!-- agreeent reference id (e.g., ""HBRREF-5ea...") -->
      <span v-if="searchMatch.matchtype == 'referencematch'">
        reference match ('{{ searchMatch.matchvalue }}')
      </span>
      <!-- linked group id -->
      <span v-if="searchMatch.matchtype == 'linkedgroupmatch'">
        linked group ('{{ searchMatch.matchvalue }}')
      </span>
      <!-- field (e.g., form input or custom input on agreement link) -->
      <span v-if="searchMatch.matchtype == 'contextfieldmatch'">
        field match ({{ searchMatch.matchvalue }})
      </span>
      <!-- agreement link name -->
      <span v-if="searchMatch.matchtype == 'linkname'">
        link name match ({{ searchMatch.matchvalue }})
      </span>
      <!-- agreement title -->
      <span v-if="searchMatch.matchtype == 'agreementitlematch'">
        agreement match ({{ searchMatch.matchvalue }})
      </span>
      <!-- context source url (e.g., url where match is from) -->
      <span v-if="searchMatch.matchtype == 'contextsourceurlmatch'">
        source match ({{ searchMatch.matchvalue }})
      </span>
      <!-- tag (TEXT_ANOTATIONS) -->
      <span v-if="searchMatch.matchtype == 'tagmatchtext'">
        text match ('{{ searchMatch.matchvalue }}')
      </span>
      <!-- tag (regular) -->
      <span v-if="searchMatch.matchtype == 'tagmatch'">
        tag match ('{{ searchMatch.matchvalue }}')
      </span>
      <!-- recent -->
      <span v-if="searchMatch.matchtype == 'recentmatch'">
        <span v-if="searchMatch.matchvalue == 0">recent (today)</span>
        <span v-if="searchMatch.matchvalue == 1">recent ({{ searchMatch.matchvalue }} day)</span>
        <span v-if="searchMatch.matchvalue > 1">recent ({{ searchMatch.matchvalue }} days)</span>
      </span>
      <!-- recent -->
      <span v-if="searchMatch.matchtype == 'filtermatch'">
        <span v-if="searchMatch.matchvalue == 0">recent (today)</span>
        <span v-if="searchMatch.matchvalue == 1"
          >matches ({{ searchMatch.matchvalue }} filter)</span
        >
        <span v-if="searchMatch.matchvalue > 1"
          >matches ({{ searchMatch.matchvalue }} filters)</span
        >
      </span>
      <!-- type -->
      <span v-if="searchMatch.matchtype == 'typematch'">
        type match ({{ searchMatch.matchvalue }})
      </span>
      <!-- category -->
      <span v-if="searchMatch.matchtype == 'categorymatch'">
        media category ({{ searchMatch.matchvalue }})
      </span>
      <!-- creator -->
      <span v-if="searchMatch.matchtype == 'creatormatch'">
        creator match ({{ searchMatch.matchvalue }})
      </span>
      <!-- collaborator -->
      <span v-if="searchMatch.matchtype == 'collaboratormatch'">
        collaborator match ({{ searchMatch.matchvalue }})
      </span>
      &nbsp;
    </span>
  </span>
</template>

<style lang="postcss" scoped>
.hrbr-search-match {
  &__match {
    color: #676767;
    padding-left: 1px;

    i {
      font-size: 11px;
      color: rgb(147, 147, 147);
    }
  }
}
</style>
