<script>
import { reactive } from 'vue';
import { mapState, storeToRefs } from 'pinia';
import { useHarbourStore } from '@/stores/harbour-store';

export default {
  name: 'CreationRenderer',
  setup() {
    const harbourStore = useHarbourStore();
    const { profilePictures } = storeToRefs(harbourStore);
    return {
      profilePictures,
    };
  },
  data() {
    return {
      field: null,
      linkObject: null,
      isActive: false,
      pic: null,
    };
  },
  created() {
  },
  methods: {
    getInitial(name, email) {
      if (name) return name[0].toUpperCase();
      else if (email) return email[0].toUpperCase();
      else return '*';
    },
  },
  computed: {
    name() {
      return this.params.data.creator_name;
    },
    email() {
      return this.params.data.agreement_link_creator;
    },
    profileImageUrl() {
      return this.profilePictures.find((pic) => pic.email === this.email)?.profileImageUrl;
    }
  },
};
</script>

<template>
  <div class="views-container">
    <!-- EMAIL links -->
    <div class="creator-updater-container">
      <!-- creator-updater photo -->
      <div class="creator-updater-image-container">
        <img
          v-if="profileImageUrl"
          :src="profileImageUrl"
          class="creator-updater-image" />
        <div v-else class="photo-initials">
          {{ getInitial(name, email) }}
        </div>
      </div>

      <!-- creator-updater details -->
      <div class="is-flex is-flex-direction-column creator-updater-details-column">
        <span class="creator-updater-name">{{ name }}</span>
        <span class="creator-updater-email">{{ email }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.photo-initials {
    padding: 0;
    margin: 0;
    line-height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important;
    color: white;
}
.views-container {
    padding: 0 5px;
    color: #787878;
}
.creator-updater-container {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}
.creator-updater-details-column {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
}
.creator-updater-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 3px;
}
.creator-updater-email {
    max-width: 100%;
    font-size: 0.8em;
    color: #9a9a9a;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -5px;
}
.creator-updater-image-container {
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    border-radius: 50%;
    margin-right: 10px;
    border: 0px solid #d9d9d9;
    background-color: #2f5a8899;
    overflow: hidden;
}
.creator-updater-image {
    width: 25px;
    height: 25px;
    max-width: 25px;
    max-height: 25px;
}
.creator-updater-edit i {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 9px !important;
    background-color: #00000008;
    height: 18px !important;
    width: 18px !important;
    border-radius: 20px;
    cursor: pointer;
    color: #02020275;
    transition: all 150ms ease;
}
.creator-updater-edit i:hover {
    font-size: 10px !important;
    color: #222;
    background-color: #e4e4e4;
}
</style>
