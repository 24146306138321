<script>
//Modal component for asset presentation/preview mode
export default {
  props: ['activeAssets', 'targetAssetId'],
  data: function () {
    return {
      isPresentationContentLoaded: false,
      initialSlideIndex: 0,
      currentSlideIndex: 0,
      isShowingDots: true,
      loadedAssets: [],
      base64ImgData: [],
      alreadyViewedAsset: [],
      isMobileView: false,
      loadedFiles: [],
    };
  },
  created: function () {
    //Init
    let self = this;

    //Is mobile-size display?
    window.addEventListener('resize', self.onWindowResize);
    if (document.body.clientWidth < 600) {
      self.isMobileView = true;
    }

    // //Start listening: switch slide on left/right keydown
    // window.addEventListener('keydown', self.handleKeyPress, true);

    // //Start listening: reload on new orientation
    // window.addEventListener('orientationchange', self.forceReloadSlides, true);

    //Showing dots
    self.isShowingDots = false;
    if (self.activeAssets.length < 2) {
      self.isShowingDots = false;
    }
    if (self.activeAssets.length > 15) {
      self.isShowingDots = false;
    }

    //Set initial slide
    if (self.targetAssetId == null || self.targetAssetId == undefined) {
      self.initialSlideIndex = 0;
    } else {
      let matchIndex = 0;
      let matchedAssetArray = self.activeAssets.filter(function (d, i) {
        if (d.id == self.targetAssetId) {
          matchIndex = i;
          return true;
        }
        return false;
      });
      if (matchedAssetArray.length != 0) {
        self.initialSlideIndex = matchIndex;
        self.currentSlideIndex = matchIndex;
      }
    }
  },
  beforeDestroy() {
    //Remove left/right key press action
    let self = this;
    window.loadedIMGBASE64 = [];
    window.loadedAUDIO = [];
    window.loaded3DMODEL = [];
    window.removeEventListener('resize', self.onWindowResize);
    window.removeEventListener('keydown', self.handleKeyPress, true);
    window.removeEventListener('orientationchange', self.forceReloadSlides, true);
  },
  methods: {
    onWindowResize() {
      if (document.body.clientWidth < 600) {
        this.isMobileView = true;
      } else {
        this.isMobileView = false;
      }
    },

    //update current slide index
    updateCurrentSlideIndex() {
      let self = this;
      self.currentSlideIndex = self.$refs.carousel.getCurrentSlide();
      self.alreadyViewedAsset.push(self.currentSlideIndex);
    },

    //Force reload
    forceReloadSlides() {
      let self = this;
      setTimeout(function () {
        self.$refs.carousel.reload();
      }, 300);
    },

    //Update button
    closeModal: function () {
      let self = this;
      self.$parent.close();
    },

    //Key press
    handleKeyPress: function (e) {
      let self = this;
      let keyCode = String(e.keyCode || e.code || e.keyIdentifier);
      //Right arrow keypress
      if (keyCode == '39') {
        self.$refs.carousel.goToNext();
      }
      //Left arrow keypress
      if (keyCode == '37') {
        self.$refs.carousel.goToPrev();
      }
    },

    //Get AR link
    getARLink: function (asset) {
      let dataUrl = '/asset?cb=now-' + Date.now() + '&id=' + asset.id;
      const a = document.createElement('a');
      if (a.relList.supports('ar')) {
        let A =
          '<a href="https://googlewebcomponents.github.io/model-viewer/examples/assets/Astronaut.usdz" rel="ar"><img />AR Preview-Ext</a>';
        let B = '<a href="' + dataUrl + '" rel="ar"><img />AR Preview-HS/a>';
        return A + ' | ' + B;
      } else {
        return '';
      }
    },

    //Get image base64
    getImgBase64: async function (assetId, assetElemId, titleTxt, assetIndex) {
      const assetFileURL = '/asset?cb=now-' + Date.now() + '&id=' + assetId;
      const result = await this.$harbourData.get(assetFileURL, {
        responseType: 'arraybuffer',
      });
      return result;
    },
  },
};
</script>
<template>
  <div
    style="
      padding: 60px;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    "
    v-on:click.self="closeModal()">

    <div v-for="(asset, assetIndex) in activeAssets" class="slide">
      <!-- load currently-unloaded slide content if current slide index nearby, keep content loaded of already viewed/loaded -->
      <div
        v-if="
          Math.abs(assetIndex - currentSlideIndex) <= 5 || loadedAssets.includes(assetIndex) == true
        ">
        <!-- loading bar (for recognized content types with available renderers) -->
        <div
          class="progress-line"
          v-show="
            !loadedFiles.includes(assetIndex) &&
            mediasupportedrendertypes.includes(asset.file_extension) == true &&
            asset.file_extension != 'eps'
          "></div>

        <!-- img -->
        <picture v-if="mediasupportedimagetypes.includes(asset.file_extension)">

          <!-- display all image types (*load directly) -->
          <img
            :title="asset.name"
            :src="'/asset?cb=now-' + Date.now() + '&id=' + asset.id" />
        </picture>

        <!-- postscript (eps) -->
        <picture v-if="mediasupportedpostscriptdocumenttypes.includes(asset.file_extension)">
          <img
            style="border: calc(10vw) solid transparent"
            v-on:load="loadedAssets.push(assetIndex)"
            :title="asset.name"
            :src="'/previewimg?id=' + asset.id"
            onerror="javascript:this.src='/static/imgs/placeholder-nopreview-eps.jpg'" />
        </picture>

        <!-- video -->
        <video
          :title="asset.name"
          v-on:loadedmetadata="loadedAssets.push(assetIndex)"
          controls
          muted
          autopictureinpicture
          :src="'/asset?cb=now-' + Date.now() + '&id=' + asset.id"
          v-if="mediasupportedvideotypes.includes(asset.file_extension)" />

        <!-- audio -->
        <div
          :title="asset.name"
          class="waveform"
          :id="'waveform-presentation-' + asset.id"
          v-if="mediasupportedaudiotypes.includes(asset.file_extension)"
          :v-init="
            initAudio(
              asset,
              'waveform-presentation-' + asset.id,
              'PRESENTATION',
              function () {
                loadedAssets.push(assetIndex);
              },
            )
          "></div>

        <!-- document -->
        <div
          :title="asset.name"
          v-if="mediasupportedbinarydocumenttypes.includes(asset.file_extension)">
          <div v-if="asset.file_extension == 'pdf'">
            <iframe
              v-if="isMobileView == false && isTabletView == false"
              :src="
                '/asset?cb=now-' +
                Date.now() +
                '&id=' +
                asset.id +
                '&exportfilename=' +
                asset.file_name
              "
              type="application/pdf"
              style="margin: 0px auto; border: none"></iframe>
            <iframe
              v-else
              :src="
                '/pdfviewer?url=' +
                encodeURIComponent('/asset?cb=now-' + Date.now() + '&id=' + mediadisplayid)
              "
              style="margin: 0px auto; border: none"></iframe>
          </div>
          <div v-if="asset.file_extension == 'epub'" class="presentation-epub">
            <div
              :id="'epub-presentation-' + asset.id"
              class="epub"
              :v-init="
                initEpub(asset, 'epub-presentation-' + asset.id, 'PRESENTATION', function () {
                  loadedAssets.push(assetIndex);
                })
              "
              style="margin: 0px auto; background-color: white"></div>
          </div>
        </div>

        <!-- 3D model -->
        <div
          :title="asset.name"
          v-if="mediasupported3dmodeltypes.includes(asset.file_extension)"
          style="color: white">
          <div
            v-show="loadedAssets.includes(assetIndex)"
            :id="'3dmodel-presentation-' + asset.id"
            class="model"
            :v-init="
              init3dModel(
                asset,
                '3dmodel-presentation-' + asset.id,
                'PRESENTATION',
                function () {
                  loadedAssets.push(assetIndex);
                },
              )
            "></div>
        </div>

        <!-- other -->
        <div v-if="mediasupportedrendertypes.includes(asset.file_extension) == false">
          <div :title="asset.name" class="slideOtherContent">
            <i
              v-if="asset.file_extension == 'doc'"
              class="fal fa-file-word slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'docx'"
              class="fal fa-file-word slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'ppt'"
              class="fal fa-file-powerpoint slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'pptx'"
              class="fal fa-file-powerpoint slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'zip'"
              class="fal fa-file-archive slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'html'"
              class="fal fa-file-code slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'xls'"
              class="fal fa-file-excel slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'xlsx'"
              class="fal fa-file-excel slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'csv'"
              class="fal fa-file-csv slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == '3ds'"
              class="fal fa-cube slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == '3mf'"
              class="fal fa-cube slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'blen'"
              class="fal fa-cube slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'stl'"
              class="fal fa-cube slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'obj'"
              class="fal fa-cube slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'fbx'"
              class="fal fa-cube slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == '3ds'"
              class="fal fa-cube slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'collada'"
              class="fal fa-cube slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'iges'"
              class="fal fa-cube slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'step'"
              class="fal fa-cube slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'vrml'"
              class="fal fa-cube slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'x3d'"
              class="fal fa-cube slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'gltf'"
              class="fal fa-cube slideOtherContentIcon"></i>
            <i
              v-else-if="asset.file_extension == 'usdz'"
              class="fal fa-cube slideOtherContentIcon"></i>
            <i v-else class="fal fa-file slideOtherContentIcon"></i>
            <BR />
            <span class="slideOtherContentText">{{ asset.file_extension?.toUpperCase() }}</span>
            <BR />
            <span class="slideOtherContentTextNoPreview"> no preview available </span>
            <b-button
              class="slideOtherContentBtn"
              v-on:click="
                openTab(
                  '/asset?cb=now-' +
                    Date.now() +
                    '&id=' +
                    asset.id +
                    '&isdownload=true&exportfilename=' +
                    asset.name +
                    '_HXID-' +
                    asset.id +
                    '.' +
                    asset.file_extension,
                  true,
                )
              ">
              Download
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <!-- controls -->
    <template slot="prevButton">
      <i class="fas fa-chevron-left" style="color: white; opacity: 0.9; font-size: 27px"></i>
    </template>
    <template slot="nextButton">
      <i class="fas fa-chevron-right" style="color: white; opacity: 0.9; font-size: 27px"></i>
    </template>
  </div>
</template>
