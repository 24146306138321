<script>
import { ref } from 'vue';
import PdfPreview from '@/pages/Dashboard/components/PdfPreview.vue';
import ModalWithIFrame from './ModalWithIFrame.vue';
import { ModalProgrammatic as Modal } from 'buefy';
import { useDashboardStore } from '@/stores/dashboard-store';

export default {
  name: 'AwaitingMeActions',
  setup() {
    const nodesToRemove = ref(new Set());
    return {
      dashboardStore: useDashboardStore(),
      nodesToRemove,
    };
  },
  methods: {
    openSignModal() {
      const rowNode = this.dashboardStore.gridApi.getRowNode(this.params.data.id);
      Modal.open({
        parent: this,
        component: ModalWithIFrame,
        hasModalCard: true,
        props: {
          url: this.params.data.url,
          linkObject: this.params.data,
          node: rowNode,
        },
      });
    },
    openPresentationPdf() {
      // Open PDF preview

      let src = `/agreepdfunsigned/${this.params.data.id}`;;
      if (this.params.data.awaitingMyReviewSigned) {
        src = `/agreepdf/${this.params.data.id}}`
      } 
      
      this.$buefy.modal.open({
        parent: this,
        component: PdfPreview,
        canCancel: true,
        hasModalCard: false,
        fullScreen: true,
        props: {
          tgturl: src,
          isEditable: false,
          linkDisplayIdToEdit: this.params.data.id,
          parent: this,
        },
        events: {
          close: () => {},
        },
      });
    },
  },
};
</script>

<template>
  <div class="renderer">
    <div class="renderer-container">

      <button class="hrbr-grid-button" @click="openPresentationPdf">
        <i class="fal fa-file-pdf"></i>
        <span>View</span>
      </button> 
      <!-- <button class="hrbr-grid-button">
        <i class="fal fa-share-square"></i>
        <span>Reassign</span>
      </button>  -->
    </div>

  </div>
</template>

<style scoped>
.is-complete {
  background-color: var(--grid-success);
  color: white;
}
.hrbr-grid-overflow-button {
  border-radius: 3px;
}
.renderer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}
.hrbr-grid-button:first-child {
  margin-right: 5px;
}
.hrbr-grid-button i {
  margin-right: 5px;
  padding: 0;
}
.renderer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
