<script>
import { useLinkObject } from '@/pages/Dashboard/composables/LinkObject'
export default {
  name: 'ActiveToggleRenderer',
  props: ['externalparams'],
  data() {
    return {
      field: null,
      linkObject: null,
      isActive: false,
    };
  },
  created() {
    if (!this.params) this.params = this.externalparams;
    this.field = this.params?.colDef?.field;
    this.linkObject = useLinkObject(this.params?.data);
  },
  beforeUnmount() {
    this.linkObject = null;
  },
  mounted() {
    setTimeout(() => {
      this.isActive = true;
    }, 5);
  },
  methods: {
    async toggleActive() {
      this.linkObject.active = !this.linkObject.active;
      await this.$harbourData.post('data?agreebuilder-activatedeactivatemylink', {
        requesttype: 'agreebuilder-activatedeactivatemylink',
        agreedisplayid: this.linkObject.id,
        isactivated: this.linkObject.active,
      });
    },
  },
};
</script>

<template>
  <div class="toggle-container" v-if="isActive">
    <b-switch
      :value="linkObject.active ? true : false"
      type="is-success"
      size="is-small"
      class="toggle"
      @input="toggleActive"
      @click.native.stop
    />
    <div class="status-text">
      <span class="p-0 m-0">{{ linkObject.active ? 'Active' : 'Inactive' }}</span>
    </div>
  </div>
</template>

<style scoped>
.toggle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.toggle {
  padding: 0;
  margin: 0;
}
.status-text {
  margin-top: 5px;;
  color: #999;
  font-size: 12px;
}
</style>
