<script setup>
import { storeToRefs } from 'pinia';
import { computed, ref, onMounted } from 'vue';
import { useHarbourStore } from '@/stores/harbour-store';
import { toTitleCase } from '@/utils/helpers/functions';

const harbourStore = useHarbourStore();
const props = defineProps({
  session: {
    type: Object,
    required: true,
  },
  totalinputs: {
    type: Number,
    required: true,
  },
  displaymode: {
    type: String,
    default: 'grid',
  },
  linkObject: {
    type: Object,
    required: true,
  },
});

const stateOptionsMap = {
  '#AGREELINKAUTHENTICATEDANDCONTENTLOADED': 'Link loaded',
  '#SIGNED': 'Signature entered',
  '#BUTTONCLICKED': 'Button clicked',
  '#AGREELINKVALIDATEDANDSUCCESFULLYSUBMITTED': 'Completed',
};
const lastActive = ref(null);
const isLive = ref(false);
const mode = ref(null);
const email = ref(null);

const totalFilledInputs = computed(() => {
  const filteredInputs = props.session.audit_trail_data_json.filter(
    (item) => !(item.item_value in stateOptionsMap),
  );

  const inputIDs = filteredInputs.map((item) => item.item_id);
  const uniqueInputIDs = [...new Set(inputIDs)];
  return uniqueInputIDs.length;
});

const getNameFromEmailBasedLink = (email) => {
  const userEmail = email;
  if (userEmail) {
    const signers = props.linkObject.emailRecipients;
    const signerInLink = signers.find((signer) => signer.recipientemail === userEmail);
    if (signerInLink) return signerInLink.fullname;
  }
  return null;
};

const getLocation = (session) => {
  const location = session?.location_data;
  const city = location?.city_name ? location.city_name : '';
  const country = location?.country_name ? location.country_name : '';
  const locationText = city || country;
  if (locationText) return `Someone in ${toTitleCase(locationText)}`;
  return '-';
};

const getSignerFromLiveSession = () => {
  const session = props.session;
  const auditTrail = session?.audit_trail_data_json;
  const auditTrailLength = auditTrail?.length;
  let userEmail = null;
  let name = null;
  if (mode.value === 'EMAILS') {
    const linkLoadedEvent = auditTrail.find(
      (item) => item.item_id === '#AGREELINKAUTHENTICATEDANDCONTENTLOADED',
    );

    if (linkLoadedEvent) {
      userEmail = linkLoadedEvent.item_details?.email;
      if (!userEmail) userEmail = getEmail.value;
      name = getNameFromEmailBasedLink(userEmail);
    }
  }

  if (!userEmail)
    userEmail = auditTrail.find((item) => item.item_id === '#USEREMAILCHANGE')?.item_value || '';

  const itemDetails = auditTrail[auditTrailLength - 1].item_details;
  const lastItem = itemDetails?.itemdisplaylabel || itemDetails?.itemcheckboxlabel;
  const signer = {
    lastItem,
    email: userEmail,
    name,
    session,
    isLive: true,
    locatiuserEmailon: getLocation(session),
  };
  return signer;
};

const getName = computed(() => {
  const session = props.signer?.session || props.session;
  if (!session) return;

  const auditTrail = session.audit_trail_data_json;

  if (mode.value === 'EMAILS') {
    const signer = getSignerFromLiveSession();
    return signer.name;
  }

  const nameItem = auditTrail.find((item) => item.item_details?.itemspeciallinkedvalue === 'NAME');
  return nameItem?.item_value || null;
});

const getEmail = computed(() => {
  const reversedAuditTrailData = props.session?.audit_trail_data_json.slice().reverse();
  const email = reversedAuditTrailData.find(
    (item) => item.item_id === '#USEREMAILCHANGE',
  )?.item_value;

  if (!email && mode.value === 'EMAILS') {
    const legacyEmail = reversedAuditTrailData.find(
      (item) => item.item_id === 'legacy_email',
    )?.item_value;
    if (legacyEmail) return legacyEmail;
  }
  return email || null;
});

const updateIsLive = computed(() => {
  if (isCompleted.value) return false;
  const latestChange =
    props.session.audit_trail_data_json[props.session.audit_trail_data_json.length - 1];
  const lastUpdateTime = latestChange?.created_time;
  if (!lastUpdateTime) return false;
  const currentTime = parseInt(new Date().getTime());
  const timeDiff = currentTime - lastUpdateTime;
  return timeDiff < 1000 * 60 * 5; // 5 minutes
});

const updateLastActive = (value) => (lastActive.value = value);

const getLatestChange = computed(() => {
  const latestChange =
    props.session.audit_trail_data_json[props.session.audit_trail_data_json.length - 1];
  updateLastActive(latestChange);

  if (latestChange?.item_details?.itemgroup === 'esignconsent') {
    return 'E-sign consent checkbox';
  }

  if (latestChange.item_id === '#AGREELINKAUTHENTICATEDANDCONTENTLOADED') {
    return 'Link loaded';
  }

  const fieldMap = {
    '#USEREMAILCHANGE': 'Email changed',
  };
  if (!latestChange.item_details?.itemdisplaylabel) {
    return fieldMap[latestChange.item_id];
  }

  if (!latestChange.item_details.itemdisplaylabel) {
    return latestChange.item_details.itemcheckboxlabel;
  }
  return latestChange.item_details.itemdisplaylabel;
});

const barStyle = computed(() => {
  let width = Math.min((totalFilledInputs.value / (props.totalinputs - 1)) * 100, 100);
  if (isCompleted.value) width = 100;
  const bg = isCompleted.value ? 'var(--grid-success)' : '#2D71AD';
  return {
    width: `${width}%`,
    backgroundColor: bg,
  };
});

const isCompleted = computed(() => {
  const auditTrail = props.session.audit_trail_data_json;
  return !!auditTrail.find((item) => item.item_id === '#AGREELINKVALIDATEDANDSUCCESFULLYSUBMITTED');
});

onMounted(() => {
  mode.value = props.linkObject?.authMethod?.authmode;
  lastActive.value =
    props.session.audit_trail_data_json[props.session.audit_trail_data_json.length - 1];
});

const isDetailView = computed(() => props.displaymode === 'grid');
</script>

<template>
  <div class="session-bar-container">
    <div class="values">
      <div class="values-row" v-if="isDetailView">
        <div v-if="updateIsLive" class="mr-2 live-signer-icon">
          <i class="fal fa-signal-stream"></i>
        </div>
        <div>
          <span class="bar-title" v-if="getName">{{ getName }}</span>
          <span class="bar-title" v-else-if="getLocation()">{{ getLocation() }}</span>
          <span class="bar-title" v-else-if="!getName && !getLocation() && !getEmail"
            >Anonymous</span
          >
          <span class="bar-email grey-color" v-if="getEmail">({{ getEmail }})</span>
        </div>
        <div class="last-active" v-if="isDetailView">
          Last active: {{ displayLocalDateTimeFromEpochMs(lastActive?.created_time || 'NOW') }}
        </div>
      </div>
    </div>

    <div class="session-row">
      <div class="session-bar">
        <div :style="barStyle" class="session-bar-indicator"></div>
      </div>
    </div>

    <div class="session-row grey-color" v-if="isDetailView">
      <div class="last-item" :class="{ 'last-item-detail': isDetailView }">
        <span class="last-user-input">{{ getLatestChange }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
.live-signer-icon {
  color: #348c2b;
  font-size: 14px;
}
.live-signer-icon i {
  font-weight: 400;
}
.last-user-input {
  font-size: 10px;
  line-height: 1em;
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.session-bar-container {
  max-width: 100%;
  width: 100% !important;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.last-item-detail {
  padding-top: 6px;
}
.bar-title {
  margin-right: 10px;
}
.expand-more {
  cursor: pointer;
}
.session-bar {
  background-color: #0000001a;
  height: 2.5px;
  max-width: 100%;
  width: 100%;
  color: white;
  line-height: 1em;
}
.last-item {
  font-size: 9.5px;
  line-height: 1em !important;
  padding: 0;
  margin: 0;
  text-align: right;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.grey-color {
  color: #686868;
}
.session-row {
  display: flex;
  align-items: center;
  line-height: 1em;
}
.session-bar-indicator {
  background: linear-gradient(90deg,#2d71ad 30%,rgb(73 136 192 / 80%));
  transition: width .2s;
  height: 100%;
}
.values-row {
  display: flex;
  align-items: center;
  font-size: 11px;
}
.last-active {
  font-size: 10px;
  color: #999;
  width: 100%;
  text-align: right;
}
.last-item-title {
  color: #666;
}
.show-details {
  padding: 10px;
}
.bar-email {
  font-weight: 500;
}
.value-list {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  max-width: 100%;
  margin-right: 20px;
  color: #999;
  overflow: hidden;
  justify-content: flex-end;
}
.value-item-detail {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.detail-value {
  font-size: 12px;
  color: #6a6a6a;
  margin-right: 10px;
  min-width: 350px !important;
}
.detail-time {
  font-size: 11px;
  margin-left: 10px;
  flex-grow: 1;
}
</style>
