<script setup>
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import { getCurrentInstance } from 'vue';
import HrbrSidebarPaneDraftsMenu from './HrbrSidebarPaneDraftsMenu.vue';
import HrbrSidebarPaneHeader from './HrbrSidebarPaneHeader.vue';
import HrbrSidebarPaneSection from './HrbrSidebarPaneSection.vue';

const instance = getCurrentInstance();
const draftsStore = useDraftsStore();

const onStartFromUploadClick = () => {
  draftsStore.openFromUpload({
    parentComponent: instance.proxy,
  });
};

const onStartFromTemplateClick = () => {
  draftsStore.openFromTemplateModal();
};

const onStartFromBlankClick = () => {
  draftsStore.openBlankDocument({ 
    parentComponent: instance.proxy,
  });
};
</script>

<template>
  <div class="hrbr-sidebar-pane-tab hrbr-sidebar-pane-drafts">
    <div class="hrbr-sidebar-pane-tab__head">
      <HrbrSidebarPaneHeader />
    </div>

    <div class="hrbr-sidebar-pane-tab__body">
      <div class="hrbr-sidebar-pane-tab__body-content">
        <HrbrSidebarPaneSection>
          <template v-slot:head>
            <div class="hrbr-sidebar-pane-tab__heading">
              <div class="hrbr-sidebar-pane-tab__heading-text">Drafts</div>
              <div class="hrbr-sidebar-pane-tab__heading-action">
                <b-dropdown
                  class="hrbr-sidebar-pane-drafts__heading-action-dropdown"
                  position="is-bottom-left"
                  animation=""
                  append-to-body
                  :triggers="['click']">
                  <template #trigger>
                    <i class="fa-solid fa-plus" title="New draft"></i>
                  </template>
                  <template #default>
                    <b-dropdown-item @click="onStartFromUploadClick">
                      <i class="fal fa-file-arrow-up"></i>
                      <span>Upload</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="onStartFromTemplateClick">
                      <i class="fal fa-layer-group"></i>
                      <span>From template</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="onStartFromBlankClick">
                      <i class="fal fa-file-pen"></i>
                      <span>Blank draft</span>
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </div>
            </div>
          </template>
          <template v-slot:content>
            <HrbrSidebarPaneDraftsMenu />
          </template>
        </HrbrSidebarPaneSection>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
/* override general styles */
.hrbr-sidebar-pane-tab {
  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px 0px;
  }

  &__body-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__heading-action {
    display: flex;
  }

  :deep(.hrbr-sidebar-pane-section),
  :deep(.hrbr-sidebar-pane-section__content) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.hrbr-sidebar-pane-drafts {

  &__heading-action-dropdown {
    
    :deep(.dropdown-trigger) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 18px;
      font-size: 14px;
      font-weight: 600 !important;
      color: #8b9198;
      cursor: pointer;

      &:hover {
        color: var(--hrbr-primary-color-active) !important;
      }
    }
  }
}
</style>

<style lang="postcss">
.hrbr-sidebar-pane-drafts {

  &__heading-action-dropdown {
    .dropdown-item {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 6px;

      i {
        display: inline-flex;
        justify-content: center;
        width: 20px;
      }
    }
  }
}

</style>
