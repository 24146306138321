<script setup>
import { computed } from 'vue';
import HrbrSidebarPaneTabAutomations from './HrbrSidebarPaneTabAutomations.vue';
import HrbrSidebarPaneTabDashboard from './HrbrSidebarPaneTabDashboard.vue';
import HrbrSidebarPaneTabDrafts from './HrbrSidebarPaneTabDrafts.vue';
import HrbrSidebarPaneTabFolders from './HrbrSidebarPaneTabFolders.vue';
import HrbrSidebarPaneTabSettings from './HrbrSidebarPaneTabSettings.vue';
import HrbrSidebarPaneTabTemplates from './HrbrSidebarPaneTabTemplates.vue';
import HrbrSidebarPaneTabWorkflows from './HrbrSidebarPaneTabWorkflows.vue';

const props = defineProps({
  tab: String,
});

const tabMap = new Map([
  ['dashboard', HrbrSidebarPaneTabDashboard],
  ['signed-by-me', HrbrSidebarPaneTabDashboard],
  ['awaiting-my-review', HrbrSidebarPaneTabDashboard],
  ['drafts', HrbrSidebarPaneTabDrafts],
  ['templates', HrbrSidebarPaneTabTemplates],
  ['folders', HrbrSidebarPaneTabFolders],
  ['settings', HrbrSidebarPaneTabSettings],
  ['automations', HrbrSidebarPaneTabAutomations],
  ['workflows', HrbrSidebarPaneTabWorkflows],
]);

const activeComponent = computed(() => {
  const component = tabMap.get(props.tab);
  return component ? component : null;
});
</script>

<template>
  <keep-alive>
    <component :is="activeComponent"></component>
  </keep-alive>
</template>

<style lang="postcss">
.hrbr-sidebar-pane-tab {

  padding: 20px;
  color: #333;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  scrollbar-width: thin;

  * {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__head {
    background: #fff;
    padding: 0 var(--head-side-padding);
    position: sticky;
    top: 0;
    z-index: 2;
  }

  &__body {
    flex: 1;
    padding: 20px 0px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
  }

  &__heading-text {
    font-size: 14.5px;
    font-weight: 600;
    color: #626262;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    user-select: none;
  }

  &__heading-badge {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
    color: var(--hrbr-black-color);
    display: inline-flex;
    flex-shrink: 0;
    padding: 2px 5px;
    border-radius: 4px;
    background: #ffd4b8;
  }
}
</style>
