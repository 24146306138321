<script setup>
import { ref, computed, onMounted } from 'vue';

const props = defineProps({
  initialTitle: {
    type: String,
    default: '',
  },
});

const emit = defineEmits([
  'close', 
  'save'
]);

const title = ref(props.initialTitle);
const input = ref(null);

const isSaveDisabled = computed(() => {
  return !title.value.length;
});

const save = () => {
  emit('save', { title: title.value });
  closeModal();
};
const closeModal = () => emit('close');

onMounted(() => {
  input.value?.focus();
});
</script>

<template>
  <div class="hrbr-drafts-title-modal modal-card">
    <header class="hrbr-drafts-title-modal__head modal-card-head">
      <p class="modal-card-title">Title</p>
    </header>

    <section class="hrbr-drafts-title-modal__body modal-card-body">
      <b-input 
        type="text"
        v-model="title"
        expanded
        ref="input">
      </b-input>
    </section>

    <footer class="hrbr-drafts-title-modal__foot modal-card-foot">
      <b-button @click="closeModal">Cancel</b-button>
      <b-button 
        @click="save" 
        type="is-primary"
        :disabled="isSaveDisabled">
        Save
      </b-button>      
    </footer>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-drafts-title-modal {
  max-width: 320px;
}
</style>
