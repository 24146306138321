<script setup>
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useLibraryStore } from '@/stores/library-store';

const libraryStore = useLibraryStore();
const { expandDetails } = libraryStore
const { hasEditPermissions } = storeToRefs(libraryStore);
const isExpanded = ref(false);
const expand = (id) => {
  if (!hasEditPermissions.value) {
    isExpanded.value = false;
    return;
  };
  expandDetails(id);
  isExpanded.value = !isExpanded.value;
}
</script>

<template>
  <div class="audit-trail-container">
    <div class="audit-trail-button-row" v-if="!!params.node.master && params.data.context_source_type !== 'CATALIS_WEBSERVICE'">
      <button class="hrbr-grid-button" @click="expand(params.data?.id)" :disabled="!hasEditPermissions">
        <i class="fal fa-list"></i>
        <span v-if="!isExpanded">View audit trail</span>
        <span v-else>Hide audit trail</span>
      </button>
    </div>
    <div class="audit-trail-button-row not-applicable" v-else>
      -
    </div>
  </div>
</template>

<style scoped>
.not-applicable {
  color: #999;
}
.audit-trail-button-row {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
}
.audit-trail-button-row > button > i {
  margin-right: 4px;
}
</style>
