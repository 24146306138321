<script setup>
import Vue from 'vue';
import { storeToRefs } from 'pinia';
import { ref, computed, onMounted, getCurrentInstance } from 'vue';
import { useHarbourStore } from '@/stores/harbour-store';
import { useDashboardStore } from '@/stores/dashboard-store';
import LiveSignerBar from '../../detail_grid/LiveSignerBar.vue';

const instance = getCurrentInstance();
const harbourStore = useHarbourStore();
const dashboardStore = useDashboardStore();
const { profilePictures } = storeToRefs(harbourStore);

const props = defineProps({
  linkObject: {
    type: Object,
    required: true,
  },
  signer: {
    type: Object,
    required: true,
  },
  editable: {
    type: Boolean,
    default: true,
  },
});

const signerProfileImageUrl = ref(null);
const signerContainer = ref(null);

const expandStream = () => dashboardStore.expandDetails(props.linkObject.id, 'live');
const getMode = computed(() => props.linkObject?.authMethod?.authmode);
const hasViewed = computed(() => props.linkObject.viewedBy.includes(props.signer.email));

const getProfileImageUrl = (email) => {
  const profile = profilePictures.value?.find((pic) => pic.email === email);
  if (!profile) return null;
  profile ? (signerProfileImageUrl.value = profile.profileImageUrl) : (signerProfileImageUrl.value = null);
};

// Get a signer's initials based on name or email, if available
const getInitial = (name, email) => {
  if (name) return name[0].toUpperCase();
  else if (email) return email[0].toUpperCase();
  else return '';
};

// Check if this signer has already completed the contract
// Different paths for email-based vs public links
const getCompletedSigners = computed(() => {
  let completed_signer = false;
  if (getMode.value === 'EMAILS') {
    completed_signer = !!props.linkObject.emailRecipientsCompleted.find((signer) => {
      return signer.recipientemail === props.signer.email;
    });
  } else {
    completed_signer = !!props.linkObject.submissions.find(
      (submission) => submission.signer_email === props.signer.email,
    );
  }
  return completed_signer;
});

// Attempt to get a signer's name
const getName = () => {
  const session = props.signer?.session;
  if (!session) return;

  const auditTrail = session.audit_trail_data_json;
  if (getMode.value === 'EMAILS' && props.signer.name) {
    return props.signer.name;
  }

  const nameItem = auditTrail.find((item) => item.item_details?.itemspeciallinkedvalue === 'NAME');
  return nameItem?.item_value || null;
};

const getNonLiveSignerName = () => {
  return props.signer.name;
};

// Open the link editor modal
const openLinkEditorModal = () => {
  const editorProps = {
    linkItem: props.linkObject,
    linkDisplayIdToEdit: props.linkObject.id,
    linkDisplayId: props.linkObject.id,
    agreementId: props.linkObject.agreementId,
    folderId: harbourStore.currentFolderId,
    parent: instance.proxy,
  };

  // Check if this is a CK link
  const ckeditorFileId = props.linkObject.ckeditorAgreementId;
  if (ckeditorFileId) {
    editorProps.ckeditorFileId = ckeditorFileId;
  }
  Vue.prototype.$openAgreementLinkModal({ props: editorProps });
};

onMounted(() => {
  getProfileImageUrl(props.signer.email);
});
</script>

<template>
  <div class="signer-container" ref="signerContainer">
    <div class="signer-data-row">
      <div class="right-column">
        <div class="right-icons">
          <div
            class="signer-signed signer-icon"
            v-if="getCompletedSigners && getMode === 'EMAILS'"
            title="Signer has completed the document">
            <i class="fas fa-check-circle"></i>
          </div>
          <div
            v-else-if="signer.isLive"
            class="signer-live is-live signer-icon"
            title="Signer is currently filling out the agreement">
            <span class="view-stream" @click.stop="expandStream">Live stream</span>
            <i class="fal fa-signal-stream"></i>
          </div>
          <div
            class="signer-viewed signer-icon"
            v-else-if="hasViewed && getMode === 'EMAILS'"
            title="Signer has viewed the link">
            <i class="fad fa-eye"></i>
          </div>
          <div
            class="signer-edit signer-icon"
            v-else-if="
              getMode === 'EMAILS' && (!getCompletedSigners && props.editable)
            "
            @click.stop="openLinkEditorModal"
            title="Edit signer">
            <i class="fas fa-pencil"></i>
          </div>
        </div>

        <div class="right-column-container">
          <div class="leftmost-column">
            <div class="signer-image-container">
              <img
                v-if="signerProfileImageUrl"
                :src="signerProfileImageUrl"
                class="signer-image" />
              <div v-if="!!signer.fullname || !!signer.email" class="photo-initials">
                {{ getInitial(signer.fullname, signer.email) }}
              </div>
              <div v-else class="photo-intiials photo-intiials-anon">
                <i class="fal fa-user"></i>
              </div>
            </div>
          </div>
          <div class="name-container">
            <div class="right-left-column">
              <div class="name-row" :title="getName()">
                <div class="live-circle" v-if="signer.isLive"></div>
                <span v-if="signer.isLive" :class="{ 'is-live': signer.isLive }">
                  <i class="fad fa-user user-icon-live"></i>
                  <span class="user-text-live">
                    {{ getName() || signer.location }}
                  </span>
                </span>
                <span v-else :class="{ 'is-live': signer.isLive }" :title="getNonLiveSignerName()">
                  <span class="user-text">
                    {{ getNonLiveSignerName() }}
                  </span>
                </span>
              </div>
              <div class="email-row" :title="signer.email || signer.recipientemail">
                {{ signer.email || signer.recipientemail }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="live-signer-bar" v-if="props.signer.session">
      <LiveSignerBar
        :session="props.signer.session"
        :totalinputs="props.linkObject.totalInputs"
        :link-object="props.linkObject"
        displaymode="live" />
    </div>
  </div>
</template>

<style scoped>
.user-icon-live {
  color: #2D71AD;
  opacity: .85;
}
.right-icons {
  display: flex;
  margin-top: 2px;
  justify-content: flex-end;
}
.signer-container {
  width: 100% !important;
  padding: 0;
}
.right-left-column {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-top: 1px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.name-container {
  padding-right: 5px;
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.name-row {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.left-column {
  flex: 1;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
}
.right-icons span {
  margin-right: 2px;
}
.is-live {
  color: var(--grid-success) !important;
}
.user-text{
  color: #666;
}
.user-text-live{
  color: #888;
}
.right-column {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  max-width: 100%;
}
.view-stream {
  white-space: nowrap;
  padding-right: 3px;
  font-size: 11px;
  cursor: pointer;
  font-weight: 500;
  letter-spacing: -.1px;
}
.view-stream:hover {
  color: #019282 !important;
}
.live-circle {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--grid-success);
  margin-right: 5px;
}
.right-column-container {
  overflow: hidden;
  display: flex;
  width: 100%;
  align-items: center;
}

.signer-container {
  padding: 0 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.signer-data-row {
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 37px;
}
.leftmost-column {
  width: 26px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signer-image-container {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 50%;
  font-size: 11px;
  border: 0px solid #d9d9d9;
  background-color: #2f5a8899;
  overflow: hidden;
}
.signer-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  font-size: 1.2em;
}
.photo-initials {
  padding: 0;
  margin: 0;
  line-height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  color: white;
}
.photo-intiials-anon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white !important;
}
.name-row {
  color: #666;
  font-size: 14px;
  line-height: 1em;
  font-weight: 400;
}
.email-row {
  color: #999;
  font-size: 11px;
  line-height: 1.1em;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.live-signer-bar {
  width: 100% !important;
  margin-top: -2px;
}
.signer-signed {
  color: var(--grid-success);
}
.signer-complete {
  cursor: pointer;
  color: var(--grid-success);
  transition: all 150ms ease;
}
.signer-complete:hover {
  color: #00786a;
}
.signer-edit i {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 9px !important;
  background-color: #00000008;
  height: 18px !important;
  width: 18px !important;
  border-radius: 20px;
  cursor: pointer;
  color: #02020275;
  transition: all 150ms ease;
}
.signer-edit i:hover {
  font-size: 10px !important;
  color: #222;
  background-color: #e4e4e4;
}
.signer-viewed {
  color: rgb(187, 168, 114);
}
</style>
