<script setup>
import { reactive, ref } from 'vue';
import { storeToRefs } from 'pinia';
import HrbrButton from '@components/ui/HrbrButton.vue';
import { useMagicAnnotationsStore } from '@/stores/magic-annotations-store.js';

const magicAnnotationsStore = useMagicAnnotationsStore();
const { getAllDocumentInputs, signaturePadModalOpened, getSignatures, getUserName, signaturePadAnnotation } = storeToRefs(magicAnnotationsStore);

const signatureUrl = ref(null);

const cancelButton = reactive({
  show: true,
  text: 'Cancel',
  action: () => signaturePadModalOpened.value = false,
});

const saveButton = reactive({
  show: true,
  text: 'Save',
  action: () => {
    const suggestion = getAllDocumentInputs.value.find(item => item.unique_box_id === signaturePadAnnotation.value.unique_box_id);
    suggestion.value = signatureUrl.value;
    suggestion.edited = true;
    signaturePadModalOpened.value = false;
  },
});

const onSignatureChanged = (item, dataUrl) => {
  signatureUrl.value = dataUrl;
}

</script>

<template>
  <b-modal
    :active="signaturePadModalOpened"
    trap-focus
    custom-class="signature-modal"
    aria-role="dialog"
    aria-label="Signature pad modal"
    close-button-aria-label="Close"
    aria-modal
    :on-cancel="() => signaturePadModalOpened = false"
  >
    <template #default="props">
      <div
        class="modal-card"
        style="width: auto"
      >
        <header class="modal-card-head">
          <div class="modal-card-title">
            Add your signature
          </div>
        </header>
        <section class="modal-card-body">
          <vue-signature-pad
            :signingsetting="'draw'"
            :storedsignatures="getSignatures"
            :storedinitials="[]"
            :inputchange="onSignatureChanged"
            :signaturepaddisabled="false"
            :isloading="false"
            :username="getUserName"
            :item="{}"
            :hidedropdownupload="true"
            :isuser="true"
          ></vue-signature-pad>
        </section>

        <footer class="modal-card-foot">
          <HrbrButton class-name="cancel-btn" :button="cancelButton" />
          <HrbrButton class-name="confirm-btn" :button="saveButton" />
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<style lang="postcss" scoped>
.signature-modal {
  z-index: 101;
  &:deep(.modal) {
    &-content {
      max-width: 640px !important;
    }
    &-card-head {
      padding: 26px 26px 0 26px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #fff;
      border: none;
    }
    &-card-body {
      padding: 26px;
      .vue-signature-pad {
        padding: 0;
        border: none;
        box-shadow: none;
      }
      .signaturecontent {
        border: none;
        height: auto !important;
        .field, .dropdown {
          padding: 0 !important;
        }
        .field:not(:last-child) {
          margin-bottom: 26px;
        }
      }
    }
    &-card-foot {
      gap: 10px;
      padding: 16px 26px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background-color: #fff;
    }
  }
  .confirm-btn {
    :deep(.section-btn) {
      border-color: #1355FF;
      border-radius: 8px;
      background-color: #1355FF;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      &:hover {
        border-color: #0133B7;
        background-color: #0133B7;
      }
    }
  }
  .cancel-btn {
    :deep(.section-btn) {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      border-radius: 8px;
    }
  }
}
</style>
