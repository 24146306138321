<script setup>
import { ModalProgrammatic as Modal } from 'buefy';
import { useRouter } from 'vue-router/composables';
import { TRIGGER_TYPE_KEYS } from '@/config/paragon-integration.js';
import automationsApiService from '@/services/automations-api-service.js';
import { useHarbourStore } from '@/stores/harbour-store.js';
import { useAutomationsStore } from '@/stores/automations-store.js';
import HrbrCatalisIntegration from '@components/Paragon/HrbrCatalisIntegration.vue';

const harbourStore = useHarbourStore();
const automationsStore = useAutomationsStore();
const router = useRouter();

const openAutomation = async (params) => {
  const { data, linkId, updateTableData } = params;

  const integration = automationsStore.paragonIntegrations.find((integrationItem) => integrationItem.type === data.integration_type);
  await selectIntegrationParameters(integration, data.linkAutomation, data.submission_id, linkId, updateTableData);
}

const selectIntegrationParameters = async (integration, linkAutomation, submissionId, linkId, updateTableData) => {
  const customIntegrationModals = {
    'custom.catalis': HrbrCatalisIntegration,
  };

  if (integration.type.startsWith('custom')) {
    Modal.open({
      parent: this,
      component: customIntegrationModals[integration.type],
      hasModalCard: true,
      props: {
        integrationMetadata: automationsStore.paragonMetadata[integration.type],
        integrationData: integration,
        linkAutomation,
        isManualTrigger: true
      },
      events: {
        'integration-manual-trigger': async ({ selectedTrigger }) => {
          automationsStore.isLoadingIntegrations = true;
          const params = {
            trigger_type: TRIGGER_TYPE_KEYS[selectedTrigger],
            submission_id: submissionId,
            agreement_link_id: linkId,
            organization_id: harbourStore.contextDict?.organizationid,
          };

          await automationsApiService.triggerAutomationEvent(linkAutomation.automation_id, params);
          await updateTableData();

          automationsStore.isLoadingIntegrations = false;
        },
      },
    });
  }
}

const openDocument = (params) => {
  const { link, data } = params;
  const submission = link.submissions.find(s => data.asset_id === s.id);
  if (!submission) return;
  const query = { folderid: link.folder, search: submission.id };
  router.push({ name: 'folders', query });
}
</script>

<template>
  <div class="action-buttons">
    <div
      v-if="params.data.status === 'failed'"
      class="btn"
      @click="openAutomation(params)"
    >
      <i class="fa-light fa-rotate-right"></i>
      Resend
    </div>
    <div
      v-if="params.data.status === 'success'"
      class="btn"
      @click="openDocument(params)"
    >
      Open document
    </div>
  </div>
</template>
<style lang="postcss" scoped>
  .btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      padding: 8px 15px;
      font-size: 12px;
      border-radius: 4px;
      border: 1px solid #dbdbdb;
      &:hover {
          opacity: .65;
      }
  }
</style>
