<script>
import { reactive, getCurrentInstance } from 'vue';
import { useDashboardStore } from '@/stores/dashboard-store';
import { useCkeditorStore } from '@components/Ckeditor/stores/ckeditor-store.js';
import { mapWritableState, mapActions } from 'pinia';
import { useLinkObject } from '@/pages/Dashboard/composables/LinkObject'

export default {
  name: 'ViewAgreementRenderer',
  setup() {
    return {
      dashboardStore: useDashboardStore(),
      ckeditorStore: useCkeditorStore(),
      instance: getCurrentInstance(),
    };
  },
  data() {
    return {
      linkObject: null,
      isCkDoc: false,
    };
  },
  created() {
    this.linkObject = useLinkObject(this.params.data);
    this.isCkDoc = this.linkObject.ckeditorAgreementId && !this.isLinkCompleted(this.linkObject);
  },
  beforeUnmount() {
    this.linkObject = null;
  },
  computed: {
    ...mapWritableState(useDashboardStore, ['previewURL', 'isPreviewModalActive']),
  },
  methods: {
    ...mapActions(useDashboardStore, ['openLinkBasedPdfPreview', 'openCkDocPreview', 'isLinkCompleted']),
    async openPreview() {
      if (this.isCkDoc) {
        this.openCkDocPreview(this.params.data, this.instance, this);
      } else {
        this.openLinkBasedPdfPreview(this.linkObject);
      }
    },
  },
};
</script>

<template>
  <div class="file-display-column">
    <button class="hrbr-grid-button" @click.stop="openPreview">
      <i v-if="isCkDoc" class="fal fa-thin fa-file-word ck-doc"></i>
      <i v-else class="fal fa-file-pdf pdf-doc"></i>
      <span class="view-text">View</span>
    </button>
  </div>
</template>

<style scoped>
.view-text {
  margin-left: 5px;
}
.file-display-column {
  color: #6a6a6a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon-container {
  font-size: 23px;
  display: inline-block;
  padding: 1px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}
.ck-doc {
  font-weight: 300 !important;
  transition: all 0.08s ease-in-out;
}
.pdf-doc {
  font-weight: 300 !important;
  transition: all 0.08s ease-in-out;
}
.icon-container:hover {
  background-color: #e2e2e2;
  color: #333;
}
.file-display-column i {
}
.file-type-pill {
  display: inline-block;
  line-height: 1em;
  background-color: #f7f7f7;
  border-radius: 10px;
  font-size: 8px;
  padding: 3px 8px;
  margin: 5px 0 0 0;
}
</style>
