<script setup>
defineProps({
  id: {
    type: String,
  },
  title: {
    type: String,
  },
  icon: {
    type: String,
  },
});

const emit = defineEmits({
  itemClick: null,
});

const handleMenuItemClick = (id) => emit('itemClick', id);
</script>

<template>
  <div class="hrbr-app-header-menu-item" @click="handleMenuItemClick(id)">
    <div class="hrbr-app-header-menu-item__icon">
      <i class="fa-light" :class="'fa-' + icon"></i>
    </div>

    <div class="hrbr-app-header-menu-item__title" :title="title">
      {{ title }}
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-app-header-menu-item {
  font-size: 15px;
  color: #666f75;
  line-height: 1.2;
  display: flex;
  align-items: center;
  column-gap: 6px;
  width: 100%;
  height: 30px;
  min-width: 0;
  padding: 6px 15px;
  position: relative;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  transition: background 0.15s, color 0.15s;

  &:hover {
    background: #e4e9ec;
    color: var(--hrbr-black-color);
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }

  &__title {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: 0.2px;
  }
}
</style>
