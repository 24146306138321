<script>
import uniqueBy from 'lodash.uniqby';
import {
  EXTERNAL_URLS,
  INTEGRATION_CUSTOM_PARAMETERS,
} from '@/config/paragon-integration.js';
import { useAutomationsStore } from '@/stores/automations-store.js';
import { useHarbourStore } from '@/stores/harbour-store.js';
import { useLinkObject } from '@/pages/Dashboard/composables/LinkObject'

import HrbrCatalisIntegration from '@components/Paragon/HrbrCatalisIntegration.vue';
import IntegrationsModal from '../ui_components/IntegrationsModal.vue';
import HrbrParagonIntegration from '@components/Paragon/HrbrParagonIntegration.vue';

export default {
  name: 'AutomationsRenderer',
  components: {
    IntegrationsModal,
  },
  setup() {
    return {
      automationsStore: useAutomationsStore(),
      harbourStore: useHarbourStore(),
    };
  },
  data() {
    return {
      linkIntegrations: [],
      linkObject: null,
    };
  },
  watch: {
    'automationsStore.linksDataChanged': {
      handler(val) {
        if (val) {
          this.linkIntegrations = this.linkObject.automations ? uniqueBy(this.linkObject.automations, 'integration_type') : [];
          this.automationsStore.linksDataChanged = false;
        }
      },
    },
  },
  created() {
    this.linkObject = useLinkObject(this.params.data);
    this.linkIntegrations = this.linkObject.automations ? uniqueBy(this.linkObject.automations, 'integration_type') : [];
  },
  methods: {

    openIntegrationsModal() {
      this.$buefy.modal.open({
        parent: this,
        component: IntegrationsModal,
        hasModalCard: true,
        props: {
          linkId: this.linkObject.id,
        },
        events: {
          'update-dashboard-view': async () => {
            this.linkIntegrations = await this.automationsStore.listHarbourIntegrations(
              this.linkObject.id,
            );
          },
        },
      });
    },

    onImgError(evt) {
      // eslint-disable-next-line no-param-reassign
      evt.target.onerror = null;
      // eslint-disable-next-line no-param-reassign
      evt.target.src = 'static/imgs/placeholder-marketplace-logo.svg';
    },

    getIcon(integration) {
      return (
        this.automationsStore.metadataLoaded &&
        this.automationsStore.paragonMetadata[integration.integration_type].icon
      );
    },

    async handleItemClick(item) {
      if (this.linkObject.getCompletionState === 'COMPLETED') {
        const resourceUrl = EXTERNAL_URLS[item.integration_type];
        if (!resourceUrl) return;
        const responseKey =
          INTEGRATION_CUSTOM_PARAMETERS[item.integration_type].selectedResponseKey;
        window.open(`${resourceUrl}${item.custom_parameters[responseKey]}`, '_blank');
        return;
      }

      const integration = this.automationsStore.paragonIntegrations.find((integrationItem) => integrationItem.type === item.integration_type);
      const shouldOpenCustomModal = await this.automationsStore.installIntegration(integration);

      if (shouldOpenCustomModal) await this.selectIntegrationParameters(integration, item);
    },

    async selectIntegrationParameters(integration, linkAutomation) {
      const customIntegrationModals = {
        'custom.catalis': HrbrCatalisIntegration,
      };

      let harbourParameters = {
        ...linkAutomation
      };

      if (integration.type.startsWith('custom')) {
        this.$buefy.modal.open({
          parent: this,
          component: customIntegrationModals[integration.type],
          hasModalCard: true,
          props: {
            integrationMetadata: this.automationsStore.paragonMetadata[integration.type],
            integrationData: integration,
            linkAutomation
          },
          events: {
            'integration-parameters-selected': async (eventParams) => {
              await this.automationsStore.onCustomIntegrationSave(
                integration.type,
                harbourParameters,
                eventParams,
                async (params) => await this.updateIntegrationParams(params)
              );
            },
          },
        });
        return;
      }


      const props = INTEGRATION_CUSTOM_PARAMETERS[integration.type].getProps(
        {
          integrationMetadata: this.automationsStore.paragonMetadata[integration.type],
          integrationName: integration.type,
          agreementLinkId: this.linkObject.id,
        },
        harbourParameters,
      );

      this.$buefy.modal.open({
        parent: this,
        component: HrbrParagonIntegration,
        hasModalCard: true,
        props,
        events: {
          'integration-parameters-selected': async (eventParams) => {
            await this.automationsStore.onParagonIntegrationSave(
              integration.type,
              harbourParameters,
              eventParams,
              async (params) => await this.updateIntegrationParams(params)
            );
          },
        },
      });
    },

    async updateIntegrationParams(harbourParameters) {
      // Update integration parameters
      try {
        await this.automationsStore.updateIntegrationParams(harbourParameters, this.linkObject.id);
        this.linkIntegrations = await this.automationsStore.listHarbourIntegrations(
          this.linkObject.id,
        );
      } catch (e) {
        this.$buefy.toast.open({
          duration: 2500,
          message: 'Error creating/updating Paragon Integration',
          position: 'is-top',
          type: 'is-danger',
        });
      }
    },
  },
};
</script>

<template>
  <div class="action-buttons">

    <!-- existing integrations -->
    <div v-for="integration in linkIntegrations" class="hrbr-grid-button" @click.stop="handleItemClick(integration)">
      <div class="icon-container">
        <img :src="getIcon(integration)" @error="onImgError" />
      </div>
    </div>

    <!-- add first integration -->
    <div
      v-if="params.data.getCompletionState !== 'COMPLETED'"
      class="btn add"
      @click.stop="openIntegrationsModal"
    >
      <span>+</span>
    </div>

    <!-- no integrations -->
    <div v-else-if="!linkIntegrations.length">
      -
    </div>

  </div>
</template>
<style lang="postcss" scoped>

.action-buttons {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 5px;
}

.btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  padding: 4px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background-color: white;
}
.btn:hover {
  border: 1px solid #b5b5b5;
}

.icon-container {
  width: 25px;
  height: 25px;
  img {
    width: 100%;
    height: 100%;
  }
}

</style>
