<script>
import HrbrAttachmentGallery from '@/components/Modals/HrbrAttachmentGallery.vue';
import { 
  getAttachmentFileIcon,
  getAttachmentExtension,
  getAttachmentFileTypeFromName,
  getAttachmentFileSizeString,
  getAttachmentDurationString
} from '@/utils/helpers/inline-attachments.js';

import { useLibraryStore } from '@/stores/library-store';
import { mapState } from 'pinia'
import { publishEvent } from '@/utils/bus';


export default {
  data() {
    return {
      row: null,
      attachments: [],
      resizeObserver: null,
      showViewMoreButton: false,
      uploadingAttachments: {value: false},
      currentAttachmentIndex: {value: 0},
      libraryStore: useLibraryStore(),
    };
  },
  created() {
    this.row = this.params.data;
    this.api = this.params.api;
    this.attachments = this.row.attachments || [];
    this.showViewMoreButton = this.attachments.length > 2;
  },
  mounted() {
  },
  computed: {
    ...mapState(useLibraryStore, ['hasEditPermissions']),
    visibleAttachments() {
      return this.attachments.slice(0, 3);
    },
  },
  methods: {
    prevAttachment() {
      this.currentAttachmentIndex.value--;
      if (this.currentAttachmentIndex.value < 0) this.currentAttachmentIndex.value = this.attachments.length - 1;
    },
    nextAttachment() {
      this.currentAttachmentIndex.value++;
      if (this.currentAttachmentIndex.value > this.attachments.length - 1) this.currentAttachmentIndex.value = 0;
    },
    updateCurrentAttachment(index) {
      this.currentAttachmentIndex.value = index;
    },
    uploadAttachments() {
      const input = document.createElement('input');
      input.type = 'file';
      input.multiple = true;

      input.onchange = async (e) => {
        const files = Array.from(e.target.files);
        if (!files.length) {
          return;
        };

        this.uploadingAttachments.value = true;
        const attachments = [];
        // promisify reading files as base64
        const readerPromises = files.map((file) => {
          return new Promise((resolve, _reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64data = reader.result;
              const attachment = {
                name: file.name,
                size: file.size,
                blob: base64data,
              };
              attachments.push(attachment);
              resolve();
            };
            reader.readAsDataURL(file);
          });
        });
        await Promise.all(readerPromises);

        try {
          const resp = await this.$harbourData.post('data?agreelinkdata-addassetattachments', {
            requesttype: 'agreelinkdata-addassetattachments',
            assetid: this.row.id,
            attachments,
          });

          const newAttachments = resp.data.newattachments?.map((attachment) => {
            const params = {
              attachmentid: attachment.id,
              assetid: this.row.id,
              folderid: this.row.folder_id,
              mimetype: attachment.mimetype,
            }
            const paramsString = Object.keys(params).map((key) => {
              return key + '=' + params[key]?.replaceAll(' ', '%20')
            }).join('&');

            const fileType = getAttachmentFileTypeFromName(attachment.name || '');
            const attachmentUrl = `/attachment/${attachment.id}`;
            const thumbnailUrl = attachment.ispreviewgenerated ? `/attachment/${attachment.id}/thumbnail` : null;

            const displayUrl = attachmentUrl;
            return {
              id: attachment.id,
              name: attachment.name,
              uploaderEmail: attachment.uploaderemail,
              fileType,
              displayUrl,
              url: attachmentUrl,
              thumbnailUrl,
              fileSizeBytes: attachment.filesizebytes,
              fileSizeString: getAttachmentFileSizeString(attachment.filesizebytes || 0),
              videoDuration: attachment.videoduration || 0
            };
          }) || [];
          this.attachments.push(...newAttachments);
          this.api.refreshCells({ rowNodes: [this.row] });

          publishEvent('attachments:attachment-added')

          this.uploadingAttachments.value = false;
          this.currentAttachmentIndex.value = this.attachments.length - 1;
        } catch (e) {
          console.error(e);
          this.uploadingAttachments.value = false;
          // show error message
          this.$buefy.toast.open({
            message: 'Unable to upload attachments.',
            type: 'is-danger',
            position: 'is-top',
            duration: 3000,
          });
        }
        
      };
      input.click();
    },
    handleUploadButtonClick() {
      if (!this.hasEditPermissions) return;
      this.uploadAttachments();
    },
    handleImageClick(attachmentIndex) {
      this.currentAttachmentIndex.value = attachmentIndex;
      const self = this;
      this.$buefy.modal.open({
        parent: this,
        fullScreen: true,
        canCancel: false,
        component: HrbrAttachmentGallery,
        props: {
          attachments: this.attachments,
          currentAttachmentIndex: this.currentAttachmentIndex,
          uploadingAttachments: this.uploadingAttachments,
          hasEditPermissions: this.hasEditPermissions,
        },
        hasModalCard: true,
        events: {
          'upload-attachments': self.uploadAttachments,
          'prev-attachment': self.prevAttachment,
          'next-attachment': self.nextAttachment,
          'update-current-attachment': self.updateCurrentAttachment,
        },
      });
    },
    getAttachmentFileIcon,
    getAttachmentExtension,
    getAttachmentDurationString
  },
};
</script>

<template>
  <div class="attachments-wrapper">

    <div v-if="!attachments.length">
      <!-- no attachments (uploading new) -->
      <span v-if="uploadingAttachments.value" class="add-attachments-cta-start-new">
        <b-icon class="loading-icon" pack="fas" icon="spinner-third"></b-icon>
      </span>
      <!-- no attachments (start new) -->
      <span v-else class="add-attachments-cta" :class="{'disabled': !hasEditPermissions}"
        @click="handleUploadButtonClick">
        <span class="text">+</span>
      </span>
    </div>

    <div v-else>
      <div class="view-more-btn" v-if="showViewMoreButton" @click="handleImageClick(0)">
        <span>+{{ (this.attachments.length - this.visibleAttachments.length) + 1 }}</span>
        <div class="view-more-shadow"></div>
      </div>
      <div class="glass" ref="attachmentsCtn"
        :style="{justifyContent: this.attachments.length > 1 ? 'left' : 'center'}">
        <ul class="dock">
          <div class="attachment upload-btn-wait" v-if="uploadingAttachments.value" title="Upload attachments">
            <b-icon class="loading-icon" pack="fas" icon="spinner-third"></b-icon>
          </div>
          <div class="attachment upload-btn" :class="{'disabled': !hasEditPermissions}" v-else
            @click="handleUploadButtonClick" title="Upload attachments">
            <span>+</span>
          </div>
          <li v-for="(attachment, index) in visibleAttachments" @click="handleImageClick(index)" class="attachment"
            :class="{'no-thumbnail': !attachment.thumbnailUrl}" :key="index">
            <!-- renderable attachment? show thumbnail -->
            <div v-if="attachment.thumbnailUrl">
              <div class="loading-bg"></div>
              <div class="image-ctn" :style="{backgroundImage: `url(${attachment.thumbnailUrl})`}">
                <span v-if="attachment.videoDuration" class="video-duration">{{
                  getAttachmentDurationString(attachment.videoDuration) }}</span>
                <img :src="attachment.thumbnailUrl" :alt="attachment.name" />
              </div>
            </div>
            <!-- unrenderable? show icon -->
            <div v-else class="icon-ctn">
              <b-icon pack="far" :icon="getAttachmentFileIcon(attachment)">
              </b-icon>

              <span>{{ getAttachmentExtension(attachment) }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<style scoped>
.attachments-wrapper {
  position: relative;
}

.attachment {
  margin: 0 3px;
  width: 200px;
  height: 85px;
  position: relative;
}

.attachment.no-thumbnail {
  background-color: #F7F7F7;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777;
}
.view-more-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 100%;
}

.view-more-btn span {
  color: white;
  z-index: 1;
}

.view-more-shadow {
  position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    padding: 1px;
    align-items: center;
    height: 100%;
    box-shadow: -8px 0px 20px 6px black;
}

.glass {
  width: 100%;
  height: 8rem;
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  display: flex;
}

.dock {
  scale: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: left;
  align-items: center;
}

.dock .attachment {
  transition: .2s all ease-out;
  cursor: pointer;
}

.dock .attachment:hover {
  transform: scale(1.04);
  filter: brightness(90%);
}

.icon-ctn {
  position: absolute;
}
.image-ctn, .icon-ctn {
  z-index: 1;
  border-radius: 4px;
}

.image-ctn, .loading-bg {
  display: flex;
  align-items: center;
  height: 85px;
  background-size: cover;
  background-position: center;
  width: 200px;
  border-radius: 4px;
  position: absolute;
}

@keyframes shine-loading-image {
  0% {
    background-position: -32px;
  }
  40%, 100% {
    background-position: 208px;
  }
}
.loading-bg {
  background-image: -webkit-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-image: linear-gradient(90deg, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-size: 250px;
  -webkit-animation: shine-loading-image 2s infinite ease-out;
  animation: shine-loading-image 2s infinite ease-out;
  width: 200px;
}
.attachment.no-thumbnail .icon-ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
}

.attachment.upload-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 45px;
  border-radius: 4px;
  background-color: #f7f7f7;
  border: solid 1px #f7f7f7;
  height: 85px;
  font-weight: 400;
  color: rgb(66 65 65 / 92%);
  font-size: 14px;
}
.attachment.upload-btn:hover {
  transform: none;
  background-color: #ededed;
  border: solid 1px #ededed;
  color: #222222;
}

.attachment.upload-btn.disabled {
  cursor: not-allowed;
}

.attachment.upload-btn-wait {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 45px;
  border-radius: 2px;
  background-color: #f7f7f7;
  border: solid 1px #f7f7f7;
  font-weight: 600;
  color: #575454db;
  height: 85px;
  cursor: wait;
}

.icon-ctn .icon {
  font-size: 4em;
  height: 64px;
}

.image-ctn img {
  visibility: hidden;
  border-radius: 4px;
}

.image-ctn .video-duration {
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  padding: 2px 4px;
  font-size: 12px;
  min-width: 50px;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading-icon {
  animation: spin .8s linear infinite;
}

.add-attachments-cta{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  padding: 4px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  background-color: white;
}
.add-attachments-cta:hover{
  border: 1px solid #b5b5b5;
}

.add-attachments-cta-start-new{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33px;
  height: 33px;
  padding: 4px;
  font-size: 12px;
  cursor: wait;
  border: 1px solid #f7f7f7;
  border-radius: 4px;
  background-color: white;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

</style>
