<script>
import { nextTick } from 'vue';
import { useDashboardStore } from '@/stores/dashboard-store';
export default {
   name: 'TitleEditor',
   setup() {
    return {
      dashboardStore: useDashboardStore()
    }
   },
   data() {
    return {
      value: null,
      original: null,
      id: null,
    }
   },
   mounted() {
    this.value = this.params.value;
    this.original = this.params.value;
    this.id = this.params.data.id;
    nextTick(() => this.$refs.input.focus());
   },
   methods: {
    getValue() {
      return this.value;
    },
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      if (this.value === null || this.value === undefined || this.value === '') return true;
      if (!this.hasCallbackFunction) return true;
      if (this.original === this.value) return true;

      // Update the title on server
      this.params.callback(this.original, this.value, this.params.data.id);
      return false;
    },
   },
   computed: {
    hasCallbackFunction() {
      return !!this.params.callback && typeof this.params.callback === 'function';
    }
   }
}
</script>

<template>
  <div class="hrbr-grid-cell">
    <textarea class="editor" v-model="value" ref="input"></textarea>
  </div>
</template>

<style scoped>
.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.title-edit-icon {
  margin: 4px 5px;
  cursor: pointer;
}
.hrbr-grid-cell {
  height: 100% !important;
  width: 100% !important;
}
.editor {
  height: 70%;
  width: 100%;
  border-radius: 5px;
  outline: none;
  padding: 4px;
  resize: none;
  border: 1px solid #eaeaea;
}
</style>
