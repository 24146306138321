<script>
import { useLibraryStore } from '@/stores/library-store';
import { mapState, storeToRefs } from 'pinia';

export default {
  props:{
    row:{
      required: true
    },
    openpresentationmode:{
      required: true
    },
    assetbackgroundcolor:{
      required: true
    }
  },
  setup() {
    return {
      libraryStore: useLibraryStore(),
    };
  },
  data: function(){
    return{
      alt:null,
      mediadisplayid:null,
      mediadisplayname:null,
      mediadata:null,
      mediafilextension:null,
      mediafilesize:null,
      medialoaded:null,
      mediaserverfetched:null,
      mediaRendered:false,
      mediapreviewloaderror:null,
      isAnimatingMouseOver:false,
      isAnimatedFirstLoaded:false,
      isAnimatedElemLoaded:false,
      isAnimatedElemActive:false,
      animatingTimerMS:0,
      libraryloadingworking:true,
      mediaOpacity:0,
      isMobileView:false,
      componentUpdateKey:0,
      //
      isagreementpdfloading:false,
      isagreementspdfloaded:false,
      //
      agreementreferenceid:null,
      asset: {},
    };
  },
  created: function(){

    //Init values (from asset)
    let self = this;
    this.asset.assetdisplay_preferredname = this.row.name;
    this.asset.assetdisplay_displayid = this.row.id;
    this.asset.assetpreview_base64img = this.row.preview_base64;
    this.asset.assetfile_fileextension = this.row.file_extension;
    this.asset.assetfile_filesizebytes = this.row.file_size;
    this.asset.assetupload_isuploaded = true;
    this.asset.assetupload_isserverfetched = true;
    this.asset.assetdisplay_isnewagreementonly = this.row.is_new_agreement;
    this.asset.assetfile_filename = this.row.file_name;

    self.alt = 'Thumbail for: ' + self.asset.assetdisplay_preferredname;
    self.mediadisplayid = self.asset.assetdisplay_displayid;
    self.mediadisplayname = self.asset.assetdisplay_preferredname;
    self.mediadata = self.asset.assetpreview_base64img;
    self.mediafilextension = self.asset.assetfile_fileextension;
    self.mediafilesize = self.asset.assetfile_filesizebytes;
    self.medialoaded = self.asset.assetupload_isuploaded;
    self.mediaserverfetched = self.asset.assetupload_isserverfetched;
    self.ismediafirstinteracted = false;
    self.ismedianewagreementonly = self.asset.assetdisplay_isnewagreementonly;
    self.mediaassetfilename = self.asset.assetfile_filename;
    self.mediapreviewthumbnail = null;
    self.mediapreviewloaderror = false;

    //Get agreement id, if exists
    let agreementIdArray = self.getNonEmptyJsonValuesByKeySearch(self.asset,'agreementreferenceid');
    if(agreementIdArray.length > 0){
      self.agreementreferenceid = agreementIdArray[0];
    }

  },
  mounted: function(){

    let self = this;
    if(self.mediasupportedaudiotypes.includes(self.mediafilextension)==false){
      self.mediaRendered = true;
    }
    self.getPdfPreview();
    //Determine if this is a web view based on size
    window.addEventListener('resize', this.onWindowResize);
    if(document.body.clientWidth < 600){
      self.isMobileView = true;
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },

  methods:{
    onWindowResize() {
      if(document.body.clientWidth < 600){
        this.isMobileView = true;
      }else{
        this.isMobileView = false;
      }
    },

    //Mouse over - set state
    mouseOverAnimatedImage:function(){
      let self = this;
      self.isAnimatingMouseOver = true;
      self.isAnimatedFirstLoaded = true;
      self.isAnimatedElemActive = true;
    },

    //Touchmove - set state
    touchMoveOverAnimatedImage:function(){
      let self = this;
      self.isAnimatingMouseOver = true;
      self.isAnimatedFirstLoaded = true;
      self.isAnimatedElemActive = true;
    },

    //Update media opacity
    updateOpacity:function(){
      let self = this;
      setTimeout(function(){ self.mediaOpacity = 1; }, 1200);
    },

    //Mouse out - set state
    mouseOutAnimatedImage:function(){
      let self = this;
      self.isAnimatingMouseOver = false;
    },

    //Animated element loaded
    animatedImageElementLoaded:function(){
      let self = this;
      self.isAnimatedElemLoaded = true;
      self.animateImageStartLoop();
    },

    //Open agreement pdf modal
    openAgreementModal:function(){
      let self = this;
      self.supportpackOpenPresentationPDF('/agreepdf/' +  self.mediadisplayid + '/' + self.agreementreferenceid);
    },

    //Delay for GIF to stop loop
    animateImageStartLoop:function(){
      let self = this;
      self.animatingTimerMS = 5000;
      setInterval(function(){
        if(self.isAnimatingMouseOver == false){
          self.animatingTimerMS = Math.max(self.animatingTimerMS - 800,0);
          if(self.animatingTimerMS == 0){
            self.isAnimatedElemActive = false;
          }
        }else{
          self.animatingTimerMS = 5000;
        }
      }, 1000);
    },

    //Call parent open presentation function
    callOpenPresentationMode:function(mediadisplayid){
      let self = this;
      self.openpresentationmode(mediadisplayid);
    },

    //Start loading of agreement PDF
    startAgreementPdfLoading:function(isVisible, entry){
      let self = this;
      if(isVisible == true){
        self.isagreementpdfloading = true;
      }
    },

    //Start asset download
    startAssetDownload:function(){
      let self = this;
      self.filesystemZipDownload([self.mediadisplayid],"Item",true,null);
    },

    //Start media interaction
    mediaInteractionStart:function(){
      let self = this;
      self.ismediafirstinteracted = true;
      self.$forceUpdate();
    },

    //Get preview url source (media base64 or path)
    getPreviewUrlSrc:function(){
      let self = this;
      if((self.mediadata != null) && (self.mediadata.includes('base64') == true)){
        return self.mediadata;
      }
      //*add requestdt to bypass default brower caching
      return "/previewimg?id=" + self.mediadisplayid + "&requestdt=" + Date.now();
    },

    getPdfPreview: async function() {
      if (this.getLoadedPreview) return;
      this.libraryStore.getAssetPreview(this.asset.assetdisplay_displayid);
    }
  },
  computed: {
    ...mapState(useLibraryStore, ['assetPreviews']),
    getLoadedPreview() {
      const existingPreview = this.assetPreviews.find(preview => preview.id === this.asset.assetdisplay_displayid)
      if (existingPreview) {
        return existingPreview.preview
      }
      return null
    }
  }
}
</script>


<template>
  <div class="vue-component-mediaplayer" v-bind:style="{ backgroundColor: assetbackgroundcolor }" :key="componentUpdateKey">
    <div class="vue-component-mediaplayer-content" :data-loaded="medialoaded" style="height:200px;width:200px;">

      <!-- preview: image -->
      <div v-if="mediasupportedimagetypes.includes(mediafilextension)" data-preview="image" :data-thumbnailtype="mediafilextension">

        <!-- preview available (*local preview data or from server) -->
        <picture v-if="mediapreviewloaderror == false">
          <img loading="lazy" :src="getPreviewUrlSrc()" v-on:error="mediapreviewloaderror = true" class="mediaplayer-previewimg" :alt="alt">
        </picture>

        <!-- preview not available (error/missing) -->
        <div v-if="mediapreviewloaderror == true" class="mediaplaceholdercontent" v-on:click="startAssetDownload()">
          <div><i class="fal fa-file-image mediaplaceholdercontenticon"></i></i></div>
          <div>{{ mediafilextension }}</div>
        </div>

      </div>

      <!-- preview: video -->
      <div v-else-if="mediasupportedvideotypes.includes(mediafilextension)" data-preview="video rendereer" :data-thumbnailtype="mediafilextension">

        <!-- video not loaded from server, local perview available -->
        <div v-if="mediadata != null && medialoaded == false">
          <video v-if="mediafilextension != 'mov'" :src="mediadata" autoplay controls muted autopictureinpicture class="previewVideo"></video>
          <div v-if="mediafilextension == 'mov'" class="mediaplaceholdercontent">
            <div><i class="fab fa-apple mediaplaceholdercontenticon"></i></div>
            <div>Uploading and generating QuickTime preview...</div>
          </div>
        </div>

        <!-- video not loaded from server, no local perview available -->
        <div v-if="mediadata == null && medialoaded == false" class="mediaplaceholdercontent">
          <div><i class="fal fa-file-video mediaplaceholdercontenticon"></i></div>
          <div>Generating video preview...</div>
        </div>

        <!-- video loaded from server -->
        <div v-if="medialoaded == true">
          <video :id="'renderedvideopreview-' + mediadisplayid" :src="'/asset?cb=now-' + Date.now() + '&id=' + mediadisplayid + '&exportfilename=' + mediadisplayname + '_HXID-' + mediadisplayid + '.' + mediafilextension + '#t=2'" preload="metadata" controls muted autopictureinpicture class="previewVideo"></video>
        </div>

      </div>

      <!-- preview: audio -->
      <div v-else-if="mediasupportedaudiotypes.includes(mediafilextension)" data-preview="audio rendereer" :data-thumbnailtype="mediafilextension">

        <!-- audio not loaded from server -->
        <div v-if="medialoaded == false">
          <div class="mediaplaceholdercontent">
            <div><i class="fal fa-wave-sine mediaplaceholdercontenticon"></i></div>
            <div>Analyzing audio...</div>
          </div>
        </div>

        <!-- audio loaded from server -->
        <div v-show="medialoaded == true">
          <!--  rendered audio with waveform -->
          <div class="preview-audio" :id="'waveform-preview-' + mediadisplayid" :v-init="initAudio(asset,'waveform-preview-' + mediadisplayid,'PREVIEW',function(){ })"></div>
        </div>

      </div>

      <!-- preview: pdf document -->
      <div v-else-if="['pdf'].includes(mediafilextension)" data-preview="binary document renderer - pdf" :data-thumbnailtype="mediafilextension" style="background-color:#525659">

        <!-- uploaded pdf is loaded from server and good to preview (*load right away) -->
        <div v-if="row.preview_base64">
          <div class="mediaplaceholdercontent" v-on:click="openpresentationmode(mediadisplayid,null)">
            <img :src="row.preview_base64" />
          </div>
        </div>
        <div v-else-if="getLoadedPreview">
          <div class="mediaplaceholdercontent" v-on:click="openpresentationmode(mediadisplayid,null)">
            <img loading="lazy" :src="getLoadedPreview" v-on:error="mediapreviewloaderror = true" class="mediaplayer-previewimg" style="background-color:#FAFBFD" :alt="alt" />
          </div>
            
        </div>
        <div v-else>
          <div class="mediaplaceholdercontent" v-on:click="openpresentationmode(mediadisplayid,null)">
            <div><i class="fal fa-file-pdf mediaplaceholdercontenticon"></i></div>
            <div>PDF</div>
          </div>
        </div>
      </div>

      <!-- preview: postscript document -->
      <div v-else-if="['eps'].includes(mediafilextension)" data-preview="postscript document renderer - eps" :data-thumbnailtype="mediafilextension">

        <!-- eps too big to render preview -->
        <div v-if="mediafilesize > 209715200" data-assetstate="eps too big to render preview">
          <!-- placeholder -->
          <div class="mediaplaceholdercontent">
            <div><i class="fal fa-file-image mediaplaceholdercontenticon"></i></div>
            <div>EPS</div>
          </div>
        </div>

        <!-- previewable eps uploading to server -->
        <div v-else-if="medialoaded == false" data-assetstate="previewable eps currently uploading server">
          <div class="mediaplaceholdercontent">
            <div><i class="fal fa-file-image mediaplaceholdercontenticon"></i></div>
            <div>Uploading EPS...</div>
          </div>
        </div>

        <!-- eps is loaded from server but no preview available -->
        <div v-else-if="(mediaserverfetched == true) && (mediadata == null)" data-assetstate="eps is loaded from server but no preview available ">
          <!-- placeholder -->
          <div class="mediaplaceholdercontent">
            <div><i class="fal fa-file-image mediaplaceholdercontenticon"></i></div>
            <div>EPS</div>
          </div>
        </div>

        <!-- eps is loaded from server and good to preview (data with asset) -->
        <div v-else-if="(medialoaded == true) && (mediadata != null)" data-assetstate="eps is loaded from server and good to preview (data with asset)">
          <picture>
            <img :src="mediadata" class="mediaplayer-previewimg" alt="thumbnail image" v-on:click="callOpenPresentationMode(mediadisplayid,medialoaded)"/>
          </picture>
        </div>

        <!-- eps is loaded from server and good to preview (waiting for preview image) -->
        <div v-else-if="(medialoaded == true) && (asset.previewimageavailable == false)" data-assetstate="eps is loaded from server and good to preview (waiting for preview image)">
          <div class="mediaplaceholdercontent">
            <div><i class="fal fa-file-image mediaplaceholdercontenticon"></i></div>
            <div>Rendering EPS preview...</div>
          </div>
        </div>

        <!-- eps is loaded from server and good to preview (data via URL request) -->
        <div v-else-if="(medialoaded == true) && (asset.previewimageavailable == true)" data-assetstate="eps is loaded from server and good to preview (data via URL request)">
            <img :src="getPreviewUrlSrc()" class="mediaplayer-previewimg" alt="thumbnail image" v-on:click="callOpenPresentationMode(mediadisplayid,medialoaded)"/>
          </picture>
        </div>

        <!-- eps default -->
        <div v-else data-assetstate="eps default">
          <!-- placeholder -->
          <div class="mediaplaceholdercontent">
            <div><i class="fal fa-file-image mediaplaceholdercontenticon"></i></div>
            <div>EPS</div>
          </div>
        </div>

      </div>

      <!-- preview: epub document -->
      <div v-else-if="['epub'].includes(mediafilextension)" data-preview="binary document renderer - epub" :data-thumbnailtype="mediafilextension" style="background-color:white;">

        <!-- large epub (no preview) -->
        <div v-if="mediafilesize > 10485760">
          <div class="mediaplaceholdercontent">
            <div><i class="fal fa-book mediaplaceholdercontenticon"></i></div>
            <div>EPUB</div>
          </div>
        </div>

        <!-- show preview -->
        <div else>

          <!-- epub not loaded from server -->
          <div v-if="medialoaded == false">
            <div class="mediaplaceholdercontent">
              <div><i class="fal fa-book mediaplaceholdercontenticon"></i></div>
              <div>Rendering EPUB preview...</div>
            </div>
          </div>

          <!-- epub being loaded/parsed via custom library -->
          <div v-show="medialoaded == true && libraryloadingworking == true">
            <div class="mediaplaceholdercontent">
              <div><i class="fal fa-book mediaplaceholdercontenticon"></i></div>
              <div>Opening EPUB...</div>
            </div>
          </div>

          <!-- epub loaded from server and ready for display -->
          <div v-show="medialoaded == true && libraryloadingworking == false">
            <div :id="'epub-preview-' + mediadisplayid" class="preview-epub" :v-init="initEpub(asset,'epub-preview-' + mediadisplayid,'PREVIEW',function(state){ libraryloadingworking = false; updateOpacity(); })" v-bind:style="{opacity: mediaOpacity}"></div>
          </div>

        </div>

      </div>

      <!-- preview: supported 3d model -->
      <div v-else-if="mediasupported3dmodeltypes.includes(mediafilextension)" data-preview="3d renderer" :data-thumbnailtype="mediafilextension">

        <!-- icon -->
        <div class="mediaplaceholdercontent" v-on:click="openpresentationmode(mediadisplayid,null)">
          <div>
            <i v-if="mediafilextension == 'glb'" class="fal fa-cube mediaplaceholdercontenticon"></i>
            <i v-if="mediafilextension == 'gltf'" class="fal fa-cube mediaplaceholdercontenticon"></i>
          </div>
          <div>{{ mediafilextension }} (3D model)</div>
        </div>

      </div>

      <!-- preview: *no renderer -->
      <div v-else data-preview="norenderer" :data-thumbnailtype="mediafilextension">

        <div class="mediaplaceholdercontent" v-on:click="openpresentationmode(mediadisplayid,null)">
          <div>
            <i v-if="mediafilextension == 'doc'" class="fal fa-file-word mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'docx'" class="fal fa-file-word mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'ppt'" class="fal fa-file-powerpoint mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'pptx'" class="fal fa-file-powerpoint mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'zip'" class="fal fa-file-archive mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'html'" class="fal fa-file-code mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'xls'" class="fal fa-file-excel mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'xlsx'" class="fal fa-file-excel mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'csv'" class="fal fa-file-csv mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == '3ds'" class="fal fa-cube mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == '3mf'" class="fal fa-cube mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'blen'" class="fal fa-cube mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'stl'" class="fal fa-cube mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'obj'" class="fal fa-cube mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'fbx'" class="fal fa-cube mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == '3ds'" class="fal fa-cube mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'collada'" class="fal fa-cube mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'iges'" class="fal fa-cube mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'step'" class="fal fa-cube mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'vrml'" class="fal fa-cube mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'x3d'" class="fal fa-cube mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'usdz'" class="fal fa-cube mediaplaceholdercontenticon"></i>
            <i v-else-if="mediafilextension == 'eps'" class="fal fa-file-image mediaplaceholdercontenticon"></i>
            <i v-else class="fal fa-file mediaplaceholdercontenticon"></i>
          </div>
          <div>{{ mediafilextension }}</div>
        </div>

      </div>

    </div>
  </div>
</template>

<style scoped>
  .vue-component-mediaplayer{
    cursor:pointer;
    width:200px;
    min-width:200px;
    max-width:200px;
    height:200px;
    min-height:200px;
    max-height:200px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-drag: none;
  }
  .vue-component-mediaplayer-content{
    position: relative;
    overflow: hidden;
    background-color: white;
    border-radius: 4px !important;
    transition: .2s all ease-out;
  }
  .vue-component-mediaplayer-content:hover{
    transform: scale(1.02);
    filter: brightness(90%);
  }

  .iconMarginRight{
    margin-right:25px;
  }

  .vue-component-mediaplayer-content .preview-epub{
    width:200px;
    height:200px;
    border-left: 1px solid #525659;
    border-right: 1px solid #525659;
    border-top: 1px solid #525659;
    border-bottom:none;
  }

  .vue-component-mediaplayer-content .preview-audio{
    width: 200px;
    padding-top: 20px;
    padding-bottom: 0px;
    background: radial-gradient(#ffffff, #cfcfcfb8) rgb(255, 255, 255);
    height: 200px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .vue-component-mediaplayer-content audio{
    margin-top:7px;
    border-radius: 0px;
    width: 175px;
  }

  .mediaplayer-previewimg {
    background-repeat: no-repeat;
    background-repeat-x: no-repeat;
    background-repeat-y: no-repeat;
    background-position: center center;
    background-position-x: center;
    background-position-y: center;
    min-width:200px;
    max-width:200px;
    height:200px;
    width:200px;
    min-height:200px;
    max-height:200px;
    object-fit:contain;
  }

  .agreementPdfLoading{
    min-width:200px;
    max-width:200px;
    height:200px;
    width:200px;
    min-height:200px;
    max-height:200px;
    background-color:#FAFBFD;
    padding:25px;
  }

  .previewVideo{
    min-width:200px;
    max-width:200px;
    height:200px;
    width:200px;
    min-height:200px;
    max-height:200px;
  }

  .mediaplaceholdercontent{
    min-width:200px;
    max-width:200px;
    height:200px;
    min-height:200px;
    max-height:200px;
    text-align:center;
    background-color:#F7F7F7;
    font-size: 13px;
    line-height:16px;
    transition: .2s all ease-out;
  }
  .mediaplaceholdercontent:hover {
    transform: scale(1.02);
    filter: brightness(90%);
  }

  .mediaplaceholdercontenticon{
    margin-top:25px;
    font-size:55px;
    padding-top:30px;
    padding-bottom:20px;
  }
  .mediaplaceholdercontenttextpadding{
    margin:15px;
  }

</style>
