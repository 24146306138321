<script>
export default {
  name: 'WebhooksUrlRenderer',
  data() {
    return {
      field: null,
      webhook: null,
    };
  },
  created() {
    this.field = this.params.colDef.field;
    this.webhook = this.params.data;
  },
  methods: {},
  computed: {},
};
</script>

<template>
  <div class="container">
    <span> {{ webhook.destination_url }} </span>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  align-items: center;
  width: 100%;
  white-space: normal;
  font-weight: 600;
}
</style>
