<script setup>
import { reactive, ref, watch, computed } from 'vue';
import { storeToRefs } from 'pinia';
import JSConfetti from 'js-confetti';
import { useMagicAnnotationsStore } from '@/stores/magic-annotations-store.js';
import HrbrButton from '@components/ui/HrbrButton.vue';
import { delay } from '@/utils/helpers/functions.js';

const magicAnnotationsStore = useMagicAnnotationsStore();
const {
  consentModalOpened,
  isFinishConsentModal,
  firstPageNum,
  pageMode,
  mageUnavailable,
  suggestionsList,
  uploadedFile,
  termsAgreed,
  firstAnnotationRef,
  documentRef,
  scrolledToFirstRef,
  suggestionsLoaded,
  topNotificationText,
  isFirstSigning
} = storeToRefs(magicAnnotationsStore);

const titleText = ref('Our AI assistant is processing the document...wait for the magic to appear!');
const buttonText = ref('');

watch(documentRef, (value) => {
  if (value) {
    documentRef.value.addEventListener('scrollend', onScrollEnd, { once: true });
  }
});

watch(isFinishConsentModal, async (value) => {
  if (value) {
    buttonText.value = 'Finish';
  }
});

watch(uploadedFile, async (value) => {
  if (!value) return;

  titleText.value = 'Our AI assistant is processing the document...wait for the magic to appear!';
  await getSuggestionsAndShowMessages();
  if (suggestionsList.value.length > 0 && isFirstSigning.value) {
    startConfetti();
  }
  await runAnimation();
});

watch(suggestionsLoaded, async (value) => {
  if (value && !consentModalOpened.value) {
    topNotificationText.value = getNotificationText();
    await runAnimation();
  }
});

watch(firstAnnotationRef, async (value) => {
  if (value) {
    scrollToFirstElement();
  }
});

const doneButton = reactive({
  show: true,
  text: buttonText,
  disabled: computed(() => isFinishConsentModal.value && !termsAgreed.value),
  action: async () => {
    consentModalOpened.value = false;
    if (isFinishConsentModal.value) {
      await magicAnnotationsStore.saveDocument();
    }
  },
});

const onScrollEnd = () => {
  scrolledToFirstRef.value = true;
}

const scrollToFirstElement = () => {
  const top = firstAnnotationRef.value.getBoundingClientRect().top;
  const topOffset = 200;
  documentRef.value.scroll({
    behavior: 'smooth',
    top: top - topOffset < 0 ? 1 : top - topOffset,
  });
};

const onModalClose = async () => {
  consentModalOpened.value = false;
};

const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return {
    hours,
    minutes,
  };
};

const getNotificationTimeText = () => {
  const {
    hours,
    minutes,
  } = toHoursAndMinutes(Math.round(suggestionsList.value.length * 0.5));
  return `${hours > 0 ? `${hours} hour${hours === 1 ? '' : 's'} and ` : ''}${minutes} minutes`;
};

const getNotificationText = () => {
  let text = '';
  if (!mageUnavailable.value) {
    text =
      suggestionsList.value.length > 5
        ? `Document processed! We found ${suggestionsList.value.length} fields, saving you ${getNotificationTimeText()}!`
        : `Document processed! We found ${suggestionsList.value.length} fields!`;

    text = suggestionsList.value.length === 0
      ? 'We were not able to find any fields, click anywhere in your document to get started'
      : text;
  }

  return text;
};

const getSuggestionsAndShowMessages = async () => {
  await magicAnnotationsStore.getDocumentSuggestions();
  pageMode.value = 'edit';

  titleText.value = getNotificationText();

  if (!mageUnavailable.value) {
    buttonText.value = suggestionsList.value.length === 0 ? 'Continue' : 'Show me the magic!'

    firstPageNum.value = suggestionsList.value[0]?.page_number || 0;
  } else {
    buttonText.value = 'Continue';
  }
};


const runAnimation = async () => {
  const pageSuggestions = magicAnnotationsStore.getPageSuggestions(firstPageNum.value);

  for (let annotation of pageSuggestions) {
    annotation.animation_visible = true;
    await delay(400);
  }
  // Finish animation
  for (let annotation of pageSuggestions) {
    annotation.animation_visible = false;
    annotation.animated = false;
  }
};

const startConfetti = () => {
  const jsConfetti = new JSConfetti();
  jsConfetti.addConfetti({
    emojis: ['⭐', '✨', '💫', '🌟'],
    emojiSize: 20,
    confettiNumber: 300,
  });
  setTimeout(() => {
    jsConfetti.addConfetti({
      emojis: ['⭐', '✨', '💫', '🌟'],
      emojiSize: 20,
      confettiNumber: 300,
    });
  }, 300);
  setTimeout(() => {
    jsConfetti.addConfetti({
      emojis: ['⭐', '✨', '💫', '🌟'],
      emojiSize: 20,
      confettiNumber: 300,
    });
  }, 800);
};
</script>

<template>
  <b-modal
    :active="consentModalOpened"
    :on-cancel="onModalClose"
    trap-focus
    custom-class="only-signer-modal"
    aria-role="dialog"
    aria-label="I'm only signer consent modal"
    close-button-aria-label="Close"
    aria-modal
  >
    <template #default="props">
      <div
        class="modal-card"
      >
        <section class="modal-card-body">
          <h3 v-if="titleText && !isFinishConsentModal">
            <i
              :class="['fa-regular', 'fa-sparkles', suggestionsLoaded ? '' : 'animated']"
            ></i>
            <span>{{titleText}}</span>
          </h3>
          <b-checkbox
            v-if="isFinishConsentModal"
            v-model="termsAgreed"
          >
            <span class="checkbox-text">
              Please check this box to agree to the <a class="terms-link" href="https://www.harbourshare.com/e-signature-consent" target="_blank">Terms and Conditions</a> of Electronic Signature Consent.
            </span>
          </b-checkbox>

          <HrbrButton
            v-if="buttonText"
            :button="doneButton"
            class-name="confirm-btn"
          />
        </section>
      </div>
    </template>
  </b-modal>
</template>

<style lang="postcss" scoped>
.only-signer-modal {
  &:deep(.modal) {
    &-content {
      width: 320px;
    }
    &-card-body {
      padding: 20px;
    }
    &-card {
      width: 100%;
      border-radius: 8px;
    }
  }
  h3 {
    display: flex;
    align-items: baseline;
    gap: 8px;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 600;
    color: #333;
    white-space: break-spaces;
    i {
      rotate: 180deg;
      background: linear-gradient(90deg, #7715B3 0%, #33094D 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 18px;
      &.animated {
        animation: leaves 1s ease-in-out infinite alternate;
      }
    }
  }
  .b-checkbox {
    align-items: flex-start;
    margin: 0  0 12px 0;
    &:hover:deep(input[type='checkbox']:not(:disabled) + .check ) {
      border-color: #1355FF;
    }
    :deep(input[type='checkbox']:checked + .check) {
      border-color: #1355FF;
      background-color: #1355FF;
    }
    :deep(input[type=checkbox]:focus + .check) {
      box-shadow: none;
    }
    :deep(input + .check) {
      width: 20px;
      height: 20px;
      border-color: #CCCCCC;
      border-radius: 2px;
    }
  }
  .checkbox-text {
    font-size: 14px;
    color: #333;
  }
  .terms-link {
    color: #1355FF;
    &:hover {
      color: #0133B7;
    }
  }
  .confirm-btn {
    height: 34px;
    text-align: right;
    >>> .section-btn {
      border: none;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      border-radius: 8px;
      background: linear-gradient(90deg, #7715B3 0%, #B015B3 100%);
      &:hover {
        background: linear-gradient(90deg, #B015B3 0%, #7715B3 100%);
      }
    }
  }

  @keyframes leaves {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1.5);
    }
  }
}
</style>
