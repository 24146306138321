<script>
import { nextTick } from 'vue';
export default {
  name: 'ColumnEditor',
  setup() {
    return {};
  },
  data() {
    return {
      valueToEdit: null,
      originalValue: null,
      valueIsArray: false,
      id: null,
      value: null,
      editorRefs: [],
    };
  },
  mounted() {
    this.id = this.params.data.id;
    this.originalValue = this.params.value;

    this.valueToEdit = this.params.value?.extraction;

    if (Array.isArray(this.valueToEdit)) {
      this.valueIsArray = true;
    } else {
      nextTick(() => this.$refs.input.focus());
    }
  },
  methods: {
    getValue() {
      // Used to pass the value back to the renderer, do not remove
      return this.value;
    },
    isCancelAfterEnd() {
      if (!this.valueToEdit) return true;
      if (!this.hasCallbackFunction) return true;

      if (this.valueIsArray) {
        this.updateValuesArray();
      }

      this.value = {
        ...this.originalValue,
        extraction: this.valueToEdit,
      };

      // Update the value on server
      const column = this.params.colDef.field;

      const newValue = { extraction: this.value.extraction };
      const params = {
        rowId: this.id,
        columnTitle: this.params.colDef.headerName,
        originalValue: this.originalValue,
        value: newValue,
        column,
      };

      if (!this.isAllowedSaving) return true;

      this.value = newValue;
      this.params.callback(params);
      return false;
    },

    trackArrayUserInput(e, index) {
      this.valueToEdit[index] = e.target.value;
    },

    updateValuesArray() {
      const newValues = [];
      this.valueToEdit.forEach((value) => {
        if (!value) return;
        newValues.push(value);
      });
      this.valueToEdit = newValues;
    },

    addAnotherEditor() {
      this.valueToEdit.push('');
      const index = this.valueToEdit.length - 1;
      nextTick(() => {
        this.$refs.editorRefs[index].focus();
        this.$refs.arrayEditors.scrollTop = this.$refs.arrayEditors.scrollHeight;
      });
    },

    getEditorRef(index) {
      return `editor-${index}`;
    },
    handleKeyDown(e) {
      const key = e.which || e.keyCode;
      if (key === 13) {
        if (!e.metaKey) {
          this.$refs.input.value += '\r\n';
          e.preventDefault();
        } else {
          this.params.stopEditing();
        }
      }
    },
  },
  computed: {
    isAllowedSaving() {
      console.debug('cellclick', this.value, this.value.extraction, this.originalValue);
      if (!this.value || !this.value.extraction) return false;
      if (this.originalValue?.extraction === this.value.extraction) return false;
      return true;
    },
    hasCallbackFunction() {
      // Call the callback here
      return !!this.params.callback && typeof this.params.callback === 'function';
    },
    getArrayValues() {
      return this.valueToEdit;
    },
    generateKey() {
      return Math.random() * 100;
    },
  },
};
</script>

<template>
  <div class="editor-main-content" ref="arrayEditors">
    <!-- Non array data, one editor -->
    <textarea class="editor" v-if="!this.valueIsArray" v-model="valueToEdit" ref="input" @keydown="handleKeyDown"></textarea>

    <!-- For editing arrays, a list of editors -->
    <div v-else class="array-editor">
      <textarea
        class="editor"
        v-for="(item, index) in getArrayValues"
        :value="item"
        :key="index"
        ref="editorRefs"
        @blur="updateValuesArray"
        @input="(e) => trackArrayUserInput(e, index)"></textarea>

      <div class="add-item" @click="addAnotherEditor"><i class="fal fa-plus"></i>Add item</div>
    </div>
  </div>
</template>

<style scoped>
.editor-main-content {
  display: flex;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  max-height: 85%;
  min-height: 85%;
  height: 100%;
  width: 100%;
}
.add-item {
  cursor: pointer;
  transition: all 250ms ease;
  border-radius: 3px;
  padding: 5px;
  line-height: 1em;
  text-align: right;
}
.add-item:hover {
  background-color: #eee;
}
.add-item i {
  margin-right: 5px;
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.title-edit-icon {
  margin: 4px 5px;
  cursor: pointer;
}
.hrbr-grid-cell {
  height: 100% !important;
  width: 100% !important;
}
.editor {
  min-height: 75px;
  font-size: 12px;
  color: #666;
  width: 100%;
  border-radius: 5px;
  outline: none;
  padding: 4px;
  resize: none;
  border: 1px solid #eaeaea;

  scrollbar-width: thin;
  scrollbar-color: #555 #eee;
}
.editor:focus {
  -webkit-box-shadow: 0px 0px 2px 2px rgba(45, 102, 157, 0.3);
  -moz-box-shadow: 0px 0px 2px 2px rgba(45, 102, 157, 0.3);
  box-shadow: 0px 0px 2px 2px rgba(45, 102, 157, 0.3);
  border: 1px solid #2d669ddd !important;
  outline: none;
}
.editor::-webkit-scrollbar {
  width: 8px;
}
.editor::-webkit-scrollbar-track {
  background: #eee;
}

.editor::-webkit-scrollbar-thumb {
  background: #cbcbcb;
  border-radius: 5px;
}

.editor::-webkit-scrollbar-thumb:hover {
  background: #333;
}
</style>
