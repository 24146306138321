<script setup>
import { ToastProgrammatic as Toast } from 'buefy';
import Vue, { onMounted, ref } from 'vue';

const emit = defineEmits(['close']);

const fullName = ref('');
const isUpdating = ref(false);

const cancelButton = {
  text: 'Set up later',
  styleType: 'secondary',
  style: {
    marginRight: '6px',
  },
};

const saveButton = {
  text: 'Save',
  styleType: 'primary',
};

const updateName = async () => {
  try {
    isUpdating.value = true;
    await updateNameRequest(fullName.value);

    Toast.open({
      message: 'Success - name was updated',
      type: 'is-success',
      position: 'is-top',
      duration: 2500,
    });
  } catch (err) {
    console.error(err);

    Toast.open({
      message: `Something went wrong...`,
      position: 'is-top',
      type: 'is-danger',
      duration: 2500,
    });
  } finally {
    isUpdating.value = false;
  }
};

const updateNameRequest = async (fullName = '', preferredName = '') => {
  const res = await Vue.prototype.$harbourData.post('data?settings-updatename', {
    requesttype: 'settings-updatename',
    fullname: fullName,
    preferredname: preferredName,
  });
  return res.data;
};

const closeModal = () => emit('close');

const onCancelClick = () => {
  closeModal();
};

const onSaveClick = () => {
  updateName();
  closeModal();
};

const setModalShownLocal = () => {
  localStorage.setItem('harbourUserNameModal', true);
};

onMounted(() => {
  setModalShownLocal();
});
</script>

<template>
  <div class="hrbr-user-name-modal modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Update your account name</p>
    </header>
    <section class="modal-card-body">
      <div class="hrbr-user-name-modal__description">
        Please update your name to complete account setup.
      </div>
      <div class="hrbr-user-name-modal__fields">
        <b-input v-model.trim="fullName" placeholder="Full name" icon-pack="fal" :icon="'pen'" data-testid="full-name">
        </b-input>
      </div>
    </section>
    <footer class="modal-card-foot">
      <HrbrButton :button="cancelButton" @click="onCancelClick" />
      <HrbrButton :button="saveButton" @click="onSaveClick" />
    </footer>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-user-name-modal {
  &__description {
    margin-bottom: 10px;
  }
}
</style>
