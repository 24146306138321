<script setup>
</script>

<template>
  <div class="name-cell">
    <div class="expander">
      <i v-if="params.node.expanded" class="fa-solid fa-caret-down"></i>
      <i v-else class="fa-solid fa-caret-right"></i>
    </div>
    <div class="group-name">
      <i :class="['fa-regular', `fa-${params.data.icon}`]"></i>
      <span>{{ params.data.name }}</span>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.name-cell {
  display: flex;
  align-items: center;
}

.expander {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  margin-right: 6px;
  cursor: pointer;
}

.group-name {
  i {
    margin-right: 8px;
  }
}
</style>
