<script>
import { ref } from 'vue';
import { mapState } from 'pinia';
import { useHarbourStore } from '@/stores/harbour-store';
import { useDashboardStore } from '@/stores/dashboard-store';
import { useLinkObject } from '@/pages/Dashboard/composables/LinkObject'

export default {
  name: 'BrandRenderer',
  setup() {
    return {
      harbourStore: useHarbourStore(),
      dashboardStore: useDashboardStore(),
    };
  },
  data() {
    return {
      field: null,
      linkObject: null,
      isActive: false,
      profilePics: null,
      logo: ref(null),
      isEmails: null,
      domain: null,
    };
  },
  created() {
    this.field = this.params.colDef.field;
    this.linkObject = useLinkObject(this.params.data);
    this.domain = this.linkObject.getBrandDomain;
    this.isEmails = this.linkObject?.authMethod?.authmode === 'EMAILS';
  },
  beforeUnmount() {
    this.linkObject = null;
  },
  async mounted() {
    this.linkObject.getBrandImage();
    this.logo = null;
  },
  methods: {
    expandDetails() {
      this.dashboardStore.expandDetails(this.linkObject.id);
    },
    getBrand(domain) {
      return this.signerBrands.find((brand) => brand.domain === domain)
    }
  },
  computed: {
    ...mapState(useDashboardStore, ['signerBrands']),
    getBrandImage() {
      return this.signerBrands.find((brand) => brand.domain === this.domain.value)
    }
  },
};
</script>

<template>
  <div class="brand-container" @click.stop="expandDetails">
    <div class="brand-image-container" >
      <img
        v-if="signerBrands.find((brand) => brand.domain === domain)?.image"
        :src="signerBrands.find((brand) => brand.domain === domain)?.image"
        class="brand-image" />

      <div class="brand-image public" v-else-if="linkObject?.authMethod?.authmode !== 'EMAILS'" title="Public link">
        <i class="fal fa-globe default-brand-icon"></i>
      </div>

      <div class="brand-image public" v-else title="Email authenticated link">
        <i class="fal fa-envelope default-brand-icon"></i>
      </div>
    </div>
  </div>
</template>

<style scoped>
.brand-container {
  border: none !important;
  line-height: 1em;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #787878;
  font-size: 14px;
  padding: 0;
  margin: 0;
}
.brand-image-container {
  height: 40px;
  width: 40px;
  background-color: #2f5a8844;
  overflow: hidden;
  border-radius: 50%;
  margin: 5px;
  box-shadow: rgb(0 0 0 / 15%) 0px 2px 1px 0px;
  border: 6px solid white;
  top: 10px;
  transition: all 0.14s ease-in-out;
  background-color: white;
  cursor: pointer;
}
.brand-image-container:hover {
  border: 5px solid white;
  background-color: #00000003;
  box-shadow: rgb(0 0 0 / 5%) 1px 3px 2px 0px;
  transform: scale(1.01);
}
.brand-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.default-brand-icon {
  font-size: 26px;
  font-weight: 200 !important;
  color: #bababa;
}
.public {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
