<script setup>
import Vue, { computed, ref, nextTick } from 'vue';
import { storeToRefs } from 'pinia';
import { DialogProgrammatic as Dialog } from 'buefy';

import { useWorkflowsStore } from '@/stores/workflows-store';

const workflowsStore = useWorkflowsStore();
const { activeWorkflow, workflows, mappings, isWorkflowsListReady } = storeToRefs(workflowsStore);
const { setActiveWorkflow, duplicateWorkflow, resetActiveWorkflow } = workflowsStore;
const workflowId = ref(null);
const overflow = ref(null);

const init = (params) => {
  workflowId.value = params.data?.workflow?.id;
};

const isCurrentWorkflow = computed(() => {
  return activeWorkflow.value?.id === workflowId.value;
});

const handleClick = () => {
  workflowsStore.resetActiveWorkflow();
  activeWorkflow.value = null;
  const workflow = workflows.value.find((wf) => wf.id === workflowId.value);
  nextTick(() => setActiveWorkflow(workflow));
};

const handleMenuClick = () => {
  overflow.value.toggle();
};

const handleRename = (params) => {
  Dialog.prompt({
    title: 'Rename approval workflow',
    message: 'Enter a name for your new workflow',
    confirmText: 'Create',
    cancelText: 'Cancel',
    onConfirm: async (newName) => {
      activeWorkflow.value.set('name', newName);
    },
  });
};

const handleDelete = async () => {
  Dialog.confirm({
    title: 'Are you sure?',
    message: 'Deleting this workflow will disconnect it from all templates',
    confirmText: 'Delete',
    cancelText: 'Cancel',
    type: 'is-danger',
    onConfirm: async () => {
      _deleteWorkflow();
    },
  });
};

const _deleteWorkflow = async () => {
  mappings.value = mappings.value.filter((m) => m.workflow_id !== workflowId.value);
  const workflow = workflows.value.find((wf) => wf.id === workflowId.value);
  workflows.value = workflows.value.filter((wf) => wf.id !== workflowId.value);

  resetActiveWorkflow();
  isWorkflowsListReady.value = false;
  const result = await Vue.prototype.$harbourData.delete(`/api/workflows/${workflowId.value}`);
  if (result.status !== 202) {
    workflows.value.push(workflow);
    return Dialog.alert({
      title: 'Error',
      message: 'Failed to delete workflow',
      type: 'is-danger',
    });
  }
  isWorkflowsListReady.value = true;
};

const handleDuplicate = () => {
  Dialog.prompt({
    title: 'Duplicate workflow',
    message: 'Enter a name for your new workflow',
    confirmText: 'Create',
    cancelText: 'Cancel',
    onConfirm: (newName) => {
      const workflow = workflows.value.find((wf) => wf.id === workflowId.value);
      duplicateWorkflow(workflow, newName);
    },
  });
};
</script>

<template>
  <div class="wf-menu-cell" :ref="init(params)" :class="{ 'active': isCurrentWorkflow }" @click="handleClick" v-if="params.value">
    <div class="left-side">
      <i class="fal fa-project-diagram"></i>
      <div class="wf-name">{{ params.value }}</div>
    </div>

    <b-dropdown class="overflow-menu" aria-role="list" ref="overflow" position="is-bottom-left" append-to-body>
      <template #trigger="{ active }">
        <button class="overflow-menu-button" @click="handleMenuClick">
          <i class="fal fa-ellipsis-v" title="More options" style="border: none;"></i>
        </button>
      </template>
      <b-dropdown-item aria-role="listitem" class="dropdown-item" @click="handleRename(params)">
        <i class="fal fa-pencil"></i>Rename
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem" class="dropdown-item" @click="handleDuplicate">
        <i class="fal fa-clone"></i>Duplicate
      </b-dropdown-item>
      <b-dropdown-item aria-role="listitem" class="dropdown-item" @click="handleDelete" v-if="workflows.length > 1">
        <i class="fal fa-trash"></i>Delete
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<style scoped>
.wf-menu-cell {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 26px;
  border: 1px solid transparent;
  height: 100%;
  cursor: pointer;
}
.left-side {
  display: flex;
  align-items: center;
}
.left-side i {
  margin-right: 15px;
  color: #999;
  transform: rotate(59deg);
}

.overflow-menu-button {
  border: none !important;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  width: 25px;
  border-radius: 50%;
}
.active {
  border: 1px solid #1355ff;
}
.dropdown-item i {
  margin-right: 10px;
}
</style>