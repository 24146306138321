<script>
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import { useHarbourStore } from '@/stores/harbour-store';

export default {
  name: 'DraftsCreatorRenderer',

  setup() {
    const draftsStore = useDraftsStore();
    const harbourStore = useHarbourStore();
    return {
      draftsStore,
      harbourStore,
    };
  },

  data() {
    return {};
  },

  computed: {
    creator() {
      return this.params.data.createdBy;
    },
    name() {
      return this.draftsStore.emailsToNames[this.email] || null;
    },
    email() {
      return this.creator;
    },
    profileImageUrl() {
      const profile = this.harbourStore.profilePictures.find((i) => i.email === this.email);
      return profile?.profileImageUrl;
    },
  },

  methods: {
    getInitial(name, email) {
      if (name) return name[0].toUpperCase();
      else if (email) return email[0].toUpperCase();
      return '*';
    },
  },
};
</script>

<template>
  <div class="draft-creator-column">
    <div class="draft-creator-column__container">
      <div class="draft-creator-column__image">
        <img v-if="profileImageUrl" :src="profileImageUrl" class="draft-creator-column__img" />
        <div v-else class="draft-creator-column__initials">
          {{ getInitial(name, email) }}
        </div>
      </div>

      <div class="draft-creator-column__details">
        <span class="draft-creator-column__name">{{ name }}</span>
        <span class="draft-creator-column__email">{{ email }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.draft-creator-column {
  padding: 0 5px;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__image {
    --size: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background-color: #849cb8;
    flex-shrink: 0;
    overflow: hidden;
    margin-right: 10px;
  }

  &__img {
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    pointer-events: none;
    user-select: none;
  }

  &__initials {
    font-size: 14px;
    color: #fff;
    line-height: 1;
  }

  &__details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    line-height: 1.1;
    overflow: hidden;
    white-space: nowrap;
  }

  &__name {
    font-size: 14px;
    color: #333;
    padding-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__email {
    font-size: 12px;
    color: #555;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
