<script setup>
import { AgGridVue } from 'ag-grid-vue';
import { DialogProgrammatic as Dialog, ToastProgrammatic as Toast } from 'buefy';
import { storeToRefs } from 'pinia';
import Vue, { markRaw, ref } from 'vue';

import filesystemApiService from '@/services/filesystem-api-service';
import { useHarbourStore } from '@/stores/harbour-store';
import { publishEvent } from '@/utils/bus';
import debounce from '@/utils/debounce';
import { useFolderActions } from './composables/use-folder-actions';
import { useFoldersMenuStore } from './stores/folders-menu-store';
import { useSidebarStore } from './stores/sidebar-store';

const EDIT_PERMISSION = 'permissions.filesystem.folder.edit';
const SHARED_WITH_ME = 'SHAREDWITHME';

const harbourStore = useHarbourStore();
const sidebarStore = useSidebarStore();

const foldersMenuStore = useFoldersMenuStore();
const { myFolders } = storeToRefs(harbourStore);

const {
  handleEditFolderName,
  handleDuplicateFolder,
  handleShareFolder,
  handleAddFolder,
  handleDeleteFolder,
  handleDownloadFolderCsv,
  handleDownloadFolderZip,
  isPermissionAvailable,
  getParentFolderPermissions,
  expandNodesToTarget,
} = useFolderActions();

const agColumnDefs = [];
const agDefaultColDef = {
  flex: 1,
  filter: true,
  resizable: false,
  suppressMovable: true,
  getQuickFilterText: (params) => params.data.name,
};

const agGridOptions = {
  treeData: true,
  rowBuffer: 4,
  animateRows: true,
  suppressContextMenu: true,
  groupDefaultExpanded: 0,
  rowClassRules: {
    'ag-row--active': (params) => {
      return params.data?.id === harbourStore.currentFolder;
    },
    'ag-row--not-draggable': (params) => {
      const folderId = params.data?.id ?? '';
      const origin = params.data?.origin;
      const special = ['#home', '#shared'];

      const containsSpecial = special.includes(folderId);
      const isSharedFolder = origin === SHARED_WITH_ME;

      if (containsSpecial) return true;
      if (isSharedFolder) return true;
      return false;
    },
  },
};

const agAutoGroupColumnDef = {
  headerName: 'Folders',
  rowDrag: true,
  cellRendererParams: {
    suppressCount: true,
    innerRenderer: 'HrbrSidebarPaneFoldersMenuRenderer',
    addFolderClicked(folder) {
      handleAddFolder(folder);
    },
    duplicateFolderClicked(folder) {
      handleDuplicateFolder(folder);
    },
    shareFolderClicked(folder) {
      handleShareFolder(folder);
    },
    downloadFolderClicked(folder, type) {
      const handlers = {
        csv: handleDownloadFolderCsv,
        zip: handleDownloadFolderZip,
      };
      const handler = handlers[type] ?? null;
      if (handler) handler(folder);
    },
    deleteFolderClicked(folder) {
      handleDeleteFolder(folder);
    },
    editFolderBlured(folder, newFolderName) {
      handleEditFolderName(folder, newFolderName);
    },
  },
  cellClassRules: {
    'hover-over': (params) => {
      return params.node === potentialParent;
    },
  },
};

const overlayNoRowsTemplate = `<div class="hrbr-sidebar-pane-folders-menu__no-folders">Loading folders...</div>`;

const getRowId = (params) => params.data.id;
const getDataPath = (params) => params.folderPath;

const onGridReady = (params) => {
  foldersMenuStore.setAgGridApi(markRaw(params.api));
  foldersMenuStore.setAgGridColumnApi(markRaw(params.columnApi));
};

const isGroupOpenByDefault = (params) => {
  return params.key === '#home';
};

const onRowClicked = (params) => {
  const folderId = params.data.id;
  const target = params.event.target;
  const isActionsClicked = !!target.closest('[data-folder-actions]');
  if (isActionsClicked) return;
  const special = ['#shared'];
  const containsSpecial = special.includes(folderId);
  if (containsSpecial) {
    expandFolder(folderId);
    return;
  }
  setCurrentFolder(folderId, params.node);
  expandFolder(folderId);
  sidebarStore.closeRightSidebar();
};

const expandFolder = (folderId) => {
  const rowNode = foldersMenuStore.agGridApi.getRowNode(folderId);
  if (!rowNode) return;
  const hasChildren = rowNode.allChildrenCount !== null;
  if (hasChildren) rowNode.setExpanded(true);
};

const setCurrentFolder = (folderId, selectedNode) => {
  harbourStore.previousFolder = harbourStore.currentFolder;
  const currentActiveRow = foldersMenuStore.agGridApi.getRowNode(harbourStore.currentFolder);
  const updatedRows = [selectedNode.data];
  if (currentActiveRow) updatedRows.push(currentActiveRow.data);
  harbourStore.setCurrentFolder(folderId);
  foldersMenuStore.agGridApi.applyTransactionAsync({ update: updatedRows }, () => publishEvent('folders:selected'));
  harbourStore.realtimeUserSync();
};

/**
 * Drag & drop functionality
 */
const isDragging = ref(false);

const rowDragText = (params) => {
  const { rowNode } = params;
  const name = rowNode.data.name;
  return name || 'Folder';
};

const refreshRows = (api, rowsToRefresh) => {
  // refresh these rows only.
  // because the grid does change detection, the refresh
  // will not happen because the underlying value has not
  // changed. to get around this, we force the refresh,
  // which skips change detection.
  let params = {
    rowNodes: rowsToRefresh,
    force: true,
  };
  api.refreshCells(params);
};

const arePathsEqual = (path1, path2) => {
  if (path1.length !== path2.length) return false;
  let equal = true;
  path1.forEach((item, index) => {
    if (path2[index] !== item) {
      equal = false;
    }
  });
  return equal;
};

const isSelectionParentOfTarget = (selectedNode, targetNode) => {
  let children = [...(selectedNode.childrenAfterGroup || [])];
  if (!targetNode) return false;
  while (children.length) {
    const node = children.shift();
    if (!node) continue;
    if (node.key === targetNode.key) return true;
    if (node.childrenAfterGroup && node.childrenAfterGroup.length) {
      children.push(...node.childrenAfterGroup);
    }
  }
  return false;
};

const isSelectionMovingAllowed = (selectedNode, targetNode) => {
  const isEditPermission = isPermissionAvailable(EDIT_PERMISSION);
  if (!isEditPermission) return false;

  const selectedFolderId = selectedNode.data.id;
  const selectedFolderOrigin = selectedNode.data.origin;
  const targetFolderId = targetNode.data.id;
  const targetFolderOrigin = targetNode.data.origin;

  const special = ['#home', '#shared'];
  const isSpecialSelected = special.includes(selectedFolderId);
  const isSharedSelected = selectedFolderOrigin === SHARED_WITH_ME;
  const isSpecialTarget = ['#shared'].includes(targetFolderId);
  const isSharedTarget = targetFolderOrigin === SHARED_WITH_ME;

  if (isSpecialSelected) return false;
  if (isSharedSelected) return false;
  if (isSpecialTarget) return false;
  if (isSharedTarget) return false;

  return true;
};

// This updates the filePath locations in our data, we update the data
// before we send it to AG Grid
const moveToPath = (newParentPath, node, allUpdatedNodes) => {
  // last part of the file path is the folder name
  let oldPath = node.data.folderPath;
  let folderName = oldPath[oldPath.length - 1];
  let newChildPath = newParentPath.slice();
  let newParentName = newChildPath[newChildPath.length - 1];
  newChildPath.push(folderName);
  node.data.folderPath = newChildPath;
  node.data.parent = newParentName;
  allUpdatedNodes.push(node.data);
  if (node.childrenAfterGroup) {
    node.childrenAfterGroup.forEach((childNode) => {
      moveToPath(newChildPath, childNode, allUpdatedNodes);
    });
  }
};

let potentialParent = null;
const setPotentialParentForNode = (api, overNode) => {
  let newPotentialParent = null;

  if (overNode) newPotentialParent = overNode;
  else newPotentialParent = null;

  let alreadySelected = potentialParent === newPotentialParent;
  if (alreadySelected) return;

  // we refresh the previous selection (if it exists) to clear
  // the highlighted and then the new selection.
  let rowsToRefresh = [];
  if (potentialParent) rowsToRefresh.push(potentialParent);
  if (newPotentialParent) rowsToRefresh.push(newPotentialParent);
  potentialParent = newPotentialParent;
  refreshRows(api, rowsToRefresh);
};

const onRowDragMove = (event) => {
  setPotentialParentForNode(event.api, event.overNode);
};

const onRowDragLeave = (event) => {
  // clear node to highlight
  setPotentialParentForNode(event.api, null);
};

const onRowDragEnd = (event) => {
  if (!potentialParent) return;

  let movingData = event.node.data;

  let newParentData = potentialParent.data;
  let newParentPath = newParentData ? newParentData.folderPath : [];
  let needToChangeParent = !arePathsEqual(newParentPath, movingData.folderPath);

  // check we are not moving a folder into a child folder
  let isParentOfTarget = isSelectionParentOfTarget(event.node, potentialParent);
  // check if it's allowed to drag the target folder to the destination folder
  let isMovingAllowed = isSelectionMovingAllowed(event.node, potentialParent);

  let invalidMode = isParentOfTarget || !isMovingAllowed;
  if (invalidMode) {
    Toast.open({
      duration: 2500,
      message: `Not allowed to move selected folder or to move to given destination folder -- thanks!`,
      position: 'is-top',
      type: 'is-warning',
    });
  }

  if (needToChangeParent && !invalidMode) {
    const isPermissionsMatch = checkPermissionsMatch(movingData, newParentData);

    if (!isPermissionsMatch) {
      confirmMoveFolder({
        movingData,
        newParentData,
        newParentPath,
        eventNode: event.node,
      });
    } else {
      moveFolder({
        movingData,
        newParentData,
        newParentPath,
        eventNode: event.node,
      });
    }
  }
  // clear node to highlight
  setPotentialParentForNode(event.api, null);
};

const checkPermissionsMatch = (movingData, newParentData) => {
  const creatorEmail = harbourStore.contextDict.systememail;

  const collaborators = [...movingData.collaborators].sort();
  const viewers = [...movingData.viewers].sort();
  let parentCollaborators = [];
  let parentViewers = [];

  if (newParentData.id === '#home') {
    parentCollaborators = [creatorEmail];
  } else {
    parentCollaborators = [...newParentData.collaborators].sort();
    parentViewers = [...newParentData.viewers].sort();
  }

  if (collaborators.length !== parentCollaborators.length) return false;
  if (viewers.length !== parentViewers.length) return false;

  let equalCollaborators = true;
  for (const [idx, value] of collaborators.entries()) {
    if (parentCollaborators[idx] !== value) {
      equalCollaborators = false;
      break;
    }
  }
  let equalViewers = true;
  for (const [idx, value] of viewers.entries()) {
    if (parentViewers[idx] !== value) {
      equalViewers = false;
      break;
    }
  }
  return equalCollaborators && equalViewers;
};

const confirmMoveFolder = ({ movingData, newParentData, newParentPath, eventNode }) => {
  Dialog.confirm({
    title: 'Drag & drop folder',
    message: `Folder permissions do not match. In this case, the permissions for the dropped folder will be updated. Do you want to continue?`,
    type: 'is-warning',
    onConfirm: () => {
      moveFolder({
        movingData,
        newParentData,
        newParentPath,
        eventNode,
        isPermissionsMatch: false,
      });
    },
  });
};

const moveFolder = async ({
  movingData,
  newParentData,
  newParentPath,
  eventNode,
  isPermissionsMatch = true,
}) => {
  try {
    isDragging.value = true;

    const targetFolderId = movingData.id;
    const targetFolderOrigin = movingData.origin;
    const destinationFolderId = newParentData.id;

    if (!isPermissionsMatch) {
      const targetFolder = harbourStore.myFolders.find((f) => f.id === targetFolderId);
      const { collaborators, viewers } = getParentFolderPermissions(destinationFolderId);
      targetFolder.collaborators = [...collaborators];
      targetFolder.viewers = [...viewers];
    }

    let updatedRows = [];
    moveToPath(newParentPath, eventNode, updatedRows);
    foldersMenuStore.agGridApi.applyTransaction({ update: updatedRows });
    foldersMenuStore.agGridApi.clearFocusedCell();

    await filesystemApiService.updateFolderParent({
      targetFolderId,
      destinationFolderId,
      targetFolderOrigin,
    });
  } catch (err) {
    harbourStore.getFolders();

    Toast.open({
      duration: 2500,
      message: `Something went wrong...`,
      position: 'is-top',
      type: 'is-danger',
    });
    console.error(err);
  } finally {
    isDragging.value = false;
  }
};

const onFilterChanged = (event) => {
  if (event.type !== 'filterChanged') return;
  expandNodesOnFiltering();
};

const onFilterChangedDebounced = debounce((event) => {
  onFilterChanged(event);
}, 300);

const expandNodesOnFiltering = () => {
  const filter = sidebarStore.filter?.toLowerCase().trim();
  if (!filter || filter.length < 2) return;

  const folderMatches = myFolders.value.filter((i) => {
    const name = i.name.toLowerCase();
    return name.includes(filter);
  });
  const folderIds = folderMatches.map((i) => i.id);

  folderIds.forEach((id) => {
    expandNodesToTarget(id);
  });
};
</script>

<template>
  <div class="hrbr-sidebar-pane-folders-menu">
    <div class="hrbr-sidebar-pane-folders-menu__folders-grid">
      <AgGridVue
        class="hrbr-sidebar-pane-folders-menu__ag-grid ag-theme-alpine hrbr-ag-font-family"
        style="width: 100%; height: 100%"
        :columnDefs="agColumnDefs"
        :rowData="myFolders"
        :defaultColDef="agDefaultColDef"
        :rowHeight="36"
        :headerHeight="40"
        :gridOptions="agGridOptions"
        :autoGroupColumnDef="agAutoGroupColumnDef"
        :getRowId="getRowId"
        :getDataPath="getDataPath"
        :rowDragText="rowDragText"
        @grid-ready="onGridReady"
        @row-clicked="onRowClicked"
        @row-drag-move="onRowDragMove"
        @row-drag-leave="onRowDragLeave"
        @row-drag-end="onRowDragEnd"
        @filter-changed="onFilterChangedDebounced"
        :isGroupOpenByDefault="isGroupOpenByDefault"
        :quickFilterText="sidebarStore.filter"
        :overlayNoRowsTemplate="overlayNoRowsTemplate">
      </AgGridVue>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar-pane-folders-menu {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  &__folders-grid {
    flex: 1;
    height: 100%;
  }

  &__ag-grid {
    --ag-grid-size: 3px;
    --ag-font-size: 14px;
    --ag-icon-size: 14px;
    --ag-row-height: 36px;
    --ag-row-hover-color: rgb(228 233 236 / 40%);
    --ag-data-color: #666f75;
    --ag-borders: none;
    --ag-header-foreground-color: #666f75;
    --ag-header-background-color: #e4e9ec;

    padding: 0;
    margin: 0;
  }

  :deep(&__no-folders) {
    font-size: 14px;
    color: #666f75;
    text-align: center;
    align-self: flex-start;
    width: 100%;
    margin-top: 20px;
  }

  :deep(.ag-header) {
    display: none;
  }

  :deep(.ag-group-value) {
    flex: 1;
  }

  :deep(.ag-row-odd) {
    background-color: transparent;
  }

  :deep(.ag-row) {
    cursor: pointer;
    border-bottom: 0;

    &--active {
      background: #E2E9FB;

      &.ag-row-hover::before {
        display: none;
      }
    }

    &--not-draggable .ag-row-drag {
      visibility: hidden !important;
      pointer-events: none !important;
      margin-right: 0;
    }

    .hrbr-sidebar-pane-folder__add-folder,
    .hrbr-sidebar-pane-folder__dropdown .dropdown-trigger {
      visibility: hidden;
    }

    &.ag-row-hover {
      .ag-row-drag,
      .hrbr-sidebar-pane-folder__add-folder,
      .hrbr-sidebar-pane-folder__dropdown .dropdown-trigger {
        visibility: visible;
      }
    }
  }

  :deep(.ag-row-animation .ag-row) {
    transition: transform 0.2s, top 0.2s, background-color 0.1s, opacity 0.2s;
  }

  :deep(.ag-cell) {
    padding: 0;
  }

  :deep(.hover-over) {
    background-color: #e4e9ec;
  }

  :deep(.ag-row-drag) {
    visibility: hidden;
  }

  :deep(.ag-icon.ag-icon-grip) {
    --ag-icon-font-family: 'Font Awesome 5 Pro';
    --ag-icon-font-code-grip: '\e411';
  }

  :deep(.ag-body-vertical-scroll) {
    display: none !important;
  }
}
</style>
