<script>
import { useSettingsPageStore } from '@/pages/Settings/stores/settings-store.js';

export default {
  data() {
    return {
      selectedRole: ''
    }
  },
  setup() {
    return {
      settingsStore: useSettingsPageStore()
    };
  },
  computed: {
    currentGroup () {
      const currentGroupId = this.params.getGroupId();
      return this.settingsStore.listUserGroups.find(group => group.group_id === currentGroupId);
    },
    rolesDropdownDisabled() {
      return this.params.isActionsDisabled() || this.currentGroup.is_default;
    }
  },
  mounted() {
    this.selectedRole = this.params.value || 'user';
  },
  methods: {
    onRoleChange(value) {
      this.settingsStore.setEditableGroup(this.currentGroup);
      this.settingsStore.editUserGroup('changeRole', {
        memberEmail: this.params.data.email,
        role: value,
      });
    }
  }
}
</script>

<template>
  <div class="actions">
    <b-dropdown
      v-model="selectedRole"
      :triggers="['click']"
      :disabled="rolesDropdownDisabled"
      class="user-role"
      aria-role="list"
      append-to-body
      @change="val => onRoleChange(val)"
    >
      <template #trigger="{ active }">
        <p
          class="tag"
          role="button"
        >
          {{ selectedRole }}
          <i v-if="active" class="icon fa-solid fa-caret-up"></i>
          <i v-else class="icon fa-solid fa-caret-down"></i>
        </p>
      </template>


      <b-dropdown-item value="admin" aria-role="listitem">Admin</b-dropdown-item>
      <b-dropdown-item value="user" aria-role="listitem">User</b-dropdown-item>
    </b-dropdown>

  </div>
</template>

<style lang="postcss" scoped>
.actions {
  width: 100%;
  display: flex;
  justify-content: center;
}
.icon {
  display: inline-flex;
  width: auto;
  margin-left: 6px !important;
  margin-right: 0 !important;
}
.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 34px;
  padding: 6px 12px;
  cursor: pointer;
  text-transform: capitalize;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #D8D8D8;
  color: #333;
  font-size: 14px;
  font-weight: 600;
}
</style>

