<script>
export default {
  data() {
    return {
      row: null,
    }
  },
  created() {
    this.row = this.params.data;
  }
};
</script>

<template>
  <div class="cell">
    <div class="matches-container">
      <div class="loading-web-matches">
        Finding on web...
      </div>
    </div>
  </div>
</template>

<style scoped>
.cell {
  font-size: 12px;
}
</style>
