<script>
import HrbrDraftsStatus from '../HrbrDraftsStatus.vue';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';

export default {
  name: 'DraftsStatusRenderer',

  components: {
    HrbrDraftsStatus,
  },

  setup() {
    const draftsStore = useDraftsStore();
    return {
      draftsStore,
    };
  },

  data() {
    return {};
  },

  computed: {
    draft() {
      return this.params.data;
    },

    isUserOwner() {
      return !this.draft.isShared;
    },

    canChangeStatus() {
      const isOrgAdmin = this.draftsStore.checkUserOrgAdminPermission(this.draft.orgId);
      return this.isUserOwner || isOrgAdmin;
    },
  },

  methods: {
    async onStatusSelected({ statusId, draftId }) {
      if (!this.canChangeStatus) return;
      await this.draftsStore.changeDraftStatus(draftId, statusId);
    },
  },
};
</script>

<template>
  <div class="draft-status-column">
    <div class="draft-status-column__container">
      <HrbrDraftsStatus 
        :draft-id="draft.id"
        :status="draft.status"
        :disabled="!canChangeStatus"
        @status-selected="onStatusSelected"
      />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.draft-status-column {
  padding: 0 5px;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
</style>
