<script>
import ModalWithIFrame from './ModalWithIFrame.vue';
import { ModalProgrammatic as Modal } from 'buefy';
import { useDashboardStore } from '@/stores/dashboard-store';
import { useHarbourStore } from '@/stores/harbour-store';
import { useLinkObject } from '@/pages/Dashboard/composables/LinkObject'

export default {
  name: 'AwaitingMeTitle',
  setup() {
    return {
      dashboardStore: useDashboardStore(),
      harbourStore: useHarbourStore(),
    };
  },
  data() {
    return {
      emailCode: null,
      linkObject: null,
    }
  },
  created() {
    this.linkObject = useLinkObject(this.params.data);
    const signers = this.linkObject.emailRecipients;
    const me = signers.find((signer) => signer.recipientemail === this.harbourStore.contextDict.systememail);
    this.emailCode = me?.emailconfirmationcode;
  },
  methods: {
    openSignModal() {
      const rowNode = this.dashboardStore.gridApi.getRowNode(this.linkObject.id);
      const url = `${this.linkObject.url}?confirmation=${this.emailCode}`;
      Modal.open({
        parent: this,
        component: ModalWithIFrame,
        hasModalCard: true,
        props: {
          url,
          linkObject: this.linkObject,
          node: rowNode,
        },
        customClass: 'awaiting-my-review-modal'
      });
    },
  },
  computed: {
    isDisabled() {
      return this.linkObject.awaitingMyReviewSigned;
    }
  }
};
</script>

<template>
  <div :class="{ 'template-name-column': !isDisabled, 'template-name-disabled': isDisabled }">
    <div class="template-name-column__title">
      <div class="template-name-column__title-icon">
        <i class="fa-light fa-file-pdf"></i>
      </div>
      <div class="template-name-column__title-text">{{ params.data?.title }}</div>
    </div>
    <div
      v-if="!isDisabled"
      class="template-name-column__start-button"
      title="Create a link using this template"
      @click="openSignModal">
      <div class="template-name-column__start">Sign</div>
    </div>
    <div
      v-else
      class="template-name-column__start-button is-complete"
      title="Link has been completed">
      <div class="template-name-column__start">Signed</div>
    </div>
  </div>
</template>

<style scoped>
.template-name-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background: #ffffff91;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  min-height: 52px;
  padding: 5px 10px;
  cursor: pointer;
}

.template-name-disabled {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background: #ffffff91;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  min-height: 52px;
  padding: 5px 10px;
  cursor: not-allowed;
  pointer-events: none;
}

.template-name-column:hover {
  background:#f5f6f7;
  color:#222222;
}

.template-name-column:hover .template-name-column__start-button {
  border-color: #b5b5b5 !important;
  background-color: #2e6aa1 !important;
}

.template-name-column__title {
  font-weight: 500;
  word-break: normal;
  line-height: 1.4;
  display: flex;
  gap: 5px;
  align-items: flex-start;
  min-width: 0;
}

.template-name-column__title-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.template-name-column__title-icon {
  display: flex;
  padding-top: 2px;
  flex-shrink: 0;
}

.template-name-column__start-button {
  padding: 5px;
  text-align:center;
  border-radius: 4px;
  border: 1px solid #ddd;
  cursor: pointer;
  background-color: #2d71ad;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  width: 75px;
  max-height: 32px;
  flex-shrink: 0;
}

.template-name-column__start {
  font-size: 13px;
  color: white;
  font-weight: 500;
  letter-spacing: .5px;
  opacity: .9;
}

.is-complete {
  background-color: var(--grid-success);
  color: white;
  pointer-events: none !important;
  user-select: none;
}
</style>

<style>

@media (max-width: 600px) {
  .awaiting-my-review-modal .modal-close {
    background-color: black;
    margin-top: 12px;
    right: 4px;
  }
}
</style>
