<script setup>
import HrbrPdfViewer from '@/components/PdfViewer/HrbrPdfViewer.vue';
import { useHarbourStore } from '@/stores/harbour-store.js';
import { useMagicAnnotationsStore } from '@/stores/magic-annotations-store.js';
import HrbrSignatureModal from '@components/Modals/HrbrSignatureModal.vue';
import OnlySignerConsentModal from '@components/Modals/OnlySignerConsentModal.vue';
import HrbrAnnotationNavigationButton from '@components/PdfViewer/HrbrAnnotationNavigationButton.vue';
import HrbrConfirmDialog from '@components/ui/HrbrConfirmDialog.vue';
import { ToastProgrammatic as Toast } from 'buefy';
import { storeToRefs } from 'pinia';
import { computed, onMounted, ref, watch } from 'vue';

const harbourStore = useHarbourStore();
const magicAnnotationsStore = useMagicAnnotationsStore();
const {
  isOnlySignerModalOpened,
  uploadedDocData,
  getUserName,
  signatureNameRef,
  documentPages,
  isLoading,
  pageMode,
  suggestionsLoaded,
  suggestionsList,
  termsAgreed,
  consentModalOpened,
  isFinishConsentModal,
  topNotificationText
} = storeToRefs(magicAnnotationsStore);

const confirmDialog = ref(null);
const isNotificationShown = ref(false);

// Transform it to support current Pdf Viewer
const editorFiles = computed(() => {
  if (!uploadedDocData.value) return [];
  return [
    {
      pdfid: uploadedDocData.value.fileId,
      pdffilename: uploadedDocData.value.fileName,
      pdfagreementname: uploadedDocData.value.fileDocumentName,
      pdfbytes: uploadedDocData.value.fileSizeBytes,
      pdfbase64: uploadedDocData.value.fileBase64,
      pdfinputmethod: uploadedDocData.value.fileInputMethod,
    },
  ];
});
const finishButton = computed(() => ({
  style: { width: '110px' },
  text: 'Finish',
  styleType: 'primary',
  iconRight: 'fa-regular fa-angle-right',
  disabled: !suggestionsLoaded,
}));

watch(topNotificationText, (value) => {
  if (value) {
    isNotificationShown.value = true;
  }
});

onMounted(() => {
  magicAnnotationsStore.getStoredSignatures();
});

const onModalClose = () => {
  confirmDialog.value.show = true;
};

const onFinish = () => {
  if (termsAgreed.value) {
    magicAnnotationsStore.saveDocument();
  } else {
    consentModalOpened.value = true;
    isFinishConsentModal.value = true;
  }
};

const confirmCloseModal = () => {
  isOnlySignerModalOpened.value = false;
  magicAnnotationsStore.clearAgreementData();
};

const transformPdfViewerPages = (pages) => {
  const pdfPages = pages.map((page) => ({
    pdfid: page.pdfId,
    pageid: page.pageId,
    pageoriginalpdfpagenumber: page.pageOriginalNumber,
    pagescalefactor: page.pageScaleFactor,
    pagewidthpx: page.pageWidth,
    pageheightpx: page.pageHeight,
    pageisactive: page.pageIsActive,
  }));
  return pdfPages;
};

const onPagesLoaded = (pages) => {
  documentPages.value = transformPdfViewerPages(pages);
};

const onDocumentError = (error) => {
  const hasPassword = error.name === 'PasswordException';
  if (hasPassword) {
    Toast.open({
      message: 'Provided PDF cannot be processed as it is password-protected. Please re-export without password to continue.',
      type: 'is-danger',
      position: 'is-top',
      duration: 3500,
    });
  } else {
    Toast.open({
      message: 'Something went wrong while loading the document',
      type: 'is-danger',
      position: 'is-top',
      duration: 3500,
    });
  }
};
</script>

<template>
  <b-modal
    :active="isOnlySignerModalOpened"
    :can-cancel="['x', 'outside']"
    trap-focus
    custom-class="only-signer-modal"
    aria-role="dialog"
    aria-label="I'm only signer modal"
    close-button-aria-label="Close"
    aria-modal
    full-screen
    :on-cancel="onModalClose"
  >
    <template #default="props">
      <div
        class="modal-card"
        style="width: auto"
      >
        <b-loading
          :active="isLoading"
          :can-cancel="false"
          animation=""
        />
        <header class="modal-card-head">
          <div class="modal-card-title">
            <div
              class="only-signer-modal__header-title"
            >
              <i
                class="only-signer-modal__header-title-icon fa-light fa-file-contract"
                title="agreement"></i>
              <span class="only-signer-modal__header-title-text">
                {{ unescapeHtmlSymbols(uploadedDocData?.fileDocumentName) || '' }}
              </span>
            </div>

            <div class="only-signer-modal__header-actions">
              <div :class="['only-signer-modal__mode-switch', pageMode, harbourStore.isMobileView ? 'mobile-view' : '']">
                <div
                  :class="['only-signer-modal__mode-switch-btn', pageMode === 'edit' ? 'active' : '']"
                  @click="pageMode = 'edit'"
                >
                  <i class="fa-solid fa-pencil"></i>
                  <span v-if="!harbourStore.isMobileView">Edit</span>
                </div>
                <div
                  :class="['only-signer-modal__mode-switch-btn', pageMode === 'preview' ? 'active' : '']"
                  @click="pageMode = 'preview'"
                >
                  <i class="fa-regular fa-eye"></i>
                  <span v-if="!harbourStore.isMobileView">Preview</span>
                </div>
              </div>
              <HrbrButton @click="onFinish" :button="finishButton"/>
            </div>

            <div
              class="only-signer-modal__close"
              @click="onModalClose"
            >
              <i class="fa-light fa-xmark"></i>
            </div>
          </div>
        </header>
        <section class="modal-card-body">
          <HrbrPdfViewer
            v-if="editorFiles.length"
            :file="uploadedDocData.fileBase64"
            :file-name="uploadedDocData.fileName"
            :file-info="{
              fileId: uploadedDocData.fileId
            }"
            :editor-files="editorFiles"
            :editor-pages="documentPages"
            :output-scale="2"
            :is-text-layer="false"
            mode="edit"
            show-page-actions
            is-only-signer-preview
            @pages-loaded="onPagesLoaded"
            @document-error="onDocumentError"
          />

          <HrbrSignatureModal />

          <!-- Hidden name signature to generate an image -->
          <div class="hidden-signature-name-wrap">
            <div>
              <span ref="signatureNameRef">{{ getUserName }}</span>
            </div>
          </div>

          <HrbrConfirmDialog
            ref="confirmDialog"
            @confirm="confirmCloseModal"
          />

          <OnlySignerConsentModal />

          <HrbrAnnotationNavigationButton v-if="suggestionsList.length > 0" />
        </section>

        <b-notification
          v-model="isNotificationShown"
          icon="sparkles"
          icon-pack="far"
          size="is-medium"
          aria-close-label="Close notification"
          has-icon
          @close="isNotificationShown = false"
        >
          {{ topNotificationText }}
        </b-notification>
      </div>
    </template>
  </b-modal>
</template>
<style lang="postcss" scoped>
.hidden-signature-name-wrap {
  position: relative;
  display: flex;
  z-index: -1;
  color: #333;
  font-family: 'La Belle Aurore';
  font-size: 80px;
  line-height: 1.8;
}

:deep(.modal-close) {
  display: none;
}

.only-signer-modal {
  .modal-card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
  }

  .modal-card-head {
    position: relative;
    padding: 14px 20px;
    background-color: #fff;
  }

  .modal-card-body {
    overflow: hidden;
    -webkit-overflow-scrolling: auto !important;
  }

  &__close {
    color: #4a4a4a;
    cursor: pointer;
  }

  &__header-title {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-right: auto;
  }

  &__header-title-icon {
    font-size: 20px;
    color: #1355FF;
  }

  &__header-title-text {
    font-weight: 500;
    font-size: 20px;
    color: #4a4a4a;
  }

  &__header-actions {
    display: flex;
    align-items: center;
  }

  &__header-preview {
    padding: 10px 20px;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;

    &:hover {
      opacity: .6;
    }
  }

  &__header-finish {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 22px 12px 25px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    background: #1355FF;
    transition: .2s ease-in;

    &:hover {
      background: #0133B7;
    }
    &:disabled {
      background-color: #BCCFFF;
    }
  }

  &__mode-switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 4px;
    margin-right: 16px;
    background-color: #F5F5F5;
    border-radius: 8px;

    &.mobile-view {
      .only-signer-modal__mode-switch-btn {
        width: 40px;
      }
    }

    &.edit {
      &:before {
        left: 4px;
      }
    }

    &.preview {
      &:before {
        left: calc(50% + 4px);
      }
    }

    &:before {
      content: '';
      position: absolute;
      width: calc(50% - 8px);
      height: calc(100% - 8px);
      border-radius: 8px;
      background: #fff;
      transition: all 0.3s;
      z-index: 0;
      box-shadow: 0 1px 2px 0 #00000040;
    }
  }

  &__mode-switch-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 11px;
    width: 120px;
    font-size: 14px;
    font-weight: 400;
    color: #555;
    transition: all 0.3s;
    z-index: 1;
    cursor: pointer;

    &.active {
      font-weight: 600;
      color: #333;
    }
  }

  .notification {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    max-width: calc(100% - 10px);
    position: fixed;
    left: 50%;
    top: 30px;
    transform: translateX(-50%);
    padding: 6px 24px;
    color: #fff;
    background-image: linear-gradient(90deg, #7715B3 0%, #33094D 100%);
    box-shadow: 0 4px 12px 0 #00000026;
    border-radius: 8px;

    :deep(.delete) {
      position: sticky;
      order: 2;
      right: unset;
      top: unset;
      background-color: transparent;
      margin-left: 10px;
      width: 30px;
      height: 30px;
      max-width: 30px;
      max-height: 30px;
    }

    :deep(.icon) {
      width: 20px;
      height: 20px;
    }

    :deep(.media) {
      align-items: center;
      font-size: 16px;
      font-weight: 400;

      &-content {
        text-align: center;
      }

      &-left {
        margin-right: 10px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    .modal-card-head {
      padding: 14px;
    }
    .modal-card-body {
      padding: 14px;
    }
  }
}
</style>
