<script setup>
// Serves as a wrapper to add various elements (like overlays, modals, notifications),
// so as not to pollute the main app component.

import { onMounted, onUnmounted, getCurrentInstance } from 'vue';
import HrbrSearch from './Search/HrbrSearch.vue';
import { useSearchStore } from './Search/stores/search-store';
import { useSidebarStore } from './Sidebar/stores/sidebar-store';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import HrbrAgreementEditorOnlySignerModal from '@components/AgreementEditor/HrbrAgreementEditorOnlySignerModal.vue';
import OnlySignerFinalModal from '@/components/Modals/OnlySignerFinalModal.vue';
import HrbrReloginModal from '@/components/Modals/HrbrReloginModal.vue';
import { subscribeTo } from '@/utils/bus';
import { ModalProgrammatic as Modal } from 'buefy';

const instance = getCurrentInstance();

const sidebarStore = useSidebarStore();
const searchStore = useSearchStore();
const draftsStore = useDraftsStore();

const openReloginModal = () => {
  Modal.open({
    parent: instance.proxy,
    component: HrbrReloginModal,
    hasModalCard: true,
    customClass: 'hrbr-relogin-modal-wrapper',
  });
};

const subscribeToEvents = () => {
  subscribeTo('drafts:created', (draft) => {
    draftsStore.onDraftCreatedEvent(draft);
  });
  subscribeTo('drafts:share-settings-updated', (draftId) => {
    draftsStore.onShareSettingsUpdatedEvent(draftId);
  });
  subscribeTo('drafts:update-time-updated', (draftId) => {
    draftsStore.onShareSettingsUpdatedEvent(draftId);
  });
  subscribeTo('access-expired', () => {
    openReloginModal();
  });
};

onMounted(() => {
  subscribeToEvents();
  searchStore.registerShortcutListener();
});

onUnmounted(() => {
  searchStore.unregisterShortcutListener();
});
</script>

<template>
  <div>
    <HrbrSearch v-if="searchStore.isActive" />

    <Transition>
      <div
        class="hrbr-sidebar-backdrop"
        v-if="sidebarStore.isSidebarOpen"
        @click="sidebarStore.setSidebarOpen(false)"></div>
    </Transition>

    <HrbrAgreementEditorOnlySignerModal />
    <OnlySignerFinalModal />

  </div>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  transition: opacity 0.5s;
  z-index: 15;
  display: none;

  &.v-leave-active {
    transition-duration: 0.3s;
  }
}

@media (max-width: 992px) {
  .hrbr-sidebar-backdrop {
    display: block;
  }
}
</style>

<style lang="postcss">
.hrbr-relogin-modal-wrapper {
  z-index: 45;
}
</style>
