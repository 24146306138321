<script>
import harbourLogoSvg from '@/assets/icons/harbour-logo-grey.svg?raw';
import { useHarbourStore } from '@/stores/harbour-store';
import { reactive } from 'vue';

const EDIT_PERMISSION = 'permissions.filesystem.folder.edit';
const CREATED_BY_ME = 'CREATEDBYME';
const SHARED_WITH_ME = 'SHAREDWITHME';

export default {
  setup() {
    const harbourStore = useHarbourStore();
    return {
      harbourStore,
    };
  },

  data() {
    return {
      folder: {},
      newFolderName: '',
      isEditFolderName: false,
      harbourLogoSvg,
    };
  },

  computed: {
    folderName() {
      return this.folder?.name || '';
    },

    sharedByName() {
      return this.folder.creatorName || '';
    },

    sharedByEmail() {
      return this.folder.creatorEmail || '';
    },

    isCreatedByMe() {
      return this.folder.origin === CREATED_BY_ME;
    },

    isSharedWithMe() {
      return this.folder.origin === SHARED_WITH_ME;
    },

    isHomeFolder() {
      return this.folder.id === '#home';
    },

    isSharedRootFolder() {
      return this.folder.id === '#shared';
    },

    isSharedByMe() {
      const systemEmail = this.harbourStore.contextDict?.systememail;
      const exclude = (email) => email !== systemEmail;
      const viewers = this.folder.viewers.filter(exclude);
      const collaborators = this.folder.collaborators.filter(exclude);
      const isSharedWith = [...viewers, ...collaborators].length > 0;
      return this.isCreatedByMe && isSharedWith;
    },

    isEditPermission() {
      const permissions = this.harbourStore.contextDict?.auth_permissions ?? [];
      return permissions.includes(EDIT_PERMISSION);
    },

    isOrgAdmin() {
      const roles = this.harbourStore.contextDict?.auth_roles || [];
      return roles.includes('orgAdmin');
    },

    isEditSharedPermission() {
      const systemEmail = this.harbourStore.contextDict?.systememail;
      const collaborators = this.folder.collaborators;

      const isCollaborator = collaborators.includes(systemEmail);
      const canEdit = this.isOrgAdmin || isCollaborator;

      if (this.isSharedWithMe && !canEdit) return false;
      return true;
    },

    showAddFolder() {
      const folderId = this.folder.id;
      if (folderId === '#home') return false;
      if (folderId === '#shared') return false;
      if (!this.isEditPermission) return false;
      if (!this.isEditSharedPermission) return false;
      return true;
    },

    showDropdownAction() {
      const folderId = this.folder.id;
      if (folderId === '#home') return false;
      if (folderId === '#shared') return false;
      if (!this.isEditPermission) return false;
      if (!this.isEditSharedPermission) return false;
      return true;
    },

    showDropdownDownloadAction() {
      const folderId = this.folder.id;
      if (folderId === '#home') return false;
      if (folderId === '#shared') return false;
      return true;
    },

    showDropdown() {
      const folderId = this.folder.id;
      if (folderId === '#home') return false;
      if (folderId === '#shared') return false;
      if (!this.someDropdownActionAvailable) return false;
      return true;
    },

    someDropdownActionAvailable() {
      return this.showDropdownAction || this.showDropdownDownloadAction;
    },

    profileImageUrl() {
      const creatorEmail = this.folder.creatorEmail;
      const profilePictures = this.harbourStore.profilePictures;
      const profile = profilePictures.find((item) => item.email[0] === creatorEmail);
      if (!profile) return null;
      return profile.profileImageUrl;
    },

    profileInitials() {
      const creatorName = this.folder.creatorName;
      const creatorEmail = this.folder.creatorEmail;
      if (creatorName) return creatorName[0].toUpperCase();
      else if (creatorEmail) return creatorEmail[0].toUpperCase();
      else return '*';
    },
  },

  methods: {
    setFolder(folderData) {
      this.folder = reactive(folderData);
    },

    handleAddFolderClick() {
      this.params.addFolderClicked(this.folder);
    },

    handleEditFolderClick() {
      const { nameInput } = this.$refs;
      this.isEditFolderName = true;
      this.newFolderName = this.folder.name;
      this.$nextTick(() => nameInput.focus());
    },

    handleDuplicateFolderClick() {
      this.params.duplicateFolderClicked(this.folder);
    },
  
    handleShareFolderClick() {
      this.params.shareFolderClicked(this.folder);
    },

    handleDownloadFolderClick(type) {
      this.params.downloadFolderClicked(this.folder, type);
    },

    handleDeleteFolderClick() {
      this.params.deleteFolderClicked(this.folder);
    },

    onNameInputBlur() {
      const { newFolderName } = this;
      this.isEditFolderName = false;
      this.newFolderName = '';
      this.params.editFolderBlured(this.folder, newFolderName);
    },
  },

  created() {
    this.setFolder(this.params.data);
  },
};
</script>

<template>
  <div class="hrbr-sidebar-pane-folder">
    <i class="fa-regular fa-folder-closed"></i>
    <div class="hrbr-sidebar-pane-folder__icon">
      <i v-if="isHomeFolder" class="fa-duotone fa-folders"></i>
      <i v-else-if="isSharedRootFolder" class="hrbr-sidebar-pane-folder__icon-shared-root fa-regular fa-user-group"></i>
      <i v-else-if="isSharedWithMe || isSharedByMe" class="fa-light fa-folder-user"></i>
      <i v-else class="fa-regular fa-folder"/>
    </div>
    
    <div class="hrbr-sidebar-pane-folder__name" :title="folderName">
      <div class="hrbr-sidebar-pane-folder__name-text"
        :style="{ 'font-style': this.harbourStore.myFolders.find(folder => folder.id === this.folder.id)?.isCopying ? 'italic' : 'normal', 'font-weight': 400 }"
        v-show="!isEditFolderName">
        <b-tooltip v-if="this.harbourStore.myFolders.find(folder => folder.id === this.folder.id)?.isCopying" label="Copying folder... This might take a while."
            position="is-right"
            type="is-dark"
            append-to-body>
            Copying...
        </b-tooltip>
        <span v-else>{{ folderName }}</span>
      </div>
      <input
        class="hrbr-sidebar-pane-folder__name-input"
        v-model="newFolderName"
        v-show="isEditFolderName"
        ref="nameInput"
        type="text"
        @blur="onNameInputBlur" />
    </div>

    <div class="hrbr-sidebar-pane-folder__actions" data-folder-actions>
      <div class="hrbr-sidebar-pane-folder__shared-by" v-if="isSharedWithMe">
        <b-tooltip
          class="hrbr-sidebar-pane-folder__shared-by-tooltip"
          :append-to-body="true"
          type="is-white"
          position="is-bottom"
          multilined>
          <div class="hrbr-sidebar-pane-folder__shared-by-profile">
            <img
              class="hrbr-sidebar-pane-folder__shared-by-profile-img"
              v-if="profileImageUrl"
              :src="profileImageUrl"
              alt="" />
            <div class="hrbr-sidebar-pane-folder__shared-by-profile-initials" v-else>
              {{ profileInitials }}
            </div>
          </div>

          <template v-slot:content>
            <div class="hrbr-sidebar-pane-folder__shared-by-content">
              <div
                class="hrbr-sidebar-pane-folder__shared-by-name"
                :title="`Shared by ${sharedByName}`">
                Shared by {{ sharedByName }}
              </div>
              <div class="hrbr-sidebar-pane-folder__shared-by-email" :title="sharedByEmail">
                {{ sharedByEmail }}
              </div>
            </div>
          </template>
        </b-tooltip>
      </div>

      <div
        class="hrbr-sidebar-pane-folder__add-folder"
        v-if="showAddFolder"
        @click="handleAddFolderClick">
        <i class="fa-regular fa-plus"></i>
      </div>

      <b-dropdown
        class="hrbr-sidebar-pane-folder__dropdown"
        v-if="showDropdown"
        :append-to-body="true"
        :mobile-modal="false"
        position="is-bottom-left">
        <template #trigger>
          <i class="fa-regular fa-ellipsis-vertical"></i>
        </template>
        <template #default>
          <b-dropdown-item v-if="showDropdownAction" @click="handleEditFolderClick">
            <i class="fal fa-pen"></i>
            <span>Rename</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="showDropdownAction" @click="handleDuplicateFolderClick">
            <i class="fal fa-copy"></i>
            <span>Duplicate folder</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="showDropdownAction" @click="handleShareFolderClick">
            <i class="fa-light fa-share-nodes"></i>
            <span>Share</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="showDropdownDownloadAction"
            @click="handleDownloadFolderClick('zip')">
            <i class="fa-light fa-folder-arrow-down"></i>
            <span>Download files (ZIP)</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="showDropdownDownloadAction"
            @click="handleDownloadFolderClick('csv')">
            <i class="fa-light fa-file-arrow-down"></i>
            <span>Download spreadsheet (CSV)</span>
          </b-dropdown-item>
          <b-dropdown-item
            class="delete-folder"
            v-if="showDropdownAction"
            @click="handleDeleteFolderClick">
            <i class="fal fa-trash-can"></i>
            <span>Delete</span>
          </b-dropdown-item>
        </template>
      </b-dropdown>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar-pane-folder {
  display: flex;
  align-items: center;
  width: 100%;

  * {
    color: #333;
  }

  &__icon {
    display: flex;
    flex-shrink: 0;
    margin-right: 12px;
  }

  &__icon-home {
    width: 15px;
    height: 15px;
    position: relative;
  }

  &__icon-shared-root {
    margin-right: 2px;
  }

  &__name {
    flex: 1;
    overflow: hidden;
  }

  &__name-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__name-input {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    display: block;
    width: 100%;
    height: 100%;
    min-height: 24px;
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    box-shadow: none;
    appearance: none;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
    line-height: normal;
  }

  &__add-folder {
    font-size: 13px;
    cursor: pointer;
    transition: color 0.15s;

    &:hover {
      color: var(--hrbr-primary-color-active);
    }
  }

  &__shared-by {
    position: relative;

    &:hover {
      .hrbr-sidebar-pane-folder__shared-by-icon {
        color: var(--hrbr-primary-color-active);
      }
    }
  }

  &__shared-by-profile {
    --profile-size: 20px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--profile-size);
    height: var(--profile-size);
    background-color: #2f5a8899;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 2px;
  }

  &__shared-by-profile-img {
    --avatar-size: 20px;

    width: var(--avatar-size);
    height: var(--avatar-size);
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    pointer-events: none;
    user-select: none;
  }

  &__shared-by-profile-initials {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__dropdown {
    :deep(.dropdown-trigger) {
      font-size: 13px;
      cursor: pointer;
      padding: 0 4px;
      transition: color 0.15s;

      &:hover {
        color: var(--hrbr-primary-color-active);
      }
    }

    :deep(.background) {
      display: none !important;
    }

    &.is-active :deep(.dropdown-trigger) {
      visibility: visible !important;
    }
  }

  &__shared-by {
    font-size: 12px;
    cursor: default;
    transition: color 0.15s;

    &:hover {
      color: var(--hrbr-primary-color-active);
    }
  }
}
</style>

<style lang="postcss">
.hrbr-sidebar-pane-folder {
  &__dropdown {
    .dropdown-item {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 6px;

      i {
        display: inline-flex;
        justify-content: center;
        width: 20px;
      }
    }
  }

  &__shared-by-tooltip {
    .tooltip-content {
      padding: 16px;
    }
  }

  &__shared-by-content {
    font-size: 15px;
    color: #2b3134;
    line-height: 1.2;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 0;
  }

  &__shared-by-name {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__shared-by-email {
    font-size: 13px;
    color: #667079;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :deep(.ag-group-expanded) {
    margin-right: 0;
  }
}
</style>
