<script>
export default {
  name: 'ApiKeysApiKeyRenderer',
  setup() { },
  data() {
    return {
      field: null,
      apiKeyObject: null,
      apiKeyOriginal: null,
      apiKeyHidden: null,
      apiKey: null,
      isHidden: true,
    };
  },
  created() {
    this.field = this.params.colDef.field;
    this.apiKeyObject = this.params.data;
    this.apiKeyOriginal = this.apiKeyObject?.id;
    this.apiKeyHidden = '*'.repeat(this.apiKeyOriginal?.length - 5) + this.apiKeyOriginal?.slice(-5);
    this.apiKey = this.apiKeyHidden;
  },
  methods: {
    showApiKey() {
      if (this.isHidden) {
        this.apiKey = this.apiKeyOriginal;
        this.isHidden = false;
      } else {
        this.apiKey = this.apiKeyHidden;
        this.isHidden = true;
      }
    },
  },
  computed: {},
};
</script>

<template>
  <div class="container">
    <span class="api-key">{{ apiKey }}</span>
    <button @click="showApiKey()">
      <i class="fad fa-eye"></i>
    </button>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  align-items: center;
  white-space: nowrap !important;
}

.api-key {
  font-variant-numeric: tabular-nums;
}

.container button {
  border: none;
  background: white;
  color: #49525c;
}

.container button:hover {
  color: #1a4c8b;
}
</style>
