<script>
import { reactive } from 'vue';
import { useHarbourStore } from '@/stores/harbour-store';
import { useDashboardStore } from '@/stores/dashboard-store';
import SignerRow from './signers-renderers/SignerRow.vue';
import { toTitleCase } from '@/utils/helpers/functions';
import { useLinkObject } from '@/pages/Dashboard/composables/LinkObject'

export default {
  name: 'SignersRenderer',
  components: { SignerRow },
  setup() {
    return {
      harbourStore: useHarbourStore(),
      dashboardStore: useDashboardStore(),
    };
  },
  data() {
    return {
      field: null,
      linkObject: null,
      isActive: true,
      mode: null,
      signer1: null,
      signer2: null,
      liveSessions: [],
      isRendererReady: false,
    };
  },
  mounted() {
    this.field = this.params.colDef.field;
    this.linkObject = useLinkObject(this.params.data);
    !!this.linkObject && this.linkObject.update();
    this.mode = this.linkObject.authMethod?.authmode;
    this.init();
  },
  beforeUnmount() {
    this.linkObject = null;
  },
  methods: {
    init() {
      this.isRendererReady = true;
    },
    // Get the signers from an email based link
    getSignersFromEmails() {
      const signers = this.linkObject.emailRecipients;

      const activeSigners = signers.filter((s) => !s.reassigned);
      return activeSigners.map((signer) => {
        return {
          name: signer.fullname,
          email: signer.recipientemail,
        };
      });
    },

    // Get the signers from a public link
    getSignersFromPublic() {
      const submissions = this.linkObject.submissions;
      return submissions.map((submission) => {
        return {
          email: submission.signer_email,
        };
      });
    },

    // Before we have a signer's name, we can use their location to identify them
    getLocation(session) {
      const location = session?.location_data;
      const city = location?.city_name ? location.city_name : '';
      const country = location?.country_name ? location.country_name : '';
      const locationText = city || country;
      if (locationText) return `${toTitleCase(locationText)}`;
      return 'Anonymous';
    },

    // Get the name of a signer in an email based link
    getNameFromEmailBasedLink(email) {
      const userEmail = email;
      if (userEmail) {
        const signers = this.linkObject.emailRecipients;
        const signerInLink = signers.find((signer) => signer.recipientemail === userEmail);
        if (signerInLink) return signerInLink.fullname;
      }
      return null;
    },
  },

  computed: {
    // Map the signers to the correct format for live sessions
    // If it is an email-based link we have more information ahead of time so we use it (name, email)
    getSignerFromLiveSession() {
      const sessions = this.getLiveSessions;
      return sessions.map((session) => {
        const auditTrail = session?.audit_trail_data_json;
        const auditTrailLength = auditTrail?.length;

        let email = null;
        let name = null;
        if (this.mode === 'EMAILS') {
          const linkLoadedEvent = auditTrail.find(
            (item) => item.item_id === '#AGREELINKAUTHENTICATEDANDCONTENTLOADED',
          );
          if (linkLoadedEvent) {
            email = linkLoadedEvent.item_details?.email;
            name = this.getNameFromEmailBasedLink(email);
          }
        }

        if (!email)
          email = auditTrail.find((item) => item.item_id === '#USEREMAILCHANGE')?.item_value || '';

        const itemDetails = auditTrail[auditTrailLength - 1]?.item_details;
        const lastItem = itemDetails?.itemdisplaylabel || itemDetails?.itemcheckboxlabel;
        const signer = {
          lastItem,
          email,
          name,
          session,
          isLive: true,
          location: this.getLocation(session),
        };
        return signer;
      });
    },

    // Get a list of completed signers depending on the link type
    getCompletedSigners() {
      return this.mode === 'EMAILS' ? this.getSignersFromEmails() : this.getSignersFromPublic();
    },

    getLiveSessions() {
      const liveSessions = this.dashboardStore.liveSessions;
      const sessions = liveSessions.filter((session) => {
        return session.link_id === this.linkObject.id
      }) || [];
      return sessions
        .filter((session) => session.audit_trail_data_json.length > 0)
        .filter((session) => {
          return session.last_updated > new Date().getTime() - 1000 * 5; // 5 minutes
        })
        .sort((a, b) => b.last_updated - a.last_updated);
    }
  },
};
</script>

<template>
  <div class="signers-container" v-if="isRendererReady" style="line-height: 1em">
    <!-- two+ live sessions -->
    <div class="signers-area" v-if="getLiveSessions?.length >= 2">
      <SignerRow
        class="signer"
        :class="{'signer': getSignerFromLiveSession[1]}"
        :signer="getSignerFromLiveSession[0]"
        :link-object="linkObject"
        v-if="getSignerFromLiveSession[0]" />
      <SignerRow
        :signer="getSignerFromLiveSession[1]"
        :link-object="linkObject"
        v-if="getSignerFromLiveSession[1]" />
    </div>

    <!-- one live session only -->
    <div class="signers-area" v-else-if="getLiveSessions?.length == 1">
      <SignerRow
        :class="{'signer': getCompletedSigners[0]}"
        :signer="getSignerFromLiveSession[0]"
        :link-object="linkObject"
        v-if="getSignerFromLiveSession[0]" />
      <SignerRow
        :signer="getCompletedSigners[0]"
        :link-object="linkObject"
        v-if="getCompletedSigners[0]" />
    </div>

    <!-- no live sessions -->
    <div class="signers-area" v-else-if="linkObject.submissions.length > 0 || mode === 'EMAILS'">
      <SignerRow
        class="no-live-session"
        :class="{'signer': getCompletedSigners[1]}"
        :signer="getCompletedSigners[0]"
        :link-object="linkObject"
        v-if="getCompletedSigners[0]" />
      <SignerRow
        :signer="getCompletedSigners[1]"
        :link-object="linkObject"
        v-if="getCompletedSigners[1]" />
    </div>

    <!-- no signers at all (public links) -->
    <div v-else class="awaiting-signers">Awaiting signers</div>

    <div
      class="bottom-bar"
      v-if="getSignersFromEmails?.length > 2 || getLiveSessions?.length > 2 || linkObject.submissions.length > 2"
      @click.stop="dashboardStore.expandDetails(linkObject.id)">
      <span>View more</span>
    </div>
  </div>
</template>

<style scoped>
.signers-container {
  height: 110px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0 10px;
}
.signer {
  border-bottom: 1px solid #aaaaaa33;
}

.no-live-session {
  padding-bottom: 5px;
}
.signers-area .signer:first-child {
  margin-bottom: 3px;
}
.bottom-bar {
  height: 10px;
  line-height: 1em;
  font-size: 10px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  display: inline-block;
  bottom: 2px;
  color: #999;
  width: 100%;
  padding: 0 3px;
  transition: all 250ms ease;
}
.bottom-bar:hover {
  color: black;
}
.awaiting-signers {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #9a9a9a;
}
</style>
